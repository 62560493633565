import { defineStyleConfig } from '@chakra-ui/react';

//TODO: restructure everything

const Progress = defineStyleConfig({
    baseStyle: {
        track: {
            bg: 'gray.100',
            height: '4px',
            borderRadius: 'full',
        },
        filledTrack: {
            bg: 'red',
            height: '4px',
            borderRadius: 'full',
        },
    },
    sizes: {
        lg: {
            track: {
                height: '8px',
            },
            filledTrack: {
                height: '8px',
            },
            
        },
        md: {
            track: {
                height: '4px',
            },
            filledTrack: {
                height: '4px',
            },
        },
        sm: {
            track: {
                height: '2px',
            },
            filledTrack: {
                height: '100%',
            },
        },
    },
    variants: {
        primary: {
            filledTrack: {
                bg: 'primary.500',
            },
        },
        secondary: {
            filledTrack: {
                bg: 'secondary.100',
            },
        },
    },
    defaultProps: {
        size: 'md',
        variant: 'secondary',
    },
});

export default Progress;
