import PropTypes from 'prop-types';
import { VStack, Box, Text } from '@chakra-ui/react';

export const StepInfo = (props) => {
  return (
    <VStack
        spacing={0}
        align={`start`}
        ps={10}
        pe={16}
    >
        <Box>
            <Text
                color={`primary.700`}
                fontWeight={`semibold`}
            >
                Step {props.currentStep}
            </Text>
        </Box>
        <Box mt={2} mb={0.5}>
            <Text
                color={`primary.800`}
                fontSize={`2xl`}
                fontWeight={`bold`}
            >
                {props.title}
            </Text>
        </Box>
        <Box>
            <Text>
                {props.desc}
            </Text>
        </Box>
    </VStack>
  )
}

StepInfo.propTypes = {
    currentStep: PropTypes.number.isRequired,
    title: PropTypes.string.isRequired,
    desc: PropTypes.string.isRequired,
}
