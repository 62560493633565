import { useQuery } from "@tanstack/react-query"
import axios from "axios" 

function fetchBankName(bank) {
    return axios.get(`https://apiliveservice.myeasygas.com/api/Onboarding/verifybankname?request=${bank}`)
}

export const useBankAccVerify = (bank, accNoLen) => {
    return useQuery({
        queryKey: ['bank account name'],
        queryFn: () => fetchBankName(bank),
        options: {
            enabled: accNoLen,
            refetchInterval: false,
            refetchIntervalInBackground: true,
            refetchOnWindowFocus: false,
            // onSuccess,
            // onError,
            // select: (data) => {
            //     const supes = data?.data.map((hero) => hero.name)
            //     // console.log(supes)
            //     return supes
            // }
        }
})
}

export const verifyOtp = () => {
    return axios.patch('')
}

export const onboardVendor = (encodedString) => {
    return axios.post(`https://apiliveservice.myeasygas.com/api/Onboarding/vendor?request=${encodedString}`)
}
