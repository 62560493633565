import { Box, Flex, Grid, GridItem, VStack, Text, HStack } from '@chakra-ui/react'
import React from 'react'
import { BarChart } from '../../../shared/BarChart'

export const Revenue = () => {

  const day = [
    {
      day: "Today",
      area: "today"
    },
    {
      day: "Yesterday",
      area: "yesterday"
    },
    {
      day: "Last 7 Days",
      area: "7days"
    },
    {
      day: "Last 30 Days",
      area: "30days"
    },
    {
      day: "Last 3 Months",
      area: "3months"
    },
    {
      day: "Last 6 Months",
      area: "6months"
    },
  ]


  return (
    <Box
      p={4} 
      pb={2}
      h={'100%'}
    >
      <Grid
        templateAreas={`"Revenue Report"`}
        gridTemplateColumns={"1fr 2fr"}
        h={`100%`}
      >
        <GridItem area={"Revenue"}>
          <VStack
            h={`100%`}
            textAlign={`start`}
            alignItems={`start`}
          >
            <Flex
              alignItems={`center`}
            >
              <Text
                fontWeight={`semibold`}
                fontSize={`lg`}
                pe={2}
              >
                Revenue
              </Text>
              <Text
                fontSize={`xs`}
                color={`primary.200`}
              >
                Today
              </Text>
            </Flex>
            <Flex
              h={`30%`}
              fontSize={`3.5xl`}
              fontWeight={`semibold`}
              alignItems={`center`}
            >
              {/* $100,000.00c */}
              0
            </Flex>
            <Text
              h={`5%`}
              fontSize={`xs`}
            >
              No Comparison data
            </Text>
            <Flex
              h={`50%`}
            >
              <Box
              p={2}
              border={`2px solid`}
              borderColor={`primary.900`}
              borderRadius={5}
              my={`auto`}
              w={`100%`}
            >
              <Grid
                templateAreas={`"today yesterday 7days"
                                "30days" "3months" "6months"`}
                gridTemplateColumns={"1fr 1fr 1fr"}
                gridTemplateRows={"1fr 1fr"}
                columnGap={2}
                rowGap={4}
              >
                {day.map((data, i) => {
                  return <GridItem
                    key={i}
                    borderRadius={`full`}
                    bg={`primary.600`}
                    fontSize={`8px`}
                    px={2}
                    py={.5}
                    textAlign={`center`}
                    justifyContent={`center`}
                  >
                    {data.day}
                  </GridItem>
                })}
              </Grid>
            </Box>
            </Flex>
          </VStack>
        </GridItem>
        <GridItem
          area={"Report"}
          h={'100%'}
        >
            <Text
              mb={3}
              fontWeight={`semibold`}
              fontSize={`base`}
            >
              Report Analysis
            </Text>
          <Box
            maxH={`85%`}
            h={`85%`}
          >
            <BarChart /> 
          </Box>
        </GridItem>
      </Grid>
    </Box>
  )
}
