import { Box, Button, ButtonGroup, Flex, Grid, GridItem, HStack, Icon, Modal, ModalBody, ModalContent, ModalHeader, ModalOverlay, Stack, Text, Textarea, useDisclosure } from "@chakra-ui/react"
import { CheckButton, EmeryAva } from "../../../../Assets";
import AuthContext from "../../Contexts/AdminContext";
import { useContext, useEffect, useState } from "react";



const AdminProductRequest = () => {

  // const { isOpen, onOpen, onClose } = useDisclosure();
  const { isOpen: isSecondModalOpen, onOpen: onSecondModalOpen, onClose: onSecondModalClose } = useDisclosure();
  const { isOpen: isThirdModalOpen, onOpen: onThirdModalOpen, onClose: onThirdModalClose } = useDisclosure();
  const { adminProductData, isLoadingProduct,isErrorProduct,handleSubmitProductId,handleRejectProductId,} = useContext(AuthContext);
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [rejectReason, setRejectReason] = useState('');

//   useEffect(() => {
//     console.log('select',selectedUserId)

// }, [selectedUserId])


  const rejectData = {
    productId : selectedUserId,
    reason: rejectReason
  };

    const TruncatedText = ({ text, limit }) => {
      const truncatedText = text.length > limit ? `${text.substring(0, limit)}...` : text;
    
      return <Text>{truncatedText}</Text>;
    };

    if (isLoadingProduct) {
      return <div>Loading....</div>
    }
  
    if (isErrorProduct) { 
      return <div>Error Fetching Data</div>
    }

    function formatPrice(price) {
      const numericPrice = parseFloat(price.replace(/,/g, '')); // Remove existing commas if any
      if (!isNaN(numericPrice)) {
        const formattedPrice = numericPrice.toLocaleString('en-US'); // Format as a number with commas
        return `₦${formattedPrice}`; // Add Naira symbol in front of the formatted price
      }
      return price;
    }

  return (
    <Box>
      <Grid templateColumns='repeat(3, 1fr)' gap={3} gridRowGap={5}>
        {
          adminProductData.map((data) => (
            <GridItem key={data.ProductID} p={2} w='100%' h='20rem'  borderRadius={'lg'} border={'1px solid #CECDCD'} position={'relative'} >
              <Text color={'font.100'} fontSize={'sm'} pb={2} >
                Product Details
              </Text>
              
              <Flex alignItems={'center'} justifyContent={'space-between'}>
                <Stack>
                  <Text color={'font.600'} fontSize={'xs'} fontWeight={'semibold'}>Product Name:</Text>
                  <Text color={'primary.300'} fontSize={'sm'} > {data.ProductName} </Text>
                </Stack>
                <Stack>
                  <Text color={'font.600'} fontSize={'xs'} fontWeight={'semibold'} >Quantity:</Text>
                  <Text color={'primary.300'} fontSize={'sm'} >{data.Quantity} </Text>
                </Stack>
              </Flex>            
              
              <Box pt={2}>
                <Text color={'font.600'} fontSize={'xs'} fontWeight={'semibold'} >Description:</Text>
                <Box 
                  color={'primary.300'} 
                  fontSize={'sm'}  
                  wordBreak="break-word"  
                >
                  <TruncatedText text={data.ProductDescription} limit={200} />
                  
                </Box>                
              </Box>
              <Flex mt={'25px'}>
                <Stack>
                  <Text color={'font.600'} fontSize={'xs'} >Price:</Text>
                  <Text color={'primary.100'} fontSize={'xl'} fontWeight={'semibold'} >
                    {formatPrice(data.Price)} 
                  </Text>
                </Stack>               
              </Flex>
              <ButtonGroup position={"absolute"} bottom={'10px'}  gap='1'  mt={'25px'} >
                <Button 
                  
                  bg="transparent" 
                  color="secondary.300" 
                  p="20px 26px" 
                  h="48px" 
                  width="50%" 
                  _focus={{ boxShadow: "none" }} 
                  _hover={{bg: 'none',
                  color: 'none',}}
                  onClick={() => {
                    console.log('pro', data)
                    setSelectedUserId(data.ProductID);// Set the selected user ID here
                    onThirdModalOpen(); // Open the rejection modal
                  }}                
                >
                  Decline
                </Button>
                <Button 
                  bg="primary.100" 
                  color="#fff" 
                  p="20px 56px" 
                  h="48px" 
                  width="60%" 
                  _focus={{ boxShadow: "none" }} 
                  _hover={{bg: 'primary.100',
                  color: 'none',}}
                  onClick={() => {
                    // console.log('pro', data)
                    setSelectedUserId(data.ProductID); // Set the selected user ID here
                    onSecondModalOpen(); 
                  }}
                >
                  Accept
                </Button>
              </ButtonGroup>
            </GridItem>
          ))
        }
        
      </Grid>

        {/* Validated MODAL */}

      <Modal isOpen={isSecondModalOpen} onClose={onSecondModalClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalBody>
            <Box textAlign={'center'} py={'20px'} >
              <Icon  boxSize='50px'   viewBox="0 0 50 50" >
                <CheckButton/>
              </Icon> 
              <Text color={'border.500'} fontSize={'base'} fontWeight={'700'} >
                Accept Product
              </Text>
              <Text color={'font.200'} fontSize={'xs'} >
                Are you sure you want to approve this product?
              </Text>
            </Box>

            <ButtonGroup gap='10' my={'10px'} >
              <Button 
                
                bg="transparent" 
                color="font.100" 
                p="20px 36px" 
                h="48px" 
                width="50%" 
                _focus={{ boxShadow: "none" }} 
                _hover={{bg: 'none',
                color: 'none',}}
                onClick={onSecondModalClose}
              >
                Cancel
              </Button>
              <Button 
                bg="primary.100" 
                color="#fff" 
                p="20px 56px" 
                h="48px" 
                width="80%" 
                _focus={{ boxShadow: "none" }} 
                _hover={{bg: 'primary.100',
                color: 'none',}}
                onClick={() => {
                  console.log('Product',selectedUserId);
                  handleSubmitProductId(selectedUserId);
                  onSecondModalClose();                 
                }}
              >
                Proceed
              </Button>
            </ButtonGroup>

            {/* <Button colorScheme='blue' mr={3} onClick={onOpen}>
              Close
            </Button> */}

          </ModalBody>

         
        </ModalContent>
      </Modal>

      {/* REJECTED MODAL */}

      <Modal isOpen={isThirdModalOpen} onClose={onThirdModalClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <Box textAlign={'center'} pt={'20px'} >             
              <Text color={'border.500'} fontSize={'xl'} fontWeight={'700'} >
                Reject Product
              </Text>
              <Text color={'font.200'} fontSize={'xs'} fontWeight={'normal'} >
                Tell us why you are rejecting this product.
              </Text>
            </Box>
          </ModalHeader>
          <ModalBody>
          <Textarea 
            placeholder='Reason' 
            border={'1px solid #B5B4B4'} 
            h={'10rem'} 
            onChange={(e) => setRejectReason(e.target.value)}
          />
                      
            <Button 
              my={'20px'}
              bg="primary.100" 
              color="#fff" 
              p="20px 56px" 
              h="48px" 
              width="100%" 
              _focus={{ boxShadow: "none" }} 
              _hover={{bg: 'primary.100',
              color: 'none',}}
              onClick={() =>{ 
                handleRejectProductId(rejectData);
                onThirdModalClose();
                
              }}
            >
              Reject Product
            </Button>  
           

          </ModalBody>         
        </ModalContent>
      </Modal>

    </Box>
  )
}

export default AdminProductRequest;