import { Box, Button, Flex, FormControl, FormLabel, Grid, GridItem, Icon, Image, Input, InputGroup, InputRightElement, Modal, ModalBody, ModalContent, ModalHeader, ModalOverlay, Stack, Table, TableContainer, Tbody, Td, Text, Th, Thead, Tr, useDisclosure } from '@chakra-ui/react'
import React, { useContext, useState, useEffect } from 'react'
import AuthContext from '../../../../Components/Admin/Contexts/AdminContext';
import  EmeryAva  from '../../../../Assets/EmeryAva.svg';
import  RightArrow  from '../../../../Assets/RightArrow.svg';
import { NoData, Weight } from '../../../../Assets';
import { useNavigate } from 'react-router-dom';
 

const MarketPlace = ({customerProductData}) => {
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [quantityData, setQuantityData] = useState('');
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { isOpen: isSecondModalOpen, onOpen: onSecondModalOpen, onClose: onSecondModalClose } = useDisclosure();
  const navigate = useNavigate();
  const {isLoadingCustomerProduct,isErrorCustomerProduct, checkoutData, setCheckoutData, profile,profileDoc,status} = useContext(AuthContext);
  const selectedProduct = customerProductData && customerProductData.find(product => product.ProductID === selectedUserId);
  const isButtonActive = selectedProduct && parseFloat(quantityData) > 0 && parseFloat(quantityData) <= selectedProduct.Quantity;

 
  
  if (isLoadingCustomerProduct) {
    return <div>Loading....</div>
  }

  if (isErrorCustomerProduct) {
    return <div>Error Fetching Data</div>
  } 


  const handleProceedToCheck = () => {
    console.log('ProfileDoc state:', profileDoc);

    if (profile === 'false' || profileDoc === 'false' || status === "1") { // Check if profile is false (incomplete)
      console.log('Navigate to profile page');
      onSecondModalOpen();
      
    }else{
      console.log('profile data is complete');
      onOpen();
      // Proceed with other actions if needed
    }
  }; 
  

  const TruncatedText = ({ text, limit }) => {
    const truncatedText = text.length > limit ? `${text.substring(0, limit)}...` : text;
  
    return <div>{truncatedText}</div>;
  }; 

  const numberWithCommas = (x) => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  function formatPrice(price) {
    const numericPrice = parseFloat(price.replace(/,/g, '')); // Remove existing commas if any
    if (!isNaN(numericPrice)) {
      const formattedPrice = numericPrice.toLocaleString('en-US'); // Format as a number with commas
      return `${formattedPrice}`; // Add Naira symbol in front of the formatted price
    }
    return price;
  }



  return (
    <Box >

      <TableContainer>
        <Table variant='unstyled' >
          <Thead backgroundColor={'primary.100'}>
            <Tr >
              <Th borderBottom={'1px solid #E8EEFF'} borderTopLeftRadius={'2xl'} color={'brand.300'} >NAME</Th>
              <Th borderBottom={'1px solid #E8EEFF'} color={'brand.300'}  textAlign={'left'} >QUANTITY AVAILABLE (MT) </Th>
              <Th borderBottom={'1px solid #E8EEFF'} color={'brand.300'}  textAlign={'left'} >PRICE PER METRIC TON (₦)</Th>
              <Th borderBottom={'1px solid #E8EEFF'} color={'brand.300'}  textAlign={'left'} >DESCRIPTION</Th>
              <Th borderBottom={'1px solid #E8EEFF'} borderTopRightRadius={'2xl'}  color={'primary.100'}  textAlign={'center'} >.</Th>
            </Tr>
          </Thead>
            
          <Tbody>
            {
              customerProductData && customerProductData.map((data, index) =>
                <Tr key={data.ProductID} bg={index % 2 === 0 ? 'white' : 'tbrand.100'} >
                  <Td fontWeight={'semibold'} >
                    <Flex alignItems={'center'} > 
                      <Box>
                        <Image
                          boxSize='30px'
                          objectFit='cover' src={EmeryAva} 
                        />
                      </Box> 
                      <Text ml={'10px'} fontWeight={'semibold'}  >
                        {data.VendorName}
                      </Text>
                    </Flex>
                  </Td>
                  <Td textAlign={'left'} fontWeight={'semibold'} >{data.Quantity} </Td>
                  <Td textAlign={'left'} fontWeight={'semibold'} >{formatPrice(data.Price)}</Td>
                  <Td textAlign={'left'}  maxW="150px" isTruncated fontWeight={'medium'} >{data.ProductDescription}</Td>
                  <Td 
                    onClick={() => {
                      console.log('purchase', data)
                      handleProceedToCheck();
                      setSelectedUserId(data.ProductID);
                      // onOpen();
                      setCheckoutData((prev) => ({
                        ...prev,
                        productId: data.ProductID,
                        unitPrice: data.Price
                      }))
                    }} 
                    // onClick={}
                    cursor={'pointer'} 
                    fontWeight={'bold'} 
                    fontSize={'sm'} 
                    color={'primary.200'}  
                    textAlign={'right'} 
                  >
                    <Image
                      boxSize='30px'
                      objectFit='cover' src={RightArrow} 
                      float={'right'}
                    />
                  </Td>
                </Tr>           
              )
            }
          </Tbody>                
        </Table>
      </TableContainer>


      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent borderRadius={'lg'} >
          {
            selectedUserId && 
            customerProductData.filter((data) => data.ProductID === selectedUserId)
            .map((data) =>
              <Box key={data.ProductID}>
                <ModalHeader>
                  <Box textAlign={'center'} pt={'20px'} >             
                    <Text color={'border.500'} fontSize={'xl'} fontWeight={'700'} >
                      Purchase Product
                    </Text>
                    <Text py={4} color={'font.200'} fontSize={'xs'} fontWeight={'normal'} >
                      How much do you want to buy
                    </Text>
                    <Text color={'font.200'} fontSize={'xs'} fontWeight={'normal'} >
                      1ton  = {formatPrice(data.Price)}
                    </Text>
                    <Text color={'font.200'} fontSize={'xs'} fontWeight={'normal'} >
                      Quantity(ton) : {quantityData && parseFloat(quantityData).toLocaleString()}
                    </Text>
                    <Text color={'font.200'} fontSize={'xs'} fontWeight={'normal'} >
                      Total Price: ₦{quantityData && (quantityData * data.Price).toLocaleString()}
                    </Text>

                  </Box>
                </ModalHeader>
                <ModalBody>
                  <Flex pb={4} justifyContent={'center'} alignItems={'center'}  > 
                    <Box>
                      <Image
                        boxSize='30px'
                        objectFit='cover' src={EmeryAva} 
                      />
                    </Box> 
                    <Text color={'primary.300'} fontWeight={'semibold'} fontSize={'sm'} > {data.ProductName} </Text>
                  </Flex>

                  <FormControl>
                    <InputGroup>
                      <Input 
                        type="text" 
                        focusBorderColor='#858383' 
                        _focus={{ boxShadow: "none" }} 
                        bg={quantityData ? 'primary.900' : 'transparent' }
                        h="50px" id="quantity"
                        fontSize="14px" required
                        borderColor="#B5B4B4"
                        value={numberWithCommas(quantityData)}
                        onChange={(e) => {
                          const value = e.target.value;
                          const newValue = value.replace(/,/g, ''); // Remove commas from the input
                          setQuantityData(newValue); // Update quantityData with the cleaned value
                          setCheckoutData((prev) => ({
                            ...prev,
                            quantity: parseFloat(newValue)
                          }))
                          console.log(checkoutData.quantityData)
                        }}
                        name={quantityData}
                      />
                      <FormLabel 
                        fontWeight="normal" 
                        fontSize="12px" 
                        htmlFor="quantity"
                        zIndex={'999'}
                      >
                        Quantity (ton)
                      </FormLabel>
                      <InputRightElement h={'100%'}>
                        <Weight />                                       
                      </InputRightElement>
                    
                    </InputGroup>
                  </FormControl>
                            
                  <Button 
                    my={'20px'}
                    bg={isButtonActive ? 'primary.100' : 'gray.400'}
                    color={isButtonActive ? '#fff' : '#000'}
                    p="20px 56px" 
                    h="48px" 
                    width="100%" 
                    _focus={{ boxShadow: "none" }} 
                    _hover={{bg: 'primary.100',
                    color: 'none',}}
                    isDisabled={!isButtonActive}
                    onClick={() =>{ 
                      console.log('ORDER PAGE')
                      // handleRejectProductId(rejectData);
                      // onThirdModalClose();
                      navigate('/custOrder')
                      // console.log(checkoutData.quantity, parseFloat(checkoutData.unitPrice))
                    }}
                  >
                    Proceed to checkout
                  </Button>  
                

                </ModalBody>       

              </Box>
            )
          }
        </ModalContent>
      </Modal>


      <Modal isOpen={isSecondModalOpen} onClose={onSecondModalClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalBody>
            <Box textAlign={'center'} py={'50px'} >
              <Icon boxSize='150px' viewBox="0 0 250 250" >
                <NoData />                      
              </Icon>  
              <Text 
                onClick={() => {
                  navigate('/custProfile');
                }}
                color={'primary.100'} fontSize={'base'} cursor={'pointer'} fontWeight={'bold'} textDecoration={'underline'} 
              >
                {profile === 'false' ? 'Kindly complete your profile' : 
                  (profileDoc === 'false' ? 'Kindly update your document' : 
                      (status === "1" ? 'Profile in Review' : '')
                )}
              </Text>
            </Box>         

        
          </ModalBody>

        
        </ModalContent>
      </Modal>

    </Box>
  )
}

export default MarketPlace;