import { Box, Button } from '@chakra-ui/react';
import React, { useContext, useState } from 'react'
import AuthContext from '../../../../Components/Admin/Contexts/AdminContext';
import { DocUpload } from '../../../Signup/Delivery/Forms/DocUpload';
import AddVehicleUpload from '../../../Signup/Delivery/Forms/AddVehicleUpload';


const VehilceAdd = () => {

    // const INITIAL_DATA = {
    //     Vehicles: []    
    // };

    const Vehicles= [];    


    const [data, setData] = useState([]);
    // const {handleUploadDelivery} = useContext(AuthContext);

    const [fileValidation, setFileValidation] = useState({       
        Vehicles: []
    })
    const [uploadError, setUploadError] = useState(false)
    const [files, setFiles] = useState({      
        Vehicles: []
    })
    const [progress, setProgress] = useState({       
        Vehicles: []
    })
    const [fileName, setFileName] = useState({        
        Vehicles: []
    })
    const [fileSize, setFileSize] = useState({      
        Vehicles: []
    })

    const height = '4px'
    const buttonVH = '10vh'
    const formHeight = `calc(100% - ${buttonVH} )`
   
    
    // const updateData = (fields) => {
    //     setData(prevData => {
    //         const newData = { ...prevData, ...fields };
    //         return newData;
    //     })
    // }

    const updateData = (fields) => {
        setData(prevData => [...prevData, fields]);
    }

  return (
    <Box>
        <AddVehicleUpload  height={formHeight} {...data} data={data} updateData={updateData} setFileValidation={setFileValidation} fileValidation={fileValidation} uploadError={uploadError} files={files} setFiles={setFiles} progress={progress} setProgress={setProgress} fileName={fileName} setFileName={setFileName} fileSize={fileSize} setFileSize={setFileSize} />
       
    </Box>
  )
}

export default VehilceAdd