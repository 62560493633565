import {
  Route,
  Routes,
} from "react-router-dom";
import { VendorPage } from './pages/VendorType.page';
import { FormPage } from './Features/Signup/Vendor/FormPage';
import Admin from './pages/Admin';
import AdminDashboard from './Components/Admin/AdminDashboard/AdminDashboard';
import AdminOverviewLayout from './Components/Admin/AdminDashboard/AdminOverview/AdminOverviewLayout';
import AdminTerminals from './Components/Admin/AdminDashboard/AdminTerminal/AdminTerminals';
import Login from './Components/Login/Login';
import AdminOnboarding from './Components/Admin/AdminDashboard/AdminOnboarding/AdminOnboarding';
import { CustomerFormPage } from './Features/Signup/Customer/FormPage';
import { DeliveryFormPage } from './Features/Signup/Delivery/FormPage';
import { CustomerContextProvider } from './Features/Signup/Customer/Contexts/CustomerContext';
import { DeliveryContextProvider } from './Features/Signup/Delivery/Contexts/DeliveryContext';
import { VendorContextProvider } from './Features/Signup/Vendor/Contexts/VendorContext';
import { DashboardLayout } from './Features/Dasboard/Vendor/DashboardLayout';
import {DeliveryDashboardLayout} from './Features/Dasboard/Delivery/DashboardLayout';
import { ChooseType } from './Features/shared/Choose.type';
import Dashboard from './Features/Dasboard/Vendor/Dashboard';
import DeliveryDashboard from './Features/Dasboard/Delivery/Dashboard';
import AdminPlants from './Components/Admin/AdminDashboard/AdminPlants/AdminPlants';
import AdminTransporter from './Components/Admin/AdminDashboard/AdminTransporter/AdminTransporter';
import AdminRequest from './Components/Admin/AdminDashboard/AdminRequest/AdminRequest';
import { AuthProvider } from './Components/Admin/Contexts/AdminContext';
import { VendorDashboardContextProvider } from './Features/Dasboard/Vendor/context/VendorDashboardContext';
import {AdminRoute, CutomerRoute, VendorRoute, DeliveryRoute} from './layout/adminProtectedRoute';
import CustomerDashboard from './Features/Dasboard/Customer/CustomerDashboard';
import AdminProduct from './Components/Admin/AdminDashboard/AdminProduct/AdminProduct';
import AdminOrder from './Components/Admin/AdminDashboard/AdminOrder/AdminOrder';
import CustomerCheckout from './Features/Dasboard/Customer/Sections/CustomerCheckout';
import LandingPage from './Components/Landing Page/LandingPage';
import Profile from './Features/Dasboard/Vendor/Profile/Profile';
import DeliveryProfile from './Features/Dasboard/Delivery/Profile/Profile';
import Request from './Features/Dasboard/Vendor/Request/Request';
import Vehicle from './Features/Dasboard/Delivery/Vehicle/Vehicle';
import Order from "./Features/Dasboard/Vendor/Order/Order";
// import { Products } from "./Features/Dasboard/Vendor/Sections/Products";
import Product from "./Features/Dasboard/Vendor/Product/Product";
import CustomerProfile from "./Features/Dasboard/Customer/Sections/CustomerProfile";
import Terms from "./pages/Terms";
import InactivityHandler from "./Components/Admin/Contexts/InactivityHandler";



function CustomerLayout() {
  return (
    <CustomerContextProvider>
      <CustomerFormPage />
    </CustomerContextProvider>
  );
}
function DeliveryLayout() {
  return (
    <DeliveryContextProvider>
      <DeliveryFormPage /> 
    </DeliveryContextProvider>
  );
}
function VendorLayout() {
  return (
    <VendorContextProvider>
      <FormPage />
    </VendorContextProvider>
  );
}
function VendorDashboard() {
  return (
    <VendorDashboardContextProvider>
      {/* <DashboardLayout /> */}
      <Dashboard />
    </VendorDashboardContextProvider>
  );
}

// function AdminLogin(){
//   return(
//     <AuthProvider>      
//       <Admin />
//     </AuthProvider>
//   )
// }

// const router = createBrowserRouter(
//   createRoutesFromElements(
//     <Route>
//       <Route path="/" element={<Admin />} />
//       <Route element={<AdminRoute />}>
//         <Route path="dashboard" element={<AdminDashboard/>}>
//           <Route index element={<AdminOverviewLayout/>} />
//           <Route path='overview' element={<AdminOverviewLayout/>} />
//           <Route path='lpg-terminals' element={<AdminTerminals/>} />
//           <Route path='lpg-plants' element={<AdminPlants/>} />
//           <Route path='lpg-transporter' element={<AdminTransporter/>} />
//           <Route path='admin' element={<AdminOnboarding/>} />
//           <Route path='requests' element={<AdminRequest/>} />
//         </Route>
//       </Route>
//       <Route path="/Login" element={<Login />} />
//       <Route path="/choose-type" element={<ChooseType />} />
//       <Route path="/user_type" element={<VendorPage />} />
//       <Route path="/vendor" element={<VendorLayout/>} />
//       <Route path="/customer" element={<CustomerLayout />} />
//       <Route path="/delivery" element={<DeliveryLayout />} />
//       {/* <Route element={<VendorRoute />}> */}
//         <Route path="dash" element={<Dashboard />}>
//           <Route index element={<VendorDashboard />} />
//           <Route path="test" element={<VendorDashboard />} />
//         </Route>
        
//       {/* </Route> */}
//         <Route path="custdash" element={<CustomerDashboard />}/>
         
//       {/* <Route path="/rest" element={<AdminOverviewLayout />} /> */}
//     </Route>
//   )
// );


function App() {
  
  return (
    <div className="App">
      <AuthProvider>
        <InactivityHandler>
          {/* <RouterProvider router={router} /> */}
          <Routes>
            <Route>
              
              <Route path="/" element={<LandingPage />} />
              <Route path="/Login" element={<Login />} />
              <Route path="/choose-type" element={<ChooseType />} />
              <Route path="/user_type" element={<VendorPage />} />
              <Route path="/vendor" element={<VendorLayout/>} />
              <Route path="/customer" element={<CustomerLayout />} />
              <Route path="/delivery" element={<DeliveryLayout />} />
              <Route path="/terms" element={<Terms />} />
              <Route path="/AdminPage" element={<Admin />} />

              <Route element={<AdminRoute />}>
                <Route path="AdminPage/dashboard" element={<AdminDashboard/>}>
                  <Route index element={<AdminOverviewLayout/>} />
                  <Route path='overview' element={<AdminOverviewLayout/>} />
                  <Route path='lpg-terminals' element={<AdminTerminals/>} />
                  <Route path='lpg-plants' element={<AdminPlants/>} />
                  <Route path='lpg-transporter' element={<AdminTransporter/>} />
                  <Route path='admin' element={<AdminOnboarding/>} />
                  <Route path='requests' element={<AdminRequest/>} />
                  <Route path='products' element={<AdminProduct/>} />
                  <Route path='orders' element={<AdminOrder/>} />

                </Route>
              </Route>

            
              <Route element={<VendorRoute />}>
                <Route path="dash" element={<VendorDashboard />}>
                  <Route index element={<DashboardLayout />} />
                  <Route path="overview" element={<DashboardLayout/>} />
                  <Route path="requests" element={<Request />} />
                  <Route path="orders" element={<Order />} />
                  <Route path="products" element={<Product/>} />
                </Route>
                <Route path="dash/profile" element={<Profile />} />           
              </Route>        

              <Route element={<DeliveryRoute />}>
                <Route path="truckdash" element={<DeliveryDashboard />}>
                  <Route index element={<DeliveryDashboardLayout />} />
                  <Route path="overview" element={<DeliveryDashboardLayout />} />
                  <Route path="vehicle" element={<Vehicle />} />
                </Route>
                <Route path="truckdash/profile" element={<DeliveryProfile />} />          
              </Route>      

              <Route element={<CutomerRoute/>}>     
                <Route path="custdash" element={<CustomerDashboard />}/>
                <Route path="custOrder" element={<CustomerCheckout />}/>
                <Route path="custProfile" element={<CustomerProfile />}/>
              </Route>

              {/* <Route path="/rest" element={<AdminOverviewLayout />} /> */}

            </Route>
          </Routes>       
        </InactivityHandler>
      </AuthProvider>
    </div>
  );
}

export default App;
