import { Box, Button, Flex, FormControl, FormLabel, HStack, Icon, Image, Input, InputGroup, InputRightElement, Modal, ModalBody, ModalContent, ModalHeader, ModalOverlay, Tab, TabList, TabPanel, TabPanels, Tabs, Text, useDisclosure } from '@chakra-ui/react';
import  EmeryAva  from '../../../../Assets/EmeryAva.svg';
import { Achevron, Aemail, Alocation, Apassword, Aperson, Aphone,  CheckButton,  HomeGrey, Location, Password } from '../../../../Assets';
import { useContext, useEffect, useState } from 'react';
import AuthContext from '../../../../Components/Admin/Contexts/AdminContext';
import { Link, useNavigate } from 'react-router-dom';
import CustomerProfileKYC from './CustomerProfileKYC';
import CustomerProfileDoc from './CustomerProfileDoc';

const CustomerProfile = () => {     

    const {profile,status} = useContext(AuthContext);

    
    return (
        <Box>
            <Tabs variant="unstyled"  > 
                <TabList justifyContent="space-between" p={'0 30px 20px'} >
                    <TabList ml={'20px'}>
                        
                        <Tab
                            color="primary.400"
                            position="relative"
                            border="1px solid #B5B4B4"
                            borderRadius= "10px"
                            marginRight='20px'
                            fontSize={'sm'}
                            width={'180px'}
                            height={'50px'}
                            _selected={{
                                color: "primary.200",
                                border: "1px solid #049541",
                                fontWeight: "semibold",
                                borderRadius: "10px",
                                backgroundColor: "font.300"
                            }}
                            
                        >                

                            <HStack alignItems="center" position="relative">
                                <Text ml={2} >Profile KYC</Text>
                            </HStack>                            
                        </Tab>
                        <Tab
                            color="primary.400"
                            position="relative"
                            border="1px solid #B5B4B4"
                            borderRadius= "10px"
                            fontSize={'sm'}
                            width={'180px'}
                            height={'50px'}
                            _selected={{
                                color: "primary.200",
                                border: "1px solid #049541",
                                fontWeight: "semibold",
                                borderRadius: "10px",
                                backgroundColor: "font.300"
                            }}
                            
                        >
                            <HStack alignItems="center" position="relative">                                                   
                                <Text ml={2} >Documents Upload</Text>
                            </HStack>
                            
                        </Tab>

                    </TabList>
                    <Box>
                        {status === "1" ? (
                            <Link to='/custdash' >
                                <Text fontWeight={'bold'} fontSize={'lg'}  py={'10px'} px={'20px'}  >
                                    Back to Marketplace                
                                </Text>
                            </Link>
                        ) : null}
                    </Box>

                </TabList>

                <TabPanels>

                    <TabPanel>
                        <CustomerProfileKYC/>
                    </TabPanel>
                    <TabPanel>
                        <CustomerProfileDoc/>
                    </TabPanel>

                </TabPanels>
            </Tabs>   

        </Box>
  )
}

export default CustomerProfile;