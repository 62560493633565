import {
    Box,
    Flex,
    HStack,
    Icon,
    Image,
    Modal,
    ModalBody,
    ModalContent,
    ModalHeader,
    ModalOverlay,
    Table,
    TableContainer,
    Tbody,
    Td,
    Text,
    Th,
    Thead,
    Tr, 
    useDisclosure
} from "@chakra-ui/react"
import { EmeryAva, cover } from "../../../../Assets"
import { useContext,  useState } from "react"
import AuthContext from "../../Contexts/AdminContext";

const AdminOnboardingTable = ({adminsData, isLoadingAdmin, isErrorAdmin}) => {
   
    const { isOpen, onOpen, onClose } = useDisclosure()
    // const {adminsData, isLoadingAdmin, isErrorAdmin,} = useContext(AuthContext);
    const [selectedUserId, setSelectedUserId] = useState(null);

    

    if (isLoadingAdmin) {
      return <div>Loading....</div>
    }

    if (isErrorAdmin) {
      return <div>Error Fetching Data</div>
    }


    adminsData.sort((a, b) => {
        const dateA = new Date(a.DateCreated);
        const dateB = new Date(b.DateCreated);
        return dateB - dateA; // Sort in descending order (newest first)
    });

    


  return (
    <Box >
        <TableContainer>
            <Table variant='striped' colorScheme='tbrand'>
                <Thead >
                    <Tr >
                      <Th borderBottom={'1px solid #E8EEFF'} color={'primary.300'} >Full Name</Th>
                      <Th borderBottom={'1px solid #E8EEFF'} color={'primary.300'}  textAlign={'center'} >Email Address </Th>
                      <Th borderBottom={'1px solid #E8EEFF'} color={'primary.300'}  textAlign={'center'} >Phone Number</Th>
                      <Th borderBottom={'1px solid #E8EEFF'} color={'primary.300'}  textAlign={'center'} >Action</Th>
                    </Tr>
                </Thead>
                
                <Tbody>
                    {
                        adminsData.map((data) =>
                            <Tr key={data.CustomerId} >
                                <Td fontWeight={'semibold'}  >
                                   <Flex alignItems={'center'} >

                                        <Icon boxSize='25px' viewBox="0 0 50 50" >
                                            <EmeryAva/>
                                        </Icon> 
                                        <Box ml={'10px'}>
                                            {data.FullName}
                                        </Box>
                                   </Flex>
                                </Td>
                                <Td textAlign={'center'} >{data.EmailAddress}</Td>
                                <Td textAlign={'center'} >{data.PhoneNumber}</Td>
                                <Td 
                                   onClick={() => {
                                    setSelectedUserId(data.CustomerId);
                                    onOpen();
                                  }} 
                                  cursor={'pointer'} 
                                  fontWeight={'bold'} 
                                  fontSize={'sm'} 
                                  color={'primary.200'}  
                                  textAlign={'center'} 
                                >
                                  View Details
                                </Td>
                            </Tr>           
                        )
                    }
                </Tbody>                
            </Table>
        </TableContainer>

        <Modal  isOpen={isOpen} onClose={onClose} >
            <ModalOverlay />
            <ModalContent borderRadius={`lg`} maxW="550px" >
                {
                    selectedUserId && 
                    adminsData.filter((data) => data.CustomerId === selectedUserId )
                    .map((data) => 
                        <Box key={data.CustomerId} >
                            <ModalHeader textAlign='center'>
                                <Box position={'relative'} pb={'60px'} >
                                    <Box>  
                                        <Image src={cover}  alt='cover' />
                                    </Box>
                                    <Icon 
                                        position={'absolute'} 
                                        top={'45px'} left={'40%'} 
                                        border={'6px solid #fff'} 
                                        borderRadius={'50%'}  
                                        boxSize='100px' 
                                        viewBox="0 0 48 52" 
                                        overflow="hidden"
                                        
                                    >
                                        <EmeryAva/>
                                    </Icon> 
                                </Box>
                                <Text fontWeight='bold' fontSize='2xl' color='primary.100'  >
                                    {data.FullName}
                                </Text>
                            </ModalHeader>
                            <ModalBody>
                                <Text fontSize={'sm'} color={'font.100'} pb={'8px'}  >
                                    Basic Details
                                </Text>
                                <Flex alignItems={'center'} justifyContent={'space-between'} >
                                    <HStack>
                                        <Text 
                                            color={'font.600'} 
                                            fontSize={'xs'} 
                                            fontWeight={'semibold'}
                                        >
                                            Phone Number:                               
                                        </Text>
                                        <Text color={'primary.300'} fontSize={'sm'}>
                                            {data.PhoneNumber}
                                        </Text>
                                    </HStack>
                                    <HStack>
                                        <Text 
                                            color={'font.600'} 
                                            fontSize={'xs'} 
                                            fontWeight={'semibold'}
                                        >
                                            Email Address: 
                                        </Text>
                                        <Text color={'primary.300'} fontSize={'sm'}  isTruncated w={'60%'} >
                                            {data.EmailAddress}
                                        </Text>
                                    </HStack>
                                </Flex>
                                <HStack pt={'20px'} pb={'10px'} >
                                    <Text 
                                        color={'font.600'} 
                                        fontSize={'xs'} 
                                        fontWeight={'semibold'} 
                                    >
                                        Last Logged In:
                                    </Text>
                                    <Text color={'primary.300'} fontSize={'sm'}  >
                                        Fri, 27 Sept. 2023, 02:21pm
                                    </Text>
                                </HStack>

                            </ModalBody>               
                        </Box>

                    )}
               
            </ModalContent>
        </Modal>      

    </Box>
  )
}

export default AdminOnboardingTable