import React, { useContext, useState } from 'react'
import { 
    BackIcon, 
    History, 
    HistoryWhite, 
    Iplant, 
    Logo, 
    Logout, 
    PlantWhite, 
    Search, 
    Terminal, 
    TerminalWhite 
} from '../../../Assets'
import  EmeryAva  from '../../../Assets/EmeryAva.svg';
import  BlueTick  from '../../../Assets/BlueTick.svg';
import { 
    Box, 
    Divider, 
    Flex, 
    HStack, 
    Icon, 
    Image, 
    Input, 
    InputGroup, 
    InputRightElement, 
    Modal, 
    ModalBody, 
    ModalContent, 
    ModalHeader, 
    ModalOverlay, 
    Tab,  
    TabList, 
    TabPanel, 
    TabPanels, 
    Table, 
    TableContainer, 
    Tabs, 
    Tbody, 
    Td, 
    Text, 
    Th, 
    Thead, 
    Tr, 
    VStack, 
    useDisclosure 
} from '@chakra-ui/react'
import MarketPlace from './Sections/MarketPlace';
import AuthContext from '../../../Components/Admin/Contexts/AdminContext';
import { useNavigate } from 'react-router-dom';
import { CustomerBg } from '../../../Assets';
import FullLogo from '../../../Assets/Full-Logo.svg';
import Element from '../../../Assets/Element.svg';
import Company from '../../../Assets/Company.svg';
import CustomerProfile from './Sections/CustomerProfile';
import HistoryPage from './HistoryPage';

const CustomerDashboard = () => { 

    const data = [
        {
          name: "Emery Westervelt",
          icon: <EmeryAva />,
          time: "ETA: 10 Mins",
          capacity: 4,
          state: "Ongoing",
          direction: "Incoming",
          stateBg: "#006FF21A",
          color: `font.700`
        },
        {
          name: "Dulce Botosh",
          icon: <EmeryAva />,
          time: "ETD: 1 Mins",
          capacity: 41,
          state: "Ongoing",
          direction: "Outgoing",
          stateBg: "#006FF21A",
          color: `font.700`
        },
        {
          name: "Erin Carder",
          icon: <EmeryAva />,
          time: "5 mins ago",
          capacity: 23,
          state: "Arrived",
          direction: "Ready for Pickup",
          stateBg: "#101A361A",
          color: `primary.100`
        },
        {
          name: "James Westervelt",
          icon: <EmeryAva />,
          time: "ETD: 120 Mins",
          capacity: 4,
          state: "Ongoing",
          direction: "Outgoing",
          stateBg: "#006FF21A",
          color: `font.700`
        },
        {
          name: "James Westervelt",
          icon: <EmeryAva />,
          time: "ETD: 120 Mins",
          capacity: 4,
          state: "Ongoing",
          direction: "Outgoing",
          stateBg: "#006FF21A",
          color: `font.700`
        },
        {
          name: "James Westervelt",
          icon: <EmeryAva />,
          time: "ETD: 120 Mins",
          capacity: 4,
          state: "Ongoing",
          direction: "Outgoing",
          stateBg: "#006FF21A",
          color: `font.700`
        },
        {
          name: "James Westervelt",
          icon: <EmeryAva />,
          time: "ETD: 120 Mins",
          capacity: 4,
          state: "Ongoing",
          direction: "Outgoing",
          stateBg: "#006FF21A",
          color: `font.700`
        },
        {
          name: "James Westervelt",
          icon: <EmeryAva />,
          time: "ETD: 120 Mins",
          capacity: 4,
          state: "Ongoing",
          direction: "Outgoing",
          stateBg: "#006FF21A",
          color: `font.700`
        },
        {
          name: "James Westervelt",
          icon: <EmeryAva />,
          time: "ETD: 120 Mins",
          capacity: 4,
          state: "Ongoing",
          direction: "Outgoing",
          stateBg: "#006FF21A",
          color: `font.700`
        },
        {
          name: "James Westervelt",
          icon: <EmeryAva />,
          time: "ETD: 120 Mins",
          capacity: 4,
          state: "Ongoing",
          direction: "Outgoing",
          stateBg: "#006FF21A",
          color: `font.700`
        },
        {
          name: "James Westervelt",
          icon: <EmeryAva />,
          time: "ETD: 120 Mins",
          capacity: 4,
          state: "Ongoing",
          direction: "Outgoing",
          stateBg: "#006FF21A",
          color: `font.700`
        },
    ]

    const [selectedTab, setSelectedTab] = useState(0);
    const [searchQuery, setSearchQuery] = useState("");
    const {handleLogout, customerProfileData, isLoadingCustomerProfileData, isErrorCustomerProfileData, customerProductData} = useContext(AuthContext);
    const { isOpen, onOpen, onClose } = useDisclosure();
    const { isOpen: isSecondModalOpen, onOpen: onSecondModalOpen, onClose: onSecondModalClose } = useDisclosure();
    const navigate = useNavigate();

    // console.log(useContext(AuthContext))

    const filteredData = customerProductData && customerProductData.filter((data) =>
        data.ProductName.toLowerCase().includes(searchQuery.toLowerCase())
    );

    const handleTabChange = (index) => {
        setSelectedTab(index); 
    }; 

    const tabs = [
        { label: "Marketplace", icon: <Terminal />, content: <MarketPlace customerProductData={filteredData} customerProfileData={customerProfileData} />, activeIcon: <TerminalWhite/> },
        { label: "History", icon: <History />, content: <HistoryPage/>, activeIcon: <HistoryWhite/> },
        { label: "Profile", icon: <Iplant />, content: <CustomerProfile/>, activeIcon: <PlantWhite/> },
    ];

    if (!handleLogout) {
        // Context value is not available, handle accordingly (e.g., show loading state or error message)
        return (
            <Box>
                <Text>Error: AuthContext value not available</Text>
            </Box>
        );
    }

  return (
    <Box >
        <Flex 
            bgImage={`url(${CustomerBg})`}
            bgSize="cover"
            bgPosition="center"
            bgRepeat="no-repeat"
            w="100%"
            p={'30px'} 
            alignItems={'center'} 
            justifyContent={'space-between'} 
        >
            <Icon boxSize='40px' viewBox="0 0 100 100" >
                <Logo/>
            </Icon> 
            <InputGroup w={'40%'}  >                       
                <Input 
                    placeholder='Search Product.....' 
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                />
                <InputRightElement>
                    <Icon  boxSize='25px'  >
                        <Search/>
                    </Icon>
                </InputRightElement>
            </InputGroup>
            <HStack pr={'10px'} >

                <Box>
                    <Image
                        boxSize='30px'
                        objectFit='cover' src={EmeryAva} 
                    />
                </Box>                 
                                    
                <Box>
                    <Text color="font.300" fontWeight="semibold" >
                        {!isLoadingCustomerProfileData && customerProfileData?.[0]?.FullName}
                    </Text>
                    <Text color="font.400" fontWeight="normal" fontSize="12px" >
                        LPG Filling Plants
                    </Text>
                    <Text color="font.400" fontSize="14px" fontWeight="semibold" >
                        Wallet Balance:  ₦{!isLoadingCustomerProfileData && customerProfileData?.[0]?.CurrentBalance}
                    </Text>
                    <Text color="font.400" fontSize="14px" fontWeight="semibold" >
                        Account Number:  {!isLoadingCustomerProfileData && customerProfileData?.[0]?.VirtualAccountNumber}
                    </Text>
                </Box>
            </HStack>
        </Flex>
        <Box>
            <Tabs variant="unstyled" bg="#fff" borderRadius="lg" pt={5}>
                <TabList justifyContent="space-between" p={'0 30px 20px'} >
                    <TabList>
                        {tabs.map((tab, index) => (
                            <Tab
                                key={index}
                                color="border.100"
                                position="relative"
                                _selected={{
                                color: "#fff",
                                borderBottom: "2px solid primary.100",
                                fontWeight: "semibold",
                                borderRadius: "50px",
                                background:"linear-gradient(to left,#101A36 95%, #049541 50%)"
                                }}
                                width={'185px'}
                                onClick={() => handleTabChange(index)}
                            >
                                <HStack alignItems="center" position="relative">
                                    
                                    <Icon
                                        boxSize="40px"
                                        viewBox="0 0 50 50"
                                        position="absolute"
                                        top="3px"
                                        left="-20px"
                                    >
                                        {selectedTab === index ? tab.activeIcon : tab.icon} 
                                    </Icon>
                                
                                    <Text ml={2} >{tab.label}</Text>
                                </HStack>
                            </Tab>
                        ))}
                    </TabList>
                    <Flex alignItems="center" w={'20%'}  mr={'20px'} justifyContent={'space-between'} >
                        <Text 
                            fontWeight={'semibold'} 
                            fontSize={'base'}
                            color={'primary.100'}  
                            onClick={onOpen}
                            cursor={'pointer'} 
                        >
                            Ongoing Order
                        </Text>
                        <HStack 
                            alignItems={'center'} position={'relative'} 
                            onClick={() => {
                                handleLogout();
                                navigate('/Login');
                            }}
                            cursor={'pointer'} 
                        >
                            <Icon
                                boxSize="50px"
                                viewBox="0 0 50 50"
                                position="relative"
                                top="14px"
                                left="18px"
                            >
                                <Logout/>
                            </Icon>
                            <Text color={'secondary.300'} fontWeight={'bold'}  >
                                Log Out
                            </Text>
                        </HStack>
                    </Flex>
                </TabList>               
                <TabPanels bg='primary.600' p={'20px 20px'} >
                    {tabs.map((tab, index) => (
                    <TabPanel key={index}>
                        {selectedTab === index && <Box>{tab.content}</Box>}
                    </TabPanel>
                    ))}
                </TabPanels>
            </Tabs>
        </Box>
        
        

        <Modal isOpen={isOpen} onClose={onClose} size={'full'} >
            <ModalOverlay />
            <ModalContent 
                borderRadius={`lg`} 
                maxW="550px" 
                h="100vh" 
                ml="auto"  
                overflowY={`auto`}
                // h={`100%`}
                sx={
                    { 
                    '::-webkit-scrollbar':{
                        display:'none'
                    }
                }}
            >
                <ModalHeader>
                    <Flex 
                        alignItems={'center'} 
                        justifyContent={'space-between'}
                        py={'20px'}  
                    >
                        <HStack 
                        alignItems={'center'}  
                        onClick={onClose} 
                        cursor={'pointer'}  
                        w={'40%'} 
                        >                
                        <Icon boxSize='15px' viewBox="0 2 20 20"  >
                            <BackIcon  />
                        </Icon>                       
                        <Text 
                            color={'primary.800'} 
                            fontWeight={'semibold'} 
                            fontSize={'sm'}  
                        >
                            Marketplace
                        </Text>                 
                        </HStack>                
                    </Flex>
                
                    <Text 
                        fontWeight='bold' 
                        fontSize='2xl' 
                        color='primary.100' 
                        
                    >
                        Ongoing Orders
                    </Text>
                </ModalHeader>
                <ModalBody>
                    <Box
                        overflowY={`auto`}
                        h={`100%`}
                        sx={
                            { 
                            '::-webkit-scrollbar':{
                                display:'none'
                            }
                            }
                        }
                    >     
                        {data.map((data, i) => (
                            <Box
                                key={i}      
                                                    
                            >
                                <HStack onClick={onSecondModalOpen} cursor={'pointer'}  >
                                    <Image
                                        boxSize='30px'
                                        objectFit='cover' src={EmeryAva} 
                                    />                         
                                    <Box
                                        ms={1}
                                        w={`70%`}
                                    >
                                        <Text
                                        fontWeight={`semibold`}
                                        fontSize={`base`}
                                        >
                                        {data.name}
                                        </Text>
                                        <Flex>
                                        <Text
                                            fontSize={`xs`}
                                            pe={4}
                                        >
                                            {data.time}
                                        </Text>
                                        <Text
                                            color={`primary.200`}
                                            fontWeight={`semibold`}
                                            fontSize={`xs`}
                                        >
                                            {data.capacity}kg
                                        </Text>
                                        </Flex>
                                    </Box>
                                    <VStack>
                                        <Text
                                            fontSize={`11px`}
                                            textAlign={`center`}
                                        >
                                        {data.direction}
                                        </Text>
                                        <Box
                                        borderRadius={`full`}
                                        color={data.color}
                                        bg={data.stateBg}
                                        py={.5}
                                        px={6}
                                        fontWeight={`semibold`}
                                        fontSize={`xs`}
                                        >
                                        {data.state}
                                        </Box>
                                    </VStack>
                                </HStack>
                                <Box position='relative' py={3} w={`100%`} >
                                <Divider />
                                </Box>
                            </Box>
                        ))}            
                    </Box>
                </ModalBody>      
            </ModalContent>
        </Modal>

        <Modal isOpen={isSecondModalOpen} onClose={onSecondModalClose} size={'4xl'} >
            <ModalOverlay />
            <ModalContent borderRadius={'3xl'}  background="linear-gradient( #e8f0f9 90%, #e8f0f9 95%, #e8f0f9 80%)"   width={'650px'}  >
            <ModalBody>
                
                <Flex alignItems={'center'} p={8} >
                    <HStack>
                        <Image
                          boxSize='100px'
                           src={FullLogo} 
                        />  
                        <Box borderLeft={'1px solid #D6DCE5'} paddingLeft={5} marginLeft={3} >
                            
                            <Text>
                                Invoice number:
                            </Text>
                            <Text fontWeight={'semibold'} fontSize={'base'} color={'primary.100'}  >
                                Nº: 000027
                            </Text>
                            <Text pt={4}>
                                Issued:
                            </Text>
                            <Text fontWeight={'semibold'} fontSize={'base'} color={'primary.100'}>
                                June 28, 2024
                            </Text>

                        </Box>
                    </HStack>
                    <Box
                        position={'absolute'} 
                        top={'70px'} right={'8%'} 
                        borderRadius={`full`}
                        color={'primary.100'}
                        bg={'#fff'}
                        py={.5}
                        textAlign={'center'}
                        // px={6}
                        fontWeight={`medium`}
                        fontSize={`sm`}
                        w={'15%'}
                      >
                        INVOICE
                    </Box>
                </Flex>

                <Box bg={'#fff'} w={"95%"} m={'auto'} borderRadius={'3xl'} p={8} mb={7}  >
                    <Flex alignItems={'center'} justifyContent={'space-between'}  >
                        <Box w={'48%'}  >
                            <HStack pb={2} >
                                <Image
                                    boxSize='15px'
                                    src={BlueTick} 
                                />  
                                <Text>
                                    Buyer Name
                                </Text>
                            </HStack>
                            <Box bg={'#F6F8FC'} borderRadius={'3xl'} p={5} >
                                
                                <HStack  >
                                    <Image
                                        boxSize='20px'
                                        src={Element} 
                                    />  
                                    <Text fontWeight={'semibold'} fontSize={'base'} >
                                        Jide Ayodele
                                    </Text>
                                </HStack>
                                <Text py={3} >
                                    (+234)903 123 1213
                                </Text>
                                <Text>
                                    92102, Lekki Drive, Ikoyi <br /> Lagos Nigeria.
                                </Text>
                            </Box>
                            
                        </Box>
                        <Box w={'48%'} >
                            <HStack pb={2}>
                                <Image
                                    boxSize='15px'
                                    src={BlueTick} 
                                />  
                                <Text>
                                    Seller Name
                                </Text>
                            </HStack>
                            <Box bg={'#F6F8FC'} borderRadius={'3xl'} p={5} >
                                
                                <HStack  >
                                    <Image
                                        boxSize='20px'
                                        src={Company} 
                                    />  
                                    <Text fontWeight={'semibold'} fontSize={'base'} >
                                        NG Gas Agency
                                    </Text>
                                </HStack>
                                <Text py={3} >
                                    (+234)903 123 1213
                                </Text>
                                <Text>
                                    92102, Lekki Drive, Ikoyi <br /> Lagos Nigeria.
                                </Text>
                            </Box>
                            
                        </Box>
                       
                    </Flex>
                    <Box mt={8} >
                        <TableContainer>
                            <Table variant='simple'>
                                <Thead>
                                    <Tr bg={'#F6F8FC'} color={'primary.100'}  >
                                        <Th borderTopLeftRadius={20} >Item</Th>
                                        <Th >Price</Th>
                                        <Th >Qty</Th>
                                        <Th borderTopRightRadius={20} >Total</Th>
                                    </Tr>
                                </Thead>
                                <Tbody>
                                <Tr>
                                    <Td color={'primary.100'} fontWeight={'semibold'} >Natural Gas</Td>
                                    <Td>₦5,250.00</Td>
                                    <Td>200</Td>
                                    <Td color={'primary.100'} fontWeight={'semibold'}  >₦1,050,000</Td>
                                </Tr>
                            
                                </Tbody>
                                
                            </Table>
                        </TableContainer>
                    </Box>
                    <Flex alignItems={'center'} justifyContent={'space-between'} mt={10}  >
                        <Box w={'45%'}>
                            <Text color={'primary.100'} fontWeight={'semibold'} >
                                Terms & Conditions:
                            </Text>
                            <Text color={'#868DA6'} fontSize={'10px'} >
                                Fees and payment terms will be established in the 
                                contract or agreement prior to the commencement of the 
                                project. An initial deposit will be required before any 
                                design work begins. We reserve the right to suspend or 
                                halt work in the event of non-payment.
                            </Text>
                        </Box>
                        <Box w={'50%'} fontSize={'12px'}  >
                            <HStack alignItems={'center'} justifyContent={'space-between'}  >
                                <Box fontWeight={'semibold'} color={'primary.100'}  >
                                    <Text>
                                        Subtotal:
                                    </Text>
                                    <Text>
                                        Discount <Text color={'#868DA6'} as={'span'}  >(Special Offer)</Text>:
                                    </Text>
                                    <Text>
                                        Tax:
                                    </Text>
                                </Box>
                                <Box>
                                    <Text>
                                        ₦1,050,000
                                    </Text>
                                    <Text>
                                        ₦1,050
                                    </Text>
                                    <Text>
                                        ₦750
                                    </Text>
                                </Box>
                            </HStack>
                            <HStack alignItems={'center'} justifyContent={'space-between'}   bg={'#F6F8FC'} color={'primary.100'} py={4} px={2} fontWeight={'semibold'} borderTopRadius={10}  >
                                <Text>
                                    Invoice Total:
                                </Text>
                                <Text>
                                    ₦1,050,000
                                </Text>
                            </HStack>
                        </Box>
                    </Flex>
                </Box>


                {/* onClick={onSecondModalClose} */}


            </ModalBody>

            
            </ModalContent>
        </Modal>
        
    </Box>
  )
}

export default CustomerDashboard

