import { Box, Flex, Grid, GridItem, HStack, Icon, Image, Text, useMediaQuery } from '@chakra-ui/react'
import React from 'react'
import {PagePlant, PageTerminal, PageTransport,} from '../../../Assets'
import One from '../../../Assets/one.png';
import Two from '../../../Assets/two.png';
import Three from '../../../Assets/three.png';
import Arrow from '../../../Assets/Arrow.svg'

const Services = () => {

  const [isSm] = useMediaQuery(`(max-width: 992px)`);
  const [isXs] = useMediaQuery(`(max-width: 760px)`);
  const [isMd] = useMediaQuery(`(max-width: 650px)`);
  const direction = isSm ? "column" : "row";

  return (
    <Box bg={'primary.100'} p={isXs ? 10 : 20}  >
      <Text fontWeight={'medium'} textAlign={'center'} fontSize={['2xl','3.5xl','5xl']} color={'#FFF'} pb={20} >
        Experience the best <br /> <Text as={'span'} fontWeight={'bold'} fontSize={['2xl','4xl','7xl']} lineHeight={'35px'} > LPG Supply </Text> 
      </Text>  
      <Grid 
       h={ isSm ? null : '800px'}
       templateRows={isSm ? 'repeat(3, 1fr)' : 'repeat(2, 1fr)' }
       templateColumns={ isSm ? '1fr' : 'repeat(2, 1fr)' }
       gap={4}
      >
   
        <GridItem 
          rowSpan={isSm ? 1 : 2 } 
          colSpan={ isSm ? 1 : 1 }  
          backgroundImage ={ `url(${PageTerminal})`}
          backgroundPosition={"center"}
          backgroundSize={'cover'}
          backgroundRepeat={"no-repeat"}
          borderRadius={'3xl'} 
          color={'#FFF'}
          display="flex"             
          flexDirection="column"      
          justifyContent="flex-end"
        >
          <Box px={ isMd ? 8 : 15} py={5} >
            
            <Text color={'brand.300'} fontWeight={'bold'} fontSize={ isMd ? 'lg' : '3xl'} >
              LPG Terminals/Depot (Vendors)
            </Text>           
          
            <Text color={'font.300'} py={4} fontSize={ isMd ? 'sm' : 'base'} >
              Terminal Depots leverage EasyGaz to efficiently create and manage LPG products, set competitive pricing, and maintain inventory, ensuring a responsive and dynamic presence in the LPG market.
            </Text>
            <Box
              // ml={'20px'} 
              w={'22%'}
              borderBottom={'2px solid #049541'}  
              fontWeight={'bold'}              
              color={'border.300'}
              cursor={'pointer'}
              display={'Flex'}
              alignItems={'center'}
              pb={'5px'}
              float={'right'}
            >
              <Text pr={3}>
                Start Selling
              </Text>
              <Image
                boxSize='15px'
                objectFit='cover'
                src={Arrow}
              />
            </Box>
          </Box>
        </GridItem>

        <GridItem 
          rowSpan={isSm ? 1 : null } 
          colSpan={ isSm ? 1 : 2 }  
          backgroundImage ={ `url(${PagePlant})`}
          backgroundPosition={"center"}
          backgroundSize={'cover'}
          backgroundRepeat={"no-repeat"}
          borderRadius={'3xl'} 
          color={'#FFF'}
          display="flex"             
          flexDirection="column"      
          justifyContent="flex-end"  
        >
          <Box px={ isMd ? 8 : 15} py={5} >
            
            <Text color={'brand.300'} fontWeight={'bold'} fontSize={ isMd ? 'lg' : '3xl'} >
              LPG Filling Plants (Customers)
            </Text>           
          
            <Text color={'font.300'} py={4} fontSize={ isMd ? 'sm' : 'base'} >
              Plant Depots experience convenience at its best, effortlessly placing LPG orders, tracking deliveries in real-time, and managing their accounts with user-friendly interfaces on EasyGaz.             
            </Text>
            <Box
              // ml={'20px'} 
              w={'22%'}
              borderBottom={'2px solid #049541'}  
              fontWeight={'bold'}              
              color={'border.300'}
              cursor={'pointer'}
              display={'Flex'}
              alignItems={'center'}
              pb={'5px'}
              float={'right'}
            >
              <Text pr={3}>
                Start Buying
              </Text>
              <Image
                boxSize='15px'
                objectFit='cover'
                src={Arrow}
              />
            </Box>
          </Box>
        </GridItem>

        <GridItem 
          rowSpan={isSm ? 1 : null } 
          colSpan={ isSm ? 1 : 2 }  
          backgroundImage ={ `url(${PageTransport})`}
          backgroundPosition={"center"}
          backgroundSize={'cover'}
          backgroundRepeat={"no-repeat"}
          borderRadius={'3xl'} 
          color={'#FFF'}
          display="flex"             
          flexDirection="column"      
          justifyContent="flex-end"  
        
        >
          <Box px={ isMd ? 8 : 15} py={5} >
            
            <Text color={'brand.300'} fontWeight={'bold'} fontSize={ isMd ? 'lg' : '3xl'} >
              LPG Transporter (Delivery)
            </Text>           
            
            <Text color={'font.300'} py={4} fontSize={ isMd ? 'sm' : 'base'} >
              Truckers find a reliable partner in EasyGaz, seamlessly accepting and managing delivery requests, updating delivery statuses, and contributing to a smooth and transparent LPG supply chain.              
            </Text>
            <Box
              // ml={'20px'} 
              w={'24%'}
              borderBottom={'2px solid #049541'}  
              fontWeight={'bold'}              
              color={'border.300'}
              cursor={'pointer'}
              display={'Flex'}
              alignItems={'center'}
              pb={'5px'}
              float={'right'}
            >
              <Text pr={3}>
                Make Delivery
              </Text>
              <Image
                boxSize='15px'
                objectFit='cover'
                src={Arrow}
              />
            </Box>
          </Box>
        </GridItem>
        {isSm ? null : 
          <GridItem colSpan={4} bg='tomato' />
        }
        
        
      </Grid>
     
    </Box>
  )
}

export default Services