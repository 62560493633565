import { extendTheme } from "@chakra-ui/react";
import Button from "./styles/ButtonStyles"
import { Checkbox } from "./styles/Checkbox";
import Progress from "./styles/Progress";
import { switchTheme } from "./styles/Switch";

const customTheme = extendTheme({
  config: {
    cssVarPrefix: "lpg",
    // initialColorMode: "system",
    // useSystemColorMode: true
  },
  colors: {
    primary:{
      100: "#101A36",
      200: "#049541",
      300: "#231F1F",
      400: "#545151",
      500: "#9D9B9B",
      600: "#F2F4F7",
      700: "#006FF2",
      800: "#100E0E", 
      900: "#E8EEFF",
    },
    secondary: {
      100: "#98A2B3",
      200: "#00DC23",
      300: "#F42529"
    },
    font: {
      100: "#3B3838",
      200: "#323232",
      300: "#E6E6E6",
      400: "#CECDCD",
      500: "#545151",
      600: "#858383",
      700: "#006FF2",
      800: "#090909",
      900: "#959191"
     
    },
    brand:{
      100: '#101828',
      200: '#000',
      300: '#FFF',
      400: '#F7F7F7',
      500: '#3A3A3A',
      600: '#515151'
    },
    border: {
      100: "#6C6A6A",
      200: "#B5B4B4",
      300: "#049541",
      400: "#E6E6E6",
      500: "#161616",
      600: "#5D6481",
    },
    tbrand: {
      100: "#F7F9FF"      
    }
  },
  fonts: {
    body: "Inter, sans-serif",
    heading: "Inter, sans-serif",
  },
  fontWeights: {
    hairline: 100,
    thin: 200,
    light: 300,
    normal: 400,
    medium: 500,
    semibold: 600,
    bold: 700,
    extrabold: 800,
    black: 900,
  },
  fontSizes: {
    xs: '0.75rem', 
    sm: '0.875rem',
    base: '1rem', 
    lg: '1.125rem',
    xl: '1.25rem',  
    '2xl': '1.5rem', 
    '3xl': '1.875rem',
    '3.5xl': '2rem',
    '4xl': '2.25rem',
    '5xl': '3rem', 
    '6xl': '3.75rem', 
    '7xl': '4.5rem',
    rand:{
      r1: '1.75rem',
    } 
  },
  styles: {
      global: {
        "label": {
          position: "absolute",
          top: "15px",
          left: "15px",
          color:"#858383",
          
        },
        "input:valid, textarea:valid":{
          background: 'primary.900'
        },
        "input:focus + label, input:valid + label, textarea:focus + label, textarea:valid + label": {
          top: 1,
          fontSize: "10px",
          fontWeight: 'semibold',
          transition: '0.3s',
        },
        "textarea:focus-visible": {
          outline: '1px solid #858383',
        },
        body: {
          fontFamily: "Inter, sans-serif",
          fontSize: "13px",
        },
      },
  },
 sizes: {
    max: 'max-content',
    min: 'min-content',
    full: '100%',
    '3xs': '14rem',
    '2xs': '16rem',
    xs: '20rem',
    sm: '24rem',
    md: '28rem',
    lg: '32rem',
    xl: '36rem',
    '2xl': '42rem',
    '3xl': '48rem',
    '4xl': '56rem',
    '5xl': '64rem',
    '6xl': '72rem',
    '7xl': '80rem',
    '8xl': '90rem',
    container: {
      sm: '640px',
      md: '768px',
      lg: '1024px',
      type: '1200px',
      xl: '1280px',
      xxl: '1440px'
    },
  },
  space: {
    px: "1px", // 1px
    0.5: "0.125rem", // 2px
    1: "0.25rem", // 4px
    1.5: "0.375rem", // 6px
    2: "0.5rem", // 8px
    2.5: "0.625rem", // 10px
    3: "0.75rem", // 12px
    3.5: "0.875rem", // 14px
    4: "1rem", // 16px
    5: "1.25rem", // 20px
    6: "1.5rem", // 24px
    7: "1.75rem", // 28px
    8: "2rem", // 32px
    9: "2.25rem", // 36px
    10: "2.5rem", // 40px
    12: "3rem", // 48px
    14: "3.5rem", // 56px
    15: "3.75rem", //60px
    16: "4rem", // 64px
    20: "5rem", // 80px
    24: "6rem", // 96px
    28: "7rem", // 112px
    29: "7.53125rem", //120.5px
    32: "8rem", // 128px
    36: "9rem", // 144px
    40: "10rem", // 160px
    44: "11rem", // 176px
    48: "12rem", // 192px
    52: "13rem", // 208px
    56: "14rem", // 224px
    60: "15rem", // 240px
    64: "16rem", // 256px
    72: "18rem", // 288px
    80: "20rem", // 320px
    96: "24rem", // 384px
  },
  radii: {
    none: '0',
    sm: '0.125rem',   // 2px
    base: '0.25rem',   // 4px
    md: '0.375rem',    // 6px
    lg: '0.5rem',      // 8px
    xl: '0.75rem',     // 12px
    '2xl': '1rem',     // 16px
    '3xl': '1.5rem',   // 24px
    full: '9999px',
  },
  breakpoints: {
    type: '1050px',
  },
  components: {
    Button,
    Checkbox,
    Progress,
    Switch: switchTheme
  }
})


export default customTheme;