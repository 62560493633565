import { Box, Divider, Flex, HStack, Icon, Image, Modal, ModalBody, ModalContent, ModalHeader, ModalOverlay, Tab, TabIndicator, TabList, TabPanel, TabPanels, Table, TableContainer, Tabs, Tbody, Td, Text, Th, Thead, Tr, VStack, useDisclosure } from '@chakra-ui/react';
import React, { useState } from 'react'
import { BackIcon, EmeryAva, Multicover, NoData, Revoke } from '../../../../Assets';
import { Cloudinary } from "@cloudinary/url-gen";
import {AdvancedImage} from '@cloudinary/react';
import {fill} from "@cloudinary/url-gen/actions/resize";


const AdminTransporterValidatedTable = ({ validatedTruckerData,isLoadingTrucker,isErrorTrucker,handleRevokeId}) => {

 

  const { isOpen, onOpen, onClose } = useDisclosure();
  const { isOpen: isSecondModalOpen, onOpen: onSecondModalOpen, onClose: onSecondModalClose } = useDisclosure();
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null);

  const cld = new Cloudinary({
    cloud: {
      cloudName: 'wordy'
    }
  });
  


  if (isLoadingTrucker) {
    return <div>Loading....</div>
  }

  if (isErrorTrucker) {
    return <div>Error Fetching Data</div>
  }

  validatedTruckerData.sort((a, b) => {
    const dateA = new Date(a.DateCreated);
    const dateB = new Date(b.DateCreated);
    return dateB - dateA; // Sort in descending order (newest first)
});

  return (
    <Box >
      <TableContainer>
          <Table variant='striped' colorScheme='tbrand'>
              <Thead>
                <Tr >
                  <Th borderBottom={'1px solid #E8EEFF'} color={'primary.300'} >LPG Terminals/Depot</Th>
                  <Th borderBottom={'1px solid #E8EEFF'} color={'primary.300'}  textAlign={'center'} >Email Address </Th>
                  <Th borderBottom={'1px solid #E8EEFF'} color={'primary.300'}  textAlign={'center'} >Phone Number</Th>
                  <Th borderBottom={'1px solid #E8EEFF'} color={'primary.300'}  textAlign={'center'} >Location</Th>
                  <Th borderBottom={'1px solid #E8EEFF'} color={'primary.300'}  textAlign={'center'} >Vehicle(s) </Th>
                  <Th borderBottom={'1px solid #E8EEFF'} color={'primary.300'}  textAlign={'center'} >Cum. Vehicle Capacity</Th>
                  <Th borderBottom={'1px solid #E8EEFF'} color={'primary.300'}  textAlign={'center'} >TIN</Th>
                  <Th borderBottom={'1px solid #E8EEFF'} color={'primary.300'}  textAlign={'center'} >Action</Th>
                </Tr>
              </Thead>
              
              <Tbody  >
                  {
                      validatedTruckerData.map((data) =>
                        <Tr key={data.CustomerId} >
                          <Td fontWeight={'semibold'}  >
                            <Flex alignItems={'center'} >
                              <Icon boxSize='25px' viewBox="0 0 50 50" >
                                <EmeryAva/>
                              </Icon> 
                              <Box ml={'10px'}>
                                {data.BusinessName}
                              </Box>
                            </Flex>
                          </Td>
                          <Td textAlign={'center'} >{data.EmailAddress}</Td>
                          <Td textAlign={'center'} >{data.PhoneNumber}</Td>
                          <Td textAlign={'center'} maxW="150px" isTruncated >{data.HomeAddress}, {data.LocalGovernment}, {data.CustomerState}</Td>
                          <Td textAlign={'center'} >{data.Vehicle}</Td>
                          <Td textAlign={'center'} >{data.vehicleCapacity}</Td>
                          <Td textAlign={'center'} >{data.TaxIdentificationNumber}</Td>
                          <Td 
                              onClick={() => {
                                setSelectedUserId(data.CustomerId);
                                onOpen();
                              }}  
                              cursor={'pointer'} 
                              fontWeight={'bold'} 
                              fontSize={'sm'} 
                              color={'primary.200'}  
                              textAlign={'center'} 
                          >
                            View Details
                          </Td>
                        </Tr>           
                      )
                  }
              </Tbody>                
          </Table>
      </TableContainer>

      <Modal  isOpen={isOpen} onClose={onClose} size={'full'} >
        <ModalOverlay />
        <ModalContent borderRadius={`lg`} maxW="550px" h="170vh" ml="auto"   >
          {
            selectedUserId &&
            validatedTruckerData.filter((data) => data.CustomerId === selectedUserId )
            .map((data) => 
              <Box key={data.CustomerId}>
                <ModalHeader>
                  <Flex 
                    alignItems={'center'} 
                    justifyContent={'space-between'}
                    py={'20px'}  
                  >
                    <HStack 
                      alignItems={'center'}  
                      onClick={onClose} 
                      cursor={'pointer'}  
                      w={'40%'} 
                    >                
                      <Icon boxSize='15px' viewBox="0 2 20 20"  >
                        <BackIcon  />
                      </Icon>                       
                      <Text 
                        color={'primary.800'} 
                        fontWeight={'semibold'} 
                        fontSize={'sm'}  
                      >
                        LPG Transporter
                      </Text>                 
                    </HStack>
                    <HStack 
                      alignItems={'center'}  
                      onClick={() => {
                        handleRevokeId(selectedUserId);                          
                        onClose();
                      }} 
                      cursor={'pointer'} 
                      ml={'0px'}  w={'23%'} 
                    >                
                      <Icon boxSize='15px' viewBox="2 2 20 20"  >
                        <Revoke  />
                      </Icon>                       
                      <Text 
                        color={'secondary.300'} 
                        fontWeight={'semibold'} 
                        fontSize={'sm'}  
                      >
                        Revoke User
                      </Text>                 
                    </HStack>
                  </Flex>
                  <Box position={'relative'} pb={'60px'}  textAlign='center' >
                    <Box>  
                      <Image src={Multicover}  alt='cover' />
                    </Box>
                    <Icon 
                      position={'absolute'} 
                      top={'45px'} left={'40%'} 
                      border={'6px solid #fff'} 
                      borderRadius={'50%'}  
                      boxSize='100px' 
                      viewBox="0 0 48 52" 
                      overflow="hidden"
                      textAlign='center'
                    >
                      <EmeryAva/>
                    </Icon> 
                    <Box
                      position={'absolute'} 
                      top={'10px'} right={'2%'} 
                      borderRadius={`full`}
                      color={'secondary.200'}
                      bg={'primary.600'}
                      py={.5}
                      // px={6}
                      fontWeight={`normal`}
                      fontSize={`xs`}
                      w={'15%'}
                    >
                      Validated
                    </Box>
                  </Box>
                  <Text 
                    fontWeight='bold' 
                    fontSize='2xl' 
                    color='primary.100' 
                    textAlign='center'  
                  >
                    FERMA Delivery
                  </Text>
                </ModalHeader>
                <ModalBody>
                  <Box>
                    <Box>
                      <Text fontSize={'sm'} color={'font.100'} pb={'8px'}  >
                        Basic Details
                      </Text>
                      <Flex alignItems={'center'} justifyContent={'space-between'} >
                        <HStack>
                          <Text 
                            color={'font.600'} 
                            fontSize={'xs'} 
                            fontWeight={'semibold'}
                          >
                            Phone Number:                               
                          </Text>
                          <Text color={'primary.300'} fontSize={'sm'}>
                            {data.PhoneNumber}
                          </Text>
                        </HStack>
                        <HStack>
                          <Text 
                              color={'font.600'} 
                              fontSize={'xs'} 
                              fontWeight={'semibold'}
                          >
                              Email Address: 
                          </Text>
                          <Text 
                            color={'primary.300'} 
                            fontSize={'sm'}  
                            isTruncated w={'50%'} 
                          >
                            {data.EmailAddress}
                          </Text>
                        </HStack>
                      </Flex>
                      <HStack pt={'20px'} pb={'10px'} >
                        <Text 
                          color={'font.600'} 
                          fontSize={'xs'} 
                          fontWeight={'semibold'} 
                        >
                          Full Address:
                        </Text>
                        <Text color={'primary.300'} fontSize={'sm'}  >
                          {data.HomeAddress}
                        </Text>
                      </HStack>
                      <HStack pt={'20px'} pb={'10px'} >
                        <Text 
                          color={'font.600'} 
                          fontSize={'xs'} 
                          fontWeight={'semibold'} 
                        >
                          LGA & State:
                        </Text>
                        <Text color={'primary.300'} fontSize={'sm'}  >
                          {data.LocalGovernment}, {data.CustomerState}
                        </Text>
                      </HStack>
                    </Box>
                    <Box py={'20px'} >
                      <Text fontSize={'sm'} color={'font.100'} pb={'8px'}  >
                        Company Details
                      </Text>
                      <Flex alignItems={'center'} justifyContent={'space-between'}>
                        <Box  >
                          <HStack py={'10px'} >
                            <Text 
                              color={'font.600'} 
                              fontSize={'xs'} 
                              fontWeight={'semibold'}
                            >
                            Bank Name:                               
                            </Text>
                            <Text color={'primary.300'} fontSize={'sm'}>
                              {data.AccountBank}
                            </Text>
                          </HStack>
                          <HStack py={'10px'}  >
                            <Text 
                              color={'font.600'} 
                              fontSize={'xs'} 
                              fontWeight={'semibold'}
                            >
                              Vehicles:                              
                            </Text>
                            <Text color={'primary.300'} fontSize={'sm'}>
                              {data.Vehicle}
                            </Text>
                          </HStack>
                          
                        </Box>
                        <Box >
                          <HStack py={'10px'} >
                            <Text 
                              color={'font.600'} 
                              fontSize={'xs'} 
                              fontWeight={'semibold'}
                            >
                              Bank Account: 
                            </Text>
                            <Text color={'primary.300'} fontSize={'sm'}  isTruncated w={'50%'} >
                              {data.AccountNumber}
                            </Text>
                          </HStack>
                          <HStack py={'10px'} >
                            <Text 
                              color={'font.600'} 
                              fontSize={'xs'} 
                              fontWeight={'semibold'}
                            >
                            Tax Number:
                            </Text>
                            <Text color={'primary.300'} fontSize={'sm'}   >
                              {data.TaxIdentificationNumber}
                            </Text>
                          </HStack>
                        </Box>
                      </Flex>             
                    </Box>
                    <Tabs variant="unstyled" bg='#fff'  borderRadius={'lg'} pt={2} >
                      <TabList p={'0 10px'}>
                          <Tab 
                            color={'border.100'} 
                            _selected={{ color: "primary.100", 
                              borderBottom: "2px solid primary.100", 
                              fontWeight: 'semibold' }}
                          >
                            Delivery
                          </Tab>             
                          <Tab 
                            color={'border.100'} 
                            _selected={{ color: "primary.100", 
                              borderBottom: "2px solid primary.100", 
                              fontWeight: 'semibold' 
                            }}
                          >
                            Vehicle(s)
                          </Tab>             
                          <Tab 
                            color={'border.100'}
                            _selected={{ color: "primary.100", 
                            borderBottom: "2px solid primary.100", 
                            fontWeight: 'semibold' }}
                          >
                            Documents
                          </Tab>             
                      </TabList>
                      <TabIndicator
                        mt="-1.5px"
                        height="2px"
                        bg="primary.100"
                        borderRadius="1px"             
                      />
                      <Divider/>
                      <TabPanels>
                        <TabPanel>
                          <Flex
                            alignItems={`center`}
                            justifyContent={`center`}
                            h={`100%`}
                          >
                            <VStack spacing={2}>
                              
                              <Icon boxSize='150px' viewBox="0 0 250 250" >
                              <NoData/>                     
                              </Icon> 
                              <Text
                                fontWeight={`bold`}
                              >
                                No Product Added
                              </Text>
                            </VStack>
                          </Flex>
                        </TabPanel>                  
                        <TabPanel>
                          <Flex
                            alignItems={`center`}
                            justifyContent={`center`}
                            h={`100%`}
                          >
                            <VStack spacing={2}>
                              
                              <Icon boxSize='150px' viewBox="0 0 250 250" >
                                <NoData />                      
                              </Icon> 
                              <Text
                                fontWeight={`bold`}
                              >
                                No Vehicle Added
                              </Text>
                            </VStack>


                          </Flex>
                        </TabPanel>                  
                        <TabPanel>
                          <Flex
                            alignItems={`center`}
                            // justifyContent={`center`}
                            direction={'column'}
                            h={`100%`}
                          >
                            
                            <HStack alignItems={`center`}>
                              <VStack>
                                <Text fontWeight={'semibold'} color={'primary.300'} textAlign={'center'}  >
                                  Certificate Of Incorporation
                                </Text>
                                <Box 
                                  as="button"
                                  key={`image-${data.CustomerId}-1`}
                                  onClick={() => {
                                    setSelectedImage(data.CertificateOfIncorporation);
                                    onSecondModalOpen();
                                  }}
                                >
                                  {data.CertificateOfIncorporation && (
                                    <AdvancedImage
                                      cldImg={cld.image(data.CertificateOfIncorporation.split('/').pop()).resize(fill().width(150).height(150))}
                                    />
                                  )}

                                </Box>
                              </VStack>
                              <VStack>
                                <Text fontWeight={'semibold'} color={'primary.300'} textAlign={'center'} >
                                  Certificate Of Registration
                                </Text>
                                <Box 
                                  px={3}
                                  as="button"
                                  key={`image-${data.CustomerId}-2`}
                                  onClick={() => {
                                    setSelectedImage(data.CertificateOfRegistration);
                                    onSecondModalOpen();
                                  }}
                                >
                                  {data.CertificateOfRegistration && (
                                    <AdvancedImage
                                      cldImg={cld.image(data.CertificateOfRegistration.split('/').pop()).resize(fill().width(150).height(150))}
                                    />
                                  )}

                                </Box>
                              </VStack>

                            </HStack>

                            <HStack alignItems={'center'} mt={'15px'} > 
                              <VStack>
                                <Text fontWeight={'semibold'} color={'primary.300'} textAlign={'center'}  >
                                  Operating Licence
                                </Text>
                                <Box
                                  as="button"
                                  key={`image-${data.CustomerId}-3`}
                                  onClick={() => {
                                    setSelectedImage(data.HaulageLicence);
                                    onSecondModalOpen();
                                  }}
                                >
                                  {data.HaulageLicence && (
                                    <AdvancedImage
                                      cldImg={cld.image(data.HaulageLicence.split('/').pop()).resize(fill().width(150).height(150))}
                                    />
                                  )}

                                </Box>

                              </VStack>
                              <VStack>
                                <Text fontWeight={'semibold'} color={'primary.300'} textAlign={'center'}  >
                                  Transit Insurance
                                </Text>
                                <Box
                                as="button"
                                key={`image-${data.CustomerId}-4`}
                                onClick={() => {
                                  setSelectedImage(data.TransitInsurance);
                                  onSecondModalOpen();
                                }}
                                >
                                  {data.TransitInsurance && (
                                    <AdvancedImage
                                      cldImg={cld.image(data.TransitInsurance.split('/').pop()).resize(fill().width(150).height(150))}
                                    />
                                  )}

                                </Box>
                              </VStack>                            

                            </HStack>

                          </Flex>
                        </TabPanel>                  
                      </TabPanels>
                    </Tabs>
                    <Box>
                    </Box>
                  </Box>
                </ModalBody>          
                
              </Box>
            )
          }
        </ModalContent>
      </Modal>        

      {/* IMAGE MODAL */}

      <Modal isOpen={isSecondModalOpen} onClose={onSecondModalClose} size={'full'}>
        <ModalOverlay />
        <ModalContent borderRadius={`lg`} maxW="550px" h="100vh" ml="auto">
          {selectedImage && (
            <Box>
              <ModalBody>
                <Box textAlign={'center'} >
                  {/* THE WIDTH AND HEIGHT CAN BE 800 BUT LARGER IMAGE WILL RESULT WITH LONGER LOADING TIME */}
                  <Box>  
                    <Image src={Multicover}  alt='cover' />
                  </Box>
                  <AdvancedImage
                    cldImg={cld.image(selectedImage.split('/').pop()).resize(fill().width(500).height(500))}
                  />
                
                </Box>
              </ModalBody>
            </Box>
          )}
        </ModalContent>
      </Modal>


    </Box>
  )
}

export default AdminTransporterValidatedTable