import { Outlet, useNavigate } from 'react-router-dom'
import { Box, Button, Flex, FormControl, FormLabel, Input, InputGroup, InputRightElement, Text } from '@chakra-ui/react'
import Nav from '../../Navbar/Nav'
import {
  HomeIcon, 
  Ahome, 
  Iaccount, 
  Iproduct,
  Iorders,
  Irequest,
  Aaccount,
  Aorders,
  Aproduct,
  Arequest, 
  Terminal,
  Aterminal,
  Iplant,
  Aplant,
  Itransport,
  Atransport,
  Iadmin,
  Aadmin,
  Apassword,
  Password
} from '../../../Assets';
import { useContext, useState } from 'react';
import AuthContext from '../Contexts/AdminContext';


export const MenuNavigation = [
  {
    icon: {
      inactive: (
        <HomeIcon/>
      ),
      active: (
        <Ahome/>
      ),
    },
    title: "Overview",
    route: "overview",
  },
  {
    icon: {
      inactive: (
        <Iaccount/>
      ),
      active: (
        <Aaccount/>
      ),
    },
    title: "Accounts",
    route: "accounts",
    submenu: [
      {
        icon: {
          inactive: (
            <Terminal/>
          ),
          active: (
            <Aterminal/>
          ),
        },
        title: 'LPG Terminals/Depot',
        route: 'lpg-terminals',
      },
      {
        icon: {
          inactive: (
            <Iplant/>
          ),
          active: (
            <Aplant/>
          ),
        },
        title: "LPG Filling Plants",
        route: "lpg-plants",
      },
      {
        icon: {
          inactive: (
            <Itransport/>
          ),
          active: (
            <Atransport/>
          ),
        },
        title: 'LPG Transporter',
        route: 'lpg-transporter',
      },
      {
        icon: {
          inactive: (
            <Iadmin/>
          ),
          active: (
            <Aadmin/>
          ),
        },
        title: 'Admin',
        route: 'admin',
      },
    ]
  },
  {
    icon: {
      inactive: (
        <Irequest/>
      ),
      active: (
        <Arequest/>
      ),
    },
    title: "Requests",
    route: "requests",
  },
  {
    icon: {
      inactive: (
        <Iorders/>
      ),
      active: (
        <Aorders/>
      ),
    },
    title: "Orders",
    route: "orders",
  },
  {
    icon: {
      inactive: (
        <Iproduct/>
      ),
      active: (
        <Aproduct/>
      ),
    },
    title: "Products",
    route: "products",
  },
    
];

const route = '/AdminPage' 

const AdminDashboard = () => {

  const initalPasswordData = {
    newPassword: '',
    confirmNewPassword: '',
  };


  const [passwordData, setPasswordData] = useState(initalPasswordData);
  const { handleChangePassword, changePassword, setChangePassword} = useContext(AuthContext);
  const navigate = useNavigate();

 

  const handlePasswordChange = (e) => {
    const { name, value } = e.target;
    setPasswordData((data) => {
      return {...data, [name]: value}
    })
  };



const handleSubmit =  (e) => {
  e.preventDefault();    
  // console.log('Login Data:', loginData);
  handleChangePassword(passwordData);
  navigate('/');
  setChangePassword(false);
  // window.location.reload(); // Refreshes the tab
  
};


  return (
    <Box height="100vh" position='relative'>
      <Nav Outlet = {<Outlet/>} MenuNavigation = {MenuNavigation} route={route} />

 

      {
        changePassword ?         
        <Box
          position="fixed"
          top={0}
          left={0}
          width="100%"
          height="100%"
          display="flex"
          justifyContent="center"
          alignItems="center"
          background=" #0909093f"
          back
          zIndex="999"
        >
          <Flex
            flexDir="column"
            width="500px"
            height="300px"
            borderRadius="32px"
            background="#fff"
            textAlign="center"
            pt={5}

          >

            <Text textAlign={'center'} color={'primary.800'} fontWeight={'bold'} fontSize={'lg'}  >
              Before You Can Proceed!
            </Text>
            <Text textAlign={'center'} color={'font.100'} >
              You need to change your password to secure your account.
            </Text>
            
            <form style={{width: "80%", margin:"auto"}} onSubmit={handleSubmit}>
                <FormControl>
                  <InputGroup>
                    <Input 
                      type="password" 
                      focusBorderColor='#858383' 
                      _focus={{ boxShadow: "none" }} 
                      bg={passwordData.newPassword ? 'primary.900' : 'transparent' }
                      h="50px" id="newPassword"
                      fontSize="14px" required
                      borderColor="#B5B4B4"
                      value={passwordData.newPassword}
                      onChange={handlePasswordChange }
                      name='newPassword'
                    />
                    <FormLabel 
                      fontWeight="normal" 
                      fontSize="12px" 
                      htmlFor="newPassword"
                      zIndex={'999'}
                    >
                      New Password
                    </FormLabel>
                    <InputRightElement h={'100%'}>
                      { 
                        passwordData.newPassword ?  <Apassword /> : <Password/> 
                      }                        
                    </InputRightElement>
                  
                  </InputGroup>
                </FormControl>
                <FormControl mt="10px">
                  <InputGroup>                  
                    <Input 
                      type="password" 
                      focusBorderColor='#858383' 
                      _focus={{ boxShadow: "none" }} 
                      bg={passwordData.confirmNewPassword ? 'primary.900' : 'transparent' }
                      h="50px" id="confirmNewPassword"
                      fontSize="14px" required
                      borderColor="#B5B4B4"
                      value={passwordData.confirmNewPassword}
                      onChange={handlePasswordChange }
                      name='confirmNewPassword'
                    />
                    <FormLabel 
                      fontWeight="normal" 
                      fontSize="12px" 
                      htmlFor="confirmNewPassword"
                      zIndex={'999'}
                    >
                        Confirm New Password
                    </FormLabel>
                    <InputRightElement h={'100%'}>
                      { 
                        passwordData.confirmNewPassword ?  <Apassword /> : <Password/> 
                      }                        
                    </InputRightElement>
                  </InputGroup>
                </FormControl>
                <Button
                  // m="60px 35px"
                  mt={'30px'}
                  bg="primary.100" 
                  color="#fff" 
                  p="20px 56px" 
                  h="48px" 
                  width="100%" 
                  _focus={{ boxShadow: "none" }} 
                  _hover={{bg: 'primary.100',
                  color: 'none',}}
                  type="submit"      

                >
                  Change Password
                </Button>
            </form>


          </Flex>

        </Box> : null
      }

       
     
    </Box>
  )
}

export default AdminDashboard