import { 
  // Box, 
  // TableContainer, 
  // Table, 
  // Thead, 
  // Tbody, 
  // Td, 
  // Th, 
  // Tr, 
  Text, 
  Flex,
  VStack,
  Icon, 
  // VStack, 
  // Icon 
} from '@chakra-ui/react';
import { EmeryAva, NoData } from '../../../../Assets'
import { Link } from 'react-router-dom';




const tableStyle = {
  overflowY: 'auto',
  height: '100%',
  background:'#fff'
};

const thStyle = {
  position: 'sticky',
  top: '0px',
  color: '#3B3838',
  fontWeight: 'normal',
  textAlign: 'center',
  background:'#fff',
  padding: '10px 0'
};

const tableCellStyle = {
  padding: '3px 16px',
  borderBottom: '1px solid #E0E0E0',
  borderTop: '1px solid #E0E0E0',
};

const thCellStyle = {
  ...tableCellStyle,
  background: '#fff',
};



const AdminOverviewRHS = () => {

  const data = [
    {
      plant: "Emery Westervelt",
      icon: <EmeryAva />,
      date: "24 Aug, 2023",
      depot: "Brick Fest Live Marlborough, MA",
      status: "In Progress",
      quantity: "21kg",
      stateBg: "#006FF21A",
      color: `font.700`
    },
    {
      plant: "Emery Westervelt",
      icon: <EmeryAva />,
      date: "24 Aug, 2023",
      depot: "Brick Fest Live Marlborough, MA",
      status: "In Progress",
      quantity: "21kg",
      stateBg: "#006FF21A",
      color: `font.700`
    },
    {
      plant: "Emery Westervelt",
      icon: <EmeryAva />,
      date: "24 Aug, 2023",
      depot: "Brick Fest Live Marlborough, MA",
      status: "In Progress",
      quantity: "21kg",
      stateBg: "#006FF21A",
      color: `font.700`
    },
    {
      plant: "Emery Westervelt",
      icon: <EmeryAva />,
      date: "24 Aug, 2023",
      depot: "Brick Fest Live Marlborough, MA",
      status: "In Progress",
      quantity: "21kg",
      stateBg: "#006FF21A",
      color: `font.700`
    },
    {
      plant: "Emery Westervelt",
      icon: <EmeryAva />,
      date: "24 Aug, 2023",
      depot: "Brick Fest Live Marlborough, MA",
      status: "In Progress",
      quantity: "21kg",
      stateBg: "#006FF21A",
      color: `font.700`
    },
    {
      plant: "Emery Westervelt",
      icon: <EmeryAva />,
      date: "24 Aug, 2023",
      depot: "Brick Fest Live Marlborough, MA",
      status: "In Progress",
      quantity: "21kg",
      stateBg: "#006FF21A",
      color: `font.700`
    },
    {
      plant: "Dulce Botosh",
      icon: <EmeryAva />,
      date: "24 Aug, 2023",
      depot: "Brick Fest Live Marlborough, MA",
      status: "In Progress",
      quantity: "21kg",
      stateBg: "#006FF21A",
      color: `font.700`
    },
    {
      plant: "Erin Carder",
      icon: <EmeryAva />,
      date: "24 Aug, 2023",
      depot: "Brick Fest Live Marlborough, MA",
      status: "Canceled",
      quantity: "21kg",
      stateBg: "#F4252921A",
      color: `secondary.300`
    },
    {
      plant: "Erin Carder",
      icon: <EmeryAva />,
      date: "24 Aug, 2023",
      depot: "Brick Fest Live Marlborough, MA",
      status: "Canceled",
      quantity: "21kg",
      stateBg: "#F4252921A",
      color: `secondary.300`
    },
    {
      plant: "Erin Carder",
      icon: <EmeryAva />,
      date: "24 Aug, 2023",
      depot: "Brick Fest Live Marlborough, MA",
      status: "Canceled",
      quantity: "21kg",
      stateBg: "#F4252921A",
      color: `secondary.300`
    },
    {
      plant: "Erin Carder",
      icon: <EmeryAva />,
      date: "24 Aug, 2023",
      depot: "Brick Fest Live Marlborough, MA",
      status: "Canceled",
      quantity: "21kg",
      stateBg: "#F4252921A",
      color: `secondary.300`
    },
    {
      plant: "James Westervelt",
      icon: <EmeryAva />,
      date: "24 Aug, 2023",
      depot: "Brick Fest Live Marlborough, MA",
      status: "Canceled",
      quantity: "21kg",
      stateBg: "#F4252921A",
      color: `secondary.300`
    },
    {
      plant: "James Westervelt",
      icon: <EmeryAva />,
      date: "24 Aug, 2023",
      depot: "Brick Fest Live Marlborough, MA",
      status: "Canceled",
      quantity: "21kg",
      stateBg: "#F4252921A",
      color: `secondary.300`
    },
    {
      plant: "James Westervelt",
      icon: <EmeryAva />,
      date: "24 Aug, 2023",
      depot: "Brick Fest Live Marlborough, MA",
      status: "Success",
      quantity: "21kg",
      stateBg: "#00DC2321A",
      color: `primary.200`
    },
    {
      plant: "James Westervelt",
      icon: <EmeryAva />,
      date: "24 Aug, 2023",
      depot: "Brick Fest Live Marlborough, MA",
      status: "Success",
      quantity: "21kg",
      stateBg: "#00DC2321A",
      color: `primary.200`
    },
    {
      plant: "James Westervelt",
      icon: <EmeryAva />,
      date: "24 Aug, 2023",
      depot: "Brick Fest Live Marlborough, MA",
      status: "Success",
      quantity: "21kg",
      stateBg: "#00DC2321A",
      color: `primary.200`
    },
    {
      plant: "James Westervelt",
      icon: <EmeryAva />,
      date: "24 Aug, 2023",
      depot: "Brick Fest Live Marlborough, MA",
      status: "Success",
      quantity: "21kg",
      stateBg: "#00DC2321A",
      color: `primary.200`
    },
    
  ]

  return(
    <div style={tableStyle}>
       <Flex 
        alignItems={'center'} 
        justifyContent={'space-between'} 
        overflowY={`none`}
        
      >
        <Text
            fontWeight={`semibold`}
            fontSize={`lg`}
            mb={1}
            color={`primary.300`}
        >
          Recent Initiated Orders
        </Text>
        <Link to='/' style={{
            color: '#049541',
            fontWeight: '600',
            fontSize: "0.875rem",
            paddingRight: "10px"
          }}
        >
          Show all
        </Link>
          
      </Flex>

      <Flex
        alignItems={`center`}
        justifyContent={`center`}
        h={`100%`}
      >
        <VStack spacing={8}>
          
          <Icon boxSize='100px' viewBox="0 0 250 250" >
            <NoData />
            
          </Icon> 
          <Text
            fontWeight={`bold`}
          >
           No Transaction yet!!
          </Text>
        </VStack>
      </Flex>

      {/* <table style={{background:'#fff', width: '100%', borderCollapse: 'collapse' }}>
        <thead>
          <tr>
            <th style={thStyle}>LPG Filling Plants</th>
            <th style={thStyle}>Status</th>
            <th style={thStyle}>LPG Terminals/Depo</th>
            <th style={thStyle}>Date</th>
            <th style={thStyle}>Quantity</th>
          </tr>
        </thead>
        <tbody>
          {
            data.map((data, i) => 
              <tr key={i}>
                <td style={tableCellStyle}>{data.plant}</td>
                <td style={tableCellStyle}>{data.status}</td>
                <td style={tableCellStyle}>{data.depot}</td>
                <td style={tableCellStyle}>{data.date}</td>
                <td style={tableCellStyle}>{data.quantity}</td>
              </tr>
            
            )
          }
        </tbody>
      </table> */}
  </div>

  )

  // return(
  //   <Box height={'100%'} bg={`#fff`}   >

  //     <Flex 
  //       alignItems={'center'} 
  //       justifyContent={'space-between'} 
  //       overflowY={`none`}
        
  //     >
  //       <Text
  //           fontWeight={`semibold`}
  //           fontSize={`lg`}
  //           mb={1}
  //           color={`primary.300`}
  //       >
  //         Recent Initiated Orders
  //       </Text>
  //       <Link to='/' style={{
  //           color: '#049541',
  //           fontWeight: '600',
  //           fontSize: "0.875rem",
  //           paddingRight: "10px"
  //         }}
  //       >
  //         Show all
  //       </Link>
          
  //     </Flex>
      
  //     <TableContainer>
  //       <Table variant='simple' bg={`#fff`}>
  //         <Thead position='sticky'> 
  //           <Tr  >
              
  //             <Th >LPG Filling Plants</Th>
  //             <Th>Status</Th>
  //             <Th>LPG Terminals/Depot</Th>
  //             <Th isNumeric>Date</Th>
  //             <Th isNumeric>Quantity</Th>
  //           </Tr>
  //         </Thead>
          
  //         <Tbody  >
            
  //           {
  //             data.map((data, i) => (
                
  //               <Tr key={i} >
  //                 <Td>{data.plant}</Td>
  //                 <Td>{data.status}</Td>
  //                 <Td>{data.depot}</Td>
  //                 <Td isNumeric>{data.date}</Td>
  //                 <Td isNumeric>{data.quantity}</Td>
  //               </Tr>

  //             ))
  //           }
           
  //         </Tbody>         
  //       </Table>
  //     </TableContainer>
  //   </Box>
  // )


  //   const font = {
  //     textTransform: "none",
  //     textAlign: "center",
  //     fontWeight: 400,
  //     color: "#3B3838",
  //     width: "20%"
  //   }
  
  //   const itemElements = [];
  
  //   for (let i = 0; i < 20; i++) {
  //     itemElements.push(
  //       <Tr key={i}>
  //         <Td style={font}>inches</Td>
  //         <Td style={font}>millimetres (mm)</Td>
  //         <Td style={font}>millimetres (mm)</Td>
  //         <Td style={font}>millimetres (mm)</Td>
  //         <Td style={font} isNumeric>{i}</Td>
  //         <Td style={font} isNumeric>{i}</Td>
  //       </Tr>
  //     );
  //   }
  
  //   return (
  //     <Box
  //       // px={6}
  //       py={4}
  //       h={`100%`}
  //       overflowY={`hidden`}
  //       bg={`#fff`}
  //     >
  //       <Flex
  //         alignItems={'center'} 
  //         justifyContent={'space-between'}
  //       >
  //         <Text
  //           fontWeight={`semibold`}
  //           fontSize={`lg`}
  //           mb={1}
  //         >
  //           Recent Initiated Orders
  //         </Text>
  //         <Link to='/' 
  //           style={{
  //             color: '#049541',
  //             fontWeight: '600',
  //             fontSize: "0.875rem",
  //             paddingRight: "10px"
  //           }}
  //         >
  //           Show all
  //         </Link>
  //       </Flex>
  //       <TableContainer>
  //         <Table>
  //           <Thead>
  //             <Tr   >
  //               <Th style={font}>LPG Filling Plants</Th>
  //               <Th style={font}>Status</Th>
  //               <Th style={font}>LPG Terminals/Depot</Th>
  //               <Th style={font}>Date</Th>
  //               <Th style={font} isNumeric>Quantity</Th>
  //             </Tr>
  //           </Thead>
  //         </Table>
  //       </TableContainer>
  //       <Box
  //         overflowY="auto"
  //         h="100%"
  //         pb={14}
  //         sx={
  //             { 
  //             '::-webkit-scrollbar':{
  //                   display:'none'
  //               }
  //             }
  //         }
  //       >
  //         <TableContainer>
  //         <Table>
  //             <Tbody>
  //               {itemElements}
  //           </Tbody>
  //             </Table>
  //         </TableContainer>
  //       </Box>
  //         {/* <Flex
  //           alignItems={`center`}
  //           justifyContent={`center`}
  //           h={`80%`}
  //         >
  //         <VStack spacing={8}>
  //           <NoData />
  //         <Text
  //           fontWeight={`bold`}
  //         >
  //           No Transaction yet!!
  //         </Text>
  //           </VStack>
  //         </Flex> */}
  //     </Box>
  //   )
}




export default AdminOverviewRHS;