import { useState } from "react";
import { Container, Stack, useMediaQuery } from "@chakra-ui/react"
import { UserType } from "./UserType"
import ArrowButton from "./Button"
import * as SVGIcons from "../../Assets/index";
import PropTypes from "prop-types"
import { useNavigate } from "react-router-dom";

export const ChooseType = (props) => {
    const [isSm] = useMediaQuery(`(max-width: 1050px)`);
    const [isXl] = useMediaQuery(`(max-width: 450px)`);
    const stackDirection = isSm ? "column" : "row";
    const buttonSize = isSm ? isXl ? "xs" : "sm" : "lg";
    const [selectedBox, setSelectedBox] = useState(null);
    const navigate = useNavigate();
console.log(selectedBox);
    const data = [
        {
            id: 1,
            icon: <SVGIcons.Person />,
            alt: "Person icon",
            type: "LPG Filling Plants (Customers)",
            desc: "These are the end-users who purchase LPG through the platform.",
            value: "Customers"
        },
        {
            id: 2,
            icon: <SVGIcons.GasStation />,
            alt: "Gas station icon",
            type: "LPG Terminals/Depot (Vendors)",
            desc: "These are the suppliers or LPG terminals that provide the gas.",
            value: "Vendors"
        },
        {
            id: 3,
            icon: <SVGIcons.Truck />,
            alt: "Truck icon",
            type: "LPG Transporter (Delivery)",
            desc: "These entities are responsible for transporting LPG from the terminals to the customers' locations.",
            value: "Delivery"
        }
    ]

  return ( 
      <Container
          maxW={"container.lg"}
          minH={`300px`}
          bg={`primary.600`}
          centerContent={true}
          borderRadius={'lg'}
          p={8}
        //   onClick={()=>{console.log(data.userType)}}
      >
        <Stack spacing={8} direction={stackDirection}>
            {data.map((data) => {
                return <UserType
                    key={data.id}
                    id={data.id}
                    icon={data.icon}
                    type={data.type}
                    alt={data.alt}
                    desc={data.desc}
                    onClick={(clickedId) => {
                        setSelectedBox(clickedId)
                        // props.updateData({ userType: data.value })
                    }}
                    isSelected={selectedBox === data.id}
                    userType={props.userType}
                    value={data.value}
                />
            })}
        </Stack>
        <ArrowButton
            size={buttonSize}
            isSelected={selectedBox}
            text="Continue"
            isIcon={true}
            icon={<SVGIcons.ArrowRight />}
            margin={10}
            disabled={true}
            move={props.next}
            onClick={() => {
                selectedBox === 1 ? navigate("/customer") :
                selectedBox === 2 ? navigate("/vendor") :
                selectedBox === 3 ? navigate("/delivery") :
                alert("you were never meant to reach here")
            }}
        />
     </Container>
  )
}

ChooseType.proptype = {
    userType: PropTypes.string,
    updateData: PropTypes.func,
    next: PropTypes.func
}