import CryptoJS from "crypto-js";

export const encrypt = (data) => {
    const key = CryptoJS.enc.Utf8.parse('zAL7X5AVRm8l4Ifs');
    const iv = CryptoJS.enc.Utf8.parse('BE/s3V0HtpPsE+1x'); // You should use a proper IV
    const dataString = JSON.stringify(data);
    // console.log(dataString)

    const encryptedData = CryptoJS.AES.encrypt(
        CryptoJS.enc.Utf8.parse(dataString),
        key,
        {
            iv,
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.Pkcs7,
        }
    );

    const encryptedText = encryptedData.toString();
    // console.log(encryptedText);
    return encryptedText;
};


export const decrypt = (encryptedText) => {
    const key = CryptoJS.enc.Utf8.parse('zAL7X5AVRm8l4Ifs');
    const iv = CryptoJS.enc.Utf8.parse('BE/s3V0HtpPsE+1x'); // Use the same IV as in encryption

    const decryptedData = CryptoJS.AES.decrypt(encryptedText, key, {
        iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7,
    });

    if (decryptedData) {
        const decryptedText = decryptedData.toString(CryptoJS.enc.Utf8);
        return decryptedText;
    } else {
        console.log('Decryption failed.');
        return null;
    }
};