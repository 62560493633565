import { Box, Flex, ListItem, Text, UnorderedList } from '@chakra-ui/react'
import React from 'react'
import { FullLogo } from '../Assets'

const Terms = () => {
  return (
    <Box>
        <Flex 
            alignItems={'center'}
            justifyContent={'space-between'}
            // justifyContent={'space-evenly'}
            // border={'1px solid red'}
            py={4}
            px={20}
            borderBottom={'1px solid #CECDCD'}
        >
        
        <Box>
            <FullLogo/>
        </Box>

        <Text color={'#fff'} >
            .
        </Text>

        </Flex>
        <Box px={20} py={4} >

            <Text>
                If you do not wish to be bound by this Agreement, do not access Hemsee
                Technologies Ltd Website. If you have any questions about this Agreement,
                please contact us via e-mail at (insert email address).
            </Text>
            <Text>                
                THIS AGREEMENT "Agreement" is entered into by and
                between Hemsee Technologies Ltd and any individual, corporation, association,
                agency, company, or other entity who accesses or uses Hemsee Technologies Ltd
                Website (the "User" or "you").
            </Text>             
            <Text>
                Hemsee Technologies Ltd Website, which is provided without
                charge to you, is a World Wide Web site on the Internet that is designed to
                allow Hemsee Technologies Ltd to communicate with users and for you to interact
                with those users. Hemsee Technologies Ltd Website is owned and operated by Hemsee
                Technologies Ltd. Hemsee Technologies Ltd Website contains or may contain
                information, communications, opinions, text, graphics, links, electronic art,
                animations, audio, video, software, photos, music, sounds and other material
                and data (collectively, "Content") formatted, organized and collected
                in a variety of forms that are generally accessible to Users, including
                directories and databases, and areas of Hemsee Technologies Ltd Website that
                can be modified by Users, such as posting classifieds, uploading multimedia
                files, registering user profiles, and creating auto-notify, personalized pages,
                and personalized project areas.
            </Text>
            
            <Text fontWeight={'bold'} >
                Access to Hemsee Technologies Ltd Website
            </Text>

            <Text>
                You are responsible
                for providing all hardware, software, telephone or other communications
                equipment and/or service to connect to the Internet and access Hemsee
                Technologies Ltd Website and are responsible for all Internet access charges,
                telephone charges or other fees or charges incurred in connecting to the
                Internet and accessing Hemsee Technologies Ltd Website.                
            </Text>
                                                        
                            
            <Text fontWeight={'bold'} >
                User Conduct
            </Text>
                            
            <Text>
                You agree to access
                and use Hemsee Technologies Ltd Website only for lawful purposes. You are
                solely responsible for the knowledge of and adherence to any and all laws,
                statutes, rules and regulations, pertaining to (i) your use of Hemsee
                Technologies Ltd Website, (ii) the use of any networks or other services
                connected to Hemsee Technologies Ltd Website, and (iii) the communications
                means by which you connect your modem, computer, or other electronic
                device to Hemsee
                Technologies Ltd Website. By accessing Hemsee Technologies Ltd Website, you
                agree that you will not:                
            </Text> 

            <UnorderedList>
                <ListItem>Restrict or inhibit any other user from using the Website,</ListItem>
                <ListItem>
                    Post, transmit or make available for sharing any unlawful, threatening, abusive, libelous,
                    defamatory, obscene, vulgar, pornographic, profane, hateful or indecent information of any kind, including
                    without limitation any transmissions constituting or encouraging conduct
                    that would be
                    likely to constitute a breach of the
                    public peace and as such a
                    criminal offence, give rise to civil liability or otherwise violate any
                    local, state, federal, or
                    international law;
                </ListItem>
                <ListItem>
                    Post, transmit or make available for sharing any information, software, or other material
                    which violates or infringes on
                    the rights of others, including material which is likely to constitute an invasion of privacy or publicity rights or
                    which is protected by copyright, trademark or other proprietary right, or
                    derivative works with respect thereto, without first obtaining permission
                    from the owner or right holder.
                </ListItem>
                <ListItem>
                    Post, transmit or make available for sharing any information, software or other material
                    which contains a virus or other harmful component capable of shutting
                    down networks and tampering with customer or other data;
                </ListItem>
                <ListItem>
                    Alter, damage or delete any
                    Content or other communications that are not your own Content or to
                    otherwise interfere with the ability of others to access Hemsee
                    Technologies Ltd Website;
                </ListItem>
                <ListItem>
                    Claim a relationship with or
                    to speak for any business, association, institution or other organization
                    for which you are not authorized to claim such a relationship;
                </ListItem>
                <ListItem>      
                    Violate any operating rule,
                    policy or guideline of your Internet access provider or online service.
                </ListItem>
            </UnorderedList>              
            <Text fontWeight={'bold'} >
                Hemsee Technologies Ltd Website content                            
            </Text>
            <Text>
                You acknowledge
                that Content on Hemsee Technologies Ltd Website is generally provided by Hemsee
                Technologies Ltd, individual contributors of Content
                ("Contributors"), third party licensees, and/or other Users. You
                acknowledge that Hemsee Technologies Ltd Website permits access to content that is protected by
                copyrights, trademarks, and other proprietary (including intellectual property)
                rights ("Intellectual Property Rights"), and that these Intellectual
                Property Rights are valid and protected in all media formats existing now or later developed and
                except as is explicitly provided below, your use of content shall be governed by
                applicable copyright and other intellectual property laws. You acknowledge that
                Hemsee Technologies Ltd Website owns a copyright in the "look and
                feel," i.e., the selection, coordination, arrangement and presentation of
                such content.
            </Text>
            <Text>
                You shall not modify, copy,
                reproduce, transmit, distribute, publish, create derivative works from, display
                or otherwise transfer or commercially exploit any of the content, in whole or in part,
                provided, however, that you may (i) make a reasonable number of digital or
                other form of copies to permit your computer hardware and software to access
                and view the content, (ii) print
                one copy of each piece of content, (iii) make and distribute a reasonable number of copies of content, in whole or in part, in hard
                copy or electronic form for internal use only. Any permitted copies of content reproduced in an unmodified form any notices
                contained in the content, such as all
                Intellectual Property Right notices, and an original source attribution to
                "Hemsee Technologies Ltd Website" and its URL address. You
                acknowledge that Hemsee Technologies Ltd Website, its Contributors, and/or
                Users remain the owners of the content and that you do not acquire any Intellectual Property Rights by
                downloading or printing content.
            </Text>

            <Text fontWeight={'bold'} >
                Intellectual Property Rights                
            </Text>             

            <Text>
                Unless otherwise indicated, the Website is our proprietary property and
                all source code, databases, functionality, software, website designs, audio,
                video, text, photographs and graphics on the Website (collectively, the
                “Content”) and the trademarks, service marks, and logos contained therein (the
                “Marks”) are owned or controlled by us or licensed to us, and are protected by
                copyright and trademark laws and various other intellectual property rights and
                unfair competition laws of Nigeria, international copyright laws, and
                international conventions. The content and the marks are provided on the
                Website “AS IS” for your information and personal use only. Except as expressly
                provided in these Terms of Use, no part of the Website and no content or marks
                may be copied, reproduced, aggregated republished, uploaded, posted, shared,
                publicly displayed, encoded, translated, transmitted, distributed, sold,
                licensed, or otherwise exploited for any commercial purpose whatsoever, without
                our express prior written permission.
            </Text>

            <Text>
                Provided that you are eligible to use the Website, you are granted a
                limited license to access and use the Website and to download or print a copy
                of any portion of the content to which you have properly gained access solely
                for your personal, non-commercial use. We reserve all rights not expressly
                granted to you in and to the Website, the content, and the marks.
            </Text>

            <Text fontWeight={'bold'} >
                Termination
            </Text>
                            
            <Text>
                The only right with
                respect to dissatisfaction with any policies, guidelines, or practices of Hemsee
                Technologies Ltd in operating Hemsee Technologies Ltd Website, or any change in
                content, is for you
                to discontinue accessing Hemsee Technologies Ltd Website. Hemsee Technologies
                Ltd may terminate or temporarily suspend your access to all or any part of Hemsee
                Technologies Ltd Website, without notice, for conduct that Hemsee Technologies
                Ltd believes is a violation of this Agreement or any policies or guidelines
                posted by Hemsee Technologies Ltd, or for other conduct which Hemsee
                Technologies Ltd believes, in its sole discretion, is harmful to Hemsee
                Technologies Ltd or other Users. Hemsee Technologies Ltd may discontinue
                operating Hemsee Technologies Ltd Website and terminate this Agreement without
                notice at any time for any reason in its sole discretion. If we
                terminate or suspend your account for any reason, you are prohibited from
                registering and creating a new account under your name, a fake name or borrowed
                name, or the name of any third party, even if you may be acting on behalf of
                the third party. In addition to terminating or suspending your account, we
                reserve the right to take appropriate legal action, including without
                limitation pursuing civil, criminal, and injunctive redress.                
            </Text>    

            <Text fontWeight={'bold'} >
                Links, Disclaimers Of Warranties, Limitations of Liability
            </Text>

            <Text fontWeight={'semibold'} >
                Links
            </Text>

            <Text>
                You understand that except for content, products or services expressly available at Hemsee Technologies
                Ltd Website, neither Hemsee Technologies Ltd, its subsidiary and parent
                companies, or affiliates, or their respective directors, officers, employees,
                and agents controls, provides, or is responsible for any content, goods or services available
                through sites on the Internet linked to or from Hemsee Technologies Ltd
                Website. All such content, goods and services are made accessible on the Internet by
                independent third parties and are not part of Hemsee Technologies Ltd Website
                or controlled by Hemsee Technologies Ltd. Hemsee Technologies Ltd neither
                endorses nor is responsible for the accuracy, completeness, usefulness, quality
                or availability of any content, goods or services available on any site linked to or from Hemsee
                Technologies Ltd Website, which are the sole responsibility of such independent
                third parties, and your use thereof is solely at your own risk. Neither Hemsee
                Technologies Ltd, its subsidiary and parent companies, or affiliates, or their
                respective directors, officers, employees, and agents shall be held responsible
                or liable, directly or indirectly, for any loss or damage caused or alleged to
                have been caused by your use of or reliance on any content, goods or services available
                on any site linked to or from Hemsee Technologies Ltd Website or your inability
                to access the Internet or any site linked to or from Hemsee Technologies Ltd
                Website.
            </Text>

            <Text fontWeight={'semibold'} >
                Disclaimer of Warranties
            </Text>
                            
            <Text>
                Please use your best
                judgment in evaluating all information contained or opinions expressed on Hemsee
                Technologies Ltd Website. It is the policy of Hemsee Technologies Ltd not to
                endorse or oppose any opinion expressed by a User or content provided by a User,
                Contributor, or other independent party. You expressly agree that your use of Hemsee
                Technologies Ltd Website is at your sole risk. Neither Hemsee Technologies
                Ltd nor any of its affiliates, or their respective directors, officers,
                employees, agents, contractors, affiliates, licensors or other suppliers
                providing content, data, information or services warrants that Hemsee
                Technologies Ltd Website or any internet site linked to or from Hemsee
                Technologies Ltd Website will be uninterrupted or error free, that defects will
                be corrected, or that this site, including the interactive areas, or the server
                that makes it available are free of viruses or other harmful components. nor do
                any of them make any warranty as to the results that may be obtained from the
                use of Hemsee Technologies Ltd website or any internet site linked to or from Hemsee
                Technologies Ltd Website or as to the timeliness, sequence, accuracy,
                authority, completeness, usefulness, non infringement, reliability,
                availability, or substance of any content, information, service, or transaction
                provided through Hemsee Technologies Ltd Website or any site linked to or from Hemsee
                Technologies Ltd Website. Hemsee Technologies Ltd Website is provided on an
                "as is," "as available" basis, without warranties of any
                kind, either express or implied, including, without limitation, those of
                merchantability and fitness for a particular purpose.
            </Text>
                            
            <Text fontWeight={'semibold'} >
                Limitation of Liability                
            </Text>                
                            
            <Text>
                Under no circumstances
                shall Hemsee Technologies Ltd, its affiliates, or their respective directors,
                officers, employees, agents, contractors, or licensors, be liable for any
                direct or incidental, special or consequential damages under or arising from
                this agreement, Hemsee Technologies Ltd Website, or any Internet site linked to
                or from Hemsee Technologies Ltd Website, whether for breach of contract,
                tortuous behavior, negligence, or under any other cause of action, including
                without limitation, any liability for damages caused or allegedly caused by any
                failure of performance, error, omission, interruption, electrical
                surge/damage/interference, deletion, defect, delay in operation or
                transmission, computer virus, communications line failure, breakdown of
                equipment, software error, infringement, unauthorized access to, or theft,
                destruction, alteration, or use of, records.
            </Text>

            <Text>
                Under no circumstances
                shall Hemsee Technologies Ltd, its affiliates, or their respective directors,
                officers, employees, agents, contractors, or licensors, be liable to you or any
                other third party for any decision made or action taken by you in reliance on
                the content contained within Hemsee Technologies Ltd Website or the content
                contained within any Internet site linked to or from Hemsee Technologies Ltd
                Website. The content within Hemsee Technologies Ltd Website and the content
                within Internet sites linked to or from Hemsee Technologies Ltd Website may
                include technical or other inaccuracies or typographical errors. 
            </Text>

            <Text>
                You specifically
                acknowledge and agree that Hemsee Technologies Ltd is not liable for any
                defamatory, offensive, fraudulent, or otherwise illegal conduct of any user. If
                you are dissatisfied with any Hemsee Technologies Ltd Website site content, or
                with the access agreement of Hemsee Technologies Ltd Website, in whole or in
                part, your sole and exclusive remedy is to discontinue using Hemsee
                Technologies Ltd Website.
            </Text>
                            
            <Text fontWeight={'bold'} >
                Privacy Policy                
            </Text>               
                            
            <Text>
                We
                care about data privacy and security. Please review our Privacy Policy: <a href="https://www.Hemseetechnologies.ng/privacypolicy">https://www.Hemseetechnologies.ng/privacypolicy</a>
                By using the Site, you agree to be bound by our Privacy Policy, which is
                incorporated into these Terms of Use. Please be advised that the site is hosted
                in Nigeria. If you access the Site from any other region of the world with laws
                or other requirements governing personal data collection, use, or disclosure
                that differ from applicable laws in Nigeria, then through your continued use of
                the Site, you are transferring your data to Nigeria, and you agree to have your
                data transferred to and processed in Nigeria.                           
            </Text>               
                            
            <Text fontWeight={'bold'} >
                Indemnity            
            </Text>                

            <Text>
                You agree to
                indemnify and hold Hemsee Technologies Ltd, its affiliates, and their
                respective directors, officers, employees, and agents from any and all
                liabilities, claims and expenses, including reasonable attorneys fees, arising
                from breach of this Agreement, any other policy, your use or access of Hemsee
                Technologies Ltd Website or any Internet site linked to or from Hemsee
                Technologies Ltd Website, or in connection with the transmission of any Content
                on Hemsee Technologies Ltd Website.
            </Text>
                            
            <Text fontWeight={'bold'} >
                Governing Law                
            </Text>               
                            
            <Text>
                These Terms
                shall be governed by and defined following the laws of Nigeria. Hemsee
                Technologies Ltd and yourself irrevocably consent that the courts of Nigeria
                shall have exclusive jurisdiction to resolve any dispute which may arise in
                connection with these terms.                
            </Text>    

            <Text fontWeight={'bold'}>
                Miscellaneous                
            </Text>       
                            
            <Text>
                This Agreement
                comprises the entire agreement between Hemsee Technologies Ltd and you, and
                supersedes any prior agreements with respect to the subject matter herein. Hemsee
                Technologies Ltd may revise this Agreement or any other policy at any time and
                from time to time, and such revision shall be effective immediately upon posting notice of such revision
                prominently on Hemsee Technologies Ltd Website. You agree to review this
                Agreement periodically to be aware of such revisions. If any such revision is
                unacceptable to you, you must discontinue accessing Hemsee Technologies Ltd
                Website.
            </Text>

            <Text>
                Your continued
                access and use of Hemsee Technologies Ltd Website following notice of any such
                revision shall conclusively be deemed acceptance of all such revisions. The
                provisions of this Agreement shall survive the termination or expiration of this Agreement. If any
                provision of this Agreement or any other policy shall be held invalid or unenforceable,
                that portion shall be construed in accordance with applicable law as nearly as
                possible to reflect the original intention of the parties and the remaining
                portions will continue in full force and effect. The failure of Hemsee
                Technologies Ltd to insist upon or enforce strict performance of any provision
                of this Agreement shall not be construed as a waiver of any provision or right.
            </Text>

            <Text>
                This Agreement is
                personal to you and you may not assign your rights or obligations thereunder to
                anyone. All logos, brand names, products, trademarks and service marks
                appearing herein may be the trademarks or service marks of their respective
                owners. References to any trademark, service mark and links to or from Hemsee
                Technologies Ltd Website have been done strictly for clarification and
                identification and does not constitute endorsement by Hemsee Technologies Ltd
                of the products, services or information offered by the owner of such
                trademark, service mark or link or endorsement of Hemsee Technologies Ltd by
                such trademark, service mark or link owner.
            </Text>

            <Text fontWeight={'bold'} >
                Contact Us
            </Text>
                            
            <Text>
                
                In order to resolve a complaint regarding the Website or to receive
                further information regarding use of the Website, please contact us at:                
            </Text>                
                            
            <Text fontWeight={'bold'}>
                Hemsee Technologies Limited <br />
                
                (insert address) <br />
                
                Nigeria <br />
                
                Phone: ________________________ <br />
                
                (Insert email address)            
            </Text>              
        </Box>
    </Box>
  )
}

export default Terms