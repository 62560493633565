import {  Stack, Text, Checkbox, Center, Icon, HStack, Box, VStack, Heading, Button } from "@chakra-ui/react";
import { StepInfo } from "../../../shared/StepInfo";
import PropTypes from "prop-types";
import { FileUpload } from "../../../shared/FileUpload";
import { Add, TruckGrey, Close } from "../../../../Assets";
import { BasicInput } from "../../../shared/BasicInput";
import { useState} from "react";

export function DocUpload(props) {

    const [step, setStep] = useState()

    const handleNewVehicle = () => {
        // console.log(props.data)
        const newFileUpload = { 
            vehiclePaperUpload: "",
            vehicleCapacity: "",
            driverLicenseUpload: ""
        }

        props.updateData({
            ...props.data,
            Vehicles: [...props.data.Vehicles, newFileUpload]
        });

        const vehicleFileValidation = {
            vehiclePaperUpload: false,
            driverLicenseUpload: false
        }

        props.setFileValidation({
            ...props.fileValidation,
            Vehicles: [...props.fileValidation.Vehicles, vehicleFileValidation]
        })

        const vehicleFiles = {
            vehiclePaperUpload: '',
            driverLicenseUpload: ''
        }
        
        props.setFiles({
            ...props.files,
            Vehicles: [...props.files.Vehicles, vehicleFiles]
        })

        const vehicleProgress = {
            vehiclePaperUpload: 0,
            driverLicenseUpload: 0 
        }

        props.setProgress({
            ...props.progress,
            Vehicles: [...props.progress.Vehicles, vehicleProgress]
        })

        const vehicleFileName = {
            vehiclePaperUpload: '',
            driverLicenseUpload: ''
        }
        
        props.setFileName({
            ...props.fileName,
            Vehicles: [...props.fileName.Vehicles, vehicleFileName]
        })

        const vehicleFileSize = {
            vehiclePaperUpload: '',
            driverLicenseUpload: ''
        }
        
        props.setFileSize({
            ...props.fileSize,
            Vehicles: [...props.fileSize.Vehicles, vehicleFileSize]
        })
    }

    const updateVehicle = (index, fieldName, value) => {
        const updatedVehicles = [...props.data.Vehicles];
        updatedVehicles[index] = {
            ...updatedVehicles[index],
            [fieldName]: value[fieldName],
        };
        
        props.updateData({
            ...props.data,
            Vehicles: updatedVehicles
        });
    };

    const text_data = [
         {
            id: 1,
            for: "CertificateOfIncorporation",
            type: "file",
            focus: { bg: 'primary.900' },
            text: "Certificate of Incorporation",
            value: props.CertificateOfIncorporation
        },
         {
            id: 2,
            for: "CertificateOfRegistration",
            type: "file",
            focus: { bg: 'primary.900' },
            text: "Certificate of Registeration",
            value: props.CertificateOfRegistration
        },
         {
            id: 3,
            for: "HaulageLicence",
            type: "file",
            focus: { bg: 'primary.900' },
            text: "Haulage Licence",
            value: props.HaulageLicence
        },
        {
            id: 4,
            for: "TransitInsurance",
            type: "file",
            focus: { bg: 'primary.900' },
            text: "Transit Insurance",
            value: props.TransitInsurance
        },
    ]
    return (
        <>
            <StepInfo currentStep={2} title={"Documents Upload"} desc={"You need to upload a document for verification."} />
            <Stack
                spacing={12} 
                overflowY={`scroll`}
                maxH={props.height}
                scrollBehavior={`auto`}
                ps={10}
                pe={16}
                pt={4}
                pb={4}
            >
                <Text fontSize={`lg`}>CAC Document</Text>
                {text_data && text_data.map((data) => {
                    return <FileUpload key={data.id} userType={"Delivery"} type={data.type} focus={data.focus} for={data.for} text={data.text} value={data.value} updateData={props.updateData} data={props.data} setFileValidation={props.setFileValidation} fileValidation={props.fileValidation} files={props.files} setFiles={props.setFiles} progress={props.progress} setProgress={props.setProgress} fileName={props.fileName} setFileName={props.setFileName} fileSize={props.fileSize} setFileSize={props.setFileSize} isVehicle={false} />
                })}

                {/* {props.data.Vehicles.map((data, i) => {
                    return (
                        <Box h={`100%`} w={`100%`} key={i}>
                            <HStack
                                pb={7}
                                justify={`space-between`}
                            >
                                <Heading>
                                    Vehicle {i + 1}
                                </Heading>
                                <Icon
                                    boxSize={'20px'}
                                    onClick={() => {
                                        // console.log(props.data)
                                        const updatedVehicles = [...props.data.Vehicles];
                                        const updatedFileValidation = [...props.fileValidation.Vehicles];
                                        const updatedFiles = [...props.files.Vehicles];
                                        const updatedProgress = [...props.progress.Vehicles];
                                        const updatedFileName = [...props.fileName.Vehicles];
                                        const updatedFileSize = [...props.fileSize.Vehicles];

                                        // const newUpdatedVehicles = updatedVehicles.splice(i, 1);
                                        // const newUpdatedFileValidation = updatedFileValidation.splice(i, 1);
                                        // const newUpdatedFiles = updatedFiles.splice(i, 1);
                                        // const newUpdatedProgress = updatedProgress.splice(i, 1);
                                        // const newUpdatedFileName = updatedFileName.splice(i, 1);
                                        // const newUpdatedFileSize = updatedFileSize.splice(i, 1);

                                        updatedVehicles.splice(i, 1);
                                        updatedFileValidation.splice(i, 1);
                                        updatedFiles.splice(i, 1);
                                        updatedProgress.splice(i, 1);
                                        updatedFileName.splice(i, 1);
                                        updatedFileSize.splice(i, 1);

                                        props.updateData({ Vehicles: updatedVehicles });
                                        // props.setFileValidation({ Vehicles: updatedFileValidation });
                                        // props.setFiles({ Vehicles: updatedFiles });
                                        // props.setProgress({ Vehicles: updatedProgress });
                                        // props.setFileName({ Vehicles: updatedFileName });
                                        // props.setFileSize({ Vehicles: updatedFileSize });

                                        // console.log("removed", i, updatedVehicles);
                                        // console.log("removed", i, updatedFileValidation);
                                        // console.log("removed", i, updatedFiles);
                                        // console.log("removed", i, updatedProgress);
                                        // console.log("removed", i, updatedFileName);
                                        // console.log("removed", i, updatedFileSize);
                                        // console.log(props.data)
                                    }}
                                >
                                    <Close />
                                </Icon>
                            </HStack>
                            <HStack gap={10} pb={10} align={`start`}>
                                <FileUpload
                                    userType={"Delivery"}
                                    type={"file"}
                                    focus={{ bg: 'primary.900' }}
                                    for={"vehiclePaperUpload"}
                                    value={data.vehiclePaperUpload}
                                    updateData={props.updateData}
                                    setFileValidation={props.setFileValidation}
                                    fileValidation={props.fileValidation}
                                    files={props.files}
                                    setFiles={props.setFiles}
                                    progress={props.progress}
                                    setProgress={props.setProgress}
                                    isVehicle
                                    step={i}
                                    index={step}
                                    setStep={setStep}
                                    fileName={props.fileName}
                                    setFileName={props.setFileName}
                                    fileSize={props.fileSize}
                                    setFileSize={props.setFileSize}
                                    data={props.data} 
                                />
                                <BasicInput
                                    type={"text"}
                                    focus={{ bg: 'primary.900' }}
                                    for={"vehicleCapacity"}
                                    text={"Vehicle Capacity"}
                                    value={data.vehicleCapacity}
                                    updateData={props.updateData}
                                    icon={<TruckGrey />}
                                    data={props.data}
                                    step={i}
                                    index={step} 
                                    isVehicle
                                />
                            </HStack>
                            <FileUpload
                                userType={"Delivery"}
                                type={"file"}
                                focus={{ bg: 'primary.900' }}
                                for={"driverLicenseUpload"}
                                text={"Driver's License"}
                                value={data.driverLicenseUpload}
                                updateData={props.updateData}
                                setFileValidation={props.setFileValidation}
                                fileValidation={props.fileValidation}
                                files={props.files}
                                setFiles={props.setFiles}
                                progress={props.progress}
                                setProgress={props.setProgress}
                                isVehicle
                                step={i}
                                index={step}
                                setStep={setStep}
                                fileName={props.fileName}
                                setFileName={props.setFileName}
                                fileSize={props.fileSize}
                                setFileSize={props.setFileSize}
                                data={props.data} 
                            />
                        </Box>
                    );
                })} */}

                   

                {/* <HStack>
                    <Icon
                        onClick={handleNewVehicle}
                    >
                        <Add />
                    </Icon>
                    <Text
                        fontWeight={`bold`}
                    >
                        Add Vehicle
                    </Text>
                </HStack> */}

                {/* <Checkbox
                    top={0}
                    left={0}
                    required
                    isChecked={props.termsAndConditions}
                    onChange={e => {
                        props.updateData({ termsAndConditions: e.target.checked })
                        console.log(props.data)
                    }}
                >
                    <Center>
                        <Text fontSize={"sm"}>I agree to LPG</Text>&nbsp;
                        <Text fontWeight={`semibold`} color={'primary.100'} fontSize={"sm"}>Terms & Condition</Text>
                        &nbsp;<Text>&</Text>&nbsp;
                        <Text fontWeight={`semibold`} color={'primary.100'} fontSize={"sm"}>Privacy Policy</Text>
                    </Center>
                </Checkbox> */}
            </Stack>

        </>
    );
}

DocUpload.prototype = {
    userType: PropTypes.string.isRequired,
    height: PropTypes.string.isRequired,
    CertificateOfIncorporation: PropTypes.string.isRequired,
    TransitInsurance: PropTypes.string.isRequired,
    NMDPRDepotOperatingLicence: PropTypes.string.isRequired,
    signedAgreement: PropTypes.string.isRequired,
    bankReferenceLetter: PropTypes.string.isRequired,
    termsAndConditions: PropTypes.string.isRequired,
    data: PropTypes.array,
    updateData: PropTypes.func,
    setFileValidation: PropTypes.func,
    fileValidation: PropTypes.object,
    uploadError: PropTypes.bool,
    files: PropTypes.object,
    setFiles: PropTypes.func,
    progress: PropTypes.object,
    setProgress: PropTypes.func,
}
