import { Box, Flex, HStack, Text, VStack, Divider } from '@chakra-ui/react'
import React from 'react'
import { EmeryAva, NoData } from '../../../../Assets'

export const OngoingOrders = () => {

  const data = [
    {
      name: "Emery Westervelt",
      icon: <EmeryAva />,
      time: "ETA: 10 Mins",
      capacity: 4,
      state: "Ongoing",
      direction: "Incoming",
      stateBg: "#006FF21A",
      color: `font.700`
    },
    {
      name: "Dulce Botosh",
      icon: <EmeryAva />,
      time: "ETD: 1 Mins",
      capacity: 41,
      state: "Ongoing",
      direction: "Outgoing",
      stateBg: "#006FF21A",
      color: `font.700`
    },
    {
      name: "Erin Carder",
      icon: <EmeryAva />,
      time: "5 mins ago",
      capacity: 23,
      state: "Arrived",
      direction: "Ready for Pickup",
      stateBg: "#101A361A",
      color: `primary.100`
    },
    {
      name: "James Westervelt",
      icon: <EmeryAva />,
      time: "ETD: 120 Mins",
      capacity: 4,
      state: "Ongoing",
      direction: "Outgoing",
      stateBg: "#006FF21A",
      color: `font.700`
    },
    {
      name: "James Westervelt",
      icon: <EmeryAva />,
      time: "ETD: 120 Mins",
      capacity: 4,
      state: "Ongoing",
      direction: "Outgoing",
      stateBg: "#006FF21A",
      color: `font.700`
    },
    {
      name: "James Westervelt",
      icon: <EmeryAva />,
      time: "ETD: 120 Mins",
      capacity: 4,
      state: "Ongoing",
      direction: "Outgoing",
      stateBg: "#006FF21A",
      color: `font.700`
    },
    {
      name: "James Westervelt",
      icon: <EmeryAva />,
      time: "ETD: 120 Mins",
      capacity: 4,
      state: "Ongoing",
      direction: "Outgoing",
      stateBg: "#006FF21A",
      color: `font.700`
    },
    {
      name: "James Westervelt",
      icon: <EmeryAva />,
      time: "ETD: 120 Mins",
      capacity: 4,
      state: "Ongoing",
      direction: "Outgoing",
      stateBg: "#006FF21A",
      color: `font.700`
    },
    {
      name: "James Westervelt",
      icon: <EmeryAva />,
      time: "ETD: 120 Mins",
      capacity: 4,
      state: "Ongoing",
      direction: "Outgoing",
      stateBg: "#006FF21A",
      color: `font.700`
    },
    {
      name: "James Westervelt",
      icon: <EmeryAva />,
      time: "ETD: 120 Mins",
      capacity: 4,
      state: "Ongoing",
      direction: "Outgoing",
      stateBg: "#006FF21A",
      color: `font.700`
    },
    {
      name: "James Westervelt",
      icon: <EmeryAva />,
      time: "ETD: 120 Mins",
      capacity: 4,
      state: "Ongoing",
      direction: "Outgoing",
      stateBg: "#006FF21A",
      color: `font.700`
    },
  ]

  return (
    <Box
      p={4}
      h={`100%`}
      overflowY={`hidden`}
    >
      <Text
        fontWeight={`semibold`}
        fontSize={`lg`}
        mb={1}
      >
        Ongoing Orders
      </Text>
      <Flex
        alignItems={`center`}
        justifyContent={`center`}
        h={`100%`}
      >
        <VStack spacing={8}>
          <NoData />
          <Text
            fontWeight={`bold`}
          >
            No Ongoing Order yet !!
          </Text>
        </VStack>
      </Flex>

      {/* <Box
        overflowY={`auto`}
        h={`100%`}
        sx={
            { 
            '::-webkit-scrollbar':{
                  display:'none'
              }
            }
        }
      >
        {data.map((data, i) => {
          return <Box
            key={i}
            
          >
            <HStack>
          <EmeryAva />
          <Box
            ms={1}
            w={`70%`}
          >
            <Text
              fontWeight={`semibold`}
              fontSize={`base`}
            >
              {data.name}
            </Text>
            <Flex>
              <Text
                fontSize={`xs`}
                pe={4}
              >
                {data.time}
              </Text>
              <Text
                color={`primary.200`}
                fontWeight={`semibold`}
                fontSize={`xs`}
              >
                {data.capacity}kg
              </Text>
            </Flex>
          </Box>
          <VStack>
            <Box
              borderRadius={`full`}
              color={data.color}
              bg={data.stateBg}
              py={.5}
              px={6}
              fontWeight={`semibold`}
              fontSize={`xs`}
            >
              {data.state}
            </Box>
            <Text
                  fontSize={`11px`}
                  textAlign={`center`}
            >
              {data.direction}
            </Text>
          </VStack>
        </HStack>
        <Box position='relative' py={3} w={`100%`} >
          <Divider />
        </Box>
          </Box>
        })}
      </Box> */}
    </Box>
  )
}
