import { 
    Box, 
    Divider, 
    Flex, 
    Icon, 
    Input, 
    InputGroup, 
    InputRightElement, 
    Modal, 
    ModalBody, 
    ModalContent, 
    ModalHeader, 
    ModalOverlay, 
    Tab, 
    TabIndicator, 
    TabList, 
    TabPanel, 
    TabPanels, 
    Tabs, 
    Text, 
    useDisclosure 
} from "@chakra-ui/react" 
import AdminPlantsTable from "./AdminPlantsTable";
import { Search } from "../../../../Assets";
import { useContext, useState } from "react";
import AuthContext from "../../Contexts/AdminContext";
import AdminPlantValidatedTable from "./AdminPlantValidatedTable";
import AdminPlantRejectedTable from "./AdminPlantRejectedTable";



const AdminPlants = () => {
    const {validatedPlantData,isLoadingCustomer,isErrorCustomer,rejectedPlantData,isLoadingRejectedPlant,isErrorRejectedPlant,handleRevokeId,} = useContext(AuthContext);
    const [searchQuery, setSearchQuery] = useState("");

    // const filteredData = customerData && customerData.filter((data) =>
    //     data.FullName.toLowerCase().includes(searchQuery.toLowerCase())
    // );

    const filteredValidatedData = validatedPlantData && validatedPlantData.filter((data) =>
        data.FullName.toLowerCase().includes(searchQuery.toLowerCase())
    );

    const filteredRejectedData = rejectedPlantData && rejectedPlantData.filter((data) =>
        data.FullName.toLowerCase().includes(searchQuery.toLowerCase())
    );
    
    // const customerCount = filteredData ? filteredData.length : 0;

    const validatedCount = filteredValidatedData ? filteredValidatedData.length : 0;
    const rejectedCount = filteredRejectedData ? filteredRejectedData.length : 0;

    return (
        <Box bg='primary.600'  >
          
            {/* <Box bg='#fff' m='20px' borderRadius={'lg'}>
                <Flex p='20px' alignItems='center' justifyContent='space-between' >
                    <Text color='primary.300' fontSize='xl' fontWeight='bold'>
                        Customer
                        <Text as='span' fontSize='xs' fontWeight='semibold' borderRadius={'1rem'}  p={'1'} color='primary.100' ml='5px' bg='primary.600' >
                            {customerCount}
                        </Text>
                    </Text>           
                    <InputGroup w={'250px'}>                       
                        <Input 
                            placeholder='Search Users.....' 
                            value={searchQuery}
                            onChange={(e) => setSearchQuery(e.target.value)}
                        />
                        <InputRightElement>
                            <Icon  boxSize='25px'  >
                                <Search/>
                            </Icon>
                        </InputRightElement>
                    </InputGroup>       
                </Flex>
                <AdminPlantsTable customerData={filteredData} isLoadingCustomer={isLoadingCustomer} isErrorCustomer={isErrorCustomer}   />
            </Box> */}

            <Tabs variant="unstyled" bg='#fff' m='20px' borderRadius={'lg'} pt={5} >
                <TabList justifyContent="space-between">
                    <TabList p={'0 10px'}>
                        <Tab color={'border.100'} _selected={{ color: "primary.100", borderBottom: "2px solid primary.100", fontWeight: 'semibold' }}>
                        <Text color='primary.300' >
                            Validated Users
                            <Text as='span' fontSize='xs' fontWeight='semibold' borderRadius={'1rem'}  p={'1'} color='primary.100' ml='5px' bg='primary.600' >
                                {validatedCount}
                            </Text>
                        </Text>     
                        </Tab>
                        <Tab color={'border.100'} _selected={{ color: "primary.100", borderBottom: "2px solid primary.100", fontWeight: 'semibold'}} >
                        <Text color='primary.300' >
                        Rejected
                            <Text as='span' fontSize='xs' fontWeight='semibold' borderRadius={'1rem'}  p={'1'} color='primary.100' ml='5px' bg='primary.600' >
                                {rejectedCount}
                            </Text>
                        </Text>     
                        </Tab>
                    </TabList>
                    <Flex alignItems="center" mr={'20px'}>
                        <InputGroup w={'250px'}>                       
                        <Input 
                            placeholder='Search Users.....' 
                            value={searchQuery}
                            onChange={(e) => setSearchQuery(e.target.value)}
                        />
                        <InputRightElement>
                            <Icon boxSize='25px'>
                                <Search/>
                            </Icon>
                        </InputRightElement>
                        </InputGroup>
                    </Flex>
                </TabList>
                <TabIndicator
                mt="-1.5px"
                height="2px"
                bg="primary.100"
                borderRadius="1px"             
                />
                <Divider/>

                <TabPanels>
                    <TabPanel>
                        <AdminPlantValidatedTable validatedPlantData={filteredValidatedData} isLoadingValidated={isLoadingCustomer} isErrorValidated={isErrorCustomer} handleRevokeId={handleRevokeId} />
                    </TabPanel>
                    <TabPanel>
                        <AdminPlantRejectedTable  rejectedPlantData={filteredRejectedData} isLoadingRejected={isLoadingRejectedPlant} isErrorRejected={isErrorRejectedPlant}/>
                    </TabPanel>         
                </TabPanels>
            </Tabs>
           
        </Box>
    )
}

export default AdminPlants