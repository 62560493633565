import { 
  Box, 
  Flex, 
  HStack, 
  Text, 
  // VStack, 
  Divider, 
  VStack,
  Icon
} from '@chakra-ui/react';
import { EmeryAva, NoData } from '../../../../Assets'


const AdminOnboardingRequest = () => {
    const data = [
      {
        name: "Emery Westervelt",
        icon: <EmeryAva />,
        time: "10 mins ago",
        account: "LPG Terminals/Depot",
        state: "Pending",
        color: `font.700`
      },
      {
        name: "Dulce Botosh",
        icon: <EmeryAva />,
        time: "1 mins ago",
        account: "LPG Terminals/Depot",
        state: "Pending",
        color: `font.700`
      },
      {
        name: "Dulce Botosh",
        icon: <EmeryAva />,
        time: "1 mins ago",
        account: "LPG Transporter",
        state: "Pending",
        color: `font.700`
      },
      {
        name: "Dulce Botosh",
        icon: <EmeryAva />,
        time: "1 mins ago",
        account: "LPG Terminals/Depot",
        state: "Pending",
        color: `font.700`
      },
      {
        name: "Dulce Botosh",
        icon: <EmeryAva />,
        time: "1 mins ago",
        account: "LPG Terminals/Depot",
        state: "Pending",
        color: `font.700`
      },
      {
        name: "Erin Carder",
        icon: <EmeryAva />,
        time: "5 hrs ago",
        account: "LPG Transporter",
        state: "Validated",
        color: `primary.200`
      },
      {
        name: "James Westervelt",
        icon: <EmeryAva />,
        time: " 9 hrs ago",
        account: "LPG Terminals/Depot",
        state: "Rejected",
        color: `secondary.300`
      },
      {
        name: "Brooklyn Simmons",
        icon: <EmeryAva />,
        time: " 12 hrs ago",
        account: "LPG Terminals/Depot",
        state: "Rejected",
        color: `secondary.300`
      },
      {
        name: "Brooklyn Simmons",
        icon: <EmeryAva />,
        time: " 12 hrs ago",
        account: "LPG Terminals/Depot",
        state: "Rejected",
        color: `secondary.300`
      },
      {
        name: "Brooklyn Simmons",
        icon: <EmeryAva />,
        time: " 12 hrs ago",
        account: "LPG Transporter",
        state: "Rejected",
        color: `secondary.300`
      },
      {
        name: "Brooklyn Simmons",
        icon: <EmeryAva />,
        time: " 12 hrs ago",
        account: "LPG Terminals/Depot",
        state: "Validated",
        color: `secondary.200`
      },
      {
        name: "Brooklyn Simmons",
        icon: <EmeryAva />,
        time: " 12 hrs ago",
        account: "LPG Transporter",
        state: "Rejected",
        color: `secondary.300`
      },
      {
        name: "Brooklyn Simmons",
        icon: <EmeryAva />,
        time: " 12 hrs ago",
        account: "LPG Terminals/Depot",
        state: "Rejected",
        color: `secondary.300`
      },
      {
        name: "Brooklyn Simmons",
        icon: <EmeryAva />,
        time: " 12 hrs ago",
        account: "LPG Terminals/Depot",
        state: "Rejected",
        color: `secondary.300`
      },
      {
        name: "Brooklyn Simmons",
        icon: <EmeryAva />,
        time: " 12 hrs ago",
        account: "LPG Transporter",
        state: "Validated",
        color: `secondary.200`
      },
    ]

  return(
    <Box
      py={4}
      height={'100%'}
    >
            
      <Flex
        alignItems={`center`}
        justifyContent={`center`}
        h={`100%`}
      >
        <VStack spacing={8}>
          
          <Icon boxSize='100px' viewBox="0 0 250 250" >
            <NoData />
            
          </Icon> 
          <Text
            fontWeight={`bold`}
          >
            No Request yet!!
          </Text>
        </VStack>
      </Flex>

      {/* <Box
        overflowY={`auto`}
        h={`100%`}
        sx={
          { 
          '::-webkit-scrollbar':{
                display:'none'
            }
          }
        }
      >

        {data.map((data, i) => {
            return <Box key={i}>
            <HStack>
              <EmeryAva />
              <Box
                ms={1}
                w={`65%`}
              >
                <Text
                  fontWeight={`semibold`}
                  fontSize={`base`}
                  color={`primary.300`}
                >
                  {data.name}
                </Text>
                <Flex alignItems='center' >
                  <Text
                    fontSize={`xs`}
                    pe={1}
                    color={`font.100`}
                  >
                    {data.account}
                  </Text>
                  <Text pe={1} >-</Text>
                  <Text
                    color={data.color}
                    fontWeight={`semibold`}
                    fontSize={`xs`}
                  >
                    {data.state}
                  </Text>
                </Flex>
              </Box>
            
              <Text
                color={`font.600`}
                fontSize={`xs`}
                textAlign={`right`}
              >
                {data.time}
              </Text>           
            
            </HStack>
            <Box position='relative' py={3} w={`100%`} >
              <Divider />
            </Box>
          </Box>
        })}

      </Box> */}
    </Box>
  )
    
    
    
}

export default AdminOnboardingRequest