import React, { useState } from 'react'
import { FullLogo} from '../../../Assets'
import { Box, Button, Collapse, Flex, HStack, IconButton, Text, VStack, useMediaQuery } from '@chakra-ui/react'
import { Link, NavLink, useNavigate } from 'react-router-dom'
import { HamburgerIcon, CloseIcon } from '@chakra-ui/icons';

const Navbar = () => {
    const navigate = useNavigate();
    const [isOpen, setIsOpen] = useState(false);
    const [isSm] = useMediaQuery(`(max-width: 992px)`);
    const [isXs] = useMediaQuery(`(max-width: 760px)`);


    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };

  return ( 
    <Flex 
        alignItems={'center'}
        justifyContent={'space-between'}
        py={4}
        px= { isXs ? 10 : 20}
        borderBottom={'1px solid #CECDCD'}
        position="sticky" 
        top={0} 
        bg="white" 
        zIndex={999} 
    >
        
        <Box>
            <FullLogo/>
        </Box>

        {
            isSm ? 
            
            (<Flex>
                <IconButton
                    onClick={toggleMenu}
                    icon={isOpen ? <CloseIcon /> : <HamburgerIcon />}
                    variant='ghost'
                    fontSize='2xl'
                    aria-label='Toggle Menu'
                    zIndex={9999}
                    _hover={{
                        bg:'transparent',
                        // color: 'gray.600', 
                    }}
                />
            </Flex>) : 
            (<>
                <Box className='Land-link' >
                    <NavLink>Home</NavLink>
                    <NavLink>About</NavLink>
                    <NavLink>Services</NavLink>
                    <NavLink>Contact Us</NavLink>
                </Box>

                <HStack alignItems={'center'} justifyContent={'space-between'} >
                    <Text pr={8} fontWeight={'semibold'} color={'font.800'} fontSize={'lg'}  >
                        <Link to='/Login'  >
                            Log In
                        </Link>
                    </Text>
                    <Button
                        // m="60px 35px"
                        bg="primary.100" 
                        color="#fff" 
                        p="25px 30px" 
                        h="48px" 
                        borderRadius={'2xl'}
                        // width="40%" 
                        mr={'auto'}
                        _focus={{ boxShadow: "none" }} 
                        _hover={{bg: 'primary.100',
                        color: 'none',}}

                        onClick={() => navigate('/user_type') }
                        
                    >
                        Register Today!
                    </Button>
                </HStack>
            
            </>)
        }

        <Collapse in={isOpen} animateOpacity>
            <VStack
                position='absolute'
                top={0}
                left={0}
                right={0}
                bottom={0}
                bg='primary.900'
                zIndex='modal'
                spacing={4}
                alignItems='flex-start'
                justifyContent='flex-start'
                py={8}
                px={10}
                h={'40vh'}
                fontSize={'lg'}
            >
                <NavLink>Home</NavLink>
                <NavLink>About</NavLink>
                <NavLink >Services</NavLink>
                <NavLink>Contact Us</NavLink>
                {/* <NavLink to='/login'>Log In</NavLink> */}
                <Button
                    // m="60px 35px"
                    bg="primary.100" 
                    color="#fff" 
                    p="15px 20px" 
                    h="48px" 
                    borderRadius={'xl'}
                    // width="40%" 
                    mr={'auto'}
                    _focus={{ boxShadow: "none" }} 
                    _hover={{bg: 'primary.100',
                    color: 'none',}}

                    onClick={() => navigate('/login') }
                    
                >
                    Log In
                </Button>
            </VStack>
        </Collapse>


    </Flex>
  )
}

export default Navbar