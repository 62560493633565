import { Stack, HStack } from "@chakra-ui/react";
import { BasicInput } from "../../../shared/BasicInput";
import { StepInfo } from "../../../shared/StepInfo";
import PropTypes from "prop-types";
import { Phone, Email, Password, Home, Location, Chevron, TruckGrey, Terminal, User } from "../../../../Assets";


export function BasicDetailForm(props) {
    const text_data = [
        // {
        //     id: 1,
        //     for: "FullName",
        //     type: "text",
        //     focus: { bg: 'primary.900' },
        //     text: "Business Name",
        //     value: props.FullName,
        //     icon: <User />
        // },
        // {
        //     id: 2,
        //     for: "PhoneNumber",
        //     type: "tel",
        //     focus: { bg: 'primary.900' },
        //     text: "Phone Number",
        //     value: props.PhoneNumber,
        //     icon: <Phone />
        // },
        {
            id: 3,
            for: "EmailAddress",
            type: "email",
            focus: { bg: 'primary.900' },
            text: "Email Address",
            value: props.EmailAddress,
            icon: <Email />
        },
        {
            id: 4,
            for: "Password",
            type: "password",
            focus: { bg: 'primary.900' },
            text: "Password",
            value: props.Password,
            icon: <Password />
        },
        {
            id: 5,
            for: "ConfirmPassword",
            type: "password",
            focus: { bg: 'primary.900' },
            text: "Confirm Password",
            value: props.ComfirmPassword,
            icon: <Password />
        },
        // {
        //     id: 6,
        //     for: "HomeAddress",
        //     type: "text",
        //     focus: { bg: 'primary.900' },
        //     text: "Full Address",
        //     value: props.HomeAddress,
        //     icon: <Home />
        // },
    ]
    
    const split_text_data = [
        // {
        //     id: 1,
        //     for: "LocalGovernment",
        //     type: "text",
        //     focus: { bg: 'primary.900' },
        //     text: "Local Government",
        //     value: props.LocalGovernment,
        //     icon: <Location />
        // },
        // {
        //     id: 2,
        //     for: "CustomerState",
        //     type: "text",
        //     focus: { bg: 'primary.900' },
        //     text: "State",
        //     value: props.CustomerState,
        //     icon: <Chevron />
        // },
    ]
    const split_text_data1 = [
        //  {
        //     id: 1,
        //     for: "ClosestLandmark",
        //     type: "text",
        //     focus: { bg: 'primary.900' },
        //     text: "Closest Landmark",
        //     value: props.ClosestLandmark,
        //     icon: <Location />
        // },
        // {
        //     id: 2,
        //     for: "StorageCapacity",
        //     type: "text",
        //     focus: { bg: 'primary.900' },
        //     text: "Storage Capacity",
        //     value: props.StorageCapacity,
        //     icon: <Terminal />
        // },
    ]
    return (
        <>
            <StepInfo currentStep={2} title={"Basic Details"} desc={"Provide the following information:"}/>
            <Stack
                spacing={8} 
                overflowY={`scroll`}
                maxH={props.height}
                scrollBehavior={`auto`}
                ps={10}
                pe={16}
                py={4}
            >
                {text_data.map((data) => {
                    return <BasicInput key={data.id} type={data.type} focus={data.focus} for={data.for} text={data.text} value={data.value} updateData={props.updateData} icon={data.icon} updateOtp={props.updateOtp} />
                })}
                <HStack spacing={5}>
                    {split_text_data.map((data) => {
                        return <BasicInput key={data.id} type={data.type} focus={data.focus} for={data.for} text={data.text} value={data.value} updateData={props.updateData} icon={data.icon} />
                    })}
                </HStack>
                <HStack spacing={5}>
                    {split_text_data1.map((data) => {
                        return <BasicInput key={data.id} type={data.type} focus={data.focus} for={data.for} text={data.text} value={data.value} updateData={props.updateData} icon={data.icon} />
                    })}
                </HStack>
            </Stack>
        </> 
    );
}

BasicDetailForm.prototype = {
    height: PropTypes.string.isRequired,
    fullName: PropTypes.string.isRequired,
    phoneNumber: PropTypes.string.isRequired,
    emailAddress: PropTypes.string.isRequired,
    password: PropTypes.string.isRequired,
    fullAddress: PropTypes.string.isRequired,
    localGovernment: PropTypes.string.isRequired,
    state: PropTypes.string.isRequired,
    updateData: PropTypes.func,
}
