import {  Stack, Text, Checkbox, Center, Button, Box } from "@chakra-ui/react";
import { StepInfo } from "../../../shared/StepInfo";
import PropTypes from "prop-types";
import { FileUpload } from "../../../shared/FileUpload";


export function DocUpload(props) {
    const text_data = [
         {
            id: 1,
            for: "CertificateOfIncorporation",
            type: "file",
            focus: { bg: 'primary.900' },
            text: "Certificate of Incorporation",
            value: props.CertificateOfIncorporation
        },
        {
            id: 2,
            for: "Form",
            type: "file",
            focus: { bg: 'primary.900' },
            text: "Form 2A & Form 7A",
            value: props.Form
        },
        {
            id: 3,
            for: "OperatingLicence",
            type: "file",
            focus: { bg: 'primary.900' },
            text: "NMDPRA LPG Depot/Terminal Operating Licence",
            value: props.OperatingLicence
        },
        {
            id: 4,
            for: "SignedAgreement",
            type: "file",
            focus: { bg: 'primary.900' },
            text: "Signed Agreement",
            value: props.SignedAgreement
        },
        {
            id: 5,
            for: "BankReferenceLetter",
            type: "file",
            focus: { bg: 'primary.900' },
            text: "Bank Reference Letter",
            value: props.BankReferenceLetter
        },
    ]
    return (
        <>
            <StepInfo currentStep={2} title={"Documents Upload"} desc={"You need to upload a document for verification."} />
            <Stack
                spacing={12} 
                overflowY={`scroll`}
                maxH={props.height}
                scrollBehavior={`auto`}
                ps={10}
                pe={16}
                pt={4}
                pb={4}
            >
                <Text fontSize={`lg`}>CAC Document</Text>
                {text_data.map((data) => {
                    return <FileUpload key={data.id} userType={"Vendor"} type={data.type} focus={data.focus} for={data.for} text={data.text} value={data.value} updateData={props.updateData} data={props.data} setFileValidation={props.setFileValidation} fileValidation={props.fileValidation} files={props.files} setFiles={props.setFiles} progress={props.progress} setProgress={props.setProgress} fileName={props.fileName} setFileName={props.setFileName} fileSize={props.fileSize} setFileSize={props.setFileSize} isVehicle={false} />
                })}


               
                {/* <Checkbox
                    top={0}
                    left={0}
                    required
                    isChecked={props.termsAndConditions}
                    onChange={e => {
                        props.updateData({ termsAndConditions: e.target.checked })
                        console.log(props.data)
                    }}
                >
                    <Center>
                        <Text>I agree to LPG</Text>&nbsp;
                        <Text fontWeight={`semibold`} color={'primary.100'}>Terms & Condition</Text>
                        &nbsp;<Text>&</Text>&nbsp;
                        <Text fontWeight={`semibold`} color={'primary.100'}>Privacy Policy</Text>
                    </Center>
                </Checkbox> */}
            </Stack>

        </>
    );
}

DocUpload.prototype = {
    height: PropTypes.string.isRequired,
    CertificateOfIncorporation: PropTypes.string.isRequired,
    Form: PropTypes.string.isRequired,
    OperatingLicence: PropTypes.string.isRequired,
    SignedAgreement: PropTypes.string.isRequired,
    BankReferenceLetter: PropTypes.string.isRequired,
    termsAndConditions: PropTypes.string.isRequired,
    updateData: PropTypes.func,
    setFileValidation: PropTypes.func,
    fileValidation: PropTypes.object,
    uploadError: PropTypes.bool,
    files: PropTypes.object,
    setFiles: PropTypes.func,
    progress: PropTypes.object,
    setProgress: PropTypes.func,
}
