import PropTypes from 'prop-types'
import { FormControl, Input, FormLabel, Text, Icon, Box, Progress, Flex, Center, useToast, Button } from '@chakra-ui/react'
import { Cloud, Info, Close, Image } from '../../Assets'
import { useState, useEffect, useContext } from 'react'
import axios from 'axios'
import { CustomerContext } from '../Signup/Customer/Contexts/CustomerContext'
import { DeliveryContext } from '../Signup/Delivery/Contexts/DeliveryContext'
import { VendorContext } from '../Signup/Vendor/Contexts/VendorContext'

export const FileUpload = (props) => { 
    const context = useContext( props.userType === "Delivery" ? DeliveryContext : props.userType === "Customer" ? CustomerContext : VendorContext )
    // const {fileName, setFileName, fileSize, setFileSize} = context;
    const [imageUrl, setImageUrl] = useState('')
    const [controller, setController] = useState(null);
    const [inputKey, setInputKey] = useState(Date.now());
    const toast = useToast()
    
    const createController = () => {
        if (controller) {
            controller.abort(); 
        }
        const newController = new AbortController();
        setController(newController);
        return newController.signal;
    };

    const size = (filesize) => {
        if (filesize <= 1023) {
            return `${Math.round(filesize * 100) / 100}B`
        } else if (filesize >= 1024 && filesize <= 1048575) {
            const sizeKB = filesize / 1024
            return `${Math.round(sizeKB * 100) / 100}KB`
        } else if (filesize >= (1024 * 1024)) {
            const sizeMB = filesize / 1048576
            return `${Math.round(sizeMB * 100) / 100}MB`
        } else {
            console.log("no, too large")
        }
    }
    
    const docKey = props.isVehicle ? `Vehicles` : [props.for];
    // console.log(docKey)
    const handleUpload = (e) => {
        // props.isVehicle && console.log(props.files.Vehicles[props.step])
        const signal = createController();
        const file = e.target.files[0]
        // props.setFiles((prev) => ({
        //     ...prev,
        //     [docKey]: props.isVehicle
        //         ? prev[docKey].map((item, index) =>
        //             index === props.index
        //                 ? { ...item, [props.for]: file }
        //                 : item
        //         )
        //         : [file],
        // }));
        // console.log(props.files.Vehicles)
        props.setFileValidation((prevValidation) => ({
            ...prevValidation,
            [docKey]: props.isVehicle
                ? prevValidation[docKey].map((item, index) =>
                    index === props.index
                    ? { ...item, [props.for]: !!file }
                    : item
                )
                : !!file,
        }));
        // console.log(props.fileValidation.Vehicles)
        props.setFileName((prevValidation) => ({
            ...prevValidation,
            [docKey]: props.isVehicle
                ? prevValidation[docKey].map((item, index) =>
                    index === props.index
                    ? { ...item, [props.for]: file.name }
                    : item
                )
                : file.name,
        }));
        // console.log(props.fileName.Vehicles)
        const convertSize = size(file.size)
        props.setFileSize((prevValidation) => ({
            ...prevValidation,
            [docKey]: props.isVehicle
                ? prevValidation[docKey].map((item, index) =>
                    index === props.index
                    ? { ...item, [props.for]: convertSize }
                    : item
                )
                : convertSize,
        }));
        // console.log(fileSize.Vehicles)
        const formData = new FormData()
        const cloudName = process.env.REACT_APP_CLOUDINARY_CLOUD_NAME
        formData.append('file', file)
        formData.append('upload_preset', process.env.REACT_APP_CLOUDINARY_PRESET_KEY)
        axios.post(
            `https://api.cloudinary.com/v1_1/${cloudName}/image/upload`,
            formData,
            {
                headers: {
                    "Content-Type": "multipart/form-data"
                },
                signal,
                onUploadProgress: e => {
                    props.setProgress((prevProgress) => ({
                        ...prevProgress,
                        [docKey]: props.isVehicle
                                ? prevProgress[docKey].map((item, index) =>
                                    index === props.index
                                    ? { ...item, [props.for]: Math.round((100 * e.loaded) / e.total) }
                                    : item
                                )
                                : Math.round((100 * e.loaded) / e.total)
                    }))
                    // console.log(props.progress)
                },
            }
        )
        .then(res => {
            setImageUrl(res.data.secure_url);
            setInputKey(Date.now());
        })
        .catch(err => {
            if (err.name === 'CanceledError') {
                setImageUrl('')
                props.setProgress((prevProgress) => ({
                    ...prevProgress,
                    [docKey]: props.isVehicle
                        ? prevProgress[docKey].map((item, index) =>
                            index === props.index
                            ? { ...item, [props.for]: 0 }
                            : item
                        )
                        : 0
                }))
                toast({
                    position: "top",
                    icon: <Info />,
                    duration: 10000,
                    description: "You cancelled the upload.",
                    status: 'warning'
                })
            } else if (err.name === 'AxiosError'){
                setImageUrl('')
                props.setProgress((prevProgress) => ({
                    ...prevProgress,
                    [docKey]: props.isVehicle
                        ? prevProgress[docKey].map((item, index) =>
                            index === props.index
                            ? { ...item, [props.for]: 0 }
                            : item
                        )
                        : 0
                }))
                toast({
                    position: "top",
                    icon: <Info />,
                    duration: 10000,
                    description: "You have poor connection.",
                    status: 'error'
                })
            } else {
                console.log(err);
            }
        });
        
    }
        
    // useEffect(() => {
    //     if (imageUrl !== '') {
    //         props.updateData((prev) => ({
    //             ...prev,
    //             [docKey]: props.isVehicle
    //                 ? prev[docKey].map((item, index) =>
    //                     index === props.index
    //                     ? { ...item, [props.for]: imageUrl }
    //                     : item
    //                 )
    //                 : imageUrl
    //         }));
    //     }
    // }, [imageUrl, docKey]);
    
    useEffect(() => {
        // console.log(props.data.Vehicles, props.value, props.step, props.index)
        if (imageUrl !== '' && !props.isVehicle) {
             if (imageUrl !== '') {
            props.updateData({ [docKey]: imageUrl });
        } 
        // } else if (imageUrl !== '' && props.isVehicle && props.index !== undefined) {
        }
        if (props.index !== undefined) {
            props.updateData({ Vehicles: props.data.Vehicles.map((item, index) => index === props.index ? { ...item, [props.for]: imageUrl } : {...item})});
        }
    }, [imageUrl, docKey]);
    

    const handleCancel = () => {
        props.setFileValidation((prevValidation) => ({
            ...prevValidation,
            [docKey]: props.isVehicle
                ? prevValidation[docKey].map((item, index) =>
                    index === props.index
                    ? { ...item, [props.for]: false }
                    : item
                )
                : false,
        }));
        if (props.isVehicle ? props.progress.Vehicles[props.index]?.[props.for] === 100 : props.progress[props.for] === 100) {
            toast({
                position: "top",
                icon: <Info />,
                duration: 10000,
                description: "You removed the uploaded file.",
                status: 'info'
            })
            props.updateData((prev) => ({
                [docKey]: props.isVehicle
                    ? prev[docKey].map((item, index) =>
                        index === props.index
                        ? { ...item, [props.for]: '' }
                        : item
                    )
                    : ''
            }));
            setImageUrl('');
            props.setProgress((prevProgress) => ({
                ...prevProgress,
                [docKey]: props.isVehicle
                    ? prevProgress[docKey].map((item, index) =>
                        index === props.index
                        ? { ...item, [props.for]: 0 }
                        : item
                    )
                    : 0
            }))
            setInputKey(Date.now());
            return
        }
        if (controller) {
            controller.abort('aborted');
        }
        setController(null);
        props.updateData((prev) => ({
            [docKey]: props.isVehicle
                ? prev[docKey].map((item, index) =>
                    index === props.index
                    ? { ...item, [props.for]: '' }
                    : item
                )
                : ''
        }));
        setImageUrl('');
        props.setProgress((prevProgress) => ({
            ...prevProgress,
            [docKey]: props.isVehicle
                ? prevProgress[docKey].map((item, index) =>
                    index === props.index
                    ? { ...item, [props.for]: 0 }
                    : item
                )
                : 0
        }))
        setInputKey(Date.now());
    }
    
        
    useEffect(() => {
        return () => {
            if (controller) {
                controller.abort();
            }
        };
    }, [controller]);

    return (
        <FormControl>
            <FormLabel
                htmlFor={props.for}
                zIndex={'docked'}
                fontWeight={`semibold`}
                fontSize={`base`}
                pos={`relative`}
                _focus={props.focus}
                h={12}
                color={`font.100`}
                border={(props.isVehicle ? props.fileValidation.Vehicles[props.step]?.[props.for] : props.fileValidation[props.for]) ? `1px solid #049541` : `1px solid #101A36`}
                bg={`primary.900`}
                borderRadius={5}
                top={0}
                left={0}
                m={0}
                onClick={() => {
                    props.isVehicle && props.setStep(props.step)
                }}
            >
                <Text
                    pos={`absolute`}
                    top={`-30px`}
                    onClick={() => {
                        // console.log(props.step)
                    }}
                >
                    {props.text}
                </Text>
                <Text
                    fontWeight={`semibold`}
                    fontSize={'xs'}
                    color={`font.500`}
                    ps={3}
                    pt={0.5}
                >
                    Document Upload
                </Text>
                <Text
                    fontWeight={`semibold`}
                    fontSize={'sm'}
                    color={`font.100`}
                    ps={3}
                >
                    {(props.isVehicle ? props.progress.Vehicles[props.step]?.[props.for] < 100 : props.progress[props.for] < 100) ? 'Click To Upload A File' : '1 File Uploaded'}
                </Text>
                <Icon
                    boxSize={5}
                    pos={`absolute`}
                    right={3}
                    top={4}
                >
                    <Cloud />
                </Icon>
            </FormLabel>
            <Input
                type='file'
                borderColor={`border.200`}
                focusBorderColor="border.100"
                _focus={props.focus}
                // required
                h={12}
                id={props.for}
                pt={4}
                fontSize={`sm`}
                color={`font.100`}
                onChange={handleUpload}
                display={'none'}
                key={inputKey}
                accept='image/*'
                
            />
            {(props.isVehicle ? props.progress.Vehicles[props.step]?.[props.for]>= 1 : props.progress[props.for] >= 1) && (<Box h={24} border={`1px solid`} borderColor={`primary.100`} mt={4} borderRadius={5} p={3} style={{transition: 'height 2s'}}>
                <Flex pos={'relative'}> 
                    <Icon
                        boxSize={'30px'}
                        viewBox="0 0 30 30">
                        <Image />
                    </Icon>
                    <Box width={'100%'} ps={4}>
                        <Text
                            fontWeight={`semibold`}
                        >
                            {props.isVehicle ? props.fileName.Vehicles[props.step]?.[props.for] : props.fileName[props.for]}
                        </Text>
                        <Text>{props.isVehicle ? props.fileSize.Vehicles[props.step]?.[props.for] : props.fileSize[props.for]}</Text>
                        <Center>
                            <Progress
                                value={props.isVehicle ? props.progress.Vehicles[props.step]?.[props.for] : props.progress[props.for]}
                                w={'100%'}
                                borderRadius={'50px'}
                                me={3}
                                size={'sm'}
                                sx={{
                                    '.css-ydxwrq': {
                                        transition: 'width .6s ease 0s',
                                    },
                                }}
                            />
                            <Text>{String(props.isVehicle ? props.progress.Vehicles[props.step]?.[props.for] : props.progress[props.for])}%</Text>
                        </Center>
                    </Box>
                    <Icon
                        boxSize={'15px'}
                        viewBox="0 0 20 20"
                        pos={'absolute'}
                        top={0}
                        right={0}
                        onClick={handleCancel}
                    >
                        <Close />
                    </Icon>
                </Flex>
            </Box>)}
           
        </FormControl>
    )
}

FileUpload.propTypes = {
    type: PropTypes.string.isRequired,
    focus: PropTypes.object.isRequired,
    for: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    value: PropTypes.string,
    updateData: PropTypes.func,
    setFileValidation: PropTypes.func,
    fileValidation: PropTypes.object,
    isVehicle: PropTypes.bool
}
