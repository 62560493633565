import { Box, Icon, Modal, ModalBody, ModalContent, ModalOverlay, Text, useDisclosure } from '@chakra-ui/react';
import Nav from '../../../Components/Navbar/Nav';
import { Outlet, useNavigate } from 'react-router-dom';
import {
    HomeIcon, 
    Ahome, 
    Iorders,
    Irequest,
    Aorders,
    Arequest, 
    Wallet,
    Awallet,
    Iproduct,
    Aproduct,
    NoData,
    
  } from '../../../Assets';
import { useContext } from 'react';
import AuthContext from '../../../Components/Admin/Contexts/AdminContext';



  


export const MenuNavigation = [
    {
      icon: {
        inactive: (
          <HomeIcon/>
        ),
        active: (
          <Ahome/>
        ),
      },
      title: "Overview",
      route: "overview",
    },    
    { 
      icon: {
        inactive: (
          <Irequest/>
        ),
        active: (
          <Arequest/>
        ),
      },
      title: "Requests",
      route: "requests",
    },
    {
      icon: {
        inactive: (
          <Iorders/>
        ),
        active: (
          <Aorders/>
        ),
      },
      title: "Orders",
      route: "orders",
    },
    {
      icon: {
        inactive: (
          <Iproduct/>
        ),
        active: (
          <Aproduct/>
        ),
      },
      title: "Products",
      route: "products",
    },
    {
      icon: {
        inactive: (
          <Wallet/>
        ),
        active: (
          <Awallet/>
        ),
      },
      title: "Wallet",
      route: "wallet",
    },
      
  ];

  const route = '/Login'

   
  
  const Dashboard = () => {

    const {profile,profileDoc} = useContext(AuthContext);
    const { isOpen: isSecondModalOpen, onOpen: onSecondModalOpen, onClose: onSecondModalClose } = useDisclosure();
    const navigate = useNavigate();


    const handleProceedToCheckout = () => {
      if (profile === 'false' || profileDoc === 'false') { // Check if profile is false (incomplete)
        console.log('Navigate to profile page');
        onSecondModalOpen();
      } else {
        return;
        // console.log('Customer profile data is complete');
        // Proceed with other actions if needed
      }
    };


  return (
    <Box height="100vh" position='relative' onClick={handleProceedToCheckout}>
      <Nav Outlet = {<Outlet/>} MenuNavigation = {MenuNavigation} route={route}  />
    
      <Modal isOpen={isSecondModalOpen} onClose={onSecondModalClose} >
        <ModalOverlay />
        <ModalContent>
          <ModalBody>
            <Box textAlign={'center'} py={'50px'} >
              <Icon boxSize='150px' viewBox="0 0 250 250" >
                <NoData />                      
              </Icon>         
              
              <Text 
                onClick={() => navigate('profile')}
                color={'primary.100'} fontSize={'base'} cursor={'pointer'} fontWeight={'bold'} textDecoration={'underline'} 
              >
                {profile === 'false' ? 'Kindly complete your profile' : (profileDoc === 'false' ? 'Kindly update your document' : '')}
              </Text>              
            
            </Box>        
       
          </ModalBody>         
        </ModalContent>
      </Modal>

    </Box>
  )
}

export default Dashboard