import { Box, Flex, Image,} from '@chakra-ui/react'
import { useState } from 'react'
import { Link, NavLink, useLocation } from 'react-router-dom'
import LHS from '../../Assets/LHS.svg'

const NavbarItem = ({items}) => {

  const [dropDown, setDropdown] = useState(false);
  const { pathname } = useLocation();

  const navLinkStyles = ({isActive}) => {
    return {
      color: isActive ? '#fff' : '#6C6A6A',
      fontWeight: isActive ?  'bold' : 'normal',
      background: isActive ? 'linear-gradient(to left,#101A36 95%, #049541 50%)' : 'none'
    }
  }

//   const subNavLinkStyles = ({isActive}) => {
//     return {
//       color: isActive ? '#101A36' : '#6C6A6A',
//       fontWeight: isActive ?  'bold' : 'normal',
//       background: isActive ? '#E8EEFF' : 'none',
//       padding: isActive ? '10px 0px 10px 0px' : '10px 0px 10px 0px',
//       fontSize: isActive ? "0.75rem" : "0.75rem"  , 
//       width: isActive ? '85%' : '85%',
//       marginleft : isActive ? 'auto' : 'auto'  
//     }
    
//   }



  return (
    <>
        {
            items.submenu ? (
                <>
                    <Box
                        onClick={() => setDropdown((prev) => !prev)}
                        p="20px 0 20px 20px"
                        cursor='pointer'
                        // background: 'linear-gradient(to left,#101A36 95%, #049541 50%)'
                        background={ pathname.includes(items.submenu.route) ? 'black' : 'white'}
                        // bgGradient={ pathname.includes(items.submenu.route) ? "linear(to left, #101A36 95%, #049541 50%)" : "black" }
                    >
                        <Flex>
                            <Box>{items.icon.inactive}</Box>
                            <Box p="0 10px">{items.title}</Box>
                        </Flex>
                    </Box>
                    <Box  display={dropDown ? 'block' : 'none'}>
                        {items.submenu.map((submenu, index) => (
                            <nav key={index} >
                                <Link
                                    to={submenu.route}
                                    key={index}                                   
                                >
                                    <Flex 
                                        alignItems="center"
                                        padding ='10px 0px 10px 10px'
                                        fontSize =  "0.625rem"  
                                        fontWeight={pathname.includes(submenu.route) ? 'semibold' : 'normal' }
                                        color={pathname.includes(submenu.route) ? 'primary.100' : 'primary.100'}
                                        backgroundColor={pathname.includes(submenu.route) ? 'primary.900' : '#fff'}
                                        width={'150px'}
                                        marginLeft={'auto'}
                                        // border={'1px solid red'}
                                        position={'relative'}
                                    >
                                        <Image  boxSize='40px'
                                            // objectFit='cover'
                                            src={LHS}
                                            left={'-10px'}
                                            position={'absolute'}
                                        />
                                        <Box>
                                            {pathname.includes(submenu.route) ? submenu.icon.active : submenu.icon.inactive}
                                        </Box>
                                        <Box p="0 10px">{submenu.title}</Box>
                                    </Flex>
                                </Link>

                            </nav>
                        ))} 
                    </Box>
                </>
                
            ) : ( 
                
                <NavLink to={items.route} style= {navLinkStyles}  > 
                    <Flex 
                        alignItems="center" 
                        width="100%" 
                        margin="10px 0" 
                        padding="10px 0 10px 20px"
                    >
                        <Box>
                            {pathname.includes(items.route)
                                ? items.icon.active
                                : items.icon.inactive
                            }
                        </Box>
                        <Box p="0 10px">
                            {items.title}
                        </Box>
                    
                    </Flex>
                </NavLink>
            ) 
        }
    </>
  )
}

export default NavbarItem;