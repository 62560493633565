import { Box, Divider, Tab, TabIndicator, TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/react'
import AdminBoardingRequest from './AdminBoardingRequest'
import AdminProductRequest from './AdminProductRequest'





const AdminRequest = () => {
  return (
    <Box  bg='primary.600'>
        <Tabs variant="unstyled" bg='#fff' m='20px' borderRadius={'lg'} pt={5} >
            <TabList p={'0 10px'}>
                <Tab color={'border.100'} _selected={{ color: "primary.100", borderBottom: "2px solid primary.100", fontWeight: 'semibold' }}>
                    Onboarding Request
                </Tab>
                <Tab color={'border.100'} _selected={{ color: "primary.100", borderBottom: "2px solid primary.100", fontWeight: 'semibold'}} >
                    Product Request
                </Tab>
            </TabList>
            <TabIndicator
            mt="-1.5px"
            height="2px"
            bg="primary.100"
            borderRadius="1px"             
            />
            <Divider/>

            <TabPanels>
                <TabPanel>
                    <AdminBoardingRequest/>
                </TabPanel>
                <TabPanel>
                    <AdminProductRequest/>
                </TabPanel>         
            </TabPanels>
      </Tabs>
    </Box>
  )
}

export default AdminRequest