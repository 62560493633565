import { 
  Box, 
  Divider, 
  Flex, 
  Icon, 
  Input, 
  InputGroup, 
  InputRightElement, 
  Tab, 
  TabIndicator, 
  TabList, 
  TabPanel, 
  TabPanels, 
  Tabs, 
  Text
} from '@chakra-ui/react'
import React, { useContext, useState } from 'react'
import { Search } from '../../../../Assets'
import AdminAllOrder from './AdminAllOrder';
import AdminIncoming from './AdminIncoming';
import AdminCancelled from './AdminCancelled';
import AdminCompleted from './AdminCompleted';
import AdminOutgoing from './AdminOutgoing';
import AuthContext from '../../Contexts/AdminContext';



const AdminOrder = () => {
  const { orderData,isLoadingOrderData,isErrorOrderData,tabId,tableData, handleTabChange } = useContext(AuthContext);
  const [searchQuery, setSearchQuery] = useState("");

  const allOrdersCount = orderData ? orderData.filter((data) => data.FullName.toLowerCase().includes(searchQuery.toLowerCase())).length : 0;
const incomingOrdersCount = orderData ? orderData.filter((data) => data.OrderStatusID === 1 && data.FullName.toLowerCase().includes(searchQuery.toLowerCase())).length : 0;
const outgoingOrdersCount = orderData ? orderData.filter((data) => data.OrderStatusID === 2 && data.FullName.toLowerCase().includes(searchQuery.toLowerCase())).length : 0;
const completedOrdersCount = orderData ? orderData.filter((data) => data.OrderStatusID === 3 && data.FullName.toLowerCase().includes(searchQuery.toLowerCase())).length : 0;
const cancelledOrdersCount = orderData ? orderData.filter((data) => data.OrderStatusID === 4 && data.FullName.toLowerCase().includes(searchQuery.toLowerCase())).length : 0;

 

  return (
    <Box  bg='primary.600'>
      <Tabs defaultIndex={tabId} onChange={(index) => handleTabChange(index)} variant="unstyled" bg='#fff' m='20px' borderRadius={'lg'} pt={5}  >
        <TabList justifyContent="space-between">
          <TabList p={'0 10px'}>
            <Tab 
              color={'border.100'} 
              _selected={{ 
                  color: "primary.100", 
                  borderBottom: "2px solid primary.100", 
                  fontWeight: 'semibold' 
              }}
              
            >
              <Text color='primary.300'>
                  All Orders
                  <Text as='span' fontSize='xs' fontWeight='semibold' borderRadius={'1rem'}  p={'1'} color='primary.100' ml='5px' bg='primary.600' >
                      {allOrdersCount}
                  </Text>
              </Text>  
            </Tab>
            <Tab color={'border.100'} _selected={{ color: "primary.100", borderBottom: "2px solid primary.100", fontWeight: 'semibold'}} >
              <Text color='primary.300' >
                  Incoming
                  <Text as='span' fontSize='xs' fontWeight='semibold' borderRadius={'1rem'}  p={'1'} color='primary.100' ml='5px' bg='primary.600' >
                    {incomingOrdersCount}
                  </Text>
              </Text>   
            </Tab>
            <Tab color={'border.100'} _selected={{ color: "primary.100", borderBottom: "2px solid primary.100", fontWeight: 'semibold'}} >
              <Text color='primary.300'>
                  Outgoing
                  <Text as='span' fontSize='xs' fontWeight='semibold' borderRadius={'1rem'}  p={'1'} color='primary.100' ml='5px' bg='primary.600' >
                    {outgoingOrdersCount}
                  </Text>
              </Text>   
            </Tab>
            <Tab color={'border.100'} _selected={{ color: "primary.100", borderBottom: "2px solid primary.100", fontWeight: 'semibold'}} >
              <Text color='primary.300'>
                  Completed
                  <Text as='span' fontSize='xs' fontWeight='semibold' borderRadius={'1rem'}  p={'1'} color='primary.100' ml='5px' bg='primary.600' >
                    {completedOrdersCount}
                  </Text>
              </Text>   
            </Tab>
            <Tab color={'border.100'} _selected={{ color: "primary.100", borderBottom: "2px solid primary.100", fontWeight: 'semibold'}} >
              <Text color='primary.300'>
                  Cancelled
                  <Text as='span' fontSize='xs' fontWeight='semibold' borderRadius={'1rem'}  p={'1'} color='primary.100' ml='5px' bg='primary.600' >
                    {cancelledOrdersCount}
                  </Text>
              </Text>   
            </Tab>
          </TabList>
          <Flex alignItems="center" mr={'20px'}>
            <InputGroup w={'250px'}>                       
              <Input placeholder='Search Users.....' />
              <InputRightElement>
                <Icon boxSize='25px'>
                  <Search
                    placeholder='Search Users.....' 
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                  />
                </Icon>
              </InputRightElement>
            </InputGroup>
          </Flex>
        </TabList>
        <TabIndicator
          mt="-1.5px"
          height="2px"
          bg="primary.100"
          borderRadius="1px"             
        />
        <Divider/>

        <TabPanels>
          <TabPanel>
              <AdminAllOrder orderData={orderData} isLoadingData={isLoadingOrderData} isErrorData={isErrorOrderData} tableData={tableData} />
          </TabPanel>
          <TabPanel>
              <AdminIncoming orderData={orderData} isLoadingData={isLoadingOrderData} isErrorData={isErrorOrderData} tableData={tableData} />
          </TabPanel>         
          <TabPanel>
              <AdminOutgoing orderData={orderData} isLoadingData={isLoadingOrderData} isErrorData={isErrorOrderData} tableData={tableData} />
          </TabPanel>         
          <TabPanel>
              <AdminCompleted orderData={orderData} isLoadingData={isLoadingOrderData} isErrorData={isErrorOrderData} tableData={tableData} />
          </TabPanel>         
          <TabPanel>
              <AdminCancelled orderData={orderData} isLoadingData={isLoadingOrderData} isErrorData={isErrorOrderData} tableData={tableData} />
          </TabPanel>         
        </TabPanels>
      </Tabs>
    </Box>
  )
}

export default AdminOrder;