import { 
    Box,
    Button, 
    Flex, 
    FormControl, 
    FormLabel, 
    Input, 
    InputGroup, 
    InputRightElement, 
    Spinner, 
    Text, 
    useToast
} from '@chakra-ui/react'
import { useContext, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Aemail, Apassword, Email, Password } from '../../Assets';
import AuthContext from '../Admin/Contexts/AdminContext';
import Loading from '../../Features/shared/Loading';


 

const Login = () => {

    const toast = useToast();

    const initialFormData = {
        emailAddress: '',
        password: '',
    };

    const { handleLogin, handleChangePassword} = useContext(AuthContext);
    const [loading, setLoading] = useState(false);
    const [loginData, setLoginData] = useState(initialFormData);
    const navigate = useNavigate();
  


    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setLoginData((data) => {
           return {...data, [name]: value}
        })
    };
 
    
    // const handleLogin = () => {
    //     console.log('Login Data:', loginData);
    //     setLoading(true);
    //     // navigate("dashboard");
    // };

    const handleSubmit = async (e) => {
        e.preventDefault();    
        setLoading(true);
        // console.log('Login Data:', loginData);
        try{

            let dat = await handleLogin(loginData);
            // console.log(dat)
             // console.log( typeof dat.status);
     
            if (dat.data.data.role === 'Vendor'){
                navigate('/dash');
                window.location.reload();
            // console.log('work please')
            }else if(dat.data.data.role === 'Customer'){
                navigate('/custdash')
                window.location.reload(); 
            }else if(dat.data.data.role === 'Delivery'){
                navigate('/truckdash')
                window.location.reload();
            }else{
                setLoading(false);
                toast({
                    title: 'Error!',
                    description: 'You are not a valid user',
                    status: 'error',
                    duration: 9000,
                    isClosable: true,
                    position:'top-center'
                });
                
            }
        }catch(error){
            setLoading(false);
                
        }
        
       
        // let dat;
        // handleLogin(loginData).then((data) => {
        //     dat=data;
        // })
    };
    
  return (
    <Box>
        <form style={{width: "80%", margin:" 0 auto"}} onSubmit={handleSubmit}  >
            <FormControl>
                <InputGroup>
                    <Input 
                        type="email" 
                        focusBorderColor='#858383' 
                        _focus={{ boxShadow: "none" }} 
                        bg={loginData.emailAddress ? 'primary.900' : 'transparent' }
                        h="50px"  id="username" 
                        fontSize="sm" required 
                        borderColor="#B5B4B4"
                        value={loginData.emailAddress}
                        onChange={handleInputChange}
                        name='emailAddress'
                    />
                    <FormLabel 
                        fontSize="xs" 
                        fontWeight="normal"  
                        htmlFor="username"
                        zIndex={'999'}
                    >
                        Username
                    </FormLabel>
                    <InputRightElement h={'100%'}>
                        { 
                            loginData.emailAddress ?  <Aemail /> : <Email/> 
                        }                        
                    </InputRightElement>
                
                </InputGroup>
            </FormControl>
            <FormControl mt="10px">
                <InputGroup>
                    <Input 
                        type="password" 
                        focusBorderColor='#858383' 
                        _focus={{ boxShadow: "none" }} 
                        bg={loginData.password ? 'primary.900' : 'transparent' }
                        h="50px" id="password"
                        fontSize="sm" required
                        borderColor="#B5B4B4"
                        value={loginData.password}
                        onChange={handleInputChange}
                        name='password'
                    />
                    <FormLabel 
                        fontWeight="normal" 
                        fontSize="xs" 
                        htmlFor="password"
                        zIndex={'999'}
                    >
                        Password
                    </FormLabel>
                    <InputRightElement h={'100%'}>
                        { 
                            loginData.password ?  <Apassword /> : <Password/> 
                        }                        
                    </InputRightElement>
                </InputGroup>
            </FormControl>


            <Text 
                color="primary.200" 
                fontSize="sm" 
                p="10px 0" 
                fontWeight="medium"
                w="80%"
            >
                Forgot Password?
            </Text>

            <Button
                m="60px 35px"
                bg="primary.100" 
                color="#fff" 
                p="20px 56px" 
                h="48px" 
                width="83%" 
                _focus={{ boxShadow: "none" }} 
                _hover={{bg: 'primary.100',
                color: 'none',}}
                type="submit" 

            >
                Login
            </Button>

            <Text fontSize='xs' textAlign='center' color='font.200'   > 
                Don’t have an account?  
                <Link to='/user_type' >
                    <b style={{color: "#101A36"}} > Register Now</b>
                </Link> 
            </Text>
        </form>

        {loading && (
              <Loading
                  text={`Processing...`}
                  color={`secondary.200`}
                  mark={true}
              />
        )}

    </Box>
  )
}

export default Login;