import { Box, Button, Flex, FormControl, FormLabel, HStack, Icon, Image, Input, InputGroup, InputRightElement, Modal, ModalBody, ModalContent, ModalOverlay, Tab, TabList, TabPanel, TabPanels, Tabs, Text, useDisclosure } from '@chakra-ui/react';
import React, { useContext, useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import AuthContext from '../../../../Components/Admin/Contexts/AdminContext';
import { Achevron, Aemail, Alocation, Apassword, Aperson, Aphone, CheckButton, HomeGrey, Password } from '../../../../Assets';
import  EmeryAva  from '../../../../Assets/EmeryAva.svg';
import DeliveryProfileKYC from './DeliveryProfileKYC';
import DeliveryProfileDoc from './DeliveryProfileDoc';


const DeliveryProfile = () => {


    // const customerData = {
    //     emailAddress:"",
    //     password: "",
    //     businessName: "",
    //     phoneNumber: "",
    //     homeAddress: "",
    //     localGovernment: "",
    //     customerState: "",
    //     customerCountry: "",
    //     postalCode: ""
    // };
    
    // const initalPasswordData = {
    //     oldPassword: '',
    //     newPassword: '',
    //     confirmNewPassword: '',
    // };
    
    // const [formData, setFormData] = useState(customerData);
    // const [passwordData, setPasswordData] = useState(initalPasswordData);
    // const { customerProfileData, isLoadingCustomerProfileData,isErrorCustomerProfileData,handleUpdateVendorProfile,handleChangeCustomerPassword,handleLogout} = useContext(AuthContext);
    // const { isOpen: isSecondModalOpen, onOpen: onSecondModalOpen, onClose: onSecondModalClose } = useDisclosure();
    // const { isOpen, onOpen, onClose } = useDisclosure();
    // const navigate = useNavigate();
 
    // useEffect(() => {
       
    //     !isLoadingCustomerProfileData &&
    //         setFormData(prevState => ({
    //             ...prevState,
    //             businessName: customerProfileData[0]?.BusinessName,
    //             phoneNumber: customerProfileData[0]?.PhoneNumber,
    //             emailAddress: customerProfileData[0]?.EmailAddress,
    //             homeAddress: customerProfileData[0]?.HomeAddress,
    //             localGovernment: customerProfileData[0]?.LocalGovernment,
    //             customerState: customerProfileData[0]?.CustomerState,
    //             customerCountry: customerProfileData[0]?.CustomerCountry,
    //             postalCode: customerProfileData[0]?.PostalCode
                
    //         }));
         
    //         console.log(customerProfileData);
    //     // console.log(formData);
    // }, [customerProfileData]);
  
    // if (isLoadingCustomerProfileData) {
    //     return <div>Loading....</div>
    // }
    
    // if (isErrorCustomerProfileData) {
    //     return <div>Error Fetching Data</div>
    // }



    // const handleInputChange = (e) => {
    //     const { name, value } = e.target;
    //     setFormData((data) => {
    //         return {...data, [name]: value}
    //     })
    // }; 

    // const handleSubmit = async(e) => {
    //     e.preventDefault(); 
    //     try{
    //         await handleUpdateVendorProfile(formData);
    //         onSecondModalOpen();

    //         // setTimeout(() => {
    //         //     navigate('/Login'); // Redirect to the login page
    //         // }, 2000); 

    //     }catch (error) {
    //         console.log('work please')
           
    //     } 
    // }

    // const handlePasswordChange = (e) => {
    //     const { name, value } = e.target;
    //     setPasswordData((data) => {
    //       return {...data, [name]: value}
    //     })
    // };
    
    // const handlePasswordSubmit =  async(e) => {
    //     e.preventDefault();    
    //     try {

    //         await handleChangeCustomerPassword(passwordData);

    //         setTimeout(() => {
    //             handleLogout();
    //             navigate('/Login'); // Redirect to the login page
    //         }, 2000); 
    //     } catch (error) {
    //         console.log('work please')
           
    //     }    
      
       
    
    // };


  return (
    <Box>
        <Tabs variant="unstyled"  >
            <TabList justifyContent="space-between" p={'0 30px 20px'}>
                <TabList ml={'20px'}>
                    
                    <Tab
                        color="primary.400"
                        position="relative"
                        border="1px solid #B5B4B4"
                        borderRadius= "10px"
                        marginRight='20px'
                        fontSize={'sm'}
                        width={'180px'}
                        height={'50px'}
                        _selected={{
                            color: "primary.200",
                            border: "1px solid #049541",
                            fontWeight: "semibold",
                            borderRadius: "10px",
                            backgroundColor: "font.300"
                        }}
                        
                    >                

                        <HStack alignItems="center" position="relative">
        {/*                                 
                            <Icon
                                boxSize="20px"
                                viewBox="0 0 50 50"
                                position="absolute"
                                top="3px"
                                left="-20px"
                                as={props.DeliveryType === 2 ? Trucking : GreenTruck} 
                            />                             */}
                            <Text ml={2} >Profile KYC</Text>
                        </HStack>                            
                    </Tab>
                    <Tab
                        color="primary.400"
                        position="relative"
                        border="1px solid #B5B4B4"
                        borderRadius= "10px"
                        fontSize={'sm'}
                        width={'180px'}
                        height={'50px'}
                        _selected={{
                            color: "primary.200",
                            border: "1px solid #049541",
                            fontWeight: "semibold",
                            borderRadius: "10px",
                            backgroundColor: "font.300"
                        }}
                        
                    >
                        <HStack alignItems="center" position="relative">
                            
                            {/* <Icon
                                boxSize="20px"
                                viewBox="0 0 50 50"
                                position="absolute"
                                top="3px"
                                left="-20px"
                                as={props.DeliveryType === 1 ? Trucking : GreenTruck} 
                            />                                    */}
                                                        
                            <Text ml={2} >Documents Upload</Text>
                        </HStack>
                        
                    </Tab>
                </TabList>

                <Box>
                    <Link to='/truckdash' >
                        <Text fontWeight={'bold'} fontSize={'lg'}  py={'10px'} px={'20px'} textDecoration={'underline'}  >
                           Back to Dashboard              
                        </Text>
                    </Link>
                </Box>

            </TabList>

            <TabPanels>

                <TabPanel>
                    <DeliveryProfileKYC/>
                </TabPanel>
                <TabPanel>
                    <DeliveryProfileDoc/>
                </TabPanel>

            </TabPanels>
        </Tabs> 

    </Box>
  )
}

export default DeliveryProfile