import { Box, Flex, HStack, Icon, Image, Stack, Text, useMediaQuery } from '@chakra-ui/react'
import React from 'react'
import { Aboutbg, PageCart, PageFuel, PageTime} from '../../../Assets';
import Stog from '../../../Assets/stog.svg'
import EmeryAva from '../../../Assets/EmeryAva.svg';
import Slider from 'react-slick';
import { Link } from 'react-router-dom';


function SampleNextArrow(props) {
  const { className, style} = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "none", background: "none" }}
    />
  );
}
  
function SamplePrevArrow(props) {
  const { className, style} = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "none", background: "none" }}
    />
  );
}



const About = ({isLoadingProduct,isErrorProduct,productData}) => {

  const [isSm] = useMediaQuery(`(max-width: 992px)`);
  const [isXs] = useMediaQuery(`(max-width: 760px)`);
  const direction = isSm ? "column" : "row";

  const settings = {
    className: "center",
    dots: false,
    infinite: true,
    speed:1000,
    slidesToShow: 6,
    // slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    autoplay: true,
    autoplaySpeed: 2000,
    centerMode: true,
    centerPadding: '40px', 
    vertical: true,
    verticalSwiping: true,
    beforeChange: function(currentSlide, nextSlide) {
      return currentSlide, nextSlide;
      // console.log("before change", currentSlide, nextSlide); 
    },
    afterChange: function(currentSlide) {
      return currentSlide;
      // console.log("after change", currentSlide);
    }
    
  };

  function formatPrice(price) {
    const numericPrice = parseFloat(price.replace(/,/g, '')); // Remove existing commas if any
    if (!isNaN(numericPrice)) {
      const formattedPrice = numericPrice.toLocaleString('en-US'); // Format as a number with commas
      return `${formattedPrice}`; // Add Naira symbol in front of the formatted price
    }
    return price;
  }

  // if (isLoadingProduct) {
  //   return <div>Loading....</div>
  // }

  // if (isErrorProduct) {
  //   return <div>Error Fetching Data</div>
  // }

  return (
    <Box position={'relative'} pl={isXs ? 10 : 20} pr={isXs ? 10 : 20} py={20} >
      <Flex alignItems={'center'} flexDir={direction} >
        <Box w={ isSm ? '100%' : '50%'} mt={'0px'} margin={isSm ? 'auto' : null }  textAlign={isSm ? 'center' : null} >
          <Text
            fontWeight={'bold'}
            fontSize={['2xl','3.5xl','5xl']}           
          >
            Powering Your <br /> LPG Experience
          </Text>
          <Image
            mt={'10'}
            boxSize='85%'    
            marginLeft={isSm ? 'auto' : null }          
            marginRight={isSm ? 'auto' : null }          
            objectFit='cover' src={Aboutbg} 
          />
        </Box>

        <Box w={ isSm ? '100%' : '50%'}  py={'30px'} margin={isSm ? 'auto' : null }    >
          <HStack alignItems={'center'} justifyContent={'space-between'} pl={ isSm ? 0 : 20} mt={10} >
            <Text fontWeight={'medium'} fontSize={'2xl'}   >
              Our Customers
            </Text>
            <Link to='/'>
              <Text fontWeight={'medium'} fontSize={'base'} color={'primary.200'} textDecoration={'underline'}  >
                See All
              </Text>
            </Link>

          </HStack>
          <Box className='cot' borderLeft={ isSm ? null : '1px solid #B5B4B4'} pl={ isSm ? 0 : 20} mt={10} >
            <Slider {...settings} >

              {
                productData && productData.map((data) =>
                  <Box 
                    py={2} 
                    px={5} 
                    borderRadius={'2xl'} 
                    // w={'200px'}
                    // border={'1px solid #E6E6E6'} 
                    key={data.ProductID}
                  
                  > 
                    <HStack alignItems={'center'} justifyContent={'space-between'} >
                      <Image    
                        ml={'-10px'}          
                        boxSize='40px'
                        objectFit='cover' 
                        src={EmeryAva} 
                      /> 
                      <Box>
                        <Text
                          fontSize={'lg'}
                          fontWeight={'bold'}
                          // py={3}
                          // textAlign={'left'}
                        >
                          {data.VendorName}
                        </Text>
                        
                        <Text
                          fontSize={'xs'}
                          color={'brand.500'}
                          // py={3}
                          textAlign={'left'}
                        >
                          ₦{formatPrice(data.Price)} per MT | Quantity Available - {data.Quantity}MT
                        </Text>
                      </Box>           
                    </HStack>
                  
                  </Box>
                )
              }

              {/* <Box 
                py={2} 
                px={5} 
                borderRadius={'2xl'} 
                // w={'200px'}
                // border={'1px solid #E6E6E6'} 
                
              > 
                <HStack alignItems={'center'} justifyContent={'space-between'} >
                  <Image    
                    ml={'-10px'}          
                    boxSize='40px'
                    objectFit='cover' 
                    src={EmeryAva} 
                  /> 
                  <Box>
                    <Text
                      fontSize={'lg'}
                      fontWeight={'bold'}
                      // py={3}
                      // textAlign={'left'}
                    >
                      Hermann Gas
                    </Text>
                    
                    <Text
                      fontSize={'xs'}
                      color={'brand.500'}
                      // py={3}
                      textAlign={'left'}
                    >
                      ₦2398.23 per kg | Quantity Available - 450kg
                    </Text>
                  </Box>           
                </HStack>
              
              </Box>
              <Box 
                py={2} 
                px={5} 
                borderRadius={'2xl'} 
                // w={'200px'}
                // border={'1px solid #E6E6E6'} 
                
              > 
                <HStack alignItems={'center'} justifyContent={'space-between'} >
                  <Image    
                    ml={'-10px'}          
                    boxSize='40px'
                    objectFit='cover' 
                    src={EmeryAva} 
                  /> 
                  <Box>
                    <Text
                      fontSize={'lg'}
                      fontWeight={'bold'}
                      // py={3}
                      // textAlign={'left'}
                    >
                      Hermann Gas
                    </Text>
                    
                    <Text
                      fontSize={'xs'}
                      color={'brand.500'}
                      // py={3}
                      textAlign={'left'}
                    >
                      ₦2398.23 per kg | Quantity Available - 450kg
                    </Text>
                  </Box>           
                </HStack>
              
              </Box>
              <Box 
                py={2} 
                px={5} 
                borderRadius={'2xl'} 
                // w={'200px'}
                // border={'1px solid #E6E6E6'} 
                
              > 
                <HStack alignItems={'center'} justifyContent={'space-between'} >
                  <Image    
                    ml={'-10px'}          
                    boxSize='40px'
                    objectFit='cover' 
                    src={EmeryAva} 
                  /> 
                  <Box>
                    <Text
                      fontSize={'lg'}
                      fontWeight={'bold'}
                      // py={3}
                      // textAlign={'left'}
                    >
                      Hermann Gas
                    </Text>
                    
                    <Text
                      fontSize={'xs'}
                      color={'brand.500'}
                      // py={3}
                      textAlign={'left'}
                    >
                      ₦2398.23 per kg | Quantity Available - 450kg
                    </Text>
                  </Box>           
                </HStack>
              
              </Box>
              <Box 
                py={2} 
                px={5} 
                borderRadius={'2xl'} 
                // w={'200px'}
                // border={'1px solid #E6E6E6'} 
                
              > 
                <HStack alignItems={'center'} justifyContent={'space-between'} >
                  <Image    
                    ml={'-10px'}          
                    boxSize='40px'
                    objectFit='cover' 
                    src={EmeryAva} 
                  /> 
                  <Box>
                    <Text
                      fontSize={'lg'}
                      fontWeight={'bold'}
                      // py={3}
                      // textAlign={'left'}
                    >
                      Hermann Gas
                    </Text>
                    
                    <Text
                      fontSize={'xs'}
                      color={'brand.500'}
                      // py={3}
                      textAlign={'left'}
                    >
                      ₦2398.23 per kg | Quantity Available - 450kg
                    </Text>
                  </Box>           
                </HStack>
              
              </Box>
              <Box 
                py={2} 
                px={5} 
                borderRadius={'2xl'} 
                // w={'200px'}
                // border={'1px solid #E6E6E6'} 
                
              > 
                <HStack alignItems={'center'} justifyContent={'space-between'} >
                  <Image    
                    ml={'-10px'}          
                    boxSize='40px'
                    objectFit='cover' 
                    src={EmeryAva} 
                  /> 
                  <Box>
                    <Text
                      fontSize={'lg'}
                      fontWeight={'bold'}
                      // py={3}
                      // textAlign={'left'}
                    >
                      Hermann Gas
                    </Text>
                    
                    <Text
                      fontSize={'xs'}
                      color={'brand.500'}
                      // py={3}
                      textAlign={'left'}
                    >
                      ₦2398.23 per kg | Quantity Available - 450kg
                    </Text>
                  </Box>           
                </HStack>
              
              </Box>
              <Box 
                py={2} 
                px={5} 
                borderRadius={'2xl'} 
                // w={'200px'}
                // border={'1px solid #E6E6E6'} 
                
              > 
                <HStack alignItems={'center'} justifyContent={'space-between'} >
                  <Image    
                    ml={'-10px'}          
                    boxSize='40px'
                    objectFit='cover' 
                    src={EmeryAva} 
                  /> 
                  <Box>
                    <Text
                      fontSize={'lg'}
                      fontWeight={'bold'}
                      // py={3}
                      // textAlign={'left'}
                    >
                      Hermann Gas
                    </Text>
                    
                    <Text
                      fontSize={'xs'}
                      color={'brand.500'}
                      // py={3}
                      textAlign={'left'}
                    >
                      ₦2398.23 per kg | Quantity Available - 450kg
                    </Text>
                  </Box>           
                </HStack>
              
              </Box>
              <Box 
                py={2} 
                px={5} 
                borderRadius={'2xl'} 
                // w={'200px'}
                // border={'1px solid #E6E6E6'} 
                
              > 
                <HStack alignItems={'center'} justifyContent={'space-between'} >
                  <Image    
                    ml={'-10px'}          
                    boxSize='40px'
                    objectFit='cover' 
                    src={EmeryAva} 
                  /> 
                  <Box>
                    <Text
                      fontSize={'lg'}
                      fontWeight={'bold'}
                      // py={3}
                      // textAlign={'left'}
                    >
                      Hermann Gas
                    </Text>
                    
                    <Text
                      fontSize={'xs'}
                      color={'brand.500'}
                      // py={3}
                      textAlign={'left'}
                    >
                      ₦2398.23 per kg | Quantity Available - 450kg
                    </Text>
                  </Box>           
                </HStack>
              
              </Box>
              <Box 
                py={2} 
                px={5} 
                borderRadius={'2xl'} 
                // w={'200px'}
                // border={'1px solid #E6E6E6'} 
                
              > 
                <HStack alignItems={'center'} justifyContent={'space-between'} >
                  <Image    
                    ml={'-10px'}          
                    boxSize='40px'
                    objectFit='cover' 
                    src={EmeryAva} 
                  /> 
                  <Box>
                    <Text
                      fontSize={'lg'}
                      fontWeight={'bold'}
                      // py={3}
                      // textAlign={'left'}
                    >
                      Hermann Gas
                    </Text>
                    
                    <Text
                      fontSize={'xs'}
                      color={'brand.500'}
                      // py={3}
                      textAlign={'left'}
                    >
                      ₦2398.23 per kg | Quantity Available - 450kg
                    </Text>
                  </Box>           
                </HStack>
              
              </Box>                      */}
              

            </Slider>
          </Box>
        </Box>
      </Flex>

      {
        isSm ? null : 
        <Image
          bg={'#fff'}
          zIndex={999}
          // border={'1px solid red'}
          borderRadius={'50%'}
          position={'absolute'}
          bottom={'42%'}
          right={'47.5%'}
          // ml={'auto'}
          // boxSize='10%'
          width={ isSm ? '10%' : '5%'}
          src={Stog} 
        />
      }
      
    </Box>
  )
}

export default About