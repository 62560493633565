import { Box, Image, Text } from "@chakra-ui/react"
import PropTypes from "prop-types"
import { 
    step1Image, 
    step2Image, 
    step3Image, 
    step4Image, 
    customerStep1Image, 
    customerStep2Image, 
    deliveryStep1Image, 
    deliveryStep2Image, 
    deliveryStep3Image
} from "../../Assets";
import { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";


const VendorImages = [
        { 
            image: step1Image, 
            alt: 'Image 1' 
        },
        { 
            image: step2Image, 
            alt: 'Image 2' 
        },
        { 
            image: step3Image, 
            alt: 'Image 3' 
        },
        { 
            image: step4Image, 
            alt: 'Image 4' 
        },
]

const CustomerImages = [
        { 
            image: customerStep1Image, 
            alt: 'Image 1' 
        },
        { 
            image: customerStep2Image, 
            alt: 'Image 2' 
        },
        { 
            image: step4Image, 
            alt: 'Image 3' 
        },
]

const CheckImages = [
        { 
            image: customerStep1Image, 
            alt: 'Image 1' 
        },
        { 
            image: customerStep2Image, 
            alt: 'Image 2' 
        },
        { 
            image: step4Image, 
            alt: 'Image 3' 
        },
]

const DeliveryImages = [
        { 
            image: deliveryStep1Image, 
            alt: 'Image 1' 
        },
        { 
            image: deliveryStep2Image, 
            alt: 'Image 2' 
        },
        { 
            image: deliveryStep3Image, 
            alt: 'Image 3' 
        },
        { 
            image: step3Image, 
            alt: 'Image 4' 
        },
]


export const OnBoardingImages = (props) => {
    const [currentImageIndex, setCurrentImageIndex] = useState(0);
    const navigate = useNavigate();
    
    useEffect(() => {
        setCurrentImageIndex(props.currentStep);
    }, [props.currentStep]);
    
     const transitionStyles = {
        transition: "opacity .4s ease-in-out",
        'opacity': 1,
     };
    
     const hiddenStyles = {
        'opacity': 0,
     };

    return (
        <>
            { props.for === 'check' ? <Link to='/custdash' >
                <Text bg={'#fff'} position={'absolute'} fontWeight={'bold'} fontSize={'lg'}  py={'10px'} px={'20px'} borderRadius={'20px'} right={'20px'} top={'20px'}  >
                        Back to Marketplace                
                </Text>
            </Link>: null }
            <Image
                style={currentImageIndex === props.currentStep ? transitionStyles : hiddenStyles}
                src={
                    props.for === 'vendor' ? VendorImages[props.currentStep].image : props.for === 'customer' ? CustomerImages[props.currentStep].image : props.for === 'delivery' ? DeliveryImages[props.currentStep].image : props.for === 'check' ? CheckImages[props.currentStep].image : alert("you were never meant to reach here"
                    )}
                alt={VendorImages[props.currentStep].alt}
                w={`100%`}
                h={`100%`}
                transition='src 1.5s'
            />
        
        </>
    )
}

OnBoardingImages.propstype = {
    currentStep: PropTypes.number.isRequired,
    for: PropTypes.oneOf(['vendor', 'customer', 'delivery'])
}
