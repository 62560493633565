import { Box, Button } from '@chakra-ui/react';
import React, { useContext, useState } from 'react'
import { DocUpload } from '../../../Signup/Vendor/Forms/DocUpload';
import AuthContext from '../../../../Components/Admin/Contexts/AdminContext';
import { useNavigate } from 'react-router-dom';

const VendorProfileDoc = () => {
    const INITIAL_DATA = {       
        CertificateOfIncorporation: "", // File(Url)
        Form: "", // File(Url)
        OperatingLicence: "", // File(Url)
        SignedAgreement: "", // File(Url)
        BankReferenceLetter: "", // File(Url)
    };

    const [data, setData] = useState(INITIAL_DATA);
    const {handleUploadVendor,handleLogout} = useContext(AuthContext);
    const navigate = useNavigate();


     const [fileValidation, setFileValidation] = useState({
        CertificateOfIncorporation: false,
        Form: false,
        OperatingLicence: false,
        SignedAgreement: false,
        BankReferenceLetter: false,
    })
    const [uploadError, setUploadError] = useState(false)
    const [files, setFiles] = useState({
        CertificateOfIncorporation: '',
        Form: '',
        OperatingLicence: '',
        SignedAgreement: '',
        BankReferenceLetter: '',
    })
    const [progress, setProgress] = useState({
        CertificateOfIncorporation: 0,
        Form: 0,
        OperatingLicence: 0,
        SignedAgreement: 0,
        BankReferenceLetter: 0,
    })
    const [fileName, setFileName] = useState({
        CertificateOfIncorporation: '',
        Form: '',
        OperatingLicence: '',
        SignedAgreement: '',
        BankReferenceLetter: '',
    })
    const [fileSize, setFileSize] = useState({
        CertificateOfIncorporation: '',
        Form: '',
        OperatingLicence: '',
        SignedAgreement: '',
        BankReferenceLetter: '',
    })
    const height = '4px'
    const buttonVH = '10vh'
    const formHeight = `calc(100% - ${buttonVH} )`

    const updateData = (fields) => {
        setData(prevData => {
            const newData = { ...prevData, ...fields };           
            return newData;
        })
    }

  return (
    <Box>
        <DocUpload  height={formHeight} {...data} data={data} updateData={updateData} setFileValidation={setFileValidation} fileValidation={fileValidation} uploadError={uploadError} files={files} setFiles={setFiles} progress={progress} setProgress={setProgress} fileName={fileName} setFileName={setFileName} fileSize={fileSize} setFileSize={setFileSize} />,
        <Box>
            <Button
                // m="60px 35px"
                // mt={'30px'}
                bg="primary.100" 
                color="#fff" 
                p="20px 56px" 
                h="48px" 
                width="100%" 
                _focus={{ boxShadow: "none" }} 
                _hover={{bg: 'primary.100',
                color: 'none',}}
                onClick={()=>{
                    handleUploadVendor(data)
                    setTimeout(() => {
                                    handleLogout();
                                    navigate('/Login'); // Redirect to the login page
                                }, 4000); 
                }}  

            >
                Save Document
            </Button>
        </Box>
  
    </Box>
  )
}

export default VendorProfileDoc