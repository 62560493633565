import { createContext, useState } from "react";

export const DeliveryContext = createContext()

export const DeliveryContextProvider = (props) => {
    const INITIAL_DATA = {
        userType: 'Delivery', // String
        businessName: "q", // String
        phoneNumber: "q", // String
        emailAddress: "q", // String
        password: "q", // String
        fullAddress: "q", // String
        localGovernment: "q", // String
        state: "q", // String
        businessAccountNumber: 1234567890, // Number
        taxIdentificationNumber: 1234567890, // Number
        certificateOfIncorporation: "", // File(Url)
        Form2Aand7A: "", // File(Url)
        termsAndConditions: false, // Boolean
    };
    const [data, setData] = useState(INITIAL_DATA)
    const [fileValidation, setFileValidation] = useState({
        certificateOfIncorporation: false,
        Form2Aand7A: false,
    })
    const [uploadError, setUploadError] = useState(false)
    const [files, setFiles] = useState({
        certificateOfIncorporation: '',
        Form2Aand7A: '',
    })
    const [progress, setProgress] = useState({
        certificateOfIncorporation: 0,
        Form2Aand7A: 0,
    })
    const [fileName, setFileName] = useState({
        Vehicles: []
    })
    const [fileSize, setFileSize] = useState({
        Vehicles: []
    })
    const height = '4px'
    const buttonVH = '10vh'
    const formHeight = `calc(100% - ${buttonVH} )`
    
    const updateData = (fields) => {
        setData(p => {
            return {...p, ...fields}
        })
    }

    const contextValue = {
        ...data,
        setData,
        fileValidation,
        setFileValidation,
        uploadError,
        setUploadError,
        files,
        setFiles,
        progress,
        setProgress,
        fileName,
        setFileName,
        fileSize,
        setFileSize,
        formHeight,
        updateData,
    };

    return (
        <DeliveryContext.Provider value={contextValue}>
            {props.children}
        </DeliveryContext.Provider>
    )
}