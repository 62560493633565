import { Box, Button, Flex, FormControl, FormLabel, HStack, Icon, Image, Input, InputGroup, InputRightElement, Modal, ModalBody, ModalContent, ModalOverlay, Text, useDisclosure, useToast } from '@chakra-ui/react';
import React, { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import AuthContext from '../../../../Components/Admin/Contexts/AdminContext';
import { Achevron, Aemail, Alocation, Apassword, Aperson, Aphone, CheckButton, HomeGrey, Password } from '../../../../Assets';
import  EmeryAva  from '../../../../Assets/EmeryAva.svg';


const DeliveryProfileKYC = () => {

    const customerData = {
        emailAddress:"",
        password: "",
        businessName: "",
        phoneNumber: "",
        homeAddress: "",
        localGovernment: "",
        customerState: "",
        customerCountry: "",
        postalCode: ""
    };
    
    const initalPasswordData = {
        oldPassword: '',
        newPassword: '',
        confirmNewPassword: '',
    };
    
    const [formData, setFormData] = useState(customerData);
    const [passwordData, setPasswordData] = useState(initalPasswordData);
    const { customerProfileData, isLoadingCustomerProfileData,isErrorCustomerProfileData,handleUpdateVendorProfile,handleChangeCustomerPassword,handleLogout} = useContext(AuthContext);
    const { isOpen: isSecondModalOpen, onOpen: onSecondModalOpen, onClose: onSecondModalClose } = useDisclosure();
    const { isOpen, onOpen, onClose } = useDisclosure();
    const toast = useToast();
    const navigate = useNavigate();
 
    useEffect(() => {
       
        !isLoadingCustomerProfileData &&
            setFormData(prevState => ({
                ...prevState,
                businessName: customerProfileData[0]?.BusinessName,
                phoneNumber: customerProfileData[0]?.PhoneNumber,
                emailAddress: customerProfileData[0]?.EmailAddress,
                homeAddress: customerProfileData[0]?.HomeAddress,
                localGovernment: customerProfileData[0]?.LocalGovernment,
                customerState: customerProfileData[0]?.CustomerState,
                customerCountry: customerProfileData[0]?.CustomerCountry,
                postalCode: customerProfileData[0]?.PostalCode
                
            }));
         
            console.log(customerProfileData);
        // console.log(formData);
    }, [customerProfileData]);
  
    if (isLoadingCustomerProfileData) {
        return <div>Loading....</div>
    }
    
    if (isErrorCustomerProfileData) {
        return <div>Error Fetching Data</div>
    }



    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData((data) => {
            return {...data, [name]: value}
        })
    }; 

    const handleSubmit = async(e) => {
        e.preventDefault(); 
        try{
            await handleUpdateVendorProfile(formData);
            // onSecondModalOpen();
            toast({
                title: 'Success!',
                description: 'Profile Successfully Updated',
                status: 'success',
                duration: 9000,
                isClosable: true,
                position: 'top-center'
            });
        }catch (error) {
            console.log('work please')
           
        } 
    }

    const handlePasswordChange = (e) => {
        const { name, value } = e.target;
        setPasswordData((data) => {
          return {...data, [name]: value}
        })
    };
    
    const handlePasswordSubmit =  async(e) => {
        e.preventDefault();    
        try {

            await handleChangeCustomerPassword(passwordData);

            setTimeout(() => {
                handleLogout();
                navigate('/Login'); // Redirect to the login page
            }, 2000); 
        } catch (error) {
            console.log('work please')
           
        }    
      
       
    
    };



  return (
    <Box>
        <form onSubmit={handleSubmit} >
            <Flex h={'50vh'} py={5} >
                <Box w={'30%'} p={5} >
                    <Text fontSize={'lg'} fontWeight={'bold'} color={'primary.800'}  >
                        Profile Details
                    </Text>
                    <Text fontSize={'sm'} color={'font.500'} py={5} >
                        Update your basic details
                    </Text>
                </Box>
                <Box w={'70%'}>
                    <Box ml={'20px'} >
                        <Image
                            boxSize='70px'
                            objectFit='cover' src={EmeryAva} 
                        />
                    </Box>  
                    <Box p={5} > 
                        <FormControl>
                            <InputGroup>
                                <Input 
                                    type="text" 
                                    focusBorderColor='#858383' 
                                    _focus={{ boxShadow: "none" }} 
                                    bg={'transparent'}
                                    h="50px"  id="businessName" 
                                    fontSize="14px" required 
                                    borderColor="#B5B4B4"
                                    value={formData.businessName}
                                    onChange={handleInputChange}
                                    name='businessName'
                                />
                                <FormLabel 
                                    fontSize="12px" 
                                    fontWeight="normal"  
                                    htmlFor="businessName"
                                    zIndex={'999'}

                                >
                                    Business Name
                                </FormLabel>
                                <InputRightElement h={'100%'}> 
                                    <Aperson />                                                     
                                </InputRightElement>
                            </InputGroup>
                        </FormControl>
                        <HStack my={5} >
                            <FormControl>
                                <InputGroup>
                                    <Input 
                                        type="tel" 
                                        focusBorderColor='#858383' 
                                        _focus={{ boxShadow: "none" }} 
                                        bg={'transparent'}
                                        h="50px"  id="phoneNumber" 
                                        fontSize="14px" required 
                                        borderColor="#B5B4B4"
                                        value={formData.phoneNumber}
                                        onChange={handleInputChange}
                                        name='phoneNumber'
                                        maxLength={11}
                                        onKeyDown={(e) => {
                                            const isValidInput = /^\d$/.test(e.key) || e.key === 'Backspace';
                                            if (!isValidInput) {
                                                e.preventDefault();
                                            }
                                        }}
                                    />
                                    <FormLabel 
                                        fontSize="12px" 
                                        fontWeight="normal"  
                                        htmlFor="phoneNumber"
                                        zIndex={'999'}
                                    >
                                        Phone Number
                                    </FormLabel>
                                    <InputRightElement h={'100%'}>
                                        <Aphone />                                                     
                                    </InputRightElement>
                                </InputGroup>
                            </FormControl>
                            <FormControl>
                                <InputGroup>
                                    <Input 
                                        type="email" 
                                        focusBorderColor='#858383' 
                                        _focus={{ boxShadow: "none" }} 
                                        bg={'transparent'}
                                        h="50px"  id="email" 
                                        fontSize="14px" required 
                                        borderColor="#B5B4B4"
                                        value={formData.emailAddress}
                                        onChange={handleInputChange}
                                        name='emailAddress'
                                    />
                                    <FormLabel 
                                        fontSize="12px" 
                                        fontWeight="normal"  
                                        htmlFor="emailAddress"
                                        zIndex={'999'}
                                    >
                                        Email Address
                                    </FormLabel>
                                    <InputRightElement h={'100%'}>
                                        <Aemail />                                                     
                                    </InputRightElement>
                                </InputGroup>
                            </FormControl>
                        </HStack>
                    </Box>
                </Box>
            </Flex>
            <Flex h={'50vh'} py={5} >
                <Box w={'30%'}  p={5}  >
                    <Text fontSize={'lg'} fontWeight={'bold'} color={'primary.800'}  >
                        LPG Transporter Address
                    </Text>
                    <Text fontSize={'sm'} color={'font.500'} py={5} >
                        Update your location.
                    </Text>
                </Box>
                <Box w={'70%'}   >
                    <Box p={5} > 
                        <FormControl>
                            <InputGroup>
                                <Input 
                                    type="text" 
                                    focusBorderColor='#858383' 
                                    _focus={{ boxShadow: "none" }} 
                                    bg={'transparent'}
                                    h="50px"  id="username" 
                                    fontSize="14px" required 
                                    borderColor="#B5B4B4"
                                    value={formData.homeAddress}
                                    onChange={handleInputChange}
                                    name='homeAddress'
                                />
                                <FormLabel 
                                    fontSize="12px" 
                                    fontWeight="normal"  
                                    htmlFor="homeAddress"
                                    zIndex={'999'}

                                >
                                    Full Address
                                </FormLabel>
                                <InputRightElement h={'100%'}>
                                    <HomeGrey />                                                     
                                </InputRightElement>
                            </InputGroup>
                        </FormControl>
                        <HStack my={5} >
                            <FormControl>
                                <InputGroup>
                                    <Input 
                                        type="text" 
                                        focusBorderColor='#858383' 
                                        _focus={{ boxShadow: "none" }} 
                                        bg={'transparent'}
                                        h="50px"  id="localGovernment" 
                                        fontSize="14px" required 
                                        borderColor="#B5B4B4"
                                        value={formData.localGovernment}
                                        onChange={handleInputChange}
                                        name='localGovernment'
                                    />
                                    <FormLabel 
                                        fontSize="12px" 
                                        fontWeight="normal"  
                                        htmlFor="localGovernment"
                                        zIndex={'999'}
                                    >
                                        Local Government
                                    </FormLabel>
                                    <InputRightElement h={'100%'}>
                                        <Alocation/>                                                     
                                    </InputRightElement>
                                </InputGroup>
                            </FormControl>
                            <FormControl>
                                <InputGroup>
                                    <Input 
                                        type="text" 
                                        focusBorderColor='#858383' 
                                        _focus={{ boxShadow: "none" }} 
                                        bg={'transparent'}
                                        h="50px"  id="state" 
                                        fontSize="14px" required 
                                        borderColor="#B5B4B4"
                                        value={formData.customerState}
                                        onChange={handleInputChange}
                                        name='customerState'
                                    />
                                    <FormLabel 
                                        fontSize="12px" 
                                        fontWeight="normal"  
                                        htmlFor="customerState"
                                        zIndex={'999'}
                                    >
                                        State
                                    </FormLabel>
                                    <InputRightElement h={'100%'}>
                                        <Achevron />                                                     
                                    </InputRightElement>
                                </InputGroup>
                            </FormControl>
                        </HStack>
                        <HStack my={5} >
                            <FormControl>
                                <InputGroup>
                                    <Input 
                                        type="text" 
                                        focusBorderColor='#858383' 
                                        _focus={{ boxShadow: "none" }} 
                                        bg={'transparent'}
                                        h="50px"  id="postalCode" 
                                        fontSize="14px" required 
                                        borderColor="#B5B4B4"
                                        value={formData.postalCode}
                                        onChange={handleInputChange}
                                        name='postalCode'
                                    />
                                    <FormLabel 
                                        fontSize="12px" 
                                        fontWeight="normal"  
                                        htmlFor="postalCode"
                                        zIndex={'999'}
                                    >
                                        Postal Code
                                    </FormLabel>
                                    <InputRightElement h={'100%'}>
                                        <Alocation/>                                                     
                                    </InputRightElement>
                                </InputGroup>
                            </FormControl>
                            <FormControl>
                                <InputGroup>
                                    <Input 
                                        type="text" 
                                        focusBorderColor='#858383' 
                                        _focus={{ boxShadow: "none" }} 
                                        bg={'transparent'}
                                        h="50px"  id="country" 
                                        fontSize="14px" required 
                                        borderColor="#B5B4B4"
                                        value={formData.customerCountry}
                                        onChange={handleInputChange}
                                        name='customerCountry'
                                    />
                                    <FormLabel 
                                        fontSize="12px" 
                                        fontWeight="normal"  
                                        htmlFor="customerCountry"
                                        zIndex={'999'}
                                    >
                                        Country
                                    </FormLabel>
                                    <InputRightElement h={'100%'}>
                                        <Achevron />                                                     
                                    </InputRightElement>
                                </InputGroup>
                            </FormControl>
                        </HStack>
                        <Box>
                            <Button
                                // m="60px 35px"
                                // mt={'30px'}
                                bg="primary.100" 
                                color="#fff" 
                                p="20px 56px" 
                                h="48px" 
                                width="100%" 
                                _focus={{ boxShadow: "none" }} 
                                _hover={{bg: 'primary.100',
                                color: 'none',}}
                                type="submit"     

                            >
                                Save Changes
                            </Button>
                        </Box>
                    </Box>
                </Box>
            </Flex>
        </form>
        <Flex>
            <Box  w={'30%'}></Box>
            <Box w={'70%'} px={5}  >
                <Button
                    // m="60px 35px"
                    // mt={'30px'}
                    bg="transparent" 
                    color="primary.100" 
                    border="1px solid #101A36"
                    p="20px 56px" 
                    h="48px" 
                    width="100%" 
                    _focus={{ boxShadow: "none" }} 
                    // _hover={{bg: 'primary.100',
                    // color: 'none',}}
                    onClick={onOpen}
                >
                    Change Password
                </Button>
            </Box>
        </Flex>

        <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent>
                <ModalBody borderRadius="32px" pb={4} >                
                  
                    <Box py={4}>
                        <Text textAlign={'center'} color={'primary.800'} fontWeight={'bold'} fontSize={'lg'}  >
                            Change Password
                        </Text>
                        <Text textAlign={'center'} color={'font.100'} >
                            Reset your password.
                        </Text>
                    </Box>

                    <form style={{width: "80%", margin:"auto"}} onSubmit={handlePasswordSubmit}>
                        <FormControl>
                            <InputGroup>
                                <Input 
                                type="password" 
                                focusBorderColor='#858383' 
                                _focus={{ boxShadow: "none" }} 
                                bg={passwordData.newPassword ? 'primary.900' : 'transparent' }
                                h="50px" id="oldPassword"
                                fontSize="14px" required
                                borderColor="#B5B4B4"
                                value={passwordData.oldPassword}
                                onChange={handlePasswordChange }
                                name='oldPassword'
                                />
                                <FormLabel 
                                fontWeight="normal" 
                                fontSize="12px" 
                                htmlFor="oldPassword"
                                zIndex={'999'}
                                >
                                Old Password
                                </FormLabel>
                                <InputRightElement h={'100%'}>
                                { 
                                    passwordData.oldPassword ?  <Apassword /> : <Password/> 
                                }                        
                                </InputRightElement>

                            </InputGroup>
                        </FormControl>
                        <FormControl mt="10px">
                            <InputGroup>
                                <Input 
                                type="password" 
                                focusBorderColor='#858383' 
                                _focus={{ boxShadow: "none" }} 
                                bg={passwordData.newPassword ? 'primary.900' : 'transparent' }
                                h="50px" id="newPassword"
                                fontSize="14px" required
                                borderColor="#B5B4B4"
                                value={passwordData.newPassword}
                                onChange={handlePasswordChange }
                                name='newPassword'
                                />
                                <FormLabel 
                                fontWeight="normal" 
                                fontSize="12px" 
                                htmlFor="newPassword"
                                zIndex={'999'}
                                >
                                New Password
                                </FormLabel>
                                <InputRightElement h={'100%'}>
                                { 
                                    passwordData.newPassword ?  <Apassword /> : <Password/> 
                                }                        
                                </InputRightElement>

                            </InputGroup>
                        </FormControl>
                        <FormControl mt="10px">
                            <InputGroup>                  
                                <Input 
                                type="password" 
                                focusBorderColor='#858383' 
                                _focus={{ boxShadow: "none" }} 
                                bg={passwordData.confirmNewPassword ? 'primary.900' : 'transparent' }
                                h="50px" id="confirmNewPassword"
                                fontSize="14px" required
                                borderColor="#B5B4B4"
                                value={passwordData.confirmNewPassword}
                                onChange={handlePasswordChange }
                                name='confirmNewPassword'
                                />
                                <FormLabel 
                                fontWeight="normal" 
                                fontSize="12px" 
                                htmlFor="confirmNewPassword"
                                zIndex={'999'}
                                >
                                    Confirm New Password
                                </FormLabel>
                                <InputRightElement h={'100%'}>
                                { 
                                    passwordData.confirmNewPassword ?  <Apassword /> : <Password/> 
                                }                        
                                </InputRightElement>
                            </InputGroup>
                        </FormControl>
                        <Button
                        // m="60px 35px"
                        mt={'30px'}
                        bg="primary.100" 
                        color="#fff" 
                        p="20px 56px" 
                        h="48px" 
                        width="100%" 
                        _focus={{ boxShadow: "none" }} 
                        _hover={{bg: 'primary.100',
                        color: 'none',}}
                        type="submit"      

                        >
                            Change Password
                        </Button>
                    </form>


                    {/* </Flex> */}

                </ModalBody>         
            </ModalContent>
        </Modal>

        <Modal isOpen={isSecondModalOpen} onClose={onSecondModalClose}>
            <ModalOverlay />
            <ModalContent>
            <ModalBody>
                <Box textAlign={'center'} py={'20px'} >
                    <Icon  boxSize='50px'   viewBox="0 0 50 50" >
                        <CheckButton/>
                    </Icon> 
                    <Text color={'border.500'} fontSize={'base'} fontWeight={'700'} >
                        Profile Updated Succesfully
                    </Text>
                    <Text color={'font.200'} fontSize={'xs'} >
                        You will be redirected to the login page
                    </Text>
                </Box>

            
                <Button 
                    bg="primary.100" 
                    color="#fff" 
                    p="20px 56px" 
                    h="48px" 
                    width="100%" 
                    _focus={{ boxShadow: "none" }} 
                    _hover={{bg: 'primary.100',
                    color: 'none',}}
                    onClick={() => {
                        handleLogout();
                        navigate('/Login');
                        // onSecondModalClose();  
                    }}
                >
                    Proceed
                </Button>
               

                {/* <Button colorScheme='blue' mr={3} onClick={onOpen}>
                Close
                </Button> */}

            </ModalBody>

            
            </ModalContent>
        </Modal>

    </Box>
  )
}

export default DeliveryProfileKYC