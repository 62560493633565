import { Stack, HStack, Text, Box, Icon, Flex } from "@chakra-ui/react";
import { BasicInput } from "../../../shared/BasicInput";
import { StepInfo } from "../../../shared/StepInfo";
import PropTypes from "prop-types";
import { 
    Phone,  
    Home, 
    Location, 
    Chevron, 
    Terminal, 
    User, 
    Card,
    GreyCard,
    Password,
    Date
} from "../../../../Assets";


export function BasicDetailForm(props) {

    // const cc_format = (value) => {
    //     const v = value.replace(/[^0-9]/gi, "").substr(0, 16);
    
    //     const parts = [];
    //     for (let i = 0; i < v.length; i += 4) {
    //       parts.push(v.substr(i, 4));
    //     }
    //     return parts.length > 1 ? parts.join(" - ") : value;
    // };

    // const expriy_format = (value) => {
    //     const expdate = value;
    //     const expDateFormatter =
    //       expdate.replace(/\//g, "").substring(0, 2) +
    //       (expdate.length > 2 ? "/" : "") +
    //       expdate.replace(/\//g, "").substring(2, 4);
    
    //     return expDateFormatter;
    // };

    const split_text_data2 = [
        {
            id: 1,
            for: "FullName",
            type: "text",
            focus: { bg: 'primary.900' },
            text: "Business Name",
            value: props.FullName,
            icon: <User />
        },
        {
            id: 2,
            for: "PhoneNumber",
            type: "tel",
            focus: { bg: 'primary.900' },
            text: "Phone Number",
            value: props.PhoneNumber,
            icon: <Phone />
        },
    ]

    const text_data = [
        
        {
            id: 1,
            for: "HomeAddress",
            type: "text",
            focus: { bg: 'primary.900' },
            text: "Full Address",
            value: props.FullAddress,
            icon: <Home />
        },
    ]
    
    const split_text_data = [
        {
            id: 1,
            for: "LocalGovernment",
            type: "text",
            focus: { bg: 'primary.900' },
            text: "Local Government",
            value: props.LocalGovernment,
            icon: <Location />
        },
        {
            id: 2,
            for: "CustomerState",
            type: "text",
            focus: { bg: 'primary.900' },
            text: "State",
            value: props.State,
            icon: <Chevron />
        },
    ]

    const split_text_data1 = [
         {
            id: 1,
            for: "PostalCode",
            type: "text",
            focus: { bg: 'primary.900' },
            text: "Postal Code",
            value: props.PostalCode,
            icon: <Location />
        },
        {
            id: 2,
            for: "CustomerCountry",
            type: "text",
            focus: { bg: 'primary.900' },
            text: "Country",
            value: props.Country,
            icon: <Terminal />
        },
    ]

//     const split_text_data3 = [
//          {
//             id: 1,
//             for: "AccountName",
//             type: "text",
//             focus: { bg: 'primary.900' },
//             text: "Card Account Name",
//             value: props.AccountName,
//             icon: <User />
//         },
//         {
//             id: 2,
//             for: "CardNumber",
//             type: "text",
//             focus: { bg: 'primary.900' },
//             text: "Card Number",
//             value: cc_format(props.CardNumber),
//             icon: <GreyCard />
//         },
//     ]

//     const split_text_data4 = [
//         {
//            id: 1,
//            for: "ExpiryDate",
//            type: "text",
//            focus: { bg: 'primary.900' },
//            text: "Exp. Date",
//            value: expriy_format(props.ExpiryDate),
//            placeholder:"mm / yy",
//            icon: <Date />
//        },
//        {
//            id: 2,
//            for: "CVV",
//            type: "password",
//            maxLength:"3",
//            focus: { bg: 'primary.900' },
//            text: "cvv",
//            value: props.CVV,
//            icon: <Password />
//        },
//    ]

    return (
        <>
            <StepInfo currentStep={1} title={"Billing Address"} desc={"Cross check detail or change if you wish to edit."}/>
            <Stack
                spacing={8} 
                overflowY={`scroll`}
                maxH={props.height}
                scrollBehavior={`auto`}
                ps={10}
                pe={16}
                py={4}
            >
                <HStack spacing={5}>
                    {split_text_data2.map((data) => {
                        return <BasicInput key={data.id} type={data.type} focus={data.focus} for={data.for} text={data.text} value={data.value} updateData={props.updateData} icon={data.icon} />
                    })}
                </HStack>
                {text_data.map((data) => {
                    return <BasicInput key={data.id} type={data.type} focus={data.focus} for={data.for} text={data.text} value={data.value} updateData={props.updateData} icon={data.icon} updateOtp={props.updateOtp} />
                })}
                <HStack spacing={5}>
                    {split_text_data.map((data) => {
                        return <BasicInput key={data.id} type={data.type} focus={data.focus} for={data.for} text={data.text} value={data.value} updateData={props.updateData} icon={data.icon} />
                    })}
                </HStack>
                <HStack spacing={5}>
                    {split_text_data1.map((data) => {
                        return <BasicInput key={data.id} type={data.type} focus={data.focus} for={data.for} text={data.text} value={data.value} updateData={props.updateData} icon={data.icon} />
                    })}
                </HStack>
            
                <Stack>   
                    <Stack spacing={8}>
                        <HStack spacing={5}>
                            {/* {split_text_data3.map((data) => {
                                return <BasicInput key={data.id} type={data.type} focus={data.focus} for={data.for} text={data.text} value={data.value} updateData={props.updateData} icon={data.icon} />
                            })} */}
                        
                                <Text fontSize={'base'} fontWeight={'Bold'} >
                                    Account Name: 
                                </Text>
                                <Text fontSize={'base'} fontWeight={'Bold'} >
                                    {props.customerProfileData[0].FullName}
                                </Text>
                            
                        </HStack>
                        <HStack spacing={5}>
                            {/* {split_text_data4.map((data) => {
                                return <BasicInput key={data.id} type={data.type} focus={data.focus} for={data.for} text={data.text} value={data.value} updateData={props.updateData} icon={data.icon} maxLength={data.maxLength} placeholder={data.placeholder} />
                            })} */}
                            
                                <Text fontSize={'base'} fontWeight={'Bold'} >
                                    Wallet Balance:
                                </Text>
                                <Text fontSize={'base'} fontWeight={'Bold'}  >
                                    ₦{props.customerProfileData[0].CurrentBalance}
                                </Text>
                            
                        </HStack>
                        <HStack spacing={5}>
                            {/* {split_text_data4.map((data) => {
                                return <BasicInput key={data.id} type={data.type} focus={data.focus} for={data.for} text={data.text} value={data.value} updateData={props.updateData} icon={data.icon} maxLength={data.maxLength} placeholder={data.placeholder} />
                            })} */}
                            
                            
                            <Flex alignItems={'center'} border={'1px solid #049541'} bg={'font.300'}  w={'170px'}  height={'40px'} borderRadius={'lg'}  >
                                <Icon mt={'22px'} ml={'10px'} boxSize='40px' viewBox="0 0 50 50" >
                                    <Card/>
                                </Icon> 
                                <Text color={'primary.200'} mr={'10px'} fontSize={'base'} fontWeight={'bold'} > Top up Wallet </Text>
                            </Flex>                       
                                    
                        </HStack>
                    </Stack>
                </Stack>
            </Stack>

        </>
    );
}

BasicDetailForm.prototype = {
    height: PropTypes.string.isRequired,
    fullName: PropTypes.string.isRequired,
    phoneNumber: PropTypes.string.isRequired,
    emailAddress: PropTypes.string.isRequired,
    password: PropTypes.string.isRequired,
    fullAddress: PropTypes.string.isRequired,
    localGovernment: PropTypes.string.isRequired,
    state: PropTypes.string.isRequired,
    AccountName: PropTypes.string.isRequired,
    cardNumber: PropTypes.string.isRequired,
    expiryDate: PropTypes.string.isRequired,
    cvv: PropTypes.string.isRequired,
    updateData: PropTypes.func,
}
