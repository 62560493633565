import { Box, Button, ButtonGroup, Flex, Grid, GridItem, HStack, Icon, Modal, ModalBody, ModalContent, ModalHeader, ModalOverlay, Stack, Text, Textarea, useDisclosure } from "@chakra-ui/react"
import { CheckButton, EmeryAva } from "../../../../Assets";
import AuthContext from "../../../../Components/Admin/Contexts/AdminContext";
import { useContext, useEffect, useState } from "react";
import { useQueryClient } from "@tanstack/react-query";


const Request = () => {

  const queryClient = useQueryClient();
    // const { isOpen, onOpen, onClose } = useDisclosure();
    const { isOpen: isSecondModalOpen, onOpen: onSecondModalOpen, onClose: onSecondModalClose } = useDisclosure();
    const { isOpen: isThirdModalOpen, onOpen: onThirdModalOpen, onClose: onThirdModalClose } = useDisclosure();
    const { vendorRequestsData,isLoadingVendorRequests,isErrorVendorRequests,handleSubmitRequestId,handleRejectRequestId,} = useContext(AuthContext);
    const [selectedUserId, setSelectedUserId] = useState(null);
    const [rejectReason, setRejectReason] = useState('');

    //   useEffect(() => {
    //     console.log('select',selectedUserId)

    // }, [selectedUserId])


    const rejectData = {
        orderId : selectedUserId,
        reason: rejectReason
    };

    // const TruncatedText = ({ text, limit }) => {
    //   const truncatedText = text.length > limit ? `${text.substring(0, limit)}...` : text;
    
    //   return <Text>{truncatedText}</Text>;
    // };

    if (isLoadingVendorRequests) {
      return <div>Loading....</div>
    }
  
    if (isErrorVendorRequests) { 
      return <div>Error Fetching Data</div>
    }


    function formatPrice(price) {
      if (typeof price === 'string') {
        // Convert the string to a number before formatting
        price = parseFloat(price);
      }
    
      if (!isNaN(price)) {
        const formattedPrice = price.toLocaleString('en-US'); // Format as a number with commas
        return `₦${formattedPrice}`; // Add Naira symbol in front of the formatted price
      }
      return price;
    }

  return (
    <Box>
      <Grid templateColumns='repeat(3, 1fr)' gap={3} gridRowGap={5} m='20px' >
        {
          vendorRequestsData.map((data) => (
            <GridItem key={data.OrderID} p={5} w='100%' h='23rem' bg='#fff' borderRadius={'lg'} border={'1px solid #CECDCD'} position={'relative'} >
              <HStack  >
                <Icon boxSize='25px' viewBox="0 0 50 50" >
                    <EmeryAva/>
                </Icon> 
                <Text color={'primary.300'} fontWeight={'semibold'} fontSize={'lg'}  >
                    {data.FullName}
                </Text>
              </HStack>
              <Box mb={'20px'} py={5}  >
                <Text color={'font.100'} fontSize={'sm'} pb={2} >
                  Order Information
                </Text>
                
                <Flex alignItems={'center'} justifyContent={'space-between'}>
                  <Stack>
                    <Text color={'font.600'} fontSize={'xs'} fontWeight={'semibold'}>Date of Order:</Text>
                    <Text color={'primary.300'} fontSize={'sm'} > {data.OrderDate} </Text>
                  </Stack>
                  {/* <Stack>
                    <Text color={'font.600'} fontSize={'xs'} fontWeight={'semibold'} >Quantity:</Text>
                    <Text color={'primary.300'} fontSize={'sm'} >{data.Quantity} </Text>
                  </Stack> */}
                </Flex>            
                
                <Box pt={2}>
                  <Text color={'font.600'} fontSize={'xs'} fontWeight={'semibold'} >Destination:</Text>
                  <Text 
                    color={'primary.300'} 
                    fontSize={'sm'}  
                    wordBreak="break-word"  
                  >
                    {/* <TruncatedText text={data.ProductDescription} limit={200} /> */}
                    {data.Destination}
                  </Text>                
                </Box>
                <Flex alignItems={'center'} justifyContent={'space-between'}>
                  <Stack>
                    <Text color={'font.600'} fontSize={'xs'} fontWeight={'semibold'} >Quantity:</Text>
                    <Text color={'primary.300'} fontSize={'sm'} >{data.Quantity} </Text>
                  </Stack>
                  <Stack>
                    <Text color={'font.600'} fontSize={'xs'} fontWeight={'semibold'}>LPG Transporter:</Text>
                    <Text color={'primary.300'} fontSize={'sm'} > { data.VehicleID === 1 ? data.LPGTransporter : 'Private Transporter'  } </Text>
                  </Stack>
                </Flex>   
              
                <Stack >
                  <Text color={'font.600'} fontSize={'xs'} >Total:</Text>
                  <Text color={'primary.100'} fontSize={'xl'} fontWeight={'semibold'} > {formatPrice(data.TotalAmount)} </Text>
                </Stack>               

              </Box>
            
              <ButtonGroup position={"absolute"} bottom={'10px'}  gap='1'  mt={'30px'} >
                <Button 
                  
                  bg="transparent" 
                  color="secondary.300" 
                  p="20px 26px" 
                  h="48px" 
                  width="50%" 
                  _focus={{ boxShadow: "none" }} 
                  _hover={{bg: 'none',
                  color: 'none',}}
                  onClick={() => {
                    // console.log('pro', data)
                    setSelectedUserId(data.OrderID);// Set the selected user ID here
                    onThirdModalOpen(); // Open the rejection modal
                  }}                
                >
                  Decline
                </Button>
                <Button 
                  bg="primary.100" 
                  color="#fff" 
                  p="20px 56px" 
                  h="48px" 
                  width="60%" 
                  _focus={{ boxShadow: "none" }} 
                  _hover={{bg: 'primary.100',
                  color: 'none',}}
                  onClick={() => {
                    // console.log('pro', data)
                    setSelectedUserId(data.OrderID); // Set the selected user ID here
                    onSecondModalOpen(); 
                  }}
                >
                  Accept
                </Button>
              </ButtonGroup>
            </GridItem>
          ))
        }
        
      </Grid>

        {/* Validated MODAL */}

      <Modal isOpen={isSecondModalOpen} onClose={onSecondModalClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalBody>
            <Box textAlign={'center'} py={'20px'} >
              <Icon  boxSize='50px'   viewBox="0 0 50 50" >
                <CheckButton/>
              </Icon> 
              <Text color={'border.500'} fontSize={'base'} fontWeight={'700'} >
                Accept Product
              </Text>
              <Text color={'font.200'} fontSize={'xs'} >
                Are you sure you want to approve this product?
              </Text>
            </Box>

            <ButtonGroup gap='10' my={'10px'} >
              <Button 
                
                bg="transparent" 
                color="font.100" 
                p="20px 36px" 
                h="48px" 
                width="50%" 
                _focus={{ boxShadow: "none" }} 
                _hover={{bg: 'none',
                color: 'none',}}
                onClick={onSecondModalClose}
              >
                Cancel
              </Button>
              <Button 
                bg="primary.100" 
                color="#fff" 
                p="20px 56px" 
                h="48px" 
                width="80%" 
                _focus={{ boxShadow: "none" }} 
                _hover={{bg: 'primary.100',
                color: 'none',}}
                onClick={() => {
                  // console.log('Product',selectedUserId);
                  // handleSubmitRequestId(selectedUserId);
                  handleSubmitRequestId(selectedUserId).then(() => {
                    queryClient.invalidateQueries('vendorRequests');
                  });
                  onSecondModalClose();                 
                }}
              >
                Proceed
              </Button>
            </ButtonGroup>

            {/* <Button colorScheme='blue' mr={3} onClick={onOpen}>
              Close
            </Button> */}

          </ModalBody>

         
        </ModalContent>
      </Modal>

      {/* REJECTED MODAL */}

      <Modal isOpen={isThirdModalOpen} onClose={onThirdModalClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <Box textAlign={'center'} pt={'20px'} >             
              <Text color={'border.500'} fontSize={'xl'} fontWeight={'700'} >
                Reject Product
              </Text>
              <Text color={'font.200'} fontSize={'xs'} fontWeight={'normal'} >
                Tell us why you are rejecting this product.
              </Text>
            </Box>
          </ModalHeader>
          <ModalBody>
          <Textarea 
            placeholder='Reason' 
            border={'1px solid #B5B4B4'} 
            h={'10rem'} 
            onChange={(e) => setRejectReason(e.target.value)}
          />
                      
            <Button 
              my={'20px'}
              bg="primary.100" 
              color="#fff" 
              p="20px 56px" 
              h="48px" 
              width="100%" 
              _focus={{ boxShadow: "none" }} 
              _hover={{bg: 'primary.100',
              color: 'none',}}
              onClick={() =>{ 
                // handleRejectRequestId(rejectData);
                handleRejectRequestId(rejectData).then(() => {
                  queryClient.invalidateQueries('vendorRequests');
                });
                onThirdModalClose();
                
              }}
            >
              Reject Product
            </Button>  
           

          </ModalBody>         
        </ModalContent>
      </Modal>

    </Box>
  )
}

export default Request