import { useState } from 'react';
import { 
    Box, 
    Button, 
    FormControl, 
    FormLabel, 
    Input, 
    InputGroup, 
    InputRightElement,
    useToast, 
    
} from '@chakra-ui/react'
import { 
    Aemail, 
    Aphone, 
    Auser, 
    Email, 
    Phone, 
    User 
} from '../../../../Assets';
import { decrypt, encrypt } from '../../../../Functions/crypt';
import axios from 'axios';
import { useContext } from 'react';
import AuthContext from '../../Contexts/AdminContext';



const AdminOnoardingForm = () => {
    
    const toast = useToast();


    const initialFormData = {
        FullName: '',
        EmailAddress: '',
        PhoneNumber: '',
    };


    const [formData, setFormData] = useState(initialFormData);
    // const {refetch} = useContext(AuthContext)
    

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData((data) => {
           return {...data, [name]: value}
        })
        // console.log(formData)
    };

 

    const handleFormSubmit = (e) => {
        e.preventDefault();     
        const encryptedData = encrypt(formData);
        const encodedString = encodeURIComponent(encryptedData)
        if (encryptedData) {
            axios.post(`https://apiliveservice.myeasygas.com/api/Onboarding/admin?request=${encodedString}`)
            .then(function (response) {
                console.log(response.data); 
                const decryptedData = decrypt(response.data);
                const decodedString = decodeURIComponent(decryptedData);
                console.log('Decrypted Data:', decodedString);

                toast({
                    title: 'Success!',
                    description: decodedString,
                    status: 'success',
                    duration: 9000,
                    isClosable: true,
                    position: 'top-center'
                });
              })
              .catch(function (error) {
                console.log(error);
                console.log(error.response); 
                const decryptedError = decrypt(error.response.data);
                const decodedString = decodeURIComponent(decryptedError);
                console.log('Decrypted Error:', decodedString);

                toast({
                    title: 'Error!',
                    description: decodedString,
                    status: 'error',
                    duration: 9000,
                    isClosable: true,
                    position:'top-center'
                });
              });
        }
        console.log('Form Data:', encryptedData);
        setFormData(initialFormData);
        // if (refetch) {
        //     console.log(refetch)
        //     refetch(); // Call refetch function when the button is clicked
        //     console.log('Second', refetch)
        // }
    }

  return (
    <Box>
         <form style={{width: "100%", margin:" 0 auto"}} onSubmit={handleFormSubmit} >
            <FormControl>
                <InputGroup>
                    <Input 
                        type="text" 
                        focusBorderColor='#858383' 
                        _focus={{ boxShadow: "none" }} 
                        bg={formData.FullName ? 'primary.900' : 'transparent' }
                        h="50px"  id="fullname" 
                        fontSize="14px" required 
                        borderColor="#B5B4B4"
                        value={formData.FullName}
                        onChange={handleInputChange}
                        name='FullName'
                    />
                    <FormLabel 
                        fontSize="12px" 
                        fontWeight="normal"  
                        htmlFor="fullname"
                        zIndex={'999'}
                    >
                        Full Name
                    </FormLabel>
                    <InputRightElement h={'100%'}>
                        { 
                            formData.FullName ?  <Auser /> : <User/> 
                        }
                    </InputRightElement>
                </InputGroup>
            </FormControl>
            <FormControl mt="10px">
                <InputGroup>
                    <Input 
                        type="email" 
                        focusBorderColor='#858383' 
                        _focus={{ boxShadow: "none" }} 
                        bg={formData.EmailAddress ? 'primary.900' : 'transparent' }
                        h="50px" id="email"
                        fontSize="14px" required
                        borderColor="#B5B4B4"
                        value={formData.EmailAddress}
                        onChange={handleInputChange}
                        name='EmailAddress'
                    />
                    <FormLabel 
                        fontWeight="normal" 
                        fontSize="12px" 
                        htmlFor="email"
                        zIndex={'999'}
                    >
                        Email Address
                    </FormLabel>
                    <InputRightElement h={'100%'}>
                        { 
                            formData.EmailAddress ?  <Aemail /> : <Email/> 
                        }                        
                    </InputRightElement>
                
                </InputGroup>
            </FormControl>
            <FormControl mt="10px">
                <InputGroup>
                    <Input 
                        type="tel"  
                        pattern="[0-9]{10,11}" 
                        focusBorderColor='#858383' 
                        _focus={{ boxShadow: "none" }}
                        bg={formData.PhoneNumber ? 'primary.900' : 'transparent' }
                        h="50px" id="number"
                        fontSize="14px" required
                        borderColor="#B5B4B4"
                        value={formData.PhoneNumber}
                        onChange={handleInputChange}
                        name='PhoneNumber'
                    />
                    <FormLabel 
                        fontWeight="normal" 
                        fontSize="12px" 
                        htmlFor="number"
                        zIndex={'999'}
                    >
                        Phone Number
                    </FormLabel>
                    <InputRightElement h={'100%'}>
                        { 
                            formData.PhoneNumber ?  <Aphone /> : <Phone/> 
                        } 
                    </InputRightElement>
                
                </InputGroup>
            </FormControl>
            <Button
                m="60px 0px"
                bg="primary.100" 
                color="#fff" 
                p="20px 56px" 
                h="48px" 
                width="100%" 
                _focus={{ boxShadow: "none" }} 
                _hover={{bg: 'primary.100',
                color: 'none',}}
                type='submit'

            >
                Add Admin
            </Button>
        </form>
    </Box>
  )
}

export default AdminOnoardingForm;