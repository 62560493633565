import { Box, Grid, GridItem} from "@chakra-ui/react";
import AdminOverviewCard from "./AdminOverviewCard";
import AdminOverviewLHS from "./AdminOverviewLHS";
import AdminOverviewRHS from "./AdminOverviewRHS";








const AdminOverviewLayout = () => {

  const gridItems = [
    {
      area: "overviewCard",
      content: <AdminOverviewCard  />
    }, 
    {
      area: "lhs",
      content:  <AdminOverviewLHS/>
    },
    {
      area: "rhs",
      content: <AdminOverviewRHS/>
    },
    
  ]

  return (
    <Box p={'5'} bg='primary.600' height='92vh' >
      <Grid
        templateAreas={`"overviewCard overviewCard overviewCard "
                        "lhs rhs rhs"`}
        gridTemplateRows={'1fr 4fr '}
        gridTemplateColumns={'1fr 1fr 100px'}
        h='100%'
        gap='5'
      >
          
        {gridItems.map((data, i) => {
          return <GridItem key={i}    area={data.area} overflowY={`auto`}>
              {data.content}
          </GridItem>
        })}
      </Grid>
    </Box>
  )
}

export default AdminOverviewLayout;