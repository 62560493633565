import React from 'react'
import PropTypes from 'prop-types'
import { Text, Flex, CircularProgress, CircularProgressLabel, Box, Icon } from '@chakra-ui/react'
import { Mark } from '../../Assets'

const Loading = props => {
    return (
        <Box
            position="fixed"
            top={0}
            left={0}
            width="100%"
            height="100%"
            display="flex"
            justifyContent="center"
            alignItems="center"
            background=" #0909093f"
            back
            zIndex="999"
        >
            <Flex
                flexDir="column"
                width="200px"
                height="200px"
                borderRadius="32px"
                background="primary.100"
                justifyContent="center"
                alignItems="center"
                textAlign="center"
            >
                <CircularProgress
                    isIndeterminate
                    // value={100}
                    size='120px'
                    thickness='8px'
                    capIsRound
                    color={props.color}
                    trackColor='#E8EEFF10'
                >
                    {props.mark && (<CircularProgressLabel>
                        <Icon
                            boxSize={'40px'}
                            viewBox="0 0 62 62"
                        >
                            <Mark />
                        </Icon>
                    </CircularProgressLabel>)}
                </CircularProgress>
                <Text mt='15px' color='#fff' fontWeight='medium'>{props.text}</Text>
            </Flex>

        </Box>
    )
}

Loading.propTypes = {
    text: PropTypes.string.isRequired,
    color: PropTypes.string.isRequired,
}

export default Loading