import { Box, Flex, Icon, HStack, useToast, useMediaQuery } from "@chakra-ui/react"
import { Logo, Info } from "../../../Assets"
import { LoadingLine } from "../../shared/LoadingLine"
import ArrowButton from "../../shared/Button"
import useMultistepForm from "../Hooks/useMultistepForm" 
import { useState } from "react"
import { BasicDetailForm } from "./Forms/BasicDetail"
import { DocUpload } from "./Forms/DocUpload"
import { OnBoardingImages } from "../../shared/OnBoardingImages"
import OtpVerify from "./Forms/OtpVerify"
import { useNavigate } from "react-router-dom"
import Loading from "../../shared/Loading"
import { encrypt, decrypt } from "../../../Functions/crypt"
import axios from "axios"
import ReviewModal from "../../shared/ReviewModal"

const INITIAL_DATA = {
    EmailAddress: "", // String
    Password: "", // String
    ConfirmPassword: "", //String
    // FullName: 'null',
    // PhoneNumber: "1232",
    // HomeAddress: "null",
    // LocalGovernment: "null",
    // CustomerState: "null",
    // StorageCapacity: "11",
    // ClosestLandmark: "null",
    // CertificateOfIncorporation: "null",
    // OperatingLicence: "null",
};

const otpData = {
    EmailAddress: "",
    otp: ""
}

export const CustomerFormPage = () => {

    const [isSm] = useMediaQuery(`(max-width: 992px)`);
    // const [isXs] = useMediaQuery(`(max-width: 760px)`);
    const direction = isSm ? "column" : "row";

    const [data, setData] = useState(INITIAL_DATA)
    const [otp, setOtp] = useState(otpData)
    // const [fileValidation, setFileValidation] = useState({
    //     CertificateOfIncorporation: false,
    //     OperatingLicence: false,
    // })
    // const [uploadError, setUploadError] = useState(false)
    // const [files, setFiles] = useState({
    //     CertificateOfIncorporation: '',
    //     OperatingLicence: '',
    // })
    // const [progress, setProgress] = useState({
    //     CertificateOfIncorporation: 0,
    //     OperatingLicence: 0,
    // })
    // const [fileName, setFileName] = useState({
    //     CertificateOfIncorporation: '',
    //     OperatingLicence: '',
    // })
    // const [fileSize, setFileSize] = useState({
    //     CertificateOfIncorporation: '',
    //     OperatingLicence: '',
    // })
    const height = '4px'
    const buttonVH = '10vh'
    const formHeight = `calc(100% - ${buttonVH} )`
    const navigate = useNavigate()
    const toast = useToast()
    
    const updateData = (fields) => {
        setData(p => {
            return {...p, ...fields}
        })
    }
    const [roll, setRoll] = useState(false)

    const updateOtp = (fields) => {
        setOtp(p => {
            return {...p, ...fields}
        })
    } 

    const [loading, setLoading] = useState(false)
    
    const { step, steps, currentStep, next, prev, lastStep, firstStep, otpStep } = useMultistepForm([
        <BasicDetailForm height={formHeight} {...data} updateData={updateData} updateOtp={updateOtp} />,
        // <DocUpload height={formHeight} {...data} updateData={updateData} setFileValidation={setFileValidation} fileValidation={fileValidation} uploadError={uploadError} files={files} setFiles={setFiles} progress={progress} setProgress={setProgress} fileName={fileName} setFileName={setFileName} fileSize={fileSize} setFileSize={setFileSize} />,
        <OtpVerify {...otp} {...data} updateOtp={updateOtp}/>
    ])

    const isDataComplete = () => {
        const requiredFields = [
            // "FullName",
            // "PhoneNumber",
            "EmailAddress",
            "Password",
            "ConfirmPassword",
            // "HomeAddress",
            // "LocalGovernment",
            // "CustomerState",
            // "ClosestLandmark",
            // "StorageCapacity",
            // "CertificateOfIncorporation",
            // "OperatingLicence",
            // "termsAndConditions"
        ];

        // Check if all required fields are not empty
        return requiredFields.every((field) => data[field]);
    };

    const isFirstStageComplete = () => {
        const requiredFields = [
            // "FullName",
            // "PhoneNumber",
            "EmailAddress",
            "Password",
            "ConfirmPassword",
            // "HomeAddress",
            // "LocalGovernment",
            // "CustomerState",
            // "ClosestLandmark",
            // "StorageCapacity",
        ];

        // Check if all required fields are not empty
        return requiredFields.every((field) => data[field]);
    };
    
    // const isSecondStageComplete = () => {
    //     const requiredFields = [
    //         "coperateAccountNumber",
    //         "companyRegistrationNumber",
    //         "taxIdentificationNumber",
    //     ];

    //     // Check if all required fields are not empty
    //     return requiredFields.every((field) => data[field]);
    // };

    const [showReviewModal, setShowReviewModal] = useState(false)

    const onSubmit = async (e) => {
        e.preventDefault();
        // if (otpStep) {
        //     // alert("they feel you nigga")
        //     // setRoll(true)
        // }
        // const isFileInputsFilled = Object.values(fileValidation).every(Boolean);
        if (otpStep) {
            setLoading(true)
            const encryptedData = encrypt(otp)
            console.log(encryptedData)
            const encodedString = encodeURIComponent(encryptedData)
            if (encryptedData) {
                try {
                    await axios.patch(`https://apiliveservice.myeasygas.com/api/Onboarding/verifyotp?request=${encodedString}`)
                        .then((res) => {
                            setLoading(false)
                            toast({ 
                                position: "top",
                                icon: <Info />,
                                duration: 10000,
                                // description: decrypt(res.data),
                                description:'Customer Signup Successful',
                                status: 'success'
                            })
                            setShowReviewModal(true)
                        }).catch((err) => {
                            setLoading(false)
                            return toast({
                                position: "top",
                                icon: <Info />,
                                duration: 10000,
                                description: decrypt(err.response.data),
                                status: 'warning'
                            })
                        })
                } catch (err) {
                    console.log(err)  
                }
            }
        }
        if (!lastStep) return next()
        if (lastStep) {
            setLoading(true)
            delete data.termsAndConditions;
            const encryptedData = encrypt(data)
            const encodedString = encodeURIComponent(encryptedData)
            // console.log(data)
            // console.log(encryptedData)
            if (encryptedData) { 
                try {
                    await axios.post(`https://apiliveservice.myeasygas.com/api/Onboarding/customer?request=${encodedString}`)
                        .then(function (res) {
                            next()
                            setLoading(false)
                            toast({
                                position: "top",
                                icon: <Info />,
                                duration: 10000,
                                description: 'OTP has been sent to email',
                                // description: decrypt(res.data),
                                status: 'success'
                            })
                        })
                        .catch(function (err) {
                            setLoading(false)
                            return toast({
                                position: "top",
                                icon: <Info />,
                                duration: 10000,
                                description: decrypt(err.response.data),
                                status: 'warning'
                            })
                        });
                } catch (error) {
                    console.log("Error during onboarding:", error);
                    setLoading(false)
                    return toast({
                        position: "top",
                        icon: <Info />,
                        duration: 10000,
                        description: decrypt(error.response.data),
                        status: 'warning'
                    })
                }
            }
        }  else {
            // setUploadError(true)
            setLoading(false)
            return toast({
                position: "top",
                icon: <Info />,
                duration: 10000,
                description: 'you haven\'t uploaded all the files',
                status: 'warning'
            })
        }
    }

    const handleClick = () => {
        if (prev) {
            prev()
        }
        if (firstStep) {
            navigate('/user_type')
        }
    };

    return (
        <HStack spacing={0} flexDir={direction}     >
            <Box width={ isSm ? `100%` : `50%`} order={isSm ?  2 : null } >
                <form onSubmit={onSubmit}>
                    <Flex
                        h={`15vh`}
                        ps={10}
                        pe={16}
                        alignItems={`end`}
                    >
                        <Icon
                            boxSize={'60px'}
                            viewBox="0 0 100 100"
                        >
                            <Logo />
                        </Icon>
                    </Flex>
                    <Box
                        h={`calc(75vh - 4px)`}
                        pt={5}
                        pb={10}
                        style={{scrollbarWidth: 'none'}}
                    >
                        {step}
                    </Box>
                    <LoadingLine step={currentStep + 3} height={height}/>
                    <Flex
                        h={`10vh`}
                        ps={10}
                        pe={16}
                        justifyContent="space-between"
                        alignItems="center"
                    >
                        <ArrowButton
                            size="xs"
                            text="Back"
                            isIcon={false}
                            variant={'outline'}
                            move={handleClick}
                        />
                        <ArrowButton
                            size={lastStep || otpStep ? "sm_x" : "sm"}
                            text={otpStep ? "Verify Account": "Next"}
                            isIcon={false}
                            type={`submit`}
                            variant={!lastStep && (!isFirstStageComplete()) ? `inactive` : 'active'}
                            disabled={lastStep ? !isDataComplete() : false}
                        />
                    </Flex>
                </form>
            </Box>
            <Box w={ isSm ? `100%` : `50%`} h={`100vh`}>
                <OnBoardingImages currentStep={currentStep} for={`customer`} />
            </Box>
            {roll && (<Loading
                text={`Verifying Account`}
                color={`primary.200`}
                mark={false}
            />)}
            {showReviewModal && <ReviewModal setShowReviewModal={setShowReviewModal} />}
            {loading && (
                <Loading
                    text={`Processing...`}
                    color={`secondary.200`}
                    mark={false}
                />
            )}
        </HStack >
    )
}
