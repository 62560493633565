import { Box, Flex, Icon, HStack, useToast, Modal, ModalOverlay, ModalContent, ModalHeader, Text, ModalBody, Button, useDisclosure, PinInput,PinInputField, Image } from "@chakra-ui/react"
import { Logo, Info,  Review } from "../../../../Assets"
import { LoadingLine } from "../../../shared/LoadingLine"
import ArrowButton from "../../../shared/Button"
import useMultistepForm from "../../../Signup/Hooks/useMultistepForm";
import { useContext, useEffect, useState } from "react"
import { BasicDetailForm } from "../Forms/BasicDetail";
// import { DocUpload } from "./Forms/DocUpload"
import { OnBoardingImages } from "../../../shared/OnBoardingImages"
// import OtpVerify from "./Forms/OtpVerify"
import { useNavigate } from "react-router-dom"
import Loading from "../../../shared/Loading"
import { encrypt, decrypt } from "../../../../Functions/crypt"
import axios from "axios"
import AuthContext from "../../../../Components/Admin/Contexts/AdminContext";
import { DeliveryDetailForm } from "../Forms/DeliveryDetails";




const CustomerCheckout = () => {

    const { customerProfileData, isLoadingCustomerProfileData, isErrorCustomerProfileData, checkoutData, setCheckoutData, handlePurchaseProduct, salesRepsProfileData, isLoadingSalesRepsProfileData,isErrorSalesRepsProfileData } = useContext(AuthContext);
    const { isOpen, onOpen, onClose } = useDisclosure()
    const [isButtonActive, setIsButtonActive] = useState(true)
    const [selectedOptionId, setSelectedOptionId] = useState(null);
    const [showTopUpModal, setShowTopUpModal] = useState(false);


    const salesRepID = salesRepsProfileData && salesRepsProfileData.salesRepID;
    
    const INITIAL_DATA = {
        ProductID: checkoutData.productId,
        Quantity: checkoutData.quantity,
        FullName: "", // String
        PhoneNumber: "", // String
        FullAddress: "", // String
        LocalGovernment: "", // String
        State: "", // String
        Country: "", // String
        AccountName: "AJ", // String
        CardNumber: "111", // String
        ExpiryDate: "11", // String
        CVV: "111", // String
        PostalCode: "", //String
        DeliveryType: 1,
        TotalAmount: checkoutData.totalAmount,
        PrivateDelivery: [
            {
                RegistrationNumber: "",
                VehicleCapacity: "",
                PhoneNumber: "",
                EmailAddress: "",
                salesRepID:selectedOptionId !== null ? selectedOptionId : "" 
            }
        ]
    };

    const otpData = {
        EmailAddress: "",
        otp: ""
    }

    const [data, setData] = useState(INITIAL_DATA);
    
    const [otps, setOtp] = useState(otpData);

    const [showReviewModal, setShowReviewModal] = useState(false);

    const [loading, setLoading] = useState(false);


    // const [fileValidation, setFileValidation] = useState({
    //     CertificateOfIncorporation: false,
    //     OperatingLicence: false,
    // })

   

    useEffect(() => {
     
        !isLoadingCustomerProfileData &&
        setData(prevState => ({
        ...prevState,
        // customerId: customerId,
        FullName: customerProfileData[0].FullName,
        PhoneNumber: customerProfileData[0].PhoneNumber,
        FullAddress: customerProfileData[0].HomeAddress,
        LocalGovernment: customerProfileData[0].LocalGovernment,
        State: customerProfileData[0].CustomerState,
        Country: customerProfileData[0].CustomerCountry,
        PostalCode: customerProfileData[0].PostalCode
          
      }));
      // }
      // console.log(customerProfileData[0].FullName);
    //   console.log(customerProfileData);
  // console.log(formData);
  }, [customerProfileData, isLoadingCustomerProfileData, isErrorCustomerProfileData]);

    const verify = async (otp) => {
        const encryptedData = encrypt(otps)
        console.log(otps, otpData)
        const encodedString = encodeURIComponent(encryptedData)
        console.log(encryptedData)
        console.log(encodedString)
        if (encryptedData) {
            try {
                const res = await axios.patch(`https://apiliveservice.myeasygas.com/api/Onboarding/verifyotp?request=${encodedString}`)
                    .then((res) => {
                        console.log(res)
                       
                        if (res.status === 200) {
                            toast({
                                position: "top",
                                icon: <Info />,
                                duration: 10000,
                                description: "Account Verified successfully",
                                status: 'success'
                            })
                            setShowReviewModal(true)
                            setTimeout(() => {
                                onClose();
                            }, 1000); 

                        }
                    })
            } catch(err) {
                console.log(err)
            }
        }
    }
  
    const [uploadError, setUploadError] = useState(false)
    // const [files, setFiles] = useState({
    //     CertificateOfIncorporation: '',
    //     OperatingLicence: '',
    // })
    // const [progress, setProgress] = useState({
    //     CertificateOfIncorporation: 0,
    //     OperatingLicence: 0,
    // })
    const height = '4px'
    const buttonVH = '10vh'
    const formHeight = `calc(100% - ${buttonVH} )`
    const navigate = useNavigate()
    const toast = useToast()
    
    const updateData = (fields) => {
        setData(p => {
            return {...p, ...fields}
        })
    }
    const [roll, setRoll] = useState(false)

    const updateOtp = (fields) => {
        setOtp(p => {
            return {...p, ...fields}
        })
    }

    const handleOptionSelect = (salesRepID) => {
        console.log(salesRepID)
        setSelectedOptionId(salesRepID);
    };

    
    const { step, steps, currentStep, next, prev, lastStep, firstStep, otpStep } = useMultistepForm([
        <BasicDetailForm height={formHeight} {...data} updateData={updateData} customerProfileData={customerProfileData} isLoadingCustomerProfileData ={isLoadingCustomerProfileData} isErrorCustomerProfileData={isErrorCustomerProfileData}  />,
        <DeliveryDetailForm height={formHeight} {...data} data={data} updateData={updateData} handleOptionSelect={handleOptionSelect} selectedOptionId={selectedOptionId} salesRepsProfileData = {salesRepsProfileData} isLoadingSalesRepsProfileData = {isLoadingSalesRepsProfileData} isErrorSalesRepsProfileData = {isErrorSalesRepsProfileData}   />,
    ])

    

    const isDataComplete = () => {
        const requiredFields = [
            "FullName",
            "PhoneNumber",
            "FullAddress",
            "LocalGovernment",
            "State",
            "Country",
            "PostalCode",

        ];

        // Check if all required fields are not empty
        return requiredFields.every((field) => data[field]);

        
    };

    const isFirstStageComplete = () => {
        const requiredFields = [
          "FullName",
          "PhoneNumber",
          "FullAddress",
          "LocalGovernment",
          "State",
          "Country",
          "PostalCode",
          "CardNumber",
          "CardAccountName",
          "ExpiryDate",
          "CVV",
        ];
        
        // if (customerProfileData && customerProfileData[0].CurrentBalance === 0) {
        //     // return; // Prevent form submission if balance is zero
        //     console.log('I am working damn!!!!!!!')

        // }

        // Check if all required fields are not empty
        return requiredFields.every((field) => data[field]);

        
    };
    
    const isSecondStageComplete = () => { 
      const requiredFields = [
        "PhoneNumber",
        "EmailAddress",
        "VehicleCapacity",
        "RegistrationNumber",
        "SalesRepID",
        "termsAndConditions"

      ];

      // Check if all required fields are not empty
      return requiredFields.every((field) => data[field]);

      
    };

    // useEffect(() => {
    //     if (customerProfileData && customerProfileData[0].CurrentBalance === 0) {
    //         // onOpen(); // Show the modal popup

    //         console.log('I am working damn!!!!!!!')
    //     }
    // }, [customerProfileData]);

    useEffect(() => {
        // Update salesRepID in INITIAL_DATA whenever selectedOptionId changes
        setData(prevState => ({
            ...prevState,
            PrivateDelivery: prevState.PrivateDelivery.map(vehicle => ({
                ...vehicle,
                salesRepID: selectedOptionId // Update salesRepID with selectedOptionId
            }))
        }));
    }, [selectedOptionId]);

    const handleNext = () => {
        if (currentStep === 0 && customerProfileData && customerProfileData[0].CurrentBalance === 0) {
            // Open the modal to inform the user
            setShowTopUpModal(true);
        } else {
            // Proceed to the next step if the modal is not shown
            if (!showTopUpModal) {
                next();
            }
        }
    };

    const handleTopUp = () => {
        // Implement your logic here to handle topping up the balance
        // You can navigate to the top-up page or perform any other action
        // For example:
        // navigate('/top-up');
        console.log('Ghetto Love man')
    };



  if (isLoadingCustomerProfileData) {
      return <div>Loading....</div>
  }

  if (isErrorCustomerProfileData) {
      return <div>Error Fetching Data</div>
  }

    const onSubmit = async (e) => {
        e.preventDefault();
        // console.log(data)
        // console.log(data.PrivateDelivery)
     
        const totalVehicleCapacity = data.PrivateDelivery.reduce((acc, curr) => acc + parseFloat(curr.VehicleCapacity), 0);
        const isVehicleCapacityValid = totalVehicleCapacity >= checkoutData.quantity;
        if (!isVehicleCapacityValid && data.DeliveryType === 2) {
           return  toast({
                title: 'Oops!',
                description: "The goods you purchased cant fit into your truck(s)",
                status: 'error',
                duration: 4000,
                isClosable: true,
                position: 'top-center'
                });
        }
        if (otpStep) {
            setRoll(true)
            // setLoading(true)
            delete data.termsAndConditions;
            const encryptedCredentials = encrypt(data);
            console.log(encryptedCredentials);
            const encodedString = encodeURIComponent(encryptedCredentials);
            console.log(encodedString);
            const token = localStorage.getItem("token")
            axios.post(
                `https://apiliveservice.myeasygas.com/api/Product/purchaseproduct?request=${encodedString}`,
                {},
                {
                    headers:{
                        Authorization: `Bearer ${token}`
                    } 
                }
            ).then((response) => {
                const res = response.data
                console.log(decrypt(res))
                const decryptedData = decrypt(res);
                // const decodedString = decodeURIComponent(decryptedData);
                // console.log('Decrypted Data:', decodedString);
                setRoll(false)
                toast({
                title: 'Success!',
                // description: decryptedData,
                description: 'Product Ordered Successfully',
                status: 'success',
                duration: 9000,
                isClosable: true,
                position: 'top-center'
                });
                
                onOpen()
            
            })
            .catch((error) => {
                console.log(error);
                console.log(error.response); 
                // console.error('Login failed:', error);
                console.log('Login failed:', error);
                const decryptedError = decrypt(error.response.data);
                // const decodedString = decodeURIComponent(decryptedError);
                // console.log('Decrypted Error:', decodedString);
                setRoll(false)
                toast({
                title: 'Error!',
                description: decryptedError ,
                description: 'Click on The Private Delivery Tab' ,
                status: 'error',
                duration: 9000,
                isClosable: true,
                position:'top-center'
                });
                
            });
            
        }
        if (!lastStep){ 
            return handleNext()
        }
    
        handleNext()
       
    }

    const handleClick = () => {
        if (prev) {
            prev()
        }
        if (firstStep) {
            navigate('/custdash')
        }
    };

    const handleCloseModal = () => {
        setShowReviewModal(false)
    };

    const handleFlexClick = (e) => {
        e.stopPropagation(); 
    };

    return (
        <HStack spacing={0}>
            <Box width={`50%`}>
                <form onSubmit={onSubmit}>
                    <Flex
                        h={`15vh`}
                        ps={10}
                        pe={16}
                        alignItems={`end`}
                    >
                        <Icon
                            boxSize={'60px'}
                            viewBox="0 0 100 100"
                        >
                            <Logo />
                        </Icon>
                    </Flex>
                    <Box
                        h={`calc(75vh - 4px)`}
                        pt={5}
                        pb={10}
                        style={{scrollbarWidth: 'none'}}
                    >
                        {step}
                    </Box>
                    <LoadingLine step={currentStep + 3} height={height}/>
                    <Flex
                        h={`10vh`}
                        ps={10}
                        pe={16}
                        justifyContent="space-between"
                        alignItems="center"
                    >
                        <ArrowButton
                            size="xs"
                            text="Back"
                            isIcon={false}
                            variant={'outline'}
                            move={handleClick}
                        />
                        <ArrowButton
                           size={!lastStep ? "sm_x" : "sm"}
                           text={!lastStep ? "Finialize Setup" : "Next"}
                            isIcon={false}
                            type={`submit`}
                            // variant={
                            //   !lastStep && (!isFirstStageComplete()) ? `inactive` : 'active'
                            // }
                            variant={
                              !lastStep &&
                              (currentStep === 0
                                  ? !isFirstStageComplete()
                                  : currentStep === 3
                                  ? !isSecondStageComplete()
                                  : false)
                                  ? "inactive"
                                  : "active"
                            }
                            disabled={lastStep ? !isDataComplete() : false}
                        />
                    </Flex>
                </form>
            </Box>
            <Box w={`50%`} h={`100vh`}>
                <OnBoardingImages currentStep={currentStep} for={`check`} />
            </Box>
            {roll && (<Loading
                text={`Verifying Account`}
                color={`primary.200`}
                mark={false}
            />)}

            {showReviewModal && <Box
                position="fixed"
                top={0}
                left={0}
                width="100%"
                height="100%"
                display="flex"
                justifyContent="center"
                alignItems="center"
                background=" #0909093f"
                onClick={handleCloseModal}
                zIndex="999"
            >
        <Flex
            flexDir="column"
            minW={`400px`}
            width="50px"
            height="auto"
            borderRadius="15px"
            background="#fff"
            textAlign="center"
            p={4}
            onClick={handleFlexClick}
            align={`center`}
        >

            <Image
                src={Review}
                w={150}
            />
            <Text 
                textAlign={'center'} 
                color={'font.100'} 
                fontSize={"xs"}
                pb={3}
            >
                {/* Our team will review your request and provide feedback within 48 hours. */}
            </Text>
            
            <ArrowButton
                width={`100%`}
                isIcon={false}
                text={`Go Home`}
                variant={`active`}
                onClick={() => {
                    navigate('/custdash')
                }}
            />


        </Flex>
            </Box> }
            {loading && (
                <Loading
                    text={`Processing...`}
                    color={`secondary.200`}
                    mark={false}
                />
            )}

            <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent borderRadius={'lg'} >
                        <Box>
                        <ModalHeader>
                            <Box textAlign={'center'} pt={'20px'} >             
                            <Text color={'border.500'} fontSize={'xl'} fontWeight={'700'} >
                                OTP Verification
                            </Text>
                            <Text py={4} color={'font.200'} fontSize={'xs'} fontWeight={'normal'} >
                                We have sent a 6-Digit code to your submitted email address.
                            </Text>

                            </Box>
                        </ModalHeader>
                        <ModalBody>
                            <HStack
                                spacing={5}
                                pt={5}
                            >
                                <PinInput 
                                    size="lg" 
                                    autoFocus 
                                    placeholder="0" 
                                    otp 
                                    colorScheme="red" focusBorderColor="primary.400"
                                    // onChange={(values) => {
                                    //     props.updateOtp({ otp: values })
                                    // }}
                                    onChange={(value) => {
                                        // verify(value)
                                        updateOtp({otp: value, EmailAddress: customerProfileData[0].EmailAddress})
                                        console.log(data)
                                        console.log(otps)
                                    }}
                                >
                                    <PinInputField />
                                    <PinInputField />
                                    <PinInputField />
                                    <PinInputField />
                                    <PinInputField />
                                    <PinInputField />
                                </PinInput>
                            </HStack>
                                    
                            <Button 
                                my={'20px'}
                                bg={isButtonActive ? 'primary.100' : 'gray.400'}
                                color={isButtonActive ? '#fff' : '#000'}
                                p="20px 56px" 
                                h="48px" 
                                width="100%" 
                                _focus={{ boxShadow: "none" }} 
                                _hover={{bg: 'primary.100',
                                color: 'none',}}
                                isDisabled={!isButtonActive}
                                onClick={() =>{ 
                                    verify(otps)
                                }}
                            >
                                Proceed to checkout
                            </Button>  
                        

                        </ModalBody>       

                        </Box>
                </ModalContent>
            </Modal>

            <Modal isOpen={showTopUpModal} onClose={() => setShowTopUpModal(false)}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Insufficient Balance</ModalHeader>
                    <ModalBody>
                        <p>Your current balance is zero. Please top up your balance to proceed.</p>
                        <Flex
                            h={`10vh`}
                            ps={10}
                            pe={16}
                            justifyContent="space-between"
                            alignItems="center"
                        >
                            <ArrowButton
                                size="xs"
                                text="Top Up Balance"
                                isIcon={false}
                                variant={'outline'}
                                move={handleTopUp}
                            //  onClick={handleTopUp}
                            />

                            <ArrowButton 
                            size="xs"
                            text="Cancel"
                            isIcon={false}
                            variant={'outline'}
                            onClick={() => setShowTopUpModal(false)}
                            />

                        </Flex>
                    </ModalBody>
                </ModalContent>
            </Modal>;

        </HStack >
    )
}

export default CustomerCheckout;