import { Button, Icon, Text, Box } from "@chakra-ui/react";
import PropTypes from "prop-types"

const ArrowButton = (props) => {
    if (props.isIcon && !props.icon) {
        console.warn("Warning: Icon is required when isIcon is true.");
    }
    const handleClick = () => {
        if (props.onClick) {
           return props.onClick();
        }
        if (props.move) {
            return props.move()
        }
    };
    return (
        <Box
            mt={props.margin}
            width={props.width ? `100%` : ''}
        >
            <Button
                size={props.size}
                variant={props.variant ? props.variant : props.isSelected ? "active" : "inactive"}
                gap={2}
                onClick={handleClick}
                type={props.type}
                isDisabled={props.isSelected ? false : props.disabled}
                opacity={0.85}
                width={props.width}
            >
                <Text>{props.text}</Text>
                {props.isIcon && (
                    <Icon boxSize={`24px`}>
                        {props.icon}
                    </Icon>
                )}
            </Button>
        </Box>
    );
};

ArrowButton.propTypes = {
    size: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    isIcon: PropTypes.bool.isRequired,
    isSelected: PropTypes.number,
    icon: PropTypes.element,
    margin: PropTypes.number,
    variant: PropTypes.string,
    move: PropTypes.func,
    type: PropTypes.string,
    disabled: PropTypes.bool,
    onClick: PropTypes.func,
    test: PropTypes.func,
}

export default ArrowButton;
