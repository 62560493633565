import { createContext, useState } from "react";

export const CustomerContext = createContext()

export const CustomerContextProvider = (props) => {
    const INITIAL_DATA = {
        userType: 'Delivery', // String
        fullName: "q", // String
        phoneNumber: "q", // String
        emailAddress: "q", // String
        password: "q", // String
        fullAddress: "q", // String
        localGovernment: "q", // String
        state: "q", // String
        closestLandmark: "q", // String
        storageCapacity: "q", // String
        certificateOfIncorporation: "", // File(Url)
        NMDPRDepotOperatingLicence: "", // File(Url)
        termsAndConditions: false, // Boolean
    };
    const [data, setData] = useState(INITIAL_DATA)
    const [fileValidation, setFileValidation] = useState({
        certificateOfIncorporation: false,
        NMDPRDepotOperatingLicence: false,
    })
    const [uploadError, setUploadError] = useState(false)
    const [files, setFiles] = useState({
        certificateOfIncorporation: '',
        NMDPRDepotOperatingLicence: '',
    })
    const [progress, setProgress] = useState({
        certificateOfIncorporation: 0,
        NMDPRDepotOperatingLicence: 0,
    })
    const [fileName, setFileName] = useState({
        certificateOfIncorporation: "",
        NMDPRDepotOperatingLicence: "",
    })
    const [fileSize, setFileSize] = useState({
        certificateOfIncorporation: null,
        NMDPRDepotOperatingLicence: null,
    })
    const buttonVH = '10vh'
    const formHeight = `calc(100% - ${buttonVH} )`
    // const navigate = useNavigate()
    
    const updateData = (fields) => {
        setData(p => {
            return {...p, ...fields}
        })
    }

    const isDataComplete = () => {
        const requiredFields = [
            "userType",
            "fullName",
            "phoneNumber",
            "emailAddress",
            "password",
            "fullAddress",
            "localGovernment",
            "state",
            "closestLandmark",
            "storageCapacity",
            "certificateOfIncorporation",
            "NMDPRDepotOperatingLicence",
            "termsAndConditions"
        ];

        // Check if all required fields are not empty
        return requiredFields.every((field) => data[field]);
    };

    const isFirstStageComplete = () => {
        const requiredFields = [
            "userType",
            "fullName",
            "phoneNumber",
            "emailAddress",
            "password",
            "fullAddress",
            "localGovernment",
            "state",
            "closestLandmark",
            "storageCapacity",
        ];

        // Check if all required fields are not empty
        return requiredFields.every((field) => data[field]);
    };

    const contextValue = {
        ...data,
        setData,
        fileValidation,
        setFileValidation,
        uploadError,
        setUploadError,
        files,
        setFiles,
        progress,
        setProgress,
        fileName,
        setFileName,
        fileSize,
        setFileSize,
        formHeight,
        updateData,
        isDataComplete,
        isFirstStageComplete,
        
    };

    return (
        <CustomerContext.Provider value={contextValue}>
            {props.children}
        </CustomerContext.Provider>
    )
}