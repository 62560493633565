import React, { useContext, useEffect, useState } from 'react'
import AuthContext from '../../../Components/Admin/Contexts/AdminContext'
import { 
    Box, 
    Divider, 
    Flex, 
    Icon, 
    Input, 
    InputGroup, 
    InputRightElement, 
    Tab, 
    TabIndicator,  
    TabList, 
    TabPanel, 
    TabPanels, 
    Tabs,  
    Text
} from '@chakra-ui/react'
import AllOrder from './AllOrder';
import CompletedOrder from './CompletedOrder';
import CancelledOrder from './CancelledOrder';
import { Search } from '../../../Assets';

const HistoryPage = () => {

    const { customerHistoryData,isLoadingCustomerHistoryData,isErrorCustomerHistoryData,tabId,tableData, handleTabChange } = useContext(AuthContext);
    const [searchQuery, setSearchQuery] = useState("");

    const allOrdersCount = customerHistoryData ? customerHistoryData.filter((data) => data.FullName.toLowerCase().includes(searchQuery.toLowerCase())).length : 0;
    const completedOrdersCount = customerHistoryData ? customerHistoryData.filter((data) => data.OrderStatusID === 3 && data.FullName.toLowerCase().includes(searchQuery.toLowerCase())).length : 0;
    const cancelledOrdersCount = customerHistoryData ? customerHistoryData.filter((data) => data.OrderStatusID === -2 && data.FullName.toLowerCase().includes(searchQuery.toLowerCase())).length : 0;
  

  return (
    <Box  bg='primary.600'>
        <Tabs defaultIndex={tabId} onChange={(index) => handleTabChange(index)} variant="unstyled" bg='#fff' m='20px' borderRadius={'lg'} pt={5}  >
        <TabList justifyContent="space-between">
            <TabList p={'0 10px'}>
            <Tab 
                color={'border.100'} 
                _selected={{ 
                    color: "primary.100", 
                    borderBottom: "2px solid primary.100", 
                    fontWeight: 'semibold' 
                }}
                
            >
                <Text color='primary.300'>
                    All Orders
                    <Text as='span' fontSize='xs' fontWeight='semibold' borderRadius={'1rem'}  p={'1'} color='primary.100' ml='5px' bg='primary.600' >
                        {allOrdersCount}
                    </Text>
                </Text>  
            </Tab>         
           
            <Tab color={'border.100'} _selected={{ color: "primary.100", borderBottom: "2px solid primary.100", fontWeight: 'semibold'}} >
                <Text color='primary.300'>
                    Successful
                    <Text as='span' fontSize='xs' fontWeight='semibold' borderRadius={'1rem'}  p={'1'} color='primary.100' ml='5px' bg='primary.600' >
                    {completedOrdersCount}
                    </Text>
                </Text>   
            </Tab>
            <Tab color={'border.100'} _selected={{ color: "primary.100", borderBottom: "2px solid primary.100", fontWeight: 'semibold'}} >
                <Text color='primary.300'>
                    Cancelled
                    <Text as='span' fontSize='xs' fontWeight='semibold' borderRadius={'1rem'}  p={'1'} color='primary.100' ml='5px' bg='primary.600' >
                    {cancelledOrdersCount}
                    </Text>
                </Text>   
            </Tab>
            </TabList>
            <Flex alignItems="center" mr={'20px'}>
            <InputGroup w={'250px'}>                       
                <Input placeholder='Search Users.....' />
                <InputRightElement>
                <Icon boxSize='25px'>
                    <Search
                    placeholder='Search Users.....' 
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                    />
                </Icon>
                </InputRightElement>
            </InputGroup>
            </Flex>
        </TabList>
        <TabIndicator
            mt="-1.5px"
            height="2px" 
            bg="primary.100"
            borderRadius="1px"             
        />

        {/* <Divider/> */}

        <TabPanels>
            <TabPanel>
                <AllOrder orderData={customerHistoryData} isLoadingData={isLoadingCustomerHistoryData} isErrorData={isErrorCustomerHistoryData} tableData={tableData} />
            </TabPanel>                   
            <TabPanel>
                <CompletedOrder  orderData={customerHistoryData} isLoadingData={isLoadingCustomerHistoryData} isErrorData={isErrorCustomerHistoryData} tableData={tableData} />
            </TabPanel>         
            <TabPanel>
                <CancelledOrder orderData={customerHistoryData} isLoadingData={isLoadingCustomerHistoryData} isErrorData={isErrorCustomerHistoryData} tableData={tableData} />
            </TabPanel>         
        </TabPanels>
        </Tabs>
    </Box>
  )
}

export default HistoryPage