import { Box, Flex, Text, FormControl, InputGroup, Input, FormLabel, InputRightElement, HStack, VStack, Switch, useToast, useDisclosure, Modal, ModalOverlay, ModalContent, ModalBody, Icon } from '@chakra-ui/react'
import { Currency, Discount, GasPump, Pen, PenGrey, Weight, Info, NoData } from '../../../../Assets'
import ArrowButton from '../../../shared/Button'
import { useContext, useEffect, useState } from 'react'
import { VendorDashboardContext } from '../context/VendorDashboardContext'
import axios from 'axios'
import { encrypt, decrypt } from '../../../../Functions/crypt'
import { useNavigate } from 'react-router-dom'
import AuthContext from '../../../../Components/Admin/Contexts/AdminContext'

export const  ProductModal = ({selectedUserId,selectedProduct,isLoadingVendorProduct}) => {

    const toast = useToast()
    const { isOpen: isSecondModalOpen, onOpen: onSecondModalOpen, onClose: onSecondModalClose } = useDisclosure();
    const navigate = useNavigate()
    const [isFormActive, setIsFormActive] = useState(true); // State to manage form activation


    const [data, setFormData] = useState({
        productId: selectedUserId,
        productName: "LPG",
        description: "Liquified Petroleum Gas",
        quantity: "", 
        price: "",
        discount: "",
        productAvailability: false, // Assuming it's a boolean field
    });

    useEffect(() => {
        if (selectedUserId && selectedProduct) { // Add a check for filteredData
            // console.log(selectedProduct);
            console.log('selectedProduct', selectedUserId)

            !isLoadingVendorProduct && setFormData(prevState => ({
                ...prevState,
                productId: selectedUserId, 
                productName: selectedProduct.ProductName,
                description: selectedProduct.ProductDescription,
                quantity: selectedProduct.Quantity,
                price: selectedProduct.Price,
                discount: selectedProduct.Discount,
                productAvailability: selectedProduct.Availability,
            }));
    
        }
    }, [selectedUserId, selectedProduct]);

    const { 
        showAddProduct, 
        setShowAddProduct, 
        showEditProduct, 
        setShowEditProduct, 
        addProduct, 
        setAddProduct, 
        editProduct,
        setEditProduct,
        validate,
        setValidate
    } = useContext(VendorDashboardContext)

    const {profile,status,profileDoc} = useContext(AuthContext);
    
    const handleCloseModal = () => {
        setShowAddProduct(false);
        setShowEditProduct(false);
    };

    const handleFlexClick = (e) => {
        e.stopPropagation();
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        // console.log("Input name:", name);
        // console.log("Input value:", value);

        if (name === 'price') {
            const formattedValue = value.replace(/\D/g, '').replace(/\B(?=(\d{3})+(?!\d))/g, ',');
            setFormData(prevData => ({
                ...prevData,
                [name]: formattedValue
            }));
        } else {
            // For other input fields, directly update the state
            setFormData(prevData => ({
                ...prevData,
                [name]: value
            }));
        }
        
        // setFormData(prevData => ({
        //     ...prevData,
        //     [name]: value
        // }));

        // console.log('data', data)

        if (name === 'discount') {
            // Handle 'discount' field separately without setting validation
            if (showAddProduct) {
                setAddProduct((data) => ({ ...data, [name]: value }));
            }
            if (showEditProduct) {
                setEditProduct((data) => ({ ...data, [name]: value }));
            }
        } else {
            // Handle other fields with validation
            setValidate((prevValidate) => ({ ...prevValidate, [name]: !!value }));

            if (showAddProduct) {
                setAddProduct((data) => ({ ...data, [name]: value }));
            }
            if (showEditProduct) {
                setEditProduct((data) => ({ ...data, [name]: value }));
            }
        }

        
    // Update the state for addProduct or editProduct for price
        if (showAddProduct) {
            setAddProduct(prevProduct => ({
                ...prevProduct,
                [name]: name === 'price' ? value.replace(/,/g, '') : value
            }));
        }
        if (showEditProduct) {
            setEditProduct(prevProduct => ({
                ...prevProduct,
                [name]: name === 'price' ? value.replace(/,/g, '') : value
            }));
        }
    };
    
    const handleAddProduct = async () => {
        
        const modifiedAddProduct = addProduct.discount === "" || !addProduct.discount
        ? { ...addProduct, discount: "0" }
            : addProduct;
        
        const allInputsValid = Object.values(validate).every((isValid) => isValid);

        if (!allInputsValid) {
            // Display an error message or handle the invalid case
            toast({
                position: 'top',
                icon: <Info />,
                duration: 10000,
                description: 'Please fill in all required fields.',
                status: 'warning',
            });
            return;
        }


        const token = localStorage.getItem("token");
        // console.log(modifiedAddProduct)
        const encryptedCredentials = encrypt(modifiedAddProduct);
        const encodedString = encodeURIComponent(encryptedCredentials);

        try {
            const response = await axios.post(`https://apiliveservice.myeasygas.com/api/Product/product?request=${encodedString}`,
            {},
            {
                headers:{
                    Authorization: `Bearer ${token}`
                } 
            });
            
            console.log(response)
            const res = response.data;      
            const decryptedData = decrypt(res);
            // console.log(decryptedData)
            toast({
                position: "top",
                icon: <Info />,
                duration: 10000,
                description: 'Product has been succesfully created',
                status: 'success'
            })
            setShowAddProduct(false);
            setAddProduct(() => {
                return {}
            })
        } catch (error) {
            // console.log(error, error.response.status)
            // console.log(decrypt(error.response.data))
            const decryptedData = decrypt(error.response.data)
            toast({
                position: "top",
                icon: <Info />,
                duration: 10000,
                description: decryptedData || error.response.statusText,
                status: 'warning'
            })
            error.response.status === 401 && navigate("/")
        }
    }

    const handleUpdateVendorProduct = (data) => {


         const priceWithoutComma = data.price.replace(/,/g, '');
        // Update the data object with the price without comma
        const updatedData = { ...data, price: priceWithoutComma };

        const { productId, ...restData } = updatedData; // Destructure productId from data object
        console.log('productId:', productId);

        const token = localStorage.getItem("token");
      console.log('updated',updatedData)
       const encryptedCredentials = encrypt(updatedData);
       console.log('ork',encryptedCredentials);
       const encodedString = encodeURIComponent(encryptedCredentials);
       console.log(encodedString);
       axios.patch(`https://apiliveservice.myeasygas.com/api/Product/product?request=${encodedString}`,
          {},
          {
            headers:{
              Authorization: `Bearer ${token}`
            } 
          }
        ).then((response) => {
           const res = response.data;      
           const decryptedData = decrypt(res);
           console.log('productData',decrypt(res))
           toast({
            position: "top",
            icon: <Info />,
            duration: 10000,
            description: decryptedData,
            status: 'success'
            })
            setShowEditProduct(false);
            // setAddProduct(() => {
            //     return {}
            // })
         })
         .catch((error) => {
           console.log(error);
           console.log(error.response); 
           const decryptedData = decrypt(error.response.data)
            toast({
                position: "top",
                icon: <Info />,
                duration: 10000,
                description: decryptedData || error.response.statusText,
                status: 'warning'
            })
            // error.response.status === 401 && navigate("/")
           // console.error('Login failed:', error);
           console.log('Vendor Data failed:', error);
         });
    };


    const handleProceedToCheckout = () => {
        if (status === "1" && profile === "true" && profileDoc === "true") {
            console.log('Navigate to dashboard page');
            onSecondModalOpen();
            setIsFormActive(false); // Disable the form
        } else {
            return;
            // console.log('Customer dashboard data is complete');
        }
    };
 
    // console.log('yesssss',handleUpdateVendorProduct)
    

    // const data = (showAddProduct && addProduct) || (showEditProduct && editProduct)
    

    return (
        <Box
            position="fixed"
            top={0}
            left={0}
            width="100%"
            height="100%"
            display="flex"
            justifyContent="center"
            alignItems="center"
            background=" #0909093f"
            onClick={handleCloseModal}
            zIndex="999"
        >
            <Flex
                flexDir="column"
                minW={`400px`}
                width="33.3333333333333333vw"
                height="auto"
                borderRadius="15px"
                background="#fff"
                textAlign="center"
                p={4}
                onClick={handleFlexClick}
            >

                <Text 
                    textAlign={'center'} 
                    color={'primary.800'} 
                    fontWeight={'bold'} 
                    fontSize={'lg'}
                    pb={2}
                >
                {(showAddProduct &&  'Add Product') || (showEditProduct && 'Edit Product')}
                </Text>
                <Text 
                    textAlign={'center'} 
                    color={'font.100'} 
                    fontSize={"xs"}
                    pb={3}
                >
                    Create your product for display
                </Text>
                {
                    isFormActive &&(

                        <form 
                            style={{width: "92%", margin:"auto"}}
                        >
                            <VStack
                                gap={5}
                            >
                                <FormControl>
                                    <InputGroup>
                                        <Input 
                                            type="text"
                                            focusBorderColor='#858383' 
                                            _focus={{ boxShadow: "none" }}
                                            h="50px" 
                                            id="productName"
                                            fontSize="14px" 
                                            required
                                            borderColor="#B5B4B4"
                                            value={data.productName}
                                            onChange={handleInputChange}
                                            name= "productName"
                                            disabled={handleProceedToCheckout()}
                                        />
                                        <FormLabel 
                                            fontWeight="normal" 
                                            fontSize="12px" 
                                            htmlFor="productName"
                                            zIndex={'999'}
                                        >
                                            Product Name
                                        </FormLabel>
                                        <InputRightElement h={'100%'}>
                                            <GasPump />                       
                                        </InputRightElement>
                                    </InputGroup>
                                </FormControl>
                                <FormControl>
                                    <InputGroup>                  
                                        <textarea
                                            style={{
                                                width: '100%',
                                                border: '1px solid #B5B4B4',
                                                borderRadius: '0.375rem',
                                                padding: '15px',
                                                paddingRight: '30px',
                                                resize:'none',
                                            }}
                                            rows={5}
                                            title='description'
                                            id='descTextarea'
                                            name='description'
                                            required
                                            value={data.description}
                                            onChange={handleInputChange}
                                            disabled={handleProceedToCheckout()}
                                        />
                                        <label
                                            style={{
                                                zIndex: '999',
                                                display: 'block',
                                            }}
                                            htmlFor="descTextarea"
                                            id="descLabel"
                                        >
                                            Description
                                        </label>
                                        <InputRightElement>
                                            <PenGrey />          
                                        </InputRightElement>
                                    </InputGroup>
                                </FormControl>
                                <HStack
                                    w={`100%`}
                                    gap={8}
                                >
                                    <FormControl>
                                        <InputGroup>
                                            <Input 
                                                type="number" 
                                                focusBorderColor='#858383' 
                                                _focus={{ boxShadow: "none" }} 
                                                h="50px" 
                                                id="quantity"
                                                fontSize="14px" 
                                                required
                                                borderColor="#B5B4B4"
                                                value={data.quantity}
                                                onChange={handleInputChange}
                                                name='quantity'
                                                disabled={handleProceedToCheckout()}
                                            />
                                            <FormLabel 
                                                fontSize="12px" 
                                                htmlFor="quantity"
                                                zIndex={'999'}
                                            >
                                                Quantity (ton)
                                            </FormLabel>
                                            <InputRightElement h={'100%'}>
                                                <Weight />                       
                                            </InputRightElement>
                                        </InputGroup>
                                    </FormControl>
                                    <FormControl>
                                        <InputGroup> 
                                            <Input 
                                                type="text" 
                                                focusBorderColor='#858383' 
                                                _focus={{ boxShadow: "none" }} 
                                                h="50px" 
                                                id="price"
                                                fontSize="14px" 
                                                required
                                                borderColor="#B5B4B4"
                                                value={data.price}
                                                onChange={handleInputChange}
                                                name='price'
                                                disabled={handleProceedToCheckout()}
                                            />
                                            <FormLabel 
                                                fontSize="12px" 
                                                htmlFor="price"
                                                zIndex={'999'}
                                            >
                                                Price
                                            </FormLabel>
                                            <InputRightElement h={'100%'}>
                                                <Currency />           
                                            </InputRightElement>
                                        </InputGroup>
                                    </FormControl>
                                </HStack>
                                {showEditProduct &&
                                    <FormControl>
                                    <HStack
                                        justifyContent={'space-between'}
                                    >
                                        <FormLabel
                                            pos={'relative'}
                                            top={0}
                                            left={0}
                                            m={0}
                                            color={`black`}
                                        >
                                            Change Product availability
                                        </FormLabel>
                                        <Switch 
                                            id='UNAVAILABLE' 
                                            pos={'relative'}
                                            top={0}
                                            left={0}
                                            value={data.productAvailability}
                                            onChange={handleInputChange}
                                            name='productAvailability'
                                        />
                                    </HStack>
                                    </FormControl>
                                }
                                <FormControl>
                                    <InputGroup>
                                        <Input 
                                            type="number" 
                                            focusBorderColor='#858383' 
                                            _focus={{ boxShadow: "none" }}
                                            h="50px" 
                                            id="discount"
                                            fontSize="14px"
                                            borderColor="#B5B4B4"
                                            value={data.discount}
                                            onChange={handleInputChange}
                                            name='discount'
                                            required
                                            disabled={handleProceedToCheckout()}
                                        />
                                        <FormLabel 
                                            fontSize="12px" 
                                            htmlFor="discount"
                                            zIndex={'999'}
                                        >
                                            Discount (Optional)
                                        </FormLabel>
                                        <InputRightElement h={'100%'}>
                                            <Discount />                      
                                        </InputRightElement>
                                    </InputGroup>
                                </FormControl>
                            </VStack>
                            <VStack
                                w={`100%`}
                                gap={6}
                                mt={6}
                            >
                                <ArrowButton
                                    width={`100%`}
                                    size="xs"
                                    isIcon={false}
                                    text={(showAddProduct && 'Add Product') || (showEditProduct && 'Update Product Details')}
                                    variant={`active`}
                                    // onClick={handleAddProduct}
                                    onClick={() => {
                                        if (showAddProduct) {
                                            handleAddProduct();
                                        } else if (showEditProduct) {
                                            handleUpdateVendorProduct({
                                                ...data,
                                                productId: selectedUserId // Assuming selectedProduct contains the productId
                                            });
                                        }
                                    }}
                                />
                                {showEditProduct && <ArrowButton
                                    width={`100%`}
                                    size="xs"
                                    isIcon={false}
                                    text='Remove Product'
                                    variant={`outline_dg`}
                                    onClick={() => {
                                    
                                    }}
                                />}
                            </VStack>
                        </form>

                    )
                }


            </Flex>

            <Modal isOpen={isSecondModalOpen} onClose={onSecondModalClose} >
                <ModalOverlay />
                <ModalContent>
                <ModalBody>
                    <Box textAlign={'center'} py={'50px'} >
                        <Icon boxSize='150px' viewBox="0 0 250 250" >
                            <NoData />                      
                        </Icon>
                        <Text 
                        color={'primary.100'} fontSize={'base'} cursor={'pointer'} fontWeight={'bold'} textDecoration={'underline'} 
                        >
                        Profile in Review
                        </Text>                               

                    </Box>        
            
                </ModalBody>         
                </ModalContent>
            </Modal>
        </Box> 
    )
}
