import { Box } from '@chakra-ui/react';
import PropTypes from 'prop-types';

export const LoadingLine = (props) => {
    return (
        <Box bg={`primary.900`}>
            <Box
                bg={`primary.100`}
                h={props.height}
                w={`calc(20% * ${props.step})`}
                transition="width 0.3s"
            >
            </Box>
        </Box>
    )
}

LoadingLine.propTypes = {
    step: PropTypes.oneOf([2, 3, 4, 5]),
    height: PropTypes.string,
}