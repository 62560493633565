import { 
    Box, 
    Button, 
    Flex, 
    Icon, 
    Input, 
    InputGroup, 
    InputRightElement, 
    Modal, 
    ModalBody, 
    ModalContent, 
    ModalHeader, 
    ModalOverlay, 
    Stack, 
    Text, 
    useDisclosure 
} from '@chakra-ui/react'
import React, { useContext, useState } from 'react'
import { Search } from '../../../../Assets';
import AdminOnoardingForm from './AdminOnoardingForm';
import AdminOnboardingTable from './AdminOnboardingTable';
import AuthContext from '../../Contexts/AdminContext';

const AdminOnboarding = () => {
    const { isOpen, onOpen, onClose } = useDisclosure()
    const {adminsData, isLoadingAdmin, isErrorAdmin,} = useContext(AuthContext);
    const [searchQuery, setSearchQuery] = useState("");


    const filteredData = adminsData && adminsData.filter((data) =>
        data.FullName.toLowerCase().includes(searchQuery.toLowerCase())
    );

    const adminCount = filteredData ? filteredData.length : 0;

  return (
    <Box bg='primary.600'  >
        
        <Box bg='#fff' m='20px' borderRadius={'lg'} >
            <Flex p='20px' alignItems='center' justifyContent='space-between' >
            <Text color='primary.300' fontSize='xl' fontWeight='bold'>
                Admin
                <Text as='span' fontSize='xs' fontWeight='semibold' borderRadius={'1rem'}  p={'1'} color='primary.100' ml='5px' bg='primary.600' >
                    {adminCount}
                </Text>
              </Text>  
                <Box>
                    <Stack spacing={8} direction='row' >
                        <InputGroup>                       
                            <Input 
                                placeholder='Search Users.....' 
                                value={searchQuery}
                                onChange={(e) => setSearchQuery(e.target.value)}
                            />
                            <InputRightElement>
                                <Icon  boxSize='25px'  >
                                    <Search/>
                                </Icon>
                            </InputRightElement>
                        </InputGroup>
                        <Button onClick={onOpen} width={'250px'} bg='primary.100' color='#fff' fontWeight='bold' fontSize='sm'    >
                            Add New Admin
                        </Button>
                    </Stack>
                </Box>
            </Flex>
            <AdminOnboardingTable adminsData={filteredData} isLoadingAdmin={isLoadingAdmin} isErrorAdmin ={isErrorAdmin}   />
        </Box>
        <Modal  isOpen={isOpen} onClose={onClose} >
            <ModalOverlay />
            <ModalContent borderRadius={`lg`}>
                <ModalHeader textAlign='center'>
                   <Text fontWeight='bold' color='primary.800' >
                        Add New Admin
                   </Text>
                   <Text fontWeight='normal' fontSize='xs'color='font.100' >
                        Onboard Admin so they can help you manage other users
                   </Text>
                </ModalHeader>
                <ModalBody>
                    <AdminOnoardingForm/>
                </ModalBody>

               
            </ModalContent>
        </Modal>
    </Box>
  )
}

export default AdminOnboarding;