import { checkboxAnatomy as parts } from "@chakra-ui/anatomy"
import {
  createMultiStyleConfigHelpers,
  defineStyle,
} from "@chakra-ui/styled-system"

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(parts.keys)

// default base style from the Checkbox theme
const baseStyle = definePartsStyle({
//   label: {
//     fontFamily: "mono"
//   },
  control: {
    padding: 2,
    borderRadius: 6,
    _checked: {
      colorScheme: 'red', // Change to the desired color
      bg: 'primary.100',
      borderColor: 'primary.100',
      _hover:{
        bg: 'primary.100',
      borderColor: 'primary.100',
      }
    },
  }
})

// Defining a custom variant
const variantCircular = definePartsStyle({
  control: defineStyle({
    rounded: "full"
  })
})
const variantBorderRadius = definePartsStyle({
  control: defineStyle({
    borderRadius: "5px"
  })
})

const variants = {
  circular: variantCircular,
  borderRadius: variantBorderRadius
}

const sizes = {
  xl: definePartsStyle({
    control: defineStyle({
      boxSize: 14
    }),
    label: defineStyle({
      fontSize: "2xl",
      marginLeft: 6
    })
  })
}

export const Checkbox = defineMultiStyleConfig({
  baseStyle,
  variants,
  sizes,
})