import { 
    Box, 
    Button, 
    FormControl, 
    FormLabel, 
    Input, 
    InputGroup, 
    InputRightElement, 
    Text 
} from '@chakra-ui/react'
import { useContext, useState } from 'react';
import {useNavigate } from 'react-router-dom';
import Loading from '../../Features/shared/Loading';
import { Aemail, Apassword, Email, Password } from '../../Assets';
import AuthContext from './Contexts/AdminContext';


const Login = () => { 

    const initialFormData = {
        emailAddress: '',
        password: '',
    };

    const { handleLogin, setChangePassword} = useContext(AuthContext);
    const [loading, setLoading] = useState(false);
    const [loginData, setLoginData] = useState(initialFormData);
    const navigate = useNavigate();


    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setLoginData((data) => {
           return {...data, [name]: value}
        })
    };
 

    
    const handleSubmit = async (e) => {
        e.preventDefault();    
        setLoading(true);
        console.log('Login Data:', loginData);
        try{
            let dat = await handleLogin(loginData);
            console.log( typeof dat.status);
    
            if (dat.data.data.role === 'Admin'){
                navigate('dashboard');
                // console.log('work please')
            }
            window.location.reload(); // Refreshes the tab
    
            // let dat;
            // handleLogin(loginData).then((data) => {
            //     dat=data;
            // })
            // console.log(dat)
            
        }catch(error){
            setLoading(false);
        }
        
    };
     
  return (
    <Box>
        <form style={{width: "80%", margin:" 0 auto"}} onSubmit={handleSubmit} >
            <FormControl>
                <InputGroup>
                    <Input 
                        type="email" 
                        focusBorderColor='#858383' 
                        _focus={{ boxShadow: "none" }} 
                        bg={loginData.emailAddress ? 'primary.900' : 'transparent' }
                        h="50px"  id="username" 
                        fontSize="14px" required 
                        borderColor="#B5B4B4"
                        value={loginData.emailAddress}
                        onChange={handleInputChange}
                        name='emailAddress'
                    />
                    <FormLabel 
                        fontSize="12px" 
                        fontWeight="normal"  
                        htmlFor="username"
                        zIndex={'999'}

                    >
                        Username
                    </FormLabel>
                    <InputRightElement h={'100%'}>
                        { 
                            loginData.emailAddress ?  <Aemail /> : <Email/> 
                        }                        
                    </InputRightElement>
                </InputGroup>
            </FormControl>
            <FormControl mt="10px">
                <InputGroup>
                    <Input 
                        type="password" 
                        focusBorderColor='#858383' 
                        _focus={{ boxShadow: "none" }} 
                        bg={loginData.password ? 'primary.900' : 'transparent' }
                        h="50px" id="password"
                        fontSize="14px" required
                        borderColor="#B5B4B4"
                        value={loginData.password}
                        onChange={handleInputChange}
                        name='password'
                    />
                    <FormLabel 
                        fontWeight="normal" 
                        fontSize="12px" 
                        htmlFor="password"
                        zIndex={'999'}
                    >
                        Password
                    </FormLabel>
                    <InputRightElement h={'100%'}>
                        { 
                            loginData.password ?  <Apassword /> : <Password/> 
                        }                        
                    </InputRightElement>
                </InputGroup>
            </FormControl>
            <Text 
                color="primary.200" 
                fontSize="14px" 
                p="10px 0" 
                fontWeight="medium"
                w="80%"
            >
                Forgot Password?
            </Text>

            <Button
                m="60px 35px"
                bg="primary.100" 
                color="#fff" 
                p="20px 56px" 
                h="48px" 
                width="83%" 
                _focus={{ boxShadow: "none" }} 
                _hover={{bg: 'primary.100',
                color: 'none',}}
                type="submit"       

            >
                Login
            </Button>
        </form>


        {loading && (
              <Loading
                  text={`Processing...`}
                  color={`secondary.200`}
                  mark={false}
              />
        )}

    </Box>
  )
}

export default Login;