import { Box, Button } from '@chakra-ui/react';
import React, { useContext, useState } from 'react'
import AuthContext from '../../../../Components/Admin/Contexts/AdminContext';
import { DocUpload } from '../../../Signup/Delivery/Forms/DocUpload';
import AddVehicleUpload from '../../../Signup/Delivery/Forms/AddVehicleUpload';
import VehilceAdd from './VehilceAdd';

const DeliveryProfileDoc = () => {

    const INITIAL_DATA = {
        CertificateOfIncorporation: "", // File(Url)
        CertificateOfRegistration: "", // File(Url)
        HaulageLicence: "", // File(Url)
        TransitInsurance: "", // File(Url)        
    };

    const [data, setData] = useState(INITIAL_DATA);
    const {handleUploadDelivery} = useContext(AuthContext);

    const [fileValidation, setFileValidation] = useState({
        CertificateOfIncorporation: false,
        CertificateOfRegistration: false,
        HaulageLicence: false,
        TransitInsurance: false,
        Vehicles: []
    })
    const [uploadError, setUploadError] = useState(false)
    const [files, setFiles] = useState({
        CertificateOfIncorporation: '',
        CertificateOfRegistration: '',
        HaulageLicence: '',
        TransitInsurance: '',
        Vehicles: []
    })
    const [progress, setProgress] = useState({
        CertificateOfIncorporation: 0,
        CertificateOfRegistration: 0,
        HaulageLicence: 0,
        TransitInsurance: 0,
        Vehicles: []
    })
    const [fileName, setFileName] = useState({
        CertificateOfIncorporation: '',
        CertificateOfRegistration: '',
        HaulageLicence: '',
        TransitInsurance: '',
        Vehicles: []
    })
    const [fileSize, setFileSize] = useState({
        CertificateOfIncorporation: '',
        CertificateOfRegistration: '',
        HaulageLicence: '',
        TransitInsurance: '',
        Vehicles: []
    })

    const height = '4px'
    const buttonVH = '10vh'
    const formHeight = `calc(100% - ${buttonVH} )`
   
    
    const updateData = (fields) => {
        setData(prevData => {
            const newData = { ...prevData, ...fields };
            return newData;
        })
    }


  return ( 
    <Box>
        <DocUpload  height={formHeight} {...data} data={data} updateData={updateData} setFileValidation={setFileValidation} fileValidation={fileValidation} uploadError={uploadError} files={files} setFiles={setFiles} progress={progress} setProgress={setProgress} fileName={fileName} setFileName={setFileName} fileSize={fileSize} setFileSize={setFileSize} />,
        <Box
         ps={12}
         pe={20}
         pt={1}
         pb={1}
        >
            <Button
                // m="60px 35px"
                // mt={'30px'}
                bg="primary.100" 
                color="#fff" 
                p="20px 56px" 
                h="48px" 
                width="100%" 
                _focus={{ boxShadow: "none" }} 
                _hover={{bg: 'primary.100',
                color: 'none',}}
                onClick={()=>{handleUploadDelivery(data)}}  

            >
                Save Document
            </Button>
        </Box>

        <Box p={5} >
            <VehilceAdd/>
        </Box>

    </Box>
  )
}

export default DeliveryProfileDoc