import React from 'react';
import { Navigate, Outlet,} from 'react-router-dom';
// import { useAuth } from '../Components/Admin/Contexts/AdminContext';

const AdminRoute = () => {
  const role = localStorage.getItem("role");
  const isLoggedIn = true

  if (isLoggedIn && role === 'Admin') {
    return <Outlet />
  }

  return <Navigate to = {`/`} />

};

const CutomerRoute = () => {
  const role = localStorage.getItem("role");
  const isLoggedIn = true

  if (isLoggedIn && role === 'Customer') {
    return <Outlet />
  }

  return <Navigate to = {`/`} />

};

const VendorRoute = () => {
  const role = localStorage.getItem("role");
  const isLoggedIn = true

  if (isLoggedIn && role === 'Vendor') {
    return <Outlet />
  }

  return <Navigate to = {`/login`} />

}; 

const DeliveryRoute = () => {
  const role = localStorage.getItem("role");
  const isLoggedIn = true

  if (isLoggedIn && role === 'Delivery') {
    return <Outlet />
  }

  return <Navigate to = {`/login`} />

}; 

export {CutomerRoute, AdminRoute, VendorRoute, DeliveryRoute};
