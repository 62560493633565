import { Box } from '@chakra-ui/react'
import React, { useContext } from 'react'
import Navbar from './Pages/Navbar'
import Home from './Pages/Home'
import About from './Pages/About'
import Services from './Pages/Services'
import Testimony from './Pages/Testimony'
import Contact from './Pages/Contact'
import AuthContext from '../Admin/Contexts/AdminContext'

const LandingPage = () => { 
  const {landingPageProductData,isLoadingLandingProduct,isErrorLandingProduct} = useContext(AuthContext);

  return (
    <Box className='landPage'  >
      {/* <Box backgroundColor={'#FFF'} > */}
        <Navbar/>
        <Home productData={landingPageProductData} isLoadingProduct={isLoadingLandingProduct} isErrorProduct={isErrorLandingProduct}   /> 
      {/* </Box> */}
        <About productData={landingPageProductData} isLoadingProduct={isLoadingLandingProduct} isErrorProduct={isErrorLandingProduct} />
        <Services/>
        <Testimony/>
        <Contact/>
    </Box>
  )
}

export default LandingPage