import { defineStyleConfig } from '@chakra-ui/react';

const Button = defineStyleConfig({
  baseStyle: {
    fontWeight: 'bold',
    letterSpacing: '0.8px',
    borderRadius: 'lg',
    _hover: {
    }
  },
  sizes: {
    lg: {
        fontSize: 'base',
        px: 40,
        py: 3,
        height: 'auto'
    },
    sm: {
        fontSize: 'base',
        px: 24,
        py: 3,
        height: 'auto'
    },
    sm_x: {
        fontSize: 'base',
        px: 14,
        py: 3,
        height: 'auto'
    },
    xs: {
        fontSize: 'base',
        px: 8,
        py: 3,
        height: 'auto'
    }
  },
  variants: {
    inactive: {
      bg: 'primary.500',
      color: 'white',
        _hover: {
          _disabled: {
            opacity: 0.7,
            color: 'white',
            bg: 'primary.500',
            cursor: 'not-allowed',
        }
      }
    },
    active: {
      bg: 'primary.100',
      color: 'white',
      _hover:{
        bg: 'primary.100',
        opacity: 1,
         _disabled: {
            opacity: 0.7,
            color: 'white',
            bg: 'primary.500',
            cursor: 'not-allowed',
        }
      }
    },
    outline: {
      border: '1px solid',
      borderColor: 'primary.100',
      color: 'primary.100',
      _hover: {
        // border: '1px solid',
        bg: 'primary.100',
        color: 'white'
      }
    },
    outline_dg: {
      border: '1px solid',
      borderColor: 'secondary.300',
      color: 'secondary.300',
      _hover: {
        // border: '1px solid',
        bg: 'secondary.300',
        color: 'white'
      }
    },
  },
  defaultProps: {
    size: 'sm',
    variant: 'inactive',
  },
});

export default Button;
