import { Box, Divider, Flex, HStack, Icon, Image, Modal, ModalBody, ModalContent, ModalHeader, ModalOverlay, Tab, TabIndicator, TabList, TabPanel, TabPanels, Table, TableContainer, Tabs, Tbody, Td, Text, Th, Thead, Tr, VStack, useDisclosure } from '@chakra-ui/react';
import React, { useState } from 'react'
import { BackIcon, EmeryAva, NoData, Revoke, cover } from '../../../../Assets';




const Incoming = ({orderData,isLoadingData,isErrorData,tableData}) => {

    const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedUserId, setSelectedUserId] = useState(null);

    
  const statusColors = {
    1: { color: '#fff', bg: '#2E90FA' },
   
    // Add more as needed
  };


  function formatPrice(price) {
    if (typeof price === 'string') {
      // Convert the string to a number before formatting
      price = parseFloat(price);
    }
  
    if (!isNaN(price)) {
      const formattedPrice = price.toLocaleString('en-US'); // Format as a number with commas
      return `₦${formattedPrice}`; // Add Naira symbol in front of the formatted price
    }
    return price;
  }


  if (isLoadingData) {
    return <div>Loading....</div>
  }

  if (isErrorData) {
    return <div>Error Fetching Data</div>
  }

  return (
    <Box >
        <TableContainer>
            <Table variant='striped' colorScheme='tbrand'>
                <Thead >
                    <Tr >
                        <Th borderBottom={'1px solid #E8EEFF'} color={'primary.300'} >LPG Filling Plants</Th>
                        <Th borderBottom={'1px solid #E8EEFF'} color={'primary.300'}  textAlign={'center'} >Delivery Status</Th>
                        <Th borderBottom={'1px solid #E8EEFF'} color={'primary.300'}  textAlign={'center'} >Date</Th>
                        <Th borderBottom={'1px solid #E8EEFF'} color={'primary.300'}  textAlign={'center'} >LPG Transporter</Th>
                        <Th borderBottom={'1px solid #E8EEFF'} color={'primary.300'}  textAlign={'center'} >Est. Arrival Time</Th>
                        <Th borderBottom={'1px solid #E8EEFF'} color={'primary.300'}  textAlign={'center'} >Quantity</Th>
                        <Th borderBottom={'1px solid #E8EEFF'} color={'primary.300'}  textAlign={'center'} >Price</Th>
                        <Th borderBottom={'1px solid #E8EEFF'} color={'primary.300'}  textAlign={'center'} >Action</Th>
                    </Tr>
                </Thead>
                
                <Tbody  >
                    {
                        orderData.map((data) =>
                            <Tr key={data.OrderID} >
                                <Td fontWeight={'semibold'}  >
                                    <Flex alignItems={'center'} >

                                        <Icon boxSize='25px' viewBox="0 0 50 50" >
                                        <EmeryAva/>
                                        </Icon> 
                                        <Box ml={'10px'}>
                                        {data.FullName}
                                        </Box>
                                    </Flex>
                                </Td>
                                <Td textAlign={'center'} >
                                   
                                        <Box
                                            borderRadius={`full`}
                                            color={'#fff'}
                                            bg={'#2E90FA'}
                                            py={0.5}
                                            px={6}
                                            fontWeight={`semibold`}
                                            fontSize={`xs`}
                                            textAlign={'center'}
                                        >
                                            {data.OrderStatusID === 1 ? 'Incoming' : 'NIL'  }
                                        </Box>
                                       
                                    
                                </Td>
                                <Td textAlign={'center'} fontSize={`xs`} >
                                {/* {data.OrderDate} */}
                                {data.OrderDate && (
                                    <>
                                    {data.OrderDate.split('T')[0]} 
                                    {/* {data.OrderDate.split('T')[1].slice(0, 5)} */}
                                    </>
                                )}
                                </Td>
                                <Td textAlign={'center'} fontSize={`xs`} >{data.Transporter}</Td>
                                <Td textAlign={'center'} fontSize={`xs`} maxW="150px" isTruncated >{data.Destination}</Td>
                                <Td textAlign={'center'} fontSize={`xs`} >{data.Quantity}tons</Td>
                                <Td textAlign={'center'} fontWeight={'semibold'} >{formatPrice(data.TotalAmount)}</Td>
                                <Td 
                                onClick={() => {
                                    setSelectedUserId(data.OrderID);
                                    onOpen();
                                }}  
                                cursor={'pointer'} 
                                fontWeight={'bold'} 
                                fontSize={'sm'} 
                                color={'primary.200'}  
                                textAlign={'center'} 
                                >
                                View Details
                                </Td>
                            </Tr>           
                        )
                    }
                </Tbody>                
            </Table>
        </TableContainer>

        <Modal  isOpen={isOpen} onClose={onClose} size={'full'} >
        <ModalOverlay />
        <ModalContent borderRadius={`lg`} maxW="550px" h="120vh" ml="auto"   >
            {
            selectedUserId && 
            orderData.filter((data) => data.OrderID === selectedUserId) 
            .map((data) =>
                <Box key={data.OrderID} >
                <ModalHeader>
                    <Flex 
                    alignItems={'center'} 
                    justifyContent={'space-between'}
                    py={'20px'}  
                    >
                    <HStack 
                        alignItems={'center'}  
                        onClick={onClose} 
                        cursor={'pointer'}  
                        w={'40%'} 
                    >                
                        <Icon boxSize='15px' viewBox="0 2 20 20"  >
                        <BackIcon  />
                        </Icon>                       
                        <Text 
                        color={'primary.800'} 
                        fontWeight={'semibold'} 
                        fontSize={'sm'}  
                        >
                        Orders
                        </Text>                 
                    </HStack>
                    
                    </Flex>
                    <Box position={'relative'} pb={'60px'}  textAlign='center' >
                    <Box>  
                        <Image src={cover}  alt='cover' />
                    </Box>
                    <Icon 
                        position={'absolute'} 
                        top={'45px'} left={'40%'} 
                        border={'6px solid #fff'} 
                        borderRadius={'50%'}  
                        boxSize='100px' 
                        viewBox="0 0 48 49" 
                        overflow="hidden"
                        textAlign='center'
                    >
                        <EmeryAva/>
                    </Icon> 
            
                    {statusColors[data.OrderStatusID] ? (
                        <Box
                        position={'absolute'} 
                        top={'10px'} right={'2%'} 
                            borderRadius={`full`}
                            color={statusColors[data.OrderStatusID].color}
                            bg={statusColors[data.OrderStatusID].bg}
                            py={0.5}
                            px={6}
                            fontWeight={`semibold`}
                            fontSize={`xs`}
                            textAlign={'center'}
                        >
                            {data.OrderStatusID === 1 ? 'Incoming' : 'NIL'  }
                        </Box>
                        ) : (
                        <Box>NIL</Box>
                    )}
                    </Box>
                    <Text 
                    fontWeight='bold' 
                    fontSize='2xl' 
                    color='primary.100' 
                    textAlign='center'  
                    >
                    {data.FullName}
                    </Text>
                </ModalHeader>
                <ModalBody >
                    <Box position={'relative'} h={'68vh'}  >
                    <Box pb={5} >
                        <Box textAlign={'center'}  >
                        <Text fontSize={'sm'} color={'font.100'} pb={'8px'}  >
                            Customer Info
                        </Text>
                        <HStack justifyContent={'center'}  >
                            <Text 
                            color={'font.600'} 
                            fontSize={'xs'} 
                            fontWeight={'semibold'}
                            >
                            Phone Number:                               
                            </Text>
                            <Text color={'primary.300'} fontSize={'sm'}>
                            {data.PhoneNumber}
                            </Text>
                        </HStack>
                        <HStack  justifyContent={'center'} >
                            <Text 
                            color={'font.600'} 
                            fontSize={'xs'} 
                            fontWeight={'semibold'}
                            >
                                Email Address: 
                            </Text>
                            <Text 
                            color={'primary.300'} 
                            fontSize={'sm'}  
                            // isTruncated w={'50%'} 
                            >
                                {data.EmailAddress}
                            </Text>
                        </HStack>
                        
                        {/* <HStack pt={'20px'} pb={'10px'} >
                            <Text 
                            color={'font.600'} 
                            fontSize={'xs'} 
                            fontWeight={'semibold'} 
                            >
                            Full Address:
                            </Text>
                            <Text color={'primary.300'} fontSize={'sm'}  >
                            {data.HomeAddress}
                            </Text>
                        </HStack>
                        <HStack pt={'20px'} pb={'10px'} >
                            <Text 
                            color={'font.600'} 
                            fontSize={'xs'} 
                            fontWeight={'semibold'} 
                            >
                            LGA & State:
                            </Text>
                            <Text color={'primary.300'} fontSize={'sm'}  >
                            {data.LocalGovernment}, {data.CustomerState}
                            </Text>
                        </HStack> */}
                        </Box>                  
                        <Tabs variant="unstyled" bg='#fff'  borderRadius={'lg'} pt={5} >
                        <TabList p={'0 10px'}>
                            <Tab 
                                color={'border.100'} 
                                _selected={{ color: "primary.100", 
                                borderBottom: "2px solid primary.100", 
                                fontWeight: 'semibold' }}
                            >
                                Order Info
                            </Tab>             
                            <Tab 
                                color={'border.100'} 
                                _selected={{ color: "primary.100", 
                                borderBottom: "2px solid primary.100", 
                                fontWeight: 'semibold' 
                                }}
                            >
                                Delivery Info
                            </Tab>             
                            <Tab 
                                color={'border.100'}
                                _selected={{ color: "primary.100", 
                                borderBottom: "2px solid primary.100", 
                                fontWeight: 'semibold' }}
                            >
                                History
                            </Tab>             
                        </TabList>
                        <TabIndicator
                            mt="-1.5px"
                            height="2px"
                            bg="primary.100"
                            borderRadius="1px"             
                        />
                        <Divider/>
                        <TabPanels>
                            <TabPanel>                      

                            <Box py={'20px'} >
                                <Text fontSize={'sm'} color={'font.100'} pb={'8px'}  >
                                Order Information
                                </Text>
                                <HStack >
                                
                                <HStack w={'50%'} >
                                    <Text 
                                    color={'font.600'} 
                                    fontSize={'xs'} 
                                    fontWeight={'semibold'}
                                    >
                                    Date of Order:                               
                                    </Text>
                                    <Text color={'primary.300'} fontSize={'sm'}>
                                    {data.OrderDate && (
                                        <>
                                        {data.OrderDate.split('T')[0]} 
                                        {/* {data.OrderDate.split('T')[1].slice(0, 5)} */}
                                        </>
                                    )}
                                    </Text>
                                </HStack>
                                <HStack w={'50%'}  >
                                    <Text 
                                    color={'font.600'} 
                                    fontSize={'xs'} 
                                    fontWeight={'semibold'}
                                    >
                                    Time:                              
                                    </Text>
                                    <Text color={'primary.300'} fontSize={'sm'}>
                                    {data.OrderDate && (
                                        <>
                                        {/* {data.OrderDate.split('T')[0]} <br /> */}
                                        {data.OrderDate.split('T')[1].slice(0, 5)}
                                        </>
                                    )}
                                    </Text>
                                </HStack>
                                
                                </HStack>
                                <Box >
                                <HStack py={'10px'} >
                                    <Text 
                                    color={'font.600'} 
                                    fontSize={'xs'} 
                                    fontWeight={'semibold'}
                                    >
                                    Destination: 
                                    </Text>
                                    <Text color={'primary.300'} fontSize={'sm'}  isTruncated w={'50%'} >
                                    {data.Destination}
                                    </Text>
                                </HStack>
                                <HStack py={'10px'} >
                                    <Text 
                                    color={'font.600'} 
                                    fontSize={'xs'} 
                                    fontWeight={'semibold'}
                                    >
                                    LPG Transporter:
                                    </Text>
                                    <Text color={'primary.300'} fontSize={'sm'}   >
                                    {'NIL'}
                                    </Text>
                                </HStack>
                                </Box>                                    
                            </Box>
                            </TabPanel>                  
                            <TabPanel>
                            <Flex
                                alignItems={`center`}
                                justifyContent={`center`}
                                h={`100%`}
                            >
                                <VStack spacing={2}>
                                
                                <Icon boxSize='150px' viewBox="0 0 250 250" >
                                    <NoData />                      
                                </Icon> 
                                <Text
                                    fontWeight={`bold`}
                                >
                                    No Documents Added
                                </Text>
                                </VStack>
                            </Flex>
                            </TabPanel>                  
                            <TabPanel>
                            <Flex
                                alignItems={`center`}
                                justifyContent={`center`}
                                h={`100%`}
                            >
                                <VStack spacing={2}>
                                
                                <Icon boxSize='150px' viewBox="0 0 250 250" >
                                    <NoData />                      
                                </Icon> 
                                <Text
                                    fontWeight={`bold`}
                                >
                                    No Product Added
                                </Text>
                                </VStack>
                            </Flex>
                            </TabPanel>                  
                        </TabPanels>
                        </Tabs>
                    </Box>
                    <Box borderTop={'1px solid #9D9B9B'} position={'absolute'}  bottom={'0px'} w={'100%'}  >
                        <HStack pt={'20px'} pb={'5px'} justifyContent={'space-between'} >
                        <Text 
                            color={'font.600'} 
                            fontSize={'xs'} 
                        >
                            Quantity:
                        </Text>
                        <Text color={'primary.300'} fontSize={'sm'} fontWeight={'semibold'} >
                            {data.Quantity}tons
                        </Text>
                        </HStack>
                        <HStack pt={'20px'} pb={'5px'} justifyContent={'space-between'} >
                        <Text 
                            color={'font.600'} 
                            fontSize={'xs'} 
                        >
                            Total:
                        </Text>
                        <Text color={'primary.300'} fontSize={'base'} fontWeight={'semibold'} >
                            {formatPrice(data.TotalAmount)}
                        </Text>
                        </HStack>
                    </Box>

                    </Box>
                </ModalBody>          
                
                </Box>
            )
            }
        </ModalContent>
        </Modal>             
    </Box>
  )
}

export default Incoming;