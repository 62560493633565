import { Stack, HStack} from "@chakra-ui/react";
import { BasicInput } from "../../../shared/BasicInput";
import { StepInfo } from "../../../shared/StepInfo";
import PropTypes from "prop-types";
import { Chevron, Security, Terminal } from "../../../../Assets";
import { useState, useEffect } from "react";
import { useBankAccVerify } from "../../Hooks/OnbordingRequests";
import { decrypt, encrypt } from "../../../../Functions/crypt";

export function CompanyInfo(props) {

    const [account, setAccount] = useState(false)

    const bankDetails = {
        bankName: props.BankName,
        accountNumber: props.AccountNumber
    }
    const encryptedData = encrypt(bankDetails)
    const encodedString = encodeURIComponent(encryptedData)
    const bankAccQuery = useBankAccVerify(encodedString, props.AccountNumber.length)
    console.log(encodedString)
    console.log(bankAccQuery.data?.data)

    useEffect(() => {
        if (props.AccountNumber.length === 10) {
            bankAccQuery.refetch();
            setAccount(true);
        } else {
            setAccount(false);
        }
    }, [props.AccountNumber]);
 
    useEffect(() => {
        if (bankAccQuery.data && bankAccQuery.data.data) {
        const decryptedData = decrypt(bankAccQuery.data.data);
        console.log(decryptedData);
        decryptedData && props.updateData({ CorporateAccountName: decryptedData });
        }
    }, [bankAccQuery.data]);

    const text_data = [
        {
            id: 1,
            for: "BankName",
            type: "text",
            focus: { bg: 'primary.900' },
            text: "Bank Name",
            value: props.BankName,
            icon: <Chevron />
        },
        {
            id: 2,
            for: "AccountNumber",
            type: "text",
            focus: { bg: 'primary.900' },
            text: "Coperate Account Number",
            value: props.AccountNumber.replace(/\D/g,''),
            icon: <Terminal />
        },
    ]

    const text_data1 = [
        {
            id: 1,
            for: "RegistrationNumber",
            type: "text",
            focus: { bg: 'primary.900' },
            text: "Company Registration Number",
            value: props.RegistrationNumber,
            icon: <Security />
        },
        {
            id: 2,
            for: "TaxIdentificationNumber",
            type: "text",
            focus: { bg: 'primary.900' },
            text: "Tax Identification Number",
            value: props.TaxIdentificationNumber,
            icon: <Security />
        },
    ]

    return (
        <>
            <StepInfo currentStep={3} title={"Company Information"} desc={"Provide the following information:"} />
            <Stack
                spacing={8} 
                overflowY={`scroll`}
                maxH={props.height}
                scrollBehavior={`auto`}
                ps={10}
                pe={16}
                py={4}
            >
                <HStack spacing={5}>
                    {text_data.map((data) => {
                        return <BasicInput key={data.id} type={data.type} focus={data.focus} for={data.for} text={data.text} value={data.value} updateData={props.updateData} icon={data.icon} />
                    })}
                </HStack>
                {account && <BasicInput type={"text"} focus={{ bg: 'primary.900' }} for={"CorporateAccountName"} text={""} value={bankAccQuery.isLoading ? "Loading" : props.CorporateAccountName} updateData={props.updateData} icon={<Terminal />} disabled/>}
                {text_data1.map((data) => {
                    return <BasicInput key={data.id} type={data.type} focus={data.focus} for={data.for} text={data.text} value={data.value} updateData={props.updateData} icon={data.icon} />
                })}
            </Stack>
        </>
    );
}

CompanyInfo.prototype = {
    height: PropTypes.string.isRequired,
    AccountNumber: PropTypes.string.isRequired,
    RegistrationNumber: PropTypes.string.isRequired,
    TaxIdentificationNumber: PropTypes.string.isRequired,
    updateData: PropTypes.func,
}
