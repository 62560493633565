import { Box, Center, Container, Text, VStack, Icon } from "@chakra-ui/react"
import { Logo } from "../Assets"
import PropTypes from "prop-types"
import { Link, useNavigate } from "react-router-dom"
import { ChooseType } from "../Features/shared/Choose.type"

export const VendorPage = (props) => {
  const navigate = useNavigate();
  return (
    <Container
      maxW={`container.lg`}
      minH={'100vh'}
      centerContent
      justifyContent={`center`}
    >
      <Icon
        boxSize={'70px'}
        viewBox="0 0 100 100"
        mt={8}
        onClick={()=>{navigate('/')}}
      >
        <Logo />
      </Icon>
      <VStack
        mt={6}
        mb={10}
        spacing={0}
      >
        <Box>
          <Text
            color={`primary.700`}
            fontWeight={`semibold`}
          >
            Step 1
          </Text>
        </Box>
        <Box
          mt={1}
          mb={3}
        >
          <Text
            color={`primary.800`}
            fontSize={`2xl`}
            fontWeight={`bold`}
          >
            Choose A User Type
          </Text>
        </Box>
        <Box>
          <Text
            textAlign={`center`}
          >
            Select from the options below to create a profile on the platform
          </Text>
      </Box>
      </VStack>
      <ChooseType updateData={props.updateData} userType={props.userType} next={props.next} />
      <Center
        mt={16}
        mb={8}
      >
        <Text
          pe={1}
        >
          Already have an account?
        </Text>
        <Text
          color={`primary.100`}
          fontWeight={`bold`}
        >
          <Link to='/Login' >
            Log In
          </Link>
        </Text>
      </Center>
    </Container>
  ) 
}

VendorPage.proptype = {
  userType: PropTypes.oneOf(['Customers', 'Vendors', 'Delivery']),
  updateData: PropTypes.func,
  next: PropTypes.func
}