import { Box, Flex, HStack, Icon, Text, VStack } from '@chakra-ui/react'
import React, { useContext } from 'react'
import { NoData, Pen } from '../../../../Assets'
import ArrowButton from '../../../shared/Button'

export const Products = () => {



  return (
    <Box
      p={4}
      h={`100%`}
      overflowY={`hidden`}
    >
      <Text
        fontWeight={`semibold`}
        fontSize={`lg`}
        mb={1}
      >
        Request
      </Text>
      <Flex
        alignItems={`center`}
        justifyContent={`center`}
        h={`100%`}
      >
        <VStack spacing={2}>
          <Icon boxSize='100px' viewBox="0 0 250 250" >
            <NoData />
          </Icon> 
          <Text
            fontWeight={`bold`}
          >
            No Request available!!
          </Text>
        </VStack>
      </Flex>

      {/* <Box
        overflowY={`auto`}
        h={`100%`}
        sx={
            { 
            '::-webkit-scrollbar':{
                  display:'none'
              }
            }
        }
      >
        {data.map((data, i) => {
          return <Box
            key={i}
            
          >
            <HStack>
          <EmeryAva />
          <Box
            ms={1}
            w={`70%`}
          >
            <Text
              fontWeight={`semibold`}
              fontSize={`base`}
            >
              {data.name}
            </Text>
            <Flex>
              <Text
                fontSize={`xs`}
                pe={4}
              >
                {data.time}
              </Text>
              <Text
                color={`primary.200`}
                fontWeight={`semibold`}
                fontSize={`xs`}
              >
                {data.capacity}kg
              </Text>
            </Flex>
          </Box>
          <VStack>
            <Box
              borderRadius={`full`}
              color={data.color}
              bg={data.stateBg}
              py={.5}
              px={6}
              fontWeight={`semibold`}
              fontSize={`xs`}
            >
              {data.state}
            </Box>
            <Text
                  fontSize={`11px`}
                  textAlign={`center`}
            >
              {data.direction}
            </Text>
          </VStack>
        </HStack>
        <Box position='relative' py={3} w={`100%`} >
          <Divider />
        </Box>
          </Box>
        })}
      </Box> */}
    </Box>
  )
}
