import PropTypes from 'prop-types'
import { FormControl, Input, FormLabel, InputGroup, InputRightElement } from '@chakra-ui/react'
import { useState } from 'react'
import { CheckButton } from '../../Assets'

export const BasicInput = (props) => {

    const [match, setMatch] = useState(false)

    return (
        <FormControl>
            <InputGroup>
                <Input
                    type={props.type}
                    borderColor={`border.200`}
                    focusBorderColor="border.100"
                    _focus={props.focus}
                    required
                    h={12}
                    id={props.for}
                    pt={4}
                    fontSize={`sm`}
                    color={`font.100`}
                    // placeholder={props.placeholder}
                    value={props.value}
                    onKeyPress={(event) => {
                    if ( props.for === "CVV" && !/[0-9]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                    onChange={e => {
                        if (!props.isVehicle && !props.isCheckout) {
                            props.updateData({ [props.for]: e.target.value })
                        }
                        if (props.for === 'EmailAddress' && !props.isCheckout) {
                            props.updateOtp({ [props.for]: e.target.value })
                        }
                        if (props.isVehicle) {
                            props.updateData(props.data.map((item, index) => index === props.step ? { ...item, [props.for]: e.target.value } : {...item}) )
                        }

                        if (props.isCheckout) {
                            props.updateData({ PrivateDelivery: props.data.PrivateDelivery.map((item, index) => index === props.step ? { ...item, [props.for]: e.target.value } : {...item}) })
                        }
                        // if ((props.for === "Password") === (props.for === "ConfirmPassword")) {
                        //     setMatch(true)
                        //     console.log("home", props.value)
                        // } else {
                        //     setMatch(false)
                        //     console.log("run", props.value)
                        // }
                    }}
                    isDisabled={props.disabled}
                    maxLength={props.for === "CVV" ? 3 : props.for === "AccountNumber" || props.for === "CorporateAccountNumber" ? 10 : 255}
                />
                <FormLabel
                    htmlFor={props.for}
                    zIndex={'docked'}
                    fontWeight={`normal`}
                    fontSize={`xs`}
                >
                    {props.text}
                </FormLabel>
                {/* {(match && props.ConfirmPassword) &&
                    <InputRightElement h={'100%'}>
                        <CheckButton />
                    </InputRightElement>
                } */}
                <InputRightElement h={'100%'}>
                    {props.icon}
                </InputRightElement>
            </InputGroup>
        </FormControl>
    )
}

BasicInput.propTypes = {
    type: PropTypes.string.isRequired,
    focus: PropTypes.object.isRequired,
    for: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    value: PropTypes.any,
    updateData: PropTypes.func,
    updateOtp: PropTypes.func,
    icon: PropTypes.element.isRequired
}
