import { Box, Flex, Grid, GridItem, HStack, Text } from '@chakra-ui/react'
import React, { useContext } from 'react'
import AuthContext from '../../../../Components/Admin/Contexts/AdminContext';

const Vehicle = () => {

    const {vehiclesData, isLoadingVehicle, isErrorVehicle} = useContext(AuthContext);
    
    if (isLoadingVehicle) {
        return <div>Loading....</div>
      }
  
      if (isErrorVehicle) {
        return <div>Error Fetching Data</div>
      }
  
   

  return ( 
    
    <Grid templateColumns='repeat(3, 1fr)' gap={6} p={5} >
        {
            vehiclesData.map((data, i) => (
                <GridItem w='100%' bg='brand.300' borderRadius={'lg'}  key={i} p={5} >
                    <HStack alignItems={'center'} >
                        <Box>
                            <Text color={'primary.300'} fontSize={'lg'} fontWeight={'semibold'} pb={8} >
                                Vehicle {i + 1}
                            </Text>
                            <HStack alignItems={'center'} >
                                <Box bg={'primary.800'} borderRadius={'lg'} px={5} py={3} >
                                    <Text color={'brand.300'} fontWeight={'semibold'} fontSize={'sm'} >
                                        Capacity
                                    </Text>
                                    <Text fontWeight={'semibold'} fontSize={'lg'} color={'brand.300'}    >
                                        {data.Capacity}tons
                                    </Text>
                                </Box>
                                <Box  bg={'primary.800'} borderRadius={'lg'} px={5} py={3}  >
                                    <Text  color={'brand.300'} fontWeight={'semibold'} fontSize={'sm'} >
                                        Deliveries Made
                                    </Text>
                                    <Text fontWeight={'semibold'} fontSize={'lg'} color={'brand.300'}>
                                        {data.DeliveriesMade}
                                    </Text>
                                </Box>
                                
                            </HStack>
                        </Box>
                        <Box></Box>
                    </HStack>
                </GridItem>

            ))
        }
        
    </Grid>

  )
}

export default Vehicle