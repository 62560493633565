import React, { useContext, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import AuthContext from './AdminContext';

const InactivityHandler = ({ children }) => {
  const navigate = useNavigate();
  const { handleLogout } = useContext(AuthContext);
  const inactivityTimeoutRef = useRef(null);

  useEffect(() => {
    const revokeToken = async () => {
      const refreshToken = localStorage.getItem('refreshToken');
      if (refreshToken) {
        try {
          await axios.post('https://apiliveservice.myeasygas.com/api/Onboarding/revoke-token', { token: refreshToken });
        } catch (error) {
          console.error('Failed to revoke token:', error);
        }
      }
      localStorage.removeItem('token');
      localStorage.removeItem('refreshToken');
      handleLogout();
      navigate('/login');
    };

    const resetInactivityTimeout = () => {
      if (inactivityTimeoutRef.current) {
        clearTimeout(inactivityTimeoutRef.current);
      }
      inactivityTimeoutRef.current = setTimeout(revokeToken, 60 * 60 * 1000); // 30 minutes
    };

    const events = ['mousemove', 'keydown', 'click'];
    events.forEach((event) => window.addEventListener(event, resetInactivityTimeout));

    resetInactivityTimeout();

    return () => {
      if (inactivityTimeoutRef.current) {
        clearTimeout(inactivityTimeoutRef.current);
      }
      events.forEach((event) => window.removeEventListener(event, resetInactivityTimeout));
    };
  }, [navigate, handleLogout]);

  return <>{children}</>;
};

export default InactivityHandler;
