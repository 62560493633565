import { Box, Flex, HStack, Icon, Image, Text, useMediaQuery } from '@chakra-ui/react'
import React from 'react';
import Slider from 'react-slick';
import { EmeryAva, Volvo,ContactImg } from '../../../Assets';
import Arrow from '../../../Assets/Arrow.svg'
import NextArrow from '../../../Assets/nextArrowImage.svg'
import PrevArrow from '../../../Assets/prevArrowImage.svg'
import Appo from '../../../Assets/appo.svg'



function SampleNextArrow(props) {
  const { className, style, onClick} = props;
  return (
    
    <img
      src={NextArrow}
      className={className}
      alt="Next"
      onClick={onClick}
      style={{ ...style,width: "60px", height: "60px", zIndex: 1, borderRadius: "50%", background:"#049541", top: "90%", right:"4%", padding:"10px" }}
    />
   
  );
}

  
function SamplePrevArrow(props) {
  const { className, style, onClick} = props;
  return (
    
    <img
      src={PrevArrow}
      className={className}
      alt="Next"
      onClick={onClick}
      style={{ ...style,width: "60px", height: "60px", zIndex: 1, borderRadius: "50%", position: "absolute", background:"#049541", padding: "10px",
      top: "90%", left: "75%",   }}
    />
   
  );
}

function NexArrow(props) {
  const { className, style, onClick} = props;
  return (
    
    <div
        className={className}
        style={{ ...style, display: "none", background: "none" }}
    />
   
  );
}
function PreArrow(props) {
  const { className, style, onClick} = props;
  return (
    
    <div
      className={className}
      style={{ ...style, display: "none", background: "none" }}
    />
   
  );
}



const Testimony = () => {

  const [isSm] = useMediaQuery(`(max-width: 992px)`);
  const [isXs] = useMediaQuery(`(max-width: 760px)`);
  const direction = isSm ? "column" : "row";

  const settings = {
    dots: false,
    infinite: true,
    speed:1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: isSm ? <NexArrow/> : <SampleNextArrow />,
    prevArrow: isSm ? <PreArrow/> :  <SamplePrevArrow />,
    autoplay: true,
    autoplaySpeed: 2000,
    // centerMode: true,
    // centerPadding: '40px', 
   
    
  };

  return (
    <Box px={ isXs ? 10 : 20} py={20} >
      <Flex  justifyContent={'space-between'} pb={10}  >
        <Text fontWeight={'bold'} fontSize={['2xl','3.5xl','5xl']} color={'primary.100'} pt={10}>
          Become A <br /> <Text as={'span'} color={'border.300'}>Success</Text> Story.
        </Text>
        <Box 
           display="flex"             
           flexDirection="column"      
           justifyContent="flex-end"  
        >
          <Box
            // ml={'20px'} 
            w={'24%'}
            color={'border.300'}
            cursor={'pointer'}
            display={'Flex'}
            alignItems={'center'}
            pb={'5px'}
            // float={'right'}
          >
            <Image
              boxSize='15px'
              objectFit='cover'
              src={Arrow}
            />
            <Text pl={3} fontSize={'xl'}  >
              Review
            </Text>
          </Box>
          <Text color={'brand.600'} >
            We have the best review from our amazing customers 
          </Text>
        </Box>

      </Flex>

      <Flex justifyContent={'space-between'} flexDir={direction} >
        <Box w={isSm ? null : '50%'} mr={isSm ? null : 10}  >
          {isSm ? null :
            <Image
              // ml={'auto'}
              // boxSize='lg'
              // w={'85%'}
              // h={'300px'}
              objectFit='cover' src={ContactImg} 
            />           
          }
        </Box>
        
        <Box ml={isSm ? null : 10} width={isSm ? '100%' : '50%'} bg={'primary.100'} borderRadius={'3xl'} color={'#FFF'} >  
          <Slider {...settings} >
            <Box 
              py={5}
              px={ isSm ? 7 : 20} 
              height={'400px'}
              borderRadius={'lg'} 
            > 
              <Image
                // boxSize='lg'
                w={ isSm ? '5%' : '15%'}
                // h={'300px'}
                objectFit='cover' src={Appo} 

              /> 
              
              <Text
                py={3}
                mt={10}
                fontSize={['sm','base','xl']}
              > 
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Expedita quaerat velit assumenda harum quis, reiciendis earum repellat labore autem ullam dignissimos modi vero at ducimus est officiis quasi rem possimus? 
              </Text>
              <Box display={'flex'} justifyContent={'flex-end'} flexDirection={'column'} >
                <Text
                  fontSize={['sm','base','xl']}
                  fontWeight={'bold'}
                  mt={isSm ? 0 : 10}
                  float={'left'}
                >
                  Gottlieb - Altenwerth
                </Text>

              </Box>
            </Box>
            <Box 
              py={5}
              px={ isSm ? 7 : 20} 
              height={'400px'}
              borderRadius={'lg'} 
            > 
              <Image
                // boxSize='lg'
                w={ isSm ? '5%' : '15%'}
                // h={'300px'}
                objectFit='cover' src={Appo} 

              /> 
              
              <Text
                py={3}
                mt={10}
                fontSize={['sm','base','xl']}
              > 
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Expedita quaerat velit assumenda harum quis, reiciendis earum repellat labore autem ullam dignissimos modi vero at ducimus est officiis quasi rem possimus? 
              </Text>
              <Box display={'flex'} justifyContent={'flex-end'} flexDirection={'column'} >
                <Text
                  fontSize={['sm','base','xl']}
                  fontWeight={'bold'}
                  mt={isSm ? 0 : 10}
                  float={'left'}
                >
                  Gottlieb - Altenwerth
                </Text>

              </Box>
            </Box>
            <Box 
              py={5}
              px={ isSm ? 7 : 20} 
              height={'400px'}
              borderRadius={'lg'} 
            > 
              <Image
                // boxSize='lg'
                w={ isSm ? '5%' : '15%'}
                // h={'300px'}
                objectFit='cover' src={Appo} 

              /> 
              
              <Text
                py={3}
                mt={10}
                fontSize={['sm','base','xl']}
              > 
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Expedita quaerat velit assumenda harum quis, reiciendis earum repellat labore autem ullam dignissimos modi vero at ducimus est officiis quasi rem possimus? 
              </Text>
              <Box display={'flex'} justifyContent={'flex-end'} flexDirection={'column'} >
                <Text
                  fontSize={['sm','base','xl']}
                  fontWeight={'bold'}
                  mt={isSm ? 0 : 10}
                  float={'left'}
                >
                  Gottlieb - Altenwerth
                </Text>

              </Box>
            </Box>
            <Box 
              py={5}
              px={ isSm ? 7 : 20} 
              height={'400px'}
              borderRadius={'lg'} 
            > 
              <Image
                // boxSize='lg'
                w={ isSm ? '5%' : '15%'}
                // h={'300px'}
                objectFit='cover' src={Appo} 

              /> 
              
              <Text
                py={3}
                mt={10}
                fontSize={['sm','base','xl']}
              > 
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Expedita quaerat velit assumenda harum quis, reiciendis earum repellat labore autem ullam dignissimos modi vero at ducimus est officiis quasi rem possimus? 
              </Text>
              <Box display={'flex'} justifyContent={'flex-end'} flexDirection={'column'} >
                <Text
                  fontSize={['sm','base','xl']}
                  fontWeight={'bold'}
                  mt={isSm ? 0 : 10}
                  float={'left'}
                >
                  Gottlieb - Altenwerth
                </Text>

              </Box>
            </Box>
            <Box 
              py={5}
              px={ isSm ? 7 : 20} 
              height={'400px'}
              borderRadius={'lg'} 
            > 
              <Image
                // boxSize='lg'
                w={ isSm ? '5%' : '15%'}
                // h={'300px'}
                objectFit='cover' src={Appo} 

              /> 
              
              <Text
                py={3}
                mt={10}
                fontSize={['sm','base','xl']}
              > 
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Expedita quaerat velit assumenda harum quis, reiciendis earum repellat labore autem ullam dignissimos modi vero at ducimus est officiis quasi rem possimus? 
              </Text>
              <Box display={'flex'} justifyContent={'flex-end'} flexDirection={'column'} >
                <Text
                  fontSize={['sm','base','xl']}
                  fontWeight={'bold'}
                  mt={isSm ? 0 : 10}
                  float={'left'}
                >
                  Gottlieb - Altenwerth
                </Text>

              </Box>
            </Box>

           

                                
          
    
          </Slider>
        </Box>
      </Flex>
      
    </Box>
  )
}

export default Testimony