import { Box, Grid, GridItem, Icon, Modal, ModalBody, ModalContent, ModalOverlay, Text, useDisclosure } from '@chakra-ui/react'
import React, { useContext } from 'react'
import { Transactions } from "./Sections/Transactions"
import { Revenue } from "./Sections/Revenue"
import { OngoingOrders } from "./Sections/OngoingOrders"
import { Products } from "./Sections/Products"
import { NoData } from '../../../Assets'
import AuthContext from '../../../Components/Admin/Contexts/AdminContext'

export const DeliveryDashboardLayout = () => {
    const gridItems = [
        {
            area: "revenue",
            content: <Revenue /> 
        },
        {
            area: "product",
            content: <Products /> 
        },
        {
            area: "ongoing",
            content: <OngoingOrders /> 
        },
        {
            area: "transaction",
            content: <Transactions /> 
        },
    ]

    const {profile,status,profileDoc} = useContext(AuthContext);
    const { isOpen: isSecondModalOpen, onOpen: onSecondModalOpen, onClose: onSecondModalClose } = useDisclosure();

    const handleProceed = () => {
        console.log('getdi', profile ,profileDoc, status  )

        console.log(profile == "true")
        console.log(profile === "true")


        if (status === "1" && profile === "true" && profileDoc === "true") {
            console.log('Navigate to dashboard page');
            onSecondModalOpen();     
        
        } else {
            console.log('dashboard data is complete');   
        }
    };


    return (
        <Box p={5} bg={'primary.600'} h={'100vh'}  onClick={handleProceed}>
            <Grid
                templateAreas={`"product revenue revenue"
                                "transaction transaction ongoing"`}
                gridTemplateRows={'1fr 2fr'}
                gridTemplateColumns={'1fr 1fr 1fr'}
                h='100%'
                gap='5'
            >
                
                {gridItems.map((data, i) => {
                    return <GridItem key={i} bg='white' borderRadius={`lg`} area={data.area} overflowY={`auto`}>
                        {data.content}
                    </GridItem>
                })}
            </Grid>

            <Modal isOpen={isSecondModalOpen} onClose={onSecondModalClose} >
                <ModalOverlay />
                <ModalContent>
                <ModalBody>
                    <Box textAlign={'center'} py={'50px'} >
                        <Icon boxSize='150px' viewBox="0 0 250 250" >
                            <NoData />                      
                        </Icon>
                        <Text 
                            color={'primary.100'} fontSize={'base'} cursor={'pointer'} fontWeight={'bold'} textDecoration={'underline'} 
                        >
                        Profile in Review
                        </Text>                               

                    </Box>        
            
                </ModalBody>         
                </ModalContent>
            </Modal>
        </Box>
    )
}
