import { Box, Divider, Flex, Icon, Input, InputGroup, InputRightElement, Tab, TabIndicator, TabList, TabPanel, TabPanels, Tabs, Text } from "@chakra-ui/react"
import { Search } from "../../../../Assets"
import AdminTerminalValidatedTable from "./AdminTerminalValidatedTable"
import AdminTerminalRejectedTable from "./AdminTerminalRejectedTable"
import AuthContext from "../../Contexts/AdminContext"
import { useContext, useState } from "react"



const AdminTerminals = () => {

  const { validatedTerminalData,isLoadingValidated,isErrorValidated,handleRevokeId, rejectedTerminalData,isLoadingRejected,isErrorRejected} = useContext(AuthContext);
  const [searchQuery, setSearchQuery] = useState("");

  const filteredValidatedData = validatedTerminalData && validatedTerminalData.filter((data) =>
  data.BusinessName.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const filteredRejectedData = rejectedTerminalData && rejectedTerminalData.filter((data) =>
  data.BusinessName.toLowerCase().includes(searchQuery.toLowerCase())
  );

// const filteredData = filteredValidatedData.concat(filteredRejectedData);

const validatedCount = filteredValidatedData ? filteredValidatedData.length : 0;
const rejectedCount = filteredRejectedData ? filteredRejectedData.length : 0;


  return ( 
    <Box  bg='primary.600'>
      <Tabs variant="unstyled" bg='#fff' m='20px' borderRadius={'lg'} pt={5} >
        <TabList justifyContent="space-between">
          <TabList p={'0 10px'}>
            <Tab color={'border.100'} _selected={{ color: "primary.100", borderBottom: "2px solid primary.100", fontWeight: 'semibold' }}>
              <Text color='primary.300' >
                Validated Users
                <Text as='span' fontSize='xs' fontWeight='semibold' borderRadius={'1rem'}  p={'1'} color='primary.100' ml='5px' bg='primary.600' >
                    {validatedCount}
                </Text>
              </Text>     
            </Tab>
            <Tab color={'border.100'} _selected={{ color: "primary.100", borderBottom: "2px solid primary.100", fontWeight: 'semibold'}} >
            <Text color='primary.300' >
              Rejected
                <Text as='span' fontSize='xs' fontWeight='semibold' borderRadius={'1rem'}  p={'1'} color='primary.100' ml='5px' bg='primary.600' >
                    {rejectedCount}
                </Text>
            </Text>     
            </Tab>
          </TabList>
          <Flex alignItems="center" mr={'20px'}>
            <InputGroup w={'250px'}>                       
              <Input 
                placeholder='Search Users.....' 
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
              />
              <InputRightElement>
                <Icon boxSize='25px'>
                  <Search/>
                </Icon>
              </InputRightElement>
            </InputGroup>
          </Flex>
        </TabList>
        <TabIndicator
          mt="-1.5px"
          height="2px"
          bg="primary.100"
          borderRadius="1px"             
        />
        <Divider/>

        <TabPanels>
          <TabPanel>
            <AdminTerminalValidatedTable validatedTerminalData={filteredValidatedData} isLoadingValidated={isLoadingValidated} isErrorValidated={isErrorValidated} handleRevokeId={handleRevokeId} />
          </TabPanel>
          <TabPanel>
            <AdminTerminalRejectedTable  rejectedTerminalData={filteredRejectedData} isLoadingRejected={isLoadingRejected} isErrorRejected={isErrorRejected}/>
          </TabPanel>         
        </TabPanels>
      </Tabs>
    </Box>
  )
}

export default AdminTerminals