import { Box, Flex, Grid, GridItem, Icon, Text, Link } from '@chakra-ui/react'
import { Ellipse, Settings } from '../../../../Assets'
import AdminOnboardingRequest from './AdminOnboardingRequest'


const AdminOverviewLHS = () => {
  return (
      <Box
          h={'100%'}
      >
        <Grid
            templateAreas={`"leftSide rightSide"
                                "bottom bottom"`}
            templateRows='1fr 4fr'
            templateColumns='(1fr 1fr)'
            h='100%'
            // h='100%'
            gap='5'
        >
            <GridItem 
                pl='2' 
                bg='#fff'             
                borderRadius='0.5rem'
                area={'leftSide'}
                p=' 10px'
                position='relative'

            >
            
                <Icon 
                    position='absolute' 
                    top='0px' 
                    right='-18px'  
                    boxSize='70px' 
                    viewBox="0 0 100 100"   
                >
                    <Ellipse/>
                </Icon>
                <Text 
                    color='primary.300' 
                    fontSize='base'
                    fontWeight='semibold'  
                >
                    LPG Terminals/Depot
                </Text>
                <Flex alignItems='center' justifyContent='space-between' m='20px 0' >
                    <Box>
                        <Text fontSize='xs' color='primary.400' >Validated</Text>
                        <Text 
                            color='primary.300' 
                            fontSize='base' 
                            fontWeight='bold' 
                        >
                            0
                        </Text>
                    </Box>
                    <Box>
                        <Text fontSize='xs' color='primary.400' >Rejected</Text>
                        <Text 
                            color='primary.300' 
                            fontSize='base' 
                            fontWeight='bold' 
                        >
                            0
                        </Text>
                    </Box>
                </Flex>
                                
                
            </GridItem>
            <GridItem 
                pl='2' 
                bg='#fff'             
                borderRadius='0.5rem'
                area={'rightSide'}
                p=' 10px'

            >
            
                <Flex alignItems='center' justifyContent='space-between'   >
                    <Text 
                        color='primary.300' 
                        fontSize='base' 
                        fontWeight='semibold'  
                    >
                        SLA
                    </Text>
                    <Flex 
                        alignItems='center' 
                        color='primary.300' 
                        fontSize='base' 
                        fontWeight='semibold'  
                    >
                        <Icon  boxSize='15px' viewBox="0 0 20 20" >
                            <Settings/>
                        </Icon>
                        <Text fontWeight='semibold' fontSize='xs' color='primary.200'  >
                            Manage SLA
                        </Text>                        
                    </Flex>
                </Flex>
                <Text color='primary.300' fontWeight='bold' fontSize='xl' mt='20px'  >
                    Not set yet 
                </Text>
            </GridItem>
            <GridItem
                pl='2' 
                bg='#fff'             
                borderRadius='0.5rem'
                area={'bottom'}
                p=' 10px'
                height={'100%'}
                overflowY={`hidden`}

            >
                <Flex alignItems={'center'} justifyContent={'space-between'}  >
                    <Text
                        fontWeight={`semibold`}
                        fontSize={`lg`}
                        mb={1}
                        color={`primary.300`}
                    >
                        Onboarding Requests
                    </Text>
                    <Link to='/' style={{
                        color: '#049541',
                        fontWeight: '600',
                        fontSize: "0.875rem",
                        paddingRight: "10px"
                        }}
                    >
                        Show all
                    </Link>
                    
                </Flex>
                <AdminOnboardingRequest/>
            </GridItem>
           
        </Grid>

        
        {/* <Flex>
            <Box
                w='250px' 
                h='110px' 
                borderRadius='0.5rem'
                bg='#fff'
                p=' 10px'
                m='0 10px'
                position='relative'
            >
                <Icon 
                    position='absolute' 
                    top='0px' 
                    right='-18px'  
                    boxSize='70px' 
                    viewBox="0 0 100 100"   
                >
                    <Ellipse/>
                </Icon>
                <Text color='primary.300' fontSize='base' fontWeight='semibold'  >LPG Terminals/Depot</Text>
                <Flex alignItems='center' justifyContent='space-between' m='20px 0' >
                    <Box>
                        <Text fontSize='xs' color='primary.400' >Validated</Text>
                        <Text color='primary.300' fontSize='base' fontWeight='bold' >0</Text>
                    </Box>
                    <Box>
                        <Text fontSize='xs' color='primary.400' >Rejected</Text>
                        <Text color='primary.300' fontSize='base' fontWeight='bold' >0</Text>
                    </Box>
                </Flex>
                            
            </Box>
            <Box
                 w='200px' 
                 h='110px' 
                 borderRadius='0.5rem'
                 bg='#fff'
                 p='10px'
                 m='0 10px'
                 position='relative'
            >
                <Flex alignItems='center' justifyContent='space-between'   >
                    <Text color='primary.300' fontSize='base' fontWeight='semibold'  >SLA</Text>
                    <Flex alignItems='center' color='primary.300' fontSize='base' fontWeight='semibold'  >
                        <Icon  boxSize='15px' viewBox="0 0 20 20" >
                            <Settings/>
                        </Icon>
                        <Text fontWeight='semibold' fontSize='xs' color='primary.200'  >
                            Manage SLA
                        </Text>                        
                    </Flex>
                </Flex>
                <Text color='primary.300' fontWeight='bold' fontSize='xl' mt='20px'  >
                    Not set yet 
                </Text>
            </Box>
        </Flex> */}
        
    </Box>
  )
}

export default AdminOverviewLHS