import { Box, Image } from '@chakra-ui/react';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import FirstCarousel from '../../Assets/firstCarousel.png';
import SecondCarousel from '../../Assets/secondCarousel.png';
import ThirdCarousel from '../../Assets/thirdCarousel.png';


function SampleNextArrow(props) {
  const { className, style} = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "none", background: "none" }}
    />
  );
}
  
  function SamplePrevArrow(props) {
  const { className, style} = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "none", background: "none" }}
    />
  );
}

 

const LoginCarousel = () => {

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    autoplay: true,
    autoplaySpeed: 2000,
    appendDots: dots => (
      <div
        style={{
          padding: "10px",
          bottom: "-185px"
        }}
      >
        <ul style={{ margin: "0px 0px 0px 440px ", bottom: "-125px",  }}> {dots} </ul>
      </div>
    ),
    
  };

  return (
    <Box height='45vh' width='100%'> 
      <Slider {...settings}>
        <Box textAlign='center' w='55%' m='auto' >
            <Image src={FirstCarousel} width='55%' m='auto' textAlign='center' />
        </Box>
        <Box textAlign='center' w='55%' m='auto'  >
          <Image src={SecondCarousel} width='55%'  m='auto' textAlign='center'   />
        </Box>
        <Box textAlign='center' w='55%' m='auto' >
          <Image src={ThirdCarousel} width='55%'  m='auto' textAlign='center'  />
        </Box>
      </Slider>
    </Box>
  
)}

export default LoginCarousel