import { createContext, useState } from "react";

export const VendorDashboardContext = createContext()

export const VendorDashboardContextProvider = (props) => {
    const addProductData = {
        productName: "LPG",
        description: "Liquified Petroleum Gas",
        quantity: "",
        price: "",
        discount: ""
    }
    const editProductData = {
        productName: "",
        description: "",
        quantity: "",
        price: "",
        discount: "",
        productAvailability: false
    }

    const inputValidation = {
        productName: true,
        description: true,
        quantity: false,
        price: false,
    }

    const [showAddProduct, setShowAddProduct] = useState(false)
    const [showEditProduct, setShowEditProduct] = useState(false)
    const [addProduct, setAddProduct] = useState(addProductData)
    const [editProduct, setEditProduct] = useState(editProductData)
    const [validate, setValidate] = useState(inputValidation)

    // const [showReviewModal, setShowReviewModal] = useState(false)

    const funcs = {
        showAddProduct,
        setShowAddProduct,
        showEditProduct,
        setShowEditProduct,
        addProduct,
        setAddProduct,
        editProduct,
        setEditProduct,
        validate,
        setValidate,
        // showReviewModal,
        // setShowReviewModal
    }
    return (
        <VendorDashboardContext.Provider value={funcs}>
            {props.children}
        </VendorDashboardContext.Provider>
    )
}