import {  Stack, Text, Checkbox, Center, Icon, HStack, Box, VStack, Heading, Button } from "@chakra-ui/react";
import { StepInfo } from "../../../shared/StepInfo";
import PropTypes from "prop-types";
import { FileUpload } from "../../../shared/FileUpload";
import { Add, TruckGrey, Close } from "../../../../Assets";
import { BasicInput } from "../../../shared/BasicInput";
import { useContext, useState} from "react";
import AuthContext from "../../../../Components/Admin/Contexts/AdminContext";

const AddVehicleUpload = (props) => {

    const [step, setStep] = useState()

    const {handleUploadVehicles} = useContext(AuthContext);


    const handleNewVehicle = () => {
        // console.log(props.data)
        const newFileUpload = { 
            vehiclePaper: "",
            capacity: "",
            driverLicense: ""
        }

        props.updateData( [...props.data, newFileUpload]);

        const vehicleFileValidation = {
            vehiclePaper: false,
            driverLicense: false
        }

        props.setFileValidation({
            ...props.fileValidation,
            Vehicles: [...props.fileValidation.Vehicles, vehicleFileValidation]
        })

        const vehicleFiles = {
            vehiclePaper: '',
            driverLicense: ''
        }
        
        props.setFiles({
            ...props.files,
            Vehicles: [...props.files.Vehicles, vehicleFiles]
        })

        const vehicleProgress = {
            vehiclePaper: 0,
            driverLicense: 0 
        }

        props.setProgress({
            ...props.progress,
            Vehicles: [...props.progress.Vehicles, vehicleProgress]
        })

        const vehicleFileName = {
            vehiclePaper: '',
            driverLicense: ''
        }
        
        props.setFileName({
            ...props.fileName,
            Vehicles: [...props.fileName.Vehicles, vehicleFileName]
        })

        const vehicleFileSize = {
            vehiclePaper: '',
            driverLicense: ''
        }
        
        props.setFileSize({
            ...props.fileSize,
            Vehicles: [...props.fileSize.Vehicles, vehicleFileSize]
        })
    }

    const updateVehicle = (index, fieldName, value) => {
        const updatedVehicles = [...props.data];
        updatedVehicles[index] = {
            ...updatedVehicles[index],
            [fieldName]: value[fieldName],
        };
        
        props.updateData([
            ...props.data,
            updatedVehicles
        ]);
    };

  return (
    <Box>
        {props.data.map((data, i) => {
            return (
                <Box h={`100%`} w={`100%`} key={i}>
                    <HStack
                        pb={7}
                        justify={`space-between`}
                    >
                        <Heading>
                            Vehicle {i + 1}
                        </Heading>
                        <Icon
                            boxSize={'20px'}
                            onClick={() => {
                                // console.log(props.data)
                                const updatedVehicles = [...props.data];
                                const updatedFileValidation = [...props.fileValidation.Vehicles];
                                const updatedFiles = [...props.files.Vehicles];
                                const updatedProgress = [...props.progress.Vehicles];
                                const updatedFileName = [...props.fileName.Vehicles];
                                const updatedFileSize = [...props.fileSize.Vehicles];

                                // const newUpdatedVehicles = updatedVehicles.splice(i, 1);
                                // const newUpdatedFileValidation = updatedFileValidation.splice(i, 1);
                                // const newUpdatedFiles = updatedFiles.splice(i, 1);
                                // const newUpdatedProgress = updatedProgress.splice(i, 1);
                                // const newUpdatedFileName = updatedFileName.splice(i, 1);
                                // const newUpdatedFileSize = updatedFileSize.splice(i, 1);

                                updatedVehicles.splice(i, 1);
                                updatedFileValidation.splice(i, 1);
                                updatedFiles.splice(i, 1);
                                updatedProgress.splice(i, 1);
                                updatedFileName.splice(i, 1);
                                updatedFileSize.splice(i, 1);

                                props.updateData(updatedVehicles);
                                // props.setFileValidation({ Vehicles: updatedFileValidation });
                                // props.setFiles({ Vehicles: updatedFiles });
                                // props.setProgress({ Vehicles: updatedProgress });
                                // props.setFileName({ Vehicles: updatedFileName });
                                // props.setFileSize({ Vehicles: updatedFileSize });

                                // console.log("removed", i, updatedVehicles);
                                // console.log("removed", i, updatedFileValidation);
                                // console.log("removed", i, updatedFiles);
                                // console.log("removed", i, updatedProgress);
                                // console.log("removed", i, updatedFileName);
                                // console.log("removed", i, updatedFileSize);
                                // console.log(props.data)
                            }}
                        >
                            <Close />
                        </Icon>
                    </HStack>
                    <HStack gap={10} pb={10} align={`start`}>
                        <FileUpload
                            userType={"Delivery"}
                            type={"file"}
                            focus={{ bg: 'primary.900' }}
                            for={"vehiclePaper"}
                            text={"Vehicle Paper"}
                            value={data.vehiclePaper}
                            updateData={props.updateData}
                            setFileValidation={props.setFileValidation}
                            fileValidation={props.fileValidation}
                            files={props.files}
                            setFiles={props.setFiles}
                            progress={props.progress}
                            setProgress={props.setProgress}
                            isVehicle
                            step={i}
                            index={step}
                            setStep={setStep}
                            fileName={props.fileName}
                            setFileName={props.setFileName}
                            fileSize={props.fileSize}
                            setFileSize={props.setFileSize}
                            data={props.data} 
                        />
                        <BasicInput
                            type={"text"}
                            focus={{ bg: 'primary.900' }}
                            for={"capacity"}
                            text={"Vehicle Capacity"}
                            value={data.Capacity}
                            updateData={props.updateData}
                            icon={<TruckGrey />}
                            data={props.data}
                            step={i}
                            index={step} 
                            isVehicle
                        />
                    </HStack>
                    <FileUpload
                        userType={"Delivery"}
                        type={"file"}
                        focus={{ bg: 'primary.900' }}
                        for={"driverLicense"}
                        text={"Driver's License"}
                        value={data.driverLicense}
                        updateData={props.updateData}
                        setFileValidation={props.setFileValidation}
                        fileValidation={props.fileValidation}
                        files={props.files}
                        setFiles={props.setFiles}
                        progress={props.progress}
                        setProgress={props.setProgress}
                        isVehicle
                        step={i}
                        index={step}
                        setStep={setStep}
                        fileName={props.fileName}
                        setFileName={props.setFileName}
                        fileSize={props.fileSize}
                        setFileSize={props.setFileSize}
                        data={props.data} 
                    />
                </Box>


            );
        })}

                

        <HStack py={5} >
            <Icon
                onClick={handleNewVehicle}
            >
                <Add />
            </Icon>
            <Text
                fontSize={'xl'}
                fontWeight={`bold`}
            >
                Add Vehicle
            </Text>
        </HStack>

        <Box
            // ps={12}
            // pe={20}
            pt={1}
            pb={1}
        >
            <Button
                // m="60px 35px"
                // mt={'30px'}
                bg="primary.100" 
                color="#fff" 
                p="20px 56px" 
                h="48px" 
                width="100%" 
                _focus={{ boxShadow: "none" }} 
                _hover={{bg: 'primary.100',
                color: 'none',}}
                onClick={()=>{handleUploadVehicles(props.data)}}     

            >
                Save Vehicles
            </Button>
        </Box>

    </Box>
  )
}

export default AddVehicleUpload