import { useState } from 'react';
import PropTypes from 'prop-types';

const useMultistepForm = (steps) => {
    const [currentStep, setCurrentStep] = useState(0);

    const next = () => {
        setCurrentStep(i => {
            if (i >= steps.length - 1) return i
            return i + 1
        });
    };
 
    const prev = () => {
        setCurrentStep(i => {
            if (i <= 0) return i
            return i - 1
        });
    };

    const goTo = (index) => {
        setCurrentStep(index)
    };

    const step = steps[currentStep];

    return {
        next,
        prev,
        goTo,
        step,
        steps,
        currentStep,
        firstStep: (currentStep === 0),
        lastStep: (currentStep === steps.length - 2),
        otpStep: (currentStep === steps.length - 1)
    };
};


useMultistepForm.propTypes = {
    steps: PropTypes.arrayOf(PropTypes.element).isRequired,
};

export default useMultistepForm;
