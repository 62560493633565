import React from 'react'
import PropTypes from 'prop-types'
import { useToast, Text, Flex, Box, Image } from '@chakra-ui/react'
import { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ArrowButton from './Button';
import { Review } from '../../Assets';


const ReviewModal = props => {

    // const toast = useToast()
    const navigate = useNavigate()
    
    const handleCloseModal = () => {
        props.setShowReviewModal(false)
    };

    const handleFlexClick = (e) => {
        e.stopPropagation();
    };

  return (
    <Box
        position="fixed"
        top={0}
        left={0}
        width="100%"
        height="100%"
        display="flex"
        justifyContent="center"
        alignItems="center"
        background=" #0909093f"
        onClick={handleCloseModal}
        zIndex="999"
    >
        <Flex
            flexDir="column"
            minW={`400px`}
            width="50px"
            height="auto"
            borderRadius="15px"
            background="#fff"
            textAlign="center"
            p={4}
            onClick={handleFlexClick}
            align={`center`}
        >

            <Image
                src={Review}
                w={150}
            />
            <Text 
                textAlign={'center'} 
                color={'font.100'} 
                fontSize={"xs"}
                pb={3}
            >
                {/* Our team will review your request and provide feedback within 48 hours. */}
            </Text>
            
            <ArrowButton
                width={`100%`}
                isIcon={false}
                text={`Go Home`}
                variant={`active`}
                onClick={() => {
                    navigate('/Login')
                }}
            />


        </Flex>
    </Box> 
  )
}

ReviewModal.propTypes = {}

export default ReviewModal