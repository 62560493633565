import { PinInput, PinInputField, HStack, Text, VStack, useToast } from "@chakra-ui/react";
import { StepInfo } from "../../../shared/StepInfo";
import axios from "axios";
import { encrypt } from "../../../../Functions/crypt";
import { Info } from "../../../../Assets";

export default function OtpVerify(props) {

    const toast = useToast()

    const verify = async (otp) => {
        const encryptedData = encrypt(otp)
        const encodedString = encodeURIComponent(encryptedData)
        // console.log(encodedString)
        if (encryptedData) {
            try {
                const res = await axios.patch(`https://apiliveservice.myeasygas.com/api/Onboarding/verifyotp?request=${encodedString}`)
                    .then((res) => {
                        if (res.status === 200) {
                            toast({
                                position: "top",
                                icon: <Info />,
                                duration: 10000,
                                description: "Account Verified successfully",
                                status: 'success'
                            })
                        }
                    })
            } catch(err) {
                console.log(err)
                toast({
                    position: "top",
                    icon: <Info />,
                    duration: 10000,
                    description: "OTP error",
                    status: 'warning'
                })
            }
        }
    }

    const resendOTP = async () => {
        const newOTPData = {
            emailAddress: props.EmailAddress,
            phoneNumber: props.PhoneNumber
        }
        const encryptedData = encrypt(newOTPData)
        const encodedString = encodeURIComponent(encryptedData)
        if (encryptedData) {
            try {
                const res = await axios.post(`https://apiliveservice.myeasygas.com/api/Onboarding/resendotp?request=${encodedString}`)
                    .then((res) => {
                        if (res.status === 200) {
                            toast({
                                position: "top",
                                icon: <Info />,
                                duration: 10000,
                                description: "Verification link sent successfully",
                                status: 'success'
                            })
                        }
                    })
            } catch(err) {
                console.log(err)
                toast({
                    position: "top",
                    icon: <Info />,
                    duration: 10000,
                    description: "OTP error",
                    status: 'warning'
                })
            }
        }
    }

    return (
        <>
            <StepInfo currentStep={5} title={"OTP Verification"} desc={"We have sent a 6-Digit code to your submitted email address."} />
            <VStack
                alignItems={`start`}
                ps={10}
                pe={16}
                pt={8}
            >
                <Text fontWeight={`bold`} fontSize={`17px`}>{props.EmailAddress}</Text>
                <Text fontSize={`xs`}>Go back to change email</Text>
                <HStack
                    spacing={5}
                    pt={5}
                >
                    <PinInput 
                        size="lg" 
                        autoFocus 
                        placeholder="0" 
                        otp 
                        colorScheme="red" 
                        focusBorderColor="primary.400" 
                        defaultValue={props.otp}
                        onChange={(values) => {
                            props.updateOtp({ otp: values })
                        }}
                        onComplete={(value) => {
                            // verify(value)
                        }}
                    >
                        <PinInputField />
                        <PinInputField />
                        <PinInputField />
                        <PinInputField />
                        <PinInputField />
                        <PinInputField />
                    </PinInput>
                </HStack>
                <Text
                    fontWeight={`bold`}
                    pt={4}
                    onClick={resendOTP}
                >
                    Resend OTP
                </Text>
            </VStack>
        </>
    );
}
