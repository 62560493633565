import { 
    Box, 
    Flex, 
    Grid, 
    GridItem, 
    Image, 
    Stack, 
    Text 

} from '@chakra-ui/react'
import React, { useContext } from 'react'
import  EmeryAva  from '../../../../Assets/EmeryAva.svg';
import AuthContext from '../../Contexts/AdminContext';

const AdminProduct = () => {

    const { approvedProductData,isLoadingapprovedProduct,isErrorapprovedProduct} = useContext(AuthContext);
    
    
    if (isLoadingapprovedProduct) {
        return <div>Loading....</div>
      }
    
    if (isErrorapprovedProduct) { 
    return <div>Error Fetching Data</div>
    }
    
    // const TruncatedText = ({ text, limit }) => {
    // const truncatedText = text.length > limit ? `${text.substring(0, limit)}...` : text;
    
    // return <Text>{truncatedText}</Text>;
    // };

    function formatPrice(price) {
        const numericPrice = parseFloat(price.replace(/,/g, '')); // Remove existing commas if any
        if (!isNaN(numericPrice)) {
            const formattedPrice = numericPrice.toLocaleString('en-US'); // Format as a number with commas
            return `₦${formattedPrice}`; // Add Naira symbol in front of the formatted price
        }
        return price;
    }

  return (
    <Box bg='primary.600'  >
        <Box  m='20px'>
            <Text py={'20px'} fontWeight={'bold'} fontSize={'2xl'} color={'#000'}    >
                Approved Products
            </Text>
            <Grid templateColumns='repeat(3, 1fr)' gap={3} gridRowGap={5} mb={'20px'} >
            
                {
                    
                    approvedProductData.map((data) => (
                        <GridItem 
                            key={data.ProductID} 
                            p={2} w='100%' h='20rem'  
                            borderRadius={'lg'} 
                            border={'1px solid #CECDCD'} 
                            position={'relative'} 
                            bg={'#fff'}
                        >
                            <Flex alignItems={'center'} justifyContent={'space-between'}>
                            <Box>
                                <Image
                                boxSize='30px'
                                objectFit='cover' src={EmeryAva} 
                                />
                            </Box>     
                            <Text color={'primary.100'} fontSize={'xl'} fontWeight={'semibold'} >
                                {formatPrice(data.Price)}
                            </Text>
                            </Flex>                 
                            <Box>
                            <Text color={'primary.300'} fontSize={'xl'} fontWeight={'semibold'} > {data.ProductName} </Text>
                            <Text 
                                color={'primary.300'} 
                                fontSize={'sm'}  
                                // wordBreak="break-word"  
                            >
                                
                                {data.ProductDescription}
                                {/* <TruncatedText text={data.Description} limit={200} /> */}
                                
                            </Text>                
                            
                            </Box>                     
                            <Flex alignItems={'center'} justifyContent={'space-between'} position={'absolute'} bottom={'5px'} >                
                            <Stack>
                                <Text color={'primary.300'} fontSize={'xs'} fontWeight={'semibold'} >Quantity Available</Text>
                                <Text color={'primary.300'} fontSize={'lg'} fontWeight={'semibold'}  >{data.Quantity}kg </Text>
                            </Stack>                       
            
                            </Flex>                     
                        </GridItem>
                    ))
                }        
            </Grid>
        </Box>
    </Box>
  )
}

export default AdminProduct