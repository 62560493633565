import {  Box, Flex, Icon, Image, Menu, MenuButton, MenuItem, MenuList, Portal, Text } from '@chakra-ui/react';
import Logo from '../../Assets/Full-Logo.svg';

import NavbarItem from './NavbarItem';
import { EmeryAva } from '../../Assets';
import { useContext } from 'react';
import AuthContext from '../Admin/Contexts/AdminContext';
import { Link, useNavigate } from 'react-router-dom';

 




const Navbar = ({MenuNavigation,route}) => {
  
  const navigate = useNavigate();

  const {handleLogout,customerProfileData, isLoadingCustomerProfileData,isErrorCustomerProfileData} = useContext(AuthContext);
 

  
  if (isLoadingCustomerProfileData) {
    return <div>Loading....</div>
  }
  
  if (isErrorCustomerProfileData) {
    return <div>Error Fetching Data</div>
  }
  
  // console.log('Customer Profile Data:', customerProfileData);


  

  return (
    <Flex 
      flexDir="column" 
      justifyContent="space-between"  
      height="100vh" overflowY='scroll' 
      className='hide-scrollbar' 
      position={'fixed'} 
      w={'15%'}
    >
        
      <Flex flexDirection="column" >

        <Box p="20px 20px 10px">
            <Image w="80%" src={Logo} alt="Logo" />
        </Box>

        <Flex flexDir="column"  >
          {MenuNavigation.map((menu, index)=> (
            <NavbarItem items={menu} key={index} />

          ))}
        </Flex>

      </Flex>

      <Menu>
        <MenuButton>
          <Flex p="20px" alignItems="center" borderTop="1px solid #CECDCD"  >
            <Box>
              <Icon boxSize='50px' viewBox="0 0 50 50" >
                <EmeryAva/>
              </Icon> 
            </Box>

            <Box pl="10px" >
              <Text color="font.100" fontWeight="semibold">
              {customerProfileData && customerProfileData.length > 0 ? customerProfileData[0].BusinessName : 'David Hahn'}
              </Text>
              <Text color="border.100" fontWeight="normal" fontSize="12px" >
                Admin
              </Text>
            </Box>

          </Flex>
        </MenuButton>
        <Portal>
          <MenuList>
            <Link to='profile' >
              <MenuItem
                color={'primary.300'}
                fontWeight={'semibold'}
              >
                View Profile
              </MenuItem>
            </Link>
            <MenuItem
              color={'secondary.300'}
              fontWeight={'semibold'}
              onClick={() => {
                handleLogout();
                navigate(`${route}`);
              }} 
            >
              Log Out
            </MenuItem>
          </MenuList>
        </Portal>
      </Menu>

    </Flex>
  )
}

export default Navbar