import { Box, Flex, Image, Text,Container } from '@chakra-ui/react'
import Logo from "../Assets/Logo.svg";
import AuthImage from "../Assets/Auth_bg_Image.png";
import Login from '../Components/Admin/Login'

const Admin = () => {
  return (
    <Flex h="100vh">
        <Box bg="primary.100" w="50%" p="20px" >
            <Flex  align={"center"} justify={"center"}>
                <Image src={AuthImage} alt="Auth_bg_Image" w="70%"  />
            </Flex>
            <Box textAlign="center" m="24px 0">
                <Text fontSize="21px" fontWeight="bold" color="#E6E6E6">
                    Manage and Track Users and <br /> Activities Effectively
                </Text>
                {/* <Text color="#CECDCD" fontSize="15px" mt="10px">
                    Lorem ipsum dolor sit amet consectetur. Mauris et feugiat nunc <br /> condimentum tempor. Risus tincidunt diam volutpat nunc <br /> praesent praesent.
                </Text> */}
            </Box>       
        </Box>
        <Container w="50%" m="auto" p="0px 50px" > 
        
            <Box flexDirection="column"
                alignItems="center"
                textAlign="center"
                
            >
                <Flex align={"center"} justify={"center"}>
                <Image src={Logo} alt="Logo" />
                </Flex>
                    
                <Box p="20px 0px">
                    <Text fontWeight="bold" fontSize="28px" >Welcome Admin!</Text>
                    <Text fontWeight="normal" fontSize="14px" color="#3B3838" p="10px 0px"  >Enter your login details to continue.</Text>
                </Box>
            </Box>
            <Login/>
        </Container>
       
    </Flex>
  )
}

export default Admin