import { Box, Button } from '@chakra-ui/react'
import React, { useContext, useState } from 'react'
import { DocUpload } from '../../../Signup/Customer/Forms/DocUpload'
import AuthContext from '../../../../Components/Admin/Contexts/AdminContext';

const CustomerProfileDoc = () => {

    const INITIAL_DATA = {       
        CertificateOfIncorporation: "",
        OperatingLicence: "",
    };

    const [data, setData] = useState(INITIAL_DATA); 
    const {handleUploadCustomer} = useContext(AuthContext);
    // const uploadCustomerData = handleUploadCustomer(data);


    const [fileValidation, setFileValidation] = useState({
        CertificateOfIncorporation: false,
        OperatingLicence: false,
    })
    const [uploadError, setUploadError] = useState(false)
    const [files, setFiles] = useState({
        CertificateOfIncorporation: '',
        OperatingLicence: '',
    })
    const [progress, setProgress] = useState({
        CertificateOfIncorporation: 0,
        OperatingLicence: 0,
    })
    const [fileName, setFileName] = useState({
        CertificateOfIncorporation: '',
        OperatingLicence: '',
    })
    const [fileSize, setFileSize] = useState({
        CertificateOfIncorporation: '',
        OperatingLicence: '',
    })

    const height = '4px'
    const buttonVH = '10vh'
    const formHeight = `calc(100% - ${buttonVH} )`

    const updateData = (fields) => {
        setData(p => {
            return {...p, ...fields}
        })
    }

  return (
    <Box>
        <DocUpload height={formHeight} {...data} updateData={updateData} setFileValidation={setFileValidation} fileValidation={fileValidation} uploadError={uploadError} files={files} setFiles={setFiles} progress={progress} setProgress={setProgress} fileName={fileName} setFileName={setFileName} fileSize={fileSize} setFileSize={setFileSize} />
    
        <Box>
            <Button
                // m="60px 35px"
                // mt={'30px'}
                bg="primary.100" 
                color="#fff" 
                p="20px 56px" 
                h="48px" 
                width="100%" 
                _focus={{ boxShadow: "none" }} 
                _hover={{bg: 'primary.100',
                color: 'none',}}
                onClick={() => {
                    handleUploadCustomer(data)
                }}  

            >
                Save Document
            </Button>
        </Box>
    </Box>
  )
}

export default CustomerProfileDoc