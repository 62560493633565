import { 
  Box, 
  Button, 
  Flex, 
  HStack, 
  Image, 
  Text, 
  useBreakpointValue, 
  useMediaQuery
} from '@chakra-ui/react'
import React from 'react'
import { Herobg } from '../../../Assets'
import { useNavigate } from 'react-router-dom';
import Slider from 'react-slick';
import  Logo from '../../../Assets/Logo.svg'
import CircleArrow from '../../../Assets/circleArrow.svg';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';



  function SampleNextArrow(props) {
    const { className, style} = props;
    return (
      <div
        className={className}
        style={{ ...style, display: "none", background: "none" }}
      />
    );
  }
    
  function SamplePrevArrow(props) {
    const { className, style} = props;
    return (
      <div
        className={className}
        style={{ ...style, display: "none", background: "none" }}
      />
    );
  }

const Home = ({isLoadingProduct,isErrorProduct,productData}) => {

  const navigate = useNavigate();
  const slidesToShow = useBreakpointValue({ base: 1, sm: 2, md: 3, lg: 4, xl: 6 });
  const [isSm] = useMediaQuery(`(max-width: 992px)`);
  const [isXs] = useMediaQuery(`(max-width: 760px)`);
  const direction = isSm ? "column" : "row";
  const justContent = isSm ? "center" : "space-between";



  const settings = {
    dots: false,
    infinite: true,
    speed:1000,
    slidesToShow: slidesToShow,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    autoplay: true,
    autoplaySpeed: 2000,
    
    
  };

  function formatPrice(price) {
    const numericPrice = parseFloat(price.replace(/,/g, '')); // Remove existing commas if any
    if (!isNaN(numericPrice)) {
      const formattedPrice = numericPrice.toLocaleString('en-US'); // Format as a number with commas
      return `${formattedPrice}`; // Add Naira symbol in front of the formatted price
    }
    return price;
  }

  if (isLoadingProduct) {
    return <div>Loading....</div>
  }

  if (isErrorProduct) {
    return <div>Error Fetching Data</div>
  }
  
 

  return (
    <Box >

      <Box  className='story' py={5} mb={'20px'} >  
        <Slider {...settings} >
          
          {
            productData && productData.map((data) =>
              <Box 
                py={2} 
                px={2} 
                borderRadius={'2xl'} 
                border={'1px solid #E6E6E6'} 
                boxShadow={'lg'}
                key={data.ProductID}
              > 
                <HStack alignItems={'center'} justifyContent={'space-between'} >
                  <Image    
                    ml={'-10px'}          
                    boxSize='40px'
                    objectFit='cover' 
                    src={Logo} 
                  /> 
                  <Image              
                    boxSize='20px'
                    objectFit='cover' 
                    src={CircleArrow} 
                  />              
                </HStack>
                <Text
                  fontSize={'xs'}
                  color={'primary.300'}
                  // py={3}
                  textAlign={'left'}
                >
                  {data.VendorName}
                </Text>
                <Text
                  fontSize={'lg'}
                  fontWeight={'bold'}
                  color={'primary.800'}
                  // py={3}
                  textAlign={'left'}
                >
                  ₦{formatPrice(data.Price)}/MT
                </Text>
                <Text
                  fontSize={'xs'}
                  color={'font.900'}
                  // py={3}
                  textAlign={'left'}
                >
                  Last update {data.DateCreated && (
                                      <>
                                        {data.DateCreated.split('T')[0]}                                        
                                      </>
                                    )}
                </Text>
              </Box>
            )
          }
          

          {/* <Box 
            py={2} 
            px={2} 
            borderRadius={'2xl'} 
            border={'1px solid #E6E6E6'} 
            boxShadow={'lg'}
            
          > 
            <HStack alignItems={'center'} justifyContent={'space-between'} >
              <Image    
                ml={'-10px'}          
                boxSize='40px'
                objectFit='cover' 
                src={EmeryAva} 
              /> 
              <Image              
                boxSize='20px'
                objectFit='cover' 
                src={CircleArrow} 
              />              
            </HStack>
            <Text
              fontSize={'xs'}
              color={'primary.300'}
              // py={3}
              textAlign={'left'}
            >
              Hermann Gas
            </Text>
            <Text
              fontSize={'lg'}
              fontWeight={'bold'}
              color={'primary.800'}
              // py={3}
              textAlign={'left'}
            >
              ₦365.11/kg
            </Text>
            <Text
              fontSize={'xs'}
              color={'font.900'}
              // py={3}
              textAlign={'left'}
            >
              Last update 4 days ago
            </Text>
          </Box>
          <Box 
            py={2} 
            px={2} 
            borderRadius={'2xl'} 
            border={'1px solid #E6E6E6'} 
            boxShadow={'lg'}
            
          > 
            <HStack alignItems={'center'} justifyContent={'space-between'} >
              <Image    
                ml={'-10px'}          
                boxSize='40px'
                objectFit='cover' 
                src={EmeryAva} 
              /> 
              <Image              
                boxSize='20px'
                objectFit='cover' 
                src={CircleArrow} 
              />              
            </HStack>
            <Text
              fontSize={'xs'}
              color={'primary.300'}
              // py={3}
              textAlign={'left'}
            >
              Hermann Gas
            </Text>
            <Text
              fontSize={'lg'}
              fontWeight={'bold'}
              color={'primary.800'}
              // py={3}
              textAlign={'left'}
            >
              ₦365.11/kg
            </Text>
            <Text
              fontSize={'xs'}
              color={'font.900'}
              // py={3}
              textAlign={'left'}
            >
              Last update 4 days ago
            </Text>
          </Box>
          <Box 
            py={2} 
            px={2} 
            borderRadius={'2xl'} 
            border={'1px solid #E6E6E6'} 
            boxShadow={'lg'}
            
          > 
            <HStack alignItems={'center'} justifyContent={'space-between'} >
              <Image    
                ml={'-10px'}          
                boxSize='40px'
                objectFit='cover' 
                src={EmeryAva} 
              /> 
              <Image              
                boxSize='20px'
                objectFit='cover' 
                src={CircleArrow} 
              />              
            </HStack>
            <Text
              fontSize={'xs'}
              color={'primary.300'}
              // py={3}
              textAlign={'left'}
            >
              Hermann Gas
            </Text>
            <Text
              fontSize={'lg'}
              fontWeight={'bold'}
              color={'primary.800'}
              // py={3}
              textAlign={'left'}
            >
              ₦365.11/kg
            </Text>
            <Text
              fontSize={'xs'}
              color={'font.900'}
              // py={3}
              textAlign={'left'}
            >
              Last update 4 days ago
            </Text>
          </Box>
          <Box 
            py={2} 
            px={2} 
            borderRadius={'2xl'} 
            border={'1px solid #E6E6E6'} 
            boxShadow={'lg'}
            
          > 
            <HStack alignItems={'center'} justifyContent={'space-between'} >
              <Image    
                ml={'-10px'}          
                boxSize='40px'
                objectFit='cover' 
                src={EmeryAva} 
              /> 
              <Image              
                boxSize='20px'
                objectFit='cover' 
                src={CircleArrow} 
              />              
            </HStack>
            <Text
              fontSize={'xs'}
              color={'primary.300'}
              // py={3}
              textAlign={'left'}
            >
              Hermann Gas
            </Text>
            <Text
              fontSize={'lg'}
              fontWeight={'bold'}
              color={'primary.800'}
              // py={3}
              textAlign={'left'}
            >
              ₦365.11/kg
            </Text>
            <Text
              fontSize={'xs'}
              color={'font.900'}
              // py={3}
              textAlign={'left'}
            >
              Last update 4 days ago
            </Text>
          </Box>
          <Box 
            py={2} 
            px={2} 
            borderRadius={'2xl'} 
            border={'1px solid #E6E6E6'} 
            boxShadow={'lg'}
            
          > 
            <HStack alignItems={'center'} justifyContent={'space-between'} >
              <Image    
                ml={'-10px'}          
                boxSize='40px'
                objectFit='cover' 
                src={EmeryAva} 
              /> 
              <Image              
                boxSize='20px'
                objectFit='cover' 
                src={CircleArrow} 
              />              
            </HStack>
            <Text
              fontSize={'xs'}
              color={'primary.300'}
              // py={3}
              textAlign={'left'}
            >
              Hermann Gas
            </Text>
            <Text
              fontSize={'lg'}
              fontWeight={'bold'}
              color={'primary.800'}
              // py={3}
              textAlign={'left'}
            >
              ₦365.11/kg
            </Text>
            <Text
              fontSize={'xs'}
              color={'font.900'}
              // py={3}
              textAlign={'left'}
            >
              Last update 4 days ago
            </Text>
          </Box>
          <Box 
            py={2} 
            px={2} 
            borderRadius={'2xl'} 
            border={'1px solid #E6E6E6'} 
            boxShadow={'lg'}
            
          > 
            <HStack alignItems={'center'} justifyContent={'space-between'} >
              <Image    
                ml={'-10px'}          
                boxSize='40px'
                objectFit='cover' 
                src={EmeryAva} 
              /> 
              <Image              
                boxSize='20px'
                objectFit='cover' 
                src={CircleArrow} 
              />              
            </HStack>
            <Text
              fontSize={'xs'}
              color={'primary.300'}
              // py={3}
              textAlign={'left'}
            >
              Hermann Gas
            </Text>
            <Text
              fontSize={'lg'}
              fontWeight={'bold'}
              color={'primary.800'}
              // py={3}
              textAlign={'left'}
            >
              ₦365.11/kg
            </Text>
            <Text
              fontSize={'xs'}
              color={'font.900'}
              // py={3}
              textAlign={'left'}
            >
              Last update 4 days ago
            </Text>
          </Box>
          <Box 
            py={2} 
            px={2} 
            borderRadius={'2xl'} 
            border={'1px solid #E6E6E6'} 
            boxShadow={'lg'}
            
          > 
            <HStack alignItems={'center'} justifyContent={'space-between'} >
              <Image    
                ml={'-10px'}          
                boxSize='40px'
                objectFit='cover' 
                src={EmeryAva} 
              /> 
              <Image              
                boxSize='20px'
                objectFit='cover' 
                src={CircleArrow} 
              />              
            </HStack>
            <Text
              fontSize={'xs'}
              color={'primary.300'}
              // py={3}
              textAlign={'left'}
            >
              Hermann Gas
            </Text>
            <Text
              fontSize={'lg'}
              fontWeight={'bold'}
              color={'primary.800'}
              // py={3}
              textAlign={'left'}
            >
              ₦365.11/kg
            </Text>
            <Text
              fontSize={'xs'}
              color={'font.900'}
              // py={3}
              textAlign={'left'}
            >
              Last update 4 days ago
            </Text>
          </Box>        */}
   
        </Slider>
      </Box>
     
      <Box pl={ isXs ? 10 : 20} pr={ isXs ? 10 : 20} >
        <Flex 
          flexDir={direction} 
          py={'20px'} 
          alignItems={'center'} 
          justifyContent={justContent} 
        >
          <Text 
            fontWeight={'extrabold'} 
            fontSize={['2xl','4xl','7xl']} 
            color={'brand.100'} 
            lineHeight={['25px','40px','80px']} 
            textAlign={ isSm ? 'center' : 'left'}              
          >
            Unlocking Efficiency <br /> In LPG Supply
          </Text>

          <Box w={isSm ? null : '30%'} textAlign={isSm ? 'center' : null} >
            <Text color={'primary.400'} fontSize={['sm', 'lg','xl']} pt={ isSm ? '37px' : '16px'} pb={'36px'}    >
              EasyGas is your all-in-one solution for streamlined LPG experiences. 
            </Text>

            <Button
              bg="primary.100" 
              color="#fff" 
              p="25px 30px" 
              h="48px" 
              borderRadius={'2xl'}
              // width="40%" 
              // mr={'auto'}
              _focus={{ boxShadow: "none" }} 
              _hover={{bg: 'primary.100',
              color: 'none',}}
              onClick={() => navigate('/user_type') }
            >
              Get Started
            </Button>      

          </Box>
        
        </Flex>    

        <Box py={'50px'} >
          {/* <Image
            ml={'auto'}
            // boxSize='xl'
            objectFit='cover' src={Herobg} 
          /> */}
           <LazyLoadImage
            //  objectFit='cover'
            src={Herobg} // use normal <img> attributes as props
            effect="blur"
            // width={image.width} 
            />
        </Box>
      </Box>

       
   
    </Box>
  )
}

export default Home