import { 
    Box, 
    Divider, 
    Flex, 
    Icon, 
    Input, 
    InputGroup, 
    InputRightElement, 
    Tab, 
    TabIndicator, 
    TabList, 
    TabPanel, 
    TabPanels, 
    Tabs, 
    Text
} from '@chakra-ui/react'
import React, { useContext, useState } from 'react'
import { Search } from '../../../../Assets'
import AdminTransporterValidatedTable from './AdminTransporterValidatedTable'
import AdminTransporterRejectedTable from './AdminTransporterRejectedTable'
import AuthContext from '../../Contexts/AdminContext'

const AdminTransporter = () => {

  
  const { validatedTruckerData,isLoadingTrucker,isErrorTrucker,rejectedTruckerData,isLoadingRejectedTrucker,isErrorRejectedTrucker,handleRevokeId} = useContext(AuthContext);
  const [searchQuery, setSearchQuery] = useState("");

  const filteredValidatedData = validatedTruckerData && validatedTruckerData.filter((data) =>
    data.BusinessName.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const filteredRejectedData = rejectedTruckerData && rejectedTruckerData.filter((data) =>
    data.BusinessName.toLowerCase().includes(searchQuery.toLowerCase())
  );

const validatedCount = filteredValidatedData ? filteredValidatedData.length : 0;
const rejectedCount = filteredRejectedData ? filteredRejectedData.length : 0;


  return (
    <Box  bg='primary.600'>
      <Tabs variant="unstyled" bg='#fff' m='20px' borderRadius={'lg'} pt={5} >
        <TabList justifyContent="space-between">
          <TabList p={'0 10px'}>
            <Tab color={'border.100'} _selected={{ color: "primary.100", borderBottom: "2px solid primary.100", fontWeight: 'semibold' }}>
              <Text color='primary.300'>
                Validated Users
              <Text as='span' fontSize='xs' fontWeight='semibold' borderRadius={'1rem'}  p={'1'} color='primary.100' ml='5px' bg='primary.600' >
                  {validatedCount}
              </Text>
              </Text>  
            </Tab>
            <Tab color={'border.100'} _selected={{ color: "primary.100", borderBottom: "2px solid primary.100", fontWeight: 'semibold'}} >
            <Text color='primary.300' >
              Rejected
              <Text as='span' fontSize='xs' fontWeight='semibold' borderRadius={'1rem'}  p={'1'} color='primary.100' ml='5px' bg='primary.600' >
                  {rejectedCount}
              </Text>
            </Text>   
            </Tab>
          </TabList>
          <Flex alignItems="center" mr={'20px'}>
            <InputGroup w={'250px'}>                       
              <Input placeholder='Search Users.....' />
              <InputRightElement>
                <Icon boxSize='25px'>
                  <Search
                    placeholder='Search Users.....' 
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                  />
                </Icon>
              </InputRightElement>
            </InputGroup>
          </Flex>
        </TabList>
        <TabIndicator
          mt="-1.5px"
          height="2px"
          bg="primary.100"
          borderRadius="1px"             
        />
        <Divider/>

        <TabPanels>
          <TabPanel>
            <AdminTransporterValidatedTable  validatedTruckerData ={filteredValidatedData} isLoadingTrucker={isLoadingTrucker} isErrorTrucker={isErrorTrucker} handleRevokeId={handleRevokeId} />
          </TabPanel>
          <TabPanel>
            <AdminTransporterRejectedTable rejectedTruckerData={filteredRejectedData} isLoadingRejectedTrucker={isLoadingRejectedTrucker} isErrorRejectedTrucker={isErrorRejectedTrucker}  />
          </TabPanel>         
        </TabPanels>
      </Tabs>
    </Box>
  )
}

export default AdminTransporter