import { 
  Box, 
  Button, 
  ButtonGroup, 
  Divider, 
  Flex, 
  Grid, 
  GridItem, 
  HStack, 
  Icon, 
  Modal, 
  ModalBody, 
  ModalContent, 
  ModalHeader, 
  ModalOverlay, 
  Stack, 
  Tab, 
  TabIndicator, 
  TabList, 
  TabPanel, 
  TabPanels, 
  Tabs, 
  Text, 
  Textarea, 
  VStack, 
  useDisclosure 
} from '@chakra-ui/react'
import React, { useContext, useState } from 'react'
import AuthContext from '../../Contexts/AdminContext';
import { BackIcon, CheckBox, CheckButton, EmeryAva, NoData } from '../../../../Assets';
import { Cloudinary } from "@cloudinary/url-gen";
import {AdvancedImage} from '@cloudinary/react';
import {fill} from "@cloudinary/url-gen/actions/resize";


const AdminBoardingRequest = () => {
 

  
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { isOpen: isSecondModalOpen, onOpen: onSecondModalOpen, onClose: onSecondModalClose } = useDisclosure();
  const { isOpen: isThirdModalOpen, onOpen: onThirdModalOpen, onClose: onThirdModalClose } = useDisclosure();
  const { isOpen: isFourthModalOpen, onOpen: onFourthModalOpen, onClose: onFourthModalClose } = useDisclosure();
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [rejectReason, setRejectReason] = useState('');
  const [selectedImage, setSelectedImage] = useState(null);

  
  
  const { adminBoardingData,isLoadingBoarding,isErrorBoarding,handleSubmitId,handleRejectId} = useContext(AuthContext);
 
  const cld = new Cloudinary({
    cloud: {
      cloudName: 'wordy'
    }
  });

  const rejectData = {
    customerId: selectedUserId,
    reason: rejectReason
  };

  // const handleValidationAndCloseModal = async () => {
  //   try {
  //     const response = await handleSubmitId(selectedUserId);
  //     if (response && response.data) {
  //       // If successful, close the modal(s)
  //       onSecondModalClose();
  //       onClose();
  //     } else {
  //       // Handle validation failure or show an error message
  //     }
  //   } catch (error) {
  //     // Handle any unexpected errors during validation
  //     console.error('Error during validation:', error);
  //     // Optionally display an error message to the user
  //   }
  // };

  if (isLoadingBoarding) {
    return <div>Loading....</div>
  }

  if (isErrorBoarding) {
    return <div>Error Fetching Data</div>
  }

  return (
    <Box>
      <Grid templateColumns='repeat(3, 1fr)' gap={3} gridRowGap={5}>
        {
          adminBoardingData.map((data,CustomerId) => (
            <GridItem key={CustomerId} p={2} w='100%' h='18rem'  borderRadius={'lg'} border={'1px solid #CECDCD'} >
              <Text color={'font.100'} fontSize={'sm'} pb={2} >
                User Information
              </Text>
              <Flex alignItems={'center'} justifyContent={'space-between'}>
                <Box>
                    <Stack>
                      <Text color={'font.600'} fontSize={'xs'} fontWeight={'semibold'}  >Business Name:</Text>
                      <Text color={'primary.300'} fontSize={'sm'} isTruncated w={'70%'}> {data.RoleId === 1 ? data.FullName : data.BusinessName} </Text>
                      <Text color={'font.600'} fontSize={'xs'} fontWeight={'semibold'} >Phone Number:</Text>
                      <Text color={'primary.300'} fontSize={'sm'} >{data.PhoneNumber} </Text>
                    </Stack>
                </Box>
                <Box>
                  <Stack>
                    <Text color={'font.600'} fontSize={'xs'} fontWeight={'semibold'}  >User Type:</Text>
                    <Text color={'primary.300'} fontSize={'sm'} >                      
                      {data.RoleId === 3 ? 'LPG Terminals/Depot' : data.RoleId === 4 ? 'LPG Transporter' : 'LPG Filling Plant'}
                    </Text>
                    <Text color={'font.600'} fontSize={'xs'} fontWeight={'semibold'} >Email Address:</Text>
                    <Text color={'primary.300'} fontSize={'sm'} >{data.EmailAddress}</Text>
                  </Stack>
                </Box>
              </Flex>
              <Box pt={2}>
                <Text color={'font.600'} fontSize={'xs'} fontWeight={'semibold'} >Full Adress:</Text>
                <Text color={'primary.300'} fontSize={'sm'} >{data.HomeAddress}</Text>
              </Box>
              <Box mt={'25px'}>
                {/* <Text pb={3} color={'font.700'} fontWeight={'semibold'} fontSize={'sm'} >
                  Expand for more details...
                </Text> */}

                <Button 
                  onClick={() => {
                    setSelectedUserId(data.CustomerId);
                    onOpen();
                  }}  
                  w={'100%'}  
                  bg='primary.100' 
                  color='#fff' 
                  fontWeight='bold' 
                  fontSize='sm'    
                >
                  View Details
                </Button>
              </Box>
            </GridItem>
          ))
        }
        
      </Grid>

      <Modal isOpen={isOpen} onClose={onClose} size={'full'}  >
        <ModalOverlay />
        <ModalContent borderRadius={`lg`} maxW="550px" h="150vh" ml="auto"    >
          {
            selectedUserId && 
            adminBoardingData.filter((data) => data.CustomerId === selectedUserId )
            .map((data) => 
            <Box key={data.CustomerId}>
              <ModalHeader >
                <Flex alignItems={'center'} justifyContent={'space-between'} >
                  <HStack alignItems={'center'}  >
                
                    <Icon boxSize='15px' viewBox="0 2 20 20" onClick={onClose} cursor={'pointer'} >
                      <BackIcon  />
                    </Icon> 
                    
                    <Text color={'primary.800'} fontWeight={'semibold'} fontSize={'sm'}  >
                      Requests
                    </Text>
                  </HStack>
                  <HStack>
                    <Text fontSize={'0.625rem'} fontWeight={'semibold'} color={'fonts.600'}>
                      Created on:
                    </Text>
                    <Text fontSize={'xs'} fontWeight={'normal'}>
                      Mon Sep 23 2097 05:04pm
                    </Text>
                  </HStack>
                </Flex>
                <Text fontSize='2xl'color='primary.100' fontWeight={'bold'} align={'left'} pt={'30px'}>
                  {data.RoleId === 1 ? data.FullName : data.BusinessName}
                </Text>
              </ModalHeader>
              <ModalBody position={'relative'} >
                <Box h={'130vh'} >
                  <Box>
                    <Text fontSize={'sm'} color={'font.100'} pb={'8px'}  >
                        Basic Details
                    </Text>
                    <Flex alignItems={'center'} justifyContent={'space-between'} >
                      <HStack w={'50%'} >
                        <Text 
                          color={'font.600'} 
                          fontSize={'xs'} 
                          fontWeight={'semibold'}
                        >
                          Phone Number:                               
                        </Text>
                        <Text color={'primary.300'} fontSize={'sm'}>
                          {data.PhoneNumber}
                        </Text>
                      </HStack>
                      <HStack w={'50%'} >
                        <Text 
                            color={'font.600'} 
                            fontSize={'xs'} 
                            fontWeight={'semibold'}
                        >
                            Email Address: 
                        </Text>
                        <Text color={'primary.300'} fontSize={'sm'}  isTruncated w={'50%'} >
                            {data.EmailAddress}
                        </Text>
                      </HStack>
                    </Flex>
                    <HStack pt={'20px'} pb={'10px'} >
                      <Text 
                        color={'font.600'} 
                        fontSize={'xs'} 
                        fontWeight={'semibold'} 
                      >
                        Full Address:
                      </Text>
                      <Text color={'primary.300'} fontSize={'sm'}  >
                        {data.HomeAddress}
                      </Text>
                    </HStack>
                    <HStack pt={'20px'} pb={'10px'} >
                      <Text 
                        color={'font.600'} 
                        fontSize={'xs'} 
                        fontWeight={'semibold'} 
                      >
                        LGA & State:
                      </Text>
                      <Text color={'primary.300'} fontSize={'sm'}  >
                        {data.LocalGovernment},{data.CustomerState}
                      </Text>
                    </HStack>
                  </Box>
                  <Box py={'20px'} >
                    <Text fontSize={'sm'} color={'font.100'} pb={'8px'}  >
                      Company Details
                    </Text>
                    <Flex alignItems={'center'} justifyContent={'space-between'}>
                      <Box w={'50%'}>
                        <HStack py={'10px'} >
                          <Text 
                            color={'font.600'} 
                            fontSize={'xs'} 
                            fontWeight={'semibold'}
                          >
                          Bank Name:                               
                          </Text>
                          <Text color={'primary.300'} fontSize={'sm'}>
                            {data.AccountBank}
                          </Text>
                        </HStack>
                        <HStack py={'10px'}  >
                          <Text 
                            color={'font.600'} 
                            fontSize={'xs'} 
                            fontWeight={'semibold'}
                          >
                          Reg. Number:                              
                          </Text>
                          <Text color={'primary.300'} fontSize={'sm'}>
                            {data.RegistrationNumber}
                          </Text>
                        </HStack>
                        
                      </Box>
                      <Box w={'50%'} mr={'auto'}  >
                        <HStack py={'10px'}  >
                          <Text 
                            color={'font.600'} 
                            fontSize={'xs'} 
                            fontWeight={'semibold'}
                          >
                            Bank Account: 
                          </Text>
                          <Text color={'primary.300'} fontSize={'sm'}  isTruncated w={'50%'} >
                            {data.AccountNumber}
                          </Text>
                        </HStack>
                        <HStack py={'10px'} >
                          <Text 
                            color={'font.600'} 
                            fontSize={'xs'} 
                            fontWeight={'semibold'}
                          >
                          Tax Number:
                          </Text>
                          <Text color={'primary.300'} fontSize={'sm'}   >
                            {data.TaxIdentificationNumber}
                          </Text>
                        </HStack>
                      </Box>
                    </Flex>             
                  </Box>
                  <Tabs variant="unstyled" bg='#fff'  borderRadius={'lg'} pt={2} >
                    <TabList p={'0 10px'}>
                        <Tab color={'border.100'} _selected={{ color: "primary.100", borderBottom: "2px solid primary.100", fontWeight: 'semibold' }}>
                          Documents
                        </Tab>             
                    </TabList>
                    <TabIndicator
                      mt="-1.5px"
                      height="2px"
                      bg="primary.100"
                      borderRadius="1px"             
                    />
                    <Divider/>
                    <TabPanels>
                      <TabPanel>
                        <Flex
                          alignItems={`center`}
                          direction={'column'}
                          // justifyContent={`center`}
                          h={`100%`}
                        >
                          <HStack alignItems={`center`}>
                            
                            <VStack>
                              <Text fontWeight={'semibold'} color={'primary.300'} textAlign={'center'}  >
                                Certificate Of Incorporation
                              </Text>
                              <Box 
                                as="button"
                                key={`image-${data.CustomerId}-1`}
                                onClick={() => {
                                  setSelectedImage(data.CertificateOfIncorporation);
                                  onFourthModalOpen();
                                }}
                              >
                                {data.CertificateOfIncorporation && (
                                  <AdvancedImage
                                    cldImg={cld.image(data.CertificateOfIncorporation.split('/').pop()).resize(fill().width(150).height(150))}
                                  />
                                )}

                              </Box>
                            </VStack>

                            {
                              // data.RoleId === 3 ?  'LPG Terminals/Depot' : 'LPG Transporter'
                              data.RoleId === 4 ?                                  
                                <VStack>
                                  <Text fontWeight={'semibold'} color={'primary.300'} textAlign={'center'} >
                                    Certificate Of Registration
                                  </Text>
                                  <Box 
                                    px={3}
                                    as="button"
                                    key={`image-${data.CustomerId}-2`}
                                    onClick={() => {
                                      setSelectedImage(data.CertificateOfRegistration);
                                      onFourthModalOpen();
                                    }}
                                  >
                                    {data.CertificateOfRegistration && (
                                      <AdvancedImage
                                        cldImg={cld.image(data.CertificateOfRegistration.split('/').pop()).resize(fill().width(150).height(150))}
                                      />
                                    )}
  
                                  </Box>
                                </VStack>
                              : null
                            }

                            {
                              // data.RoleId === 3 ?  'LPG Terminals/Depot' : 'LPG Transporter'
                              data.RoleId === 1 ?                                  
                                <VStack>
                                  <Text fontWeight={'semibold'} color={'primary.300'} textAlign={'center'} >
                                    Operating Licence
                                  </Text>
                                  <Box 
                                    px={3}
                                    as="button"
                                    key={`image-${data.CustomerId}-8`}
                                    onClick={() => {
                                      setSelectedImage(data.OperatingLicence);
                                      onFourthModalOpen();
                                    }}
                                  >
                                    {data.OperatingLicence && (
                                      <AdvancedImage
                                        cldImg={cld.image(data.OperatingLicence.split('/').pop()).resize(fill().width(150).height(150))}
                                      />
                                    )}
  
                                  </Box>
                                </VStack>
                              : null
                            }
                            


                            {
                              
                              // data.RoleId === 3 ?  'LPG Terminals/Depot' : 'LPG Transporter'
                              data.RoleId === 3 ?  
                              <VStack>
                                <Text fontWeight={'semibold'} color={'primary.300'} textAlign={'center'}  >
                                  Form 2A & Form 7A 
                                </Text>
                                <Box
                                  as="button"
                                  key={`image-${data.CustomerId}-3`}
                                  onClick={() => {
                                    setSelectedImage(data.Form);
                                    onFourthModalOpen();
                                  }}
                                >
                                  {data.Form && (
                                    <AdvancedImage
                                      cldImg={cld.image(data.Form.split('/').pop()).resize(fill().width(150).height(150))}
                                    />
                                  )}

                                </Box>

                              </VStack>
                              : null
                              
                            }

                            {
                              
                              // data.RoleId === 3 ?  'LPG Terminals/Depot' : 'LPG Transporter'
                              data.RoleId === 3 ?  
                              <VStack>
                                <Text fontWeight={'semibold'} color={'primary.300'} textAlign={'center'}  >
                                Bank Reference Letter
                                </Text>
                                <Box
                                  as="button"
                                  key={`image-${data.CustomerId}-4`}
                                  onClick={() => {
                                    setSelectedImage(data.OperatingLicence);
                                    onFourthModalOpen();
                                  }}
                                >
                                  {data.BankReferenceLetter && (
                                    <AdvancedImage
                                      cldImg={cld.image(data.BankReferenceLetter.split('/').pop()).resize(fill().width(150).height(150))}
                                    />
                                  )}

                                </Box>

                              </VStack>
                              : null
                              
                            }
                            

                          </HStack>

                          <HStack alignItems={'center'} mt={'15px'} > 
                            
                            {
                              // data.RoleId === 3 ?  'LPG Terminals/Depot' : 'LPG Transporter'
                              data.RoleId === 4 ?  
                              <VStack>
                                <Text fontWeight={'semibold'} color={'primary.300'} textAlign={'center'}  >
                                  Operating Licence
                                </Text>
                                <Box
                                  as="button"
                                  key={`image-${data.CustomerId}-5`}
                                  onClick={() => {
                                    setSelectedImage(data.HaulageLicence);
                                    onFourthModalOpen();
                                  }}
                                >
                                  {data.HaulageLicence && (
                                    <AdvancedImage
                                      cldImg={cld.image(data.HaulageLicence.split('/').pop()).resize(fill().width(150).height(150))}
                                    />
                                  )}

                                </Box>

                              </VStack>
                              : null

                            }

                            {
                              
                              // data.RoleId === 3 ?  'LPG Terminals/Depot' : 'LPG Transporter'
                              data.RoleId === 4 ?  
                              <VStack>
                                <Text fontWeight={'semibold'} color={'primary.300'} textAlign={'center'}  >
                                  Transit Insurance
                                </Text>
                                <Box
                                as="button"
                                key={`image-${data.CustomerId}-6`}
                                onClick={() => {
                                  setSelectedImage(data.TransitInsurance);
                                  onFourthModalOpen();
                                }}
                                >
                                  {data.TransitInsurance && (
                                    <AdvancedImage
                                      cldImg={cld.image(data.TransitInsurance.split('/').pop()).resize(fill().width(150).height(150))}
                                    />
                                  )}

                                </Box>
                              </VStack>                            
                              : null
                            }

                            {
                              
                              // data.RoleId === 3 ?  'LPG Terminals/Depot' : 'LPG Transporter'
                              data.RoleId === 3 ?  
                              <VStack>
                                <Text fontWeight={'semibold'} color={'primary.300'} textAlign={'center'}  >
                                  Operating Licence
                                </Text>
                                <Box
                                  as="button"
                                  key={`image-${data.CustomerId}-7`}
                                  onClick={() => {
                                    setSelectedImage(data.OperatingLicence);
                                    onFourthModalOpen();
                                  }}
                                >
                                  {data.OperatingLicence && (
                                    <AdvancedImage
                                      cldImg={cld.image(data.OperatingLicence.split('/').pop()).resize(fill().width(150).height(150))}
                                    />
                                  )}

                                </Box>

                              </VStack>
                              : null
                              
                            }

                            {
                              
                              // data.RoleId === 3 ?  'LPG Terminals/Depot' : 'LPG Transporter'
                              data.RoleId === 3 ?  
                              <VStack>
                                <Text fontWeight={'semibold'} color={'primary.300'} textAlign={'center'}  >
                                  SignedAgreement
                                </Text>
                                <Box
                                  as="button"
                                  key={`image-${data.CustomerId}-8`}
                                  onClick={() => {
                                    setSelectedImage(data.OperatingLicence);
                                    onFourthModalOpen();
                                  }}
                                >
                                  {data.SignedAgreement && (
                                    <AdvancedImage
                                      cldImg={cld.image(data.SignedAgreement.split('/').pop()).resize(fill().width(150).height(150))}
                                    />
                                  )}

                                </Box>

                              </VStack>
                              : null
                              
                            }
                            


                          </HStack>

                        </Flex>
                      </TabPanel>                  
                    </TabPanels>
                  </Tabs>
                  <Box>
                </Box>
                <ButtonGroup gap='10' position={'absolute'} bottom={'15px'}>
                  <Button 
                    
                    bg="transparent" 
                    border="1px solid #F42529"
                    color="secondary.300" 
                    p="20px 56px" 
                    h="48px" 
                    width="100%" 
                    _focus={{ boxShadow: "none" }} 
                    _hover={{bg: 'none',
                    color: 'none',}}
                    onClick={onThirdModalOpen}
                  >
                    Reject User
                  </Button>
                  <Button 
                    bg="primary.100" 
                    color="#fff" 
                    p="20px 56px" 
                    h="48px" 
                    width="150%" 
                    _focus={{ boxShadow: "none" }} 
                    _hover={{bg: 'primary.100',
                    color: 'none',}}
                    onClick={onSecondModalOpen}
                  >
                    Validate User
                  </Button>
                </ButtonGroup>
                </Box>
              </ModalBody>            

            </Box>
          )}
        </ModalContent>
      </Modal>

      {/* Validated Modal */}

      <Modal isOpen={isSecondModalOpen} onClose={onSecondModalClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalBody>
            <Box textAlign={'center'} py={'20px'} >
              <Icon  boxSize='50px'   viewBox="0 0 50 50" >
                <CheckButton/>
              </Icon> 
              <Text color={'border.500'} fontSize={'base'} fontWeight={'700'} >
                Validate User
              </Text>
              <Text color={'font.200'} fontSize={'xs'} >
                Are you sure you want to approve this user?
              </Text>
            </Box>

            <ButtonGroup gap='10' my={'10px'} >
              <Button 
                
                bg="transparent" 
                color="font.100" 
                p="20px 36px" 
                h="48px" 
                width="50%" 
                _focus={{ boxShadow: "none" }} 
                _hover={{bg: 'none',
                color: 'none',}}
                onClick={onSecondModalClose}
              >
                Cancel
              </Button>
              <Button 
                bg="primary.100" 
                color="#fff" 
                p="20px 56px" 
                h="48px" 
                width="80%" 
                _focus={{ boxShadow: "none" }} 
                _hover={{bg: 'primary.100',
                color: 'none',}}
                onClick={() => {handleSubmitId(selectedUserId);
                  onSecondModalClose();
                  onClose();
                }}
              >
                Proceed
              </Button>
            </ButtonGroup>

            {/* <Button colorScheme='blue' mr={3} onClick={onOpen}>
              Close
            </Button> */}

          </ModalBody>

         
        </ModalContent>
      </Modal>

      {/* REJECTED MODAL */}

      <Modal isOpen={isThirdModalOpen} onClose={onThirdModalClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <Box textAlign={'center'} pt={'20px'} >             
              <Text color={'border.500'} fontSize={'xl'} fontWeight={'700'} >
                Reject User
              </Text>
              <Text color={'font.200'} fontSize={'xs'} fontWeight={'normal'} >
                Tell us why you are rejecting this user.
              </Text>
            </Box>
          </ModalHeader>
          <ModalBody>
            <Textarea 
              placeholder='Reason' 
              border={'1px solid #B5B4B4'} 
              h={'10rem'} 
              onChange={(e) => setRejectReason(e.target.value)}
            />
                       
            <Button 
              my={'20px'}
              bg="primary.100" 
              color="#fff" 
              p="20px 56px" 
              h="48px" 
              width="100%" 
              _focus={{ boxShadow: "none" }} 
              _hover={{bg: 'primary.100',
              color: 'none',}}
              onClick={() =>{ 
                handleRejectId(rejectData);
                onThirdModalClose();
                onClose();
              }}
            >
              Reject User
            </Button>  
            {/* <Button colorScheme='blue' mr={3} onClick={onOpen}>
              Close
            </Button> */}

          </ModalBody>         
        </ModalContent>
      </Modal>

       {/* IMAGE MODAL */}

      <Modal isOpen={isFourthModalOpen} onClose={onFourthModalClose} size={'full'}>
        <ModalOverlay />
        <ModalContent borderRadius={`lg`} maxW="550px" h="100vh" ml="auto">
          {selectedImage && (
            <Box>
               <Flex alignItems={'center'} justifyContent={'space-between'} pt={10} px={8}  >
                  <HStack alignItems={'center'}  >
                
                    <Icon boxSize='20px' viewBox="0 2 20 20" onClick={onFourthModalClose} cursor={'pointer'} >
                      <BackIcon  />
                    </Icon> 
                    
                    <Text color={'primary.800'} fontWeight={'semibold'} fontSize={'lg'}  >
                      Go Back
                    </Text>
                  </HStack>
                  <HStack>
                    <Text fontSize={'0.625rem'} fontWeight={'semibold'} color={'fonts.600'}>
                      Created on:
                    </Text>
                    <Text fontSize={'xs'} fontWeight={'normal'}>
                      Mon Sep 23 2097 05:04pm
                    </Text>
                  </HStack>
                </Flex>
              <ModalBody>
                <Box textAlign={'center'} >
                  {/* THE WIDTH AND HEIGHT CAN BE 800 BUT LARGER IMAGE WILL RESULT WITH LONGER LOADING TIME */}
                  <AdvancedImage
                    cldImg={cld.image(selectedImage.split('/').pop()).resize(fill().width(500).height(500))}
                  />
                
                </Box>
              </ModalBody>
            </Box>
          )}
        </ModalContent>
      </Modal>


    </Box>
  )
}

export default AdminBoardingRequest