import { Outlet } from 'react-router-dom'
import { Box, Flex, useDisclosure,Icon, Modal, ModalBody, ModalContent, ModalOverlay, Text, } from '@chakra-ui/react'
import Navbar from './Navbar'
import AuthContext from '../Admin/Contexts/AdminContext';
import { useContext } from 'react';
import { NoData } from '../../Assets';


const Nav = ({MenuNavigation,route}) => {

  const {profile,status,profileDoc} = useContext(AuthContext);
    const { isOpen: isSecondModalOpen, onOpen: onSecondModalOpen, onClose: onSecondModalClose } = useDisclosure();


  const handleProceedToCheckout = () => {

    if (status === "1" && profile === "true" && profileDoc === "true") {
        console.log('Navigate to dashboard page');
        onSecondModalOpen();     
    
    } else {
        return;
        // console.log('Customer dashboard data is complete');   
    }
  };

  return (
    <Flex height="100vh" position='relative'>
      <Box w="15%"  >
        <Navbar MenuNavigation = {MenuNavigation} route={route} />
      </Box>
      <Box w="85%"  bg='primary.600'  border={"1px solid #E6E6E6"} onClick={handleProceedToCheckout}  >
        <Box 
          width="100%"   
          height="8vh" 
          background="#fff" 
          boxShadow="lg"  
        >
        </Box>

        <Outlet />

      </Box>

      <Modal isOpen={isSecondModalOpen} onClose={onSecondModalClose} >
                <ModalOverlay />
                <ModalContent>
                <ModalBody>
                    <Box textAlign={'center'} py={'50px'} >
                        <Icon boxSize='150px' viewBox="0 0 250 250" >
                            <NoData />                      
                        </Icon>
                        <Text 
                        color={'primary.100'} fontSize={'base'} cursor={'pointer'} fontWeight={'bold'} textDecoration={'underline'} 
                        >
                        Profile in Review
                        </Text>                               

                    </Box>        
            
                </ModalBody>         
                </ModalContent>
            </Modal>
    </Flex>  
  )
}

export default Nav