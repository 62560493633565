import { Box, TableContainer, Table, TableCaption, Thead, Tbody, Td, Th, Tr, Text, Flex, VStack, Icon } from '@chakra-ui/react'
import { NoData } from '../../../../Assets'

export const Transactions = () => {
  const font = {
    textTransform: "none",
    textAlign: "center",
    fontWeight: 400,
    color: "#3B3838",
    width: "20%",
    // "white-space": "nowrap",
    // overflow: "hidden",
    // "text-overflow": "ellipsis",
  }

  const itemElements = [];

  for (let i = 0; i < 20; i++) {
    itemElements.push(
      <Tr key={i} style={{width: `100%`}}>
        <Td style={font}>inches</Td>
        <Td style={font}>millimetres (mm)</Td>
        <Td style={font}>millimetres (mm)</Td>
        <Td style={font}>millimetres (mm)</Td>
        <Td style={font} isNumeric>{i}</Td>
      </Tr>
    );
  }

  return (
    <Box
      // px={6}
      py={4}
      h={`100%`}
      overflowY={`hidden`}
    >
      <Text
        fontWeight={`semibold`}
        fontSize={`lg`}
        px={4}
      >
        Transactions
      </Text>
      <TableContainer>
        <Table>
          <Thead>
            <Tr>
              <Th style={font}>LPG Filling Plants</Th>
              <Th style={font}>Order Status</Th>
              <Th style={font}>Date</Th>
              <Th style={font}>Quantity</Th>
              <Th style={font} isNumeric>Price</Th>
            </Tr>
            </Thead>
          </Table>
          </TableContainer>
      {/* <Box
        overflowY="auto"
        h="100%"
        pb={14}
        sx={
            { 
            '::-webkit-scrollbar':{
                  display:'none'
              }
            }
        }
      >
        <TableContainer>
        <Table>
            <Tbody>
              {itemElements}
          </Tbody>
            </Table>
        </TableContainer>
        </Box> */}
          <Flex
            alignItems={`center`}
            justifyContent={`center`}
            h={`80%`}
          >
          <VStack spacing={8}>
            <NoData />
          <Text
            fontWeight={`bold`}
          >
            No Transaction yet!!
          </Text>
            </VStack>
          </Flex>
    </Box>
  )
}
