import { Box, Container, Flex, Image, Text, useMediaQuery } from "@chakra-ui/react"
import LoginForm from "./LoginForm"
import LoginCarousel from "./LoginCarousel"
// import {  Logo } from "../../Assets"
import  Logo from '../../Assets/Logo.svg'
import { Link } from "react-router-dom"
const Login = () => {


    const [isSm] = useMediaQuery(`(max-width: 992px)`);
    const [isXs] = useMediaQuery(`(max-width: 760px)`);
    const direction = isSm ? "column" : "row";


  return (
    <Flex h="100vh" flexDir={direction} >
        <Box bg="primary.100" w={ isSm ? "100%" : "60%"} p="20px"  >
            <Box >
                <Link to='/' >
                    <Image w="10%" src={Logo} alt="Logo" />
                </Link>
            </Box>
                <LoginCarousel/>
            <Box  m="40px 0 0">
                <Text fontSize="xl" fontWeight="bold" color="font.300">
                    Manage and Track Users and <br /> Activities Effectively
                </Text>
                {/* <Text color="font.400" fontSize="xs" mt="10px">
                    Lorem ipsum dolor sit amet consectetur. Mauris et feugiat nunc <br /> condimentum tempor. Risus tincidunt diam volutpat nunc <br /> praesent praesent.
                </Text> */}
            </Box>       
        </Box>
        <Container w={ isSm ? "100%" : "40%"} m="auto" p={isSm ? "20px 50px" : "0px 50px"} > 
            <Box flexDirection="column"
                alignItems="center"
                textAlign="center"
            >
                <Box p="20px 0px">
                    <Text fontWeight="bold" fontSize="rand.r1" >Hey There 👋🏽</Text>
                    <Text fontWeight="normal" fontSize="sm" color="font.100" p="10px 0px"  >Enter your login details to continue.</Text>
                </Box>
            </Box>
            <LoginForm/>
        </Container>
   
    </Flex>
  )
}

export default Login;