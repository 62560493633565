import { Box, Flex, Icon, Text } from "@chakra-ui/react"
import PropTypes from "prop-types"
import { CheckBox } from "../../Assets"

export const UserType = (props) => {
  return (
    <Box
      p={3}
      borderRadius={'2xl'}
      bg={`white`}
      width="100%"
      onClick={() => {
        props.onClick(props.id)
      }}
      borderColor={props.isSelected ? 'primary.100' : "transparent"}
      borderWidth={4}
      pos={"relative"}
    >
      {props.isSelected && (
        <Box
          pos={`absolute`}
          top={0}
          right={0}
          transform={`translate(40%, -40%)`}
        >
          <CheckBox />
      </Box>)}
      <Flex
        h={`80px`}
        alignItems={`center`}
      >
        <Icon
          boxSize={'35px'}
          viewBox="0 0 50 50"
        >
          {props.icon}
        </Icon>
      </Flex>
      <Box
        mb={4}
        w={{ base: '100%', md: '80%' }}
      >
        <Text
          fontWeight={"bold"}
          color={`primary.300`}
          fontSize={`base`}
        >
          {props.type}
        </Text>
      </Box>
      <Box>
        <Text>{props.desc}</Text>
      </Box>
    </Box>
  )
}

UserType.propTypes = {
  userType: PropTypes.oneOf(['', 'Customers', 'Vendors', 'Delivery']),
  id: PropTypes.number.isRequired,
  icon: PropTypes.element.isRequired,
  alt: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  desc: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  isSelected: PropTypes.bool,
  value: PropTypes.string,
}