import { 
    Box, 
    Flex, 
    HStack, 
    Icon, 
    Image, 
    Tab, 
    TabList, 
    TabPanel, 
    TabPanels, 
    Tabs, 
    Text 
} from '@chakra-ui/react';
import React, { useContext, useState } from 'react'
import { 
    Asetting, 
    FullLogo, 
    Iplant, 
    Logo, 
    Logout, 
    PlantWhite, 
    Setting, 
    VendorBg
} from '../../../../Assets';
import  EmeryAva  from '../../../../Assets/EmeryAva.svg';
import { CustomerBg } from '../../../../Assets';
import VendorProfile from './VendorProfile';
import AuthContext from '../../../../Components/Admin/Contexts/AdminContext';
import { useNavigate } from 'react-router-dom';

const Profile = () => { 

    const [selectedTab, setSelectedTab] = useState(0);
    const {handleLogout,customerProfileData, isLoadingCustomerProfileData} = useContext(AuthContext);
    const navigate = useNavigate();

    const handleTabChange = (index) => {
        setSelectedTab(index);
    };

    const tabs = [
        { label: "Profile", icon: <Iplant />, content: <VendorProfile/>, activeIcon: <PlantWhite/> },
        { label: "Wallet Account", icon: <Setting />, content: "Wallet Account Content", activeIcon: <Asetting/> },
    ];

  return ( 
    <Box>
        <Flex
            bgImage={`url(${CustomerBg})`} 
            bgSize="cover"
            bgPosition="center"
            bgRepeat="no-repeat"
            w="100%"
            p={'30px 40px'} 
            alignItems={'center'} 
            justifyContent={'space-between'} 
        >
            {/* <Icon boxSize='50px' viewBox="0 0 50 50" > */}
            <Box onClick={()=>{
                navigate('/dash')
                window.location.reload(); 
                }} >
                <Logo/>
            </Box>
            {/* </Icon>  */}
           
            <HStack pr={'10px'} >

                <Box>
                    <Image
                        boxSize='30px'
                        objectFit='cover' src={EmeryAva} 
                    />
                </Box>                 
                                    
                <Box  >
                    <Text color="font.300" fontWeight="semibold" onClick={()=> console.log(isLoadingCustomerProfileData)}>
                        {!isLoadingCustomerProfileData && customerProfileData[0]?.BusinessName}
                    </Text>
                    <Text color="font.400" fontWeight="normal" fontSize="12px" >
                        LPG Terminals/Depot
                    </Text>
                    <Text color="font.400" fontSize="14px" fontWeight="semibold" >
                        Account Number:  {!isLoadingCustomerProfileData && customerProfileData?.[0]?.VirtualAccountNumber}
                    </Text>
                </Box>
            </HStack>
        </Flex>
        <Box>
            <Tabs variant="unstyled" bg="#fff"  borderRadius="lg" pt={5}>
                <TabList justifyContent="space-between" p={'0 50px 20px'}>
                    <TabList>
                        {tabs.map((tab, index) => (
                            <Tab
                                key={index}
                                color="border.100"
                                position="relative"
                                _selected={{
                                    color: "#fff",
                                    borderBottom: "2px solid primary.100",
                                    fontWeight: "semibold",
                                    borderRadius: "50px",
                                    background:"linear-gradient(to left,#101A36 95%, #049541 50%)"
                                }}
                                width={'190px'}
                                onClick={() => handleTabChange(index)}
                            >
                                <HStack alignItems="center" position="relative">
                                    <Box>
                                        <Icon
                                            boxSize="40px"
                                            viewBox="0 0 50 50"
                                            position="absolute"
                                            top="3px"
                                            left="-17px"
                                        >
                                        {selectedTab === index ? tab.activeIcon : tab.icon} {/* Change the icon conditionally */}
                                        </Icon>
                                    </Box>
                                    <Text>{tab.label}</Text>
                                </HStack>
                            </Tab>
                        ))}
                    </TabList>
                    <Flex alignItems="center"  justifyContent={'space-between'} >
                        <HStack 
                            alignItems={'center'} position={'relative'} 
                            onClick={() => {
                                handleLogout();
                                navigate('/Login');
                                }}
                                 cursor={'pointer'} 
                        >
                            <Icon
                                boxSize="50px"
                                viewBox="0 0 50 50"
                                position="relative"
                                top="14px"
                                left="18px"
                            >
                                <Logout/>
                            </Icon>
                            <Text color={'secondary.300'} fontWeight={'bold'}  >
                                Log Out
                            </Text>
                        </HStack>
                    </Flex>
                </TabList>               
                <TabPanels bg='primary.600'  p={'20px 20px'} >
                    {tabs.map((tab, index) => (
                    <TabPanel key={index}>
                        {selectedTab === index && <Box>{tab.content}</Box>}
                    </TabPanel>
                    ))}
                </TabPanels>
            </Tabs>
        </Box>
        
        

 
    </Box>
  )
}

export default Profile